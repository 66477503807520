import config from "../config";
import localStorageService from "../services/localStorageService";

let messenger = new Object({
  downloadWAReceipt: (id, locale, type) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/messenger/wa-receipt-" +
          encodeURIComponent(id) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&id=" +
          encodeURIComponent(id) +
          "&type=" +
          encodeURIComponent(type) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadMailReceipt: (id, locale, type) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/messenger/mail-receipt-" +
          encodeURIComponent(id) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&id=" +
          encodeURIComponent(id) +
          "&type=" +
          encodeURIComponent(type) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
});

export default messenger;
