<template>
  <div>
    <messageBox ref="msgBox" />
    <GuestEditor
      ref="guestEditor"
      v-model="guestEditorDialog"
      @guestConfigured="onGuestConfigured"
      :vcoutcomes="$store.state.vcoutcomes"
      :livesoutcomes="$store.state.livesoutcomes"
      :programs="$store.state.programs"
      :categories="$store.state.categories"
      :regions="$store.state.regions"
      :radios="guest_radios"
      :schedulers="$store.state.schedulers"
      :territories_radios="$store.state.territories_radios"
      :filtered_users="flt_users"
      :users="users"
      :locales="guest_locales"
      :types="guest_types"
    />
    <PaymentEditor
      ref="paymentEditor"
      v-model="paymentEditorDialog"
      @paymentConfigured="onPaymentConfigured"
      :users_types="$store.state.anagrafica_types"
      :provinces="$store.state.provinces"
      :countries="$store.state.countries"
      :payment_methods="$store.state.payment_methods"
      :versions="products_versions"
    />

    <GuestOutcomeEditor
      ref="guestOutcomeEditor"
      v-model="guestOutcomeDialog"
      @outcomeConfigured="onGuestOutcomeConfigured"
      :vcoutcomes="$store.state.vcoutcomes"
      :livesoutcomes="$store.state.livesoutcomes"
      :filtered_users="flt_users"
      :users="users"
    />

    <OutcomesResume
      ref="outcomesResumeDialog"
      v-model="outcomesResumeDialog"
      :outcomes="fcoutcomes"
    />

    <v-dialog overlay-opacity="0.6" width="600" :value="paymentPickerDialog">
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("guests-send-validate-payment-title") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="paymentPickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <span style="font-size:20px;">{{
                      $t("guests-send-validate-payment-confirm")
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-date-picker v-model="paymentPickerDate"></v-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmValidatePayment()"
                color="green"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="paymentPickerDialog = false"
                color="primary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-card class="ma-4">
      <v-toolbar color="#0044a3">
        <v-icon color="white" large class="mr-2">mdi-card-text-outline</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("guests-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <v-row v-if="fetchingFilters || fetchingFCOutcomes" class="text-center">
        <v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row
        v-if="
          $store.state.hasRight('59') &&
            !fetchingFilters &&
            !fetchingFCOutcomes &&
            (filters.view == 0 ||
              filters.view == 1 ||
              filters.view == 2 ||
              filters.view == 9)
        "
        class="text-center"
      >
        <v-col>
          <v-btn
            :disabled="
              fetchingFCOutcomes || fetchingFilters || fetchingData || loading
            "
            color="#0044a3"
            @click.stop="addGuest()"
            >{{ $t("guests-add-guest") }}</v-btn
          >
        </v-col>
      </v-row>

      <div style="margin:15px">
        <v-row v-if="!fetchingFilters && !fetchingFCOutcomes" justify="center">
          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.view"
              outlined
              item-text="text"
              item-value="value"
              :items="views"
              :label="$t('guests-filter-type')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.type"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_types"
              :label="$t('guests-filter-type')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.scheduler"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_schedulers"
              :label="$t('guests-filter-scheduler')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.program"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_programs"
              :label="$t('guests-filter-program')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.radio"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_radios"
              :label="$t('guests-filter-radio')"
              hide-details
            />
          </v-col>

          <v-col v-if="filters.view <= 2">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.outcome"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_outcomes"
              :label="$t('guests-filter-outcome')"
              hide-details
              return-object
            />
          </v-col>
        </v-row>

        <v-row v-if="!fetchingFilters && !fetchingFCOutcomes" justify="center">
          <v-col class="text-center">
            <v-text-field
              :disabled="fetchingData || loading"
              :value="filter"
              @change="v => (filter = v)"
              append-icon="mdi-magnify"
              :label="$t('gbl-search')"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-operator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="coordinator"
              :items="coordinators"
              :loading="isLoadingCoordinator"
              :search-input.sync="searchCoordinatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-coordinator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="speaker"
              :items="speakers"
              :loading="isLoadingSpeaker"
              :search-input.sync="searchSpeakerInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-speaker')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="fetchingData || loading"
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('guests-filter-datestart')"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="fetchingData || loading"
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('guests-filter-dateend')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="paymentValidationInProgress" class="text-center">
        <v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-data-table
        dense
        v-if="
          !fetchingFilters &&
            !fetchingFCOutcomes &&
            !paymentValidationInProgress
        "
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="guests"
        :item-class="row_classes"
        :options.sync="options"
        :server-items-length="totalGuests"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.pagato="{ item }">
          <v-icon v-if="item.pagato == 'Y'" color="green">mdi-check</v-icon>
        </template>

        <template v-slot:item.palinsesto_origine="{ item }">
          {{ item.nome_palinsesto }}
        </template>

        <template v-slot:item.id_radio="{ item }">
          {{ item.nome_radio }}
        </template>

         <template v-slot:item.id_programma="{ item }">
          {{ item.nome_programma }}
        </template>

        <template v-slot:item.ID="{ item }">
          <span v-html="getItemID(item)" />
        </template>

        <template v-slot:item.operatore="{ item }">
          <v-tooltip bottom
            ><template v-slot:activator="{ on }"
              ><v-icon v-if="item.nome_operatore" color="cyan" v-on="on"
                >mdi-information-outline</v-icon
              > </template
            ><span>{{ item.nome_operatore }}</span></v-tooltip
          >
          {{ item.codice_operatore }}
        </template>

        <template v-slot:item.coordinamento="{ item }">
          <v-tooltip bottom
            ><template v-slot:activator="{ on }"
              ><v-icon v-if="item.nome_coordinamento" color="cyan" v-on="on"
                >mdi-information-outline</v-icon
              > </template
            ><span>{{ item.nome_coordinamento }}</span></v-tooltip
          >
          {{ item.codice_coordinamento }}
        </template>

        <template v-slot:item.firstcall="{ item }">
          <v-tooltip bottom
            ><template v-slot:activator="{ on }"
              ><v-icon v-if="item.nome_firstcall" color="cyan" v-on="on"
                >mdi-information-outline</v-icon
              > </template
            ><span>{{ item.nome_firstcall }}</span></v-tooltip
          >
          {{ item.codice_firstcall }}
        </template>

        <template v-slot:item.speaker="{ item }">
          <v-tooltip bottom
            ><template v-slot:activator="{ on }"
              ><v-icon v-if="item.nome_speaker" color="cyan" v-on="on"
                >mdi-information-outline</v-icon
              > </template
            ><span>{{ item.nome_speaker }}</span></v-tooltip
          >
          {{ item.codice_speaker }}
        </template>

        <template v-slot:item.nome_intervistato_1="{ item }">
          {{
            item.nome_intervistato_1 +
              (item.nome_intervistato_2 ? ", " + item.nome_intervistato_2 : "")
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div style="min-width:120px">
            <v-tooltip
              v-if="
                $store.state.hasRight('70') &&
                  filters.view == 6 &&
                  item.tipologia == 2
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="primary"
                  @click.stop="sendPaymentMail(item)"
                  v-on="on"
                  ><v-icon>mdi-email</v-icon></v-btn
                ></template
              ><span>{{
                $t("guests-action-send-postpayment-mail")
              }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                $store.state.hasRight('146') &&
                  filters.view == 5 &&
                  item.tipologia == 2
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="sendPrePaymentWA(item)"
                  v-on="on"
                  ><v-icon>mdi-whatsapp</v-icon></v-btn
                ></template
              ><span>{{
                $t("guests-action-send-prepayment-wa")
              }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                $store.state.hasRight('146') &&
                  filters.view == 5 &&
                  item.tipologia == 2
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="purple"
                  @click.stop="sendPrePaymentMail(item)"
                  v-on="on"
                  ><v-icon>mdi-email</v-icon></v-btn
                ></template
              ><span>{{
                $t("guests-action-send-prepayment-mail")
              }}</span></v-tooltip
            >

            <v-tooltip
              v-if="$store.state.hasRight('62') && filters.view <= 2"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="outcomeGuest(item)"
                  v-on="on"
                  ><v-icon>mdi-target</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-outcome") }}</span></v-tooltip
            >

            <v-tooltip v-if="$store.state.hasRight('58')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="orange"
                  @click.stop="showPdf(item)"
                  v-on="on"
                  ><v-icon>mdi-file-pdf-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-pdf") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                ($store.state.hasRight('61') && filters.view <= 2) ||
                  filters.view == 9
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="gray"
                  @click.stop="editGuest(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-edit") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                $store.state.hasRight('69') &&
                  (filters.view == 5 || filters.view == 6)
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="gray"
                  @click.stop="editGuestPayment(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-edit-payment") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                $store.state.hasRight('60') &&
                  (filters.view <= 2 || filters.view == 9)
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="orange"
                  @click.stop="voidGuest(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-void") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                $store.state.hasRight('60') &&
                  (filters.view <= 2 || filters.view == 9)
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteGuest(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-delete") }}</span></v-tooltip
            >
          </div>
        </template>

        <template v-slot:item.data_intervista="{ item }">
          {{ item.data_intervista | toLocaleDate }}
        </template>

        <template v-slot:item.orario_intervista="{ item }">
          {{ item.data_intervista | toLocaleTimeFromDate }}
        </template>

        <template v-slot:item.data_inserimento="{ item }">
          {{ item.data_inserimento | toLocaleDate }}
        </template>

        <template v-slot:item.data_pagamento="{ item }">
          {{ item.data_pagamento | toLocaleDate }}
        </template>

        <template v-slot:item.data_convalida="{ item }">
          {{ item.data_convalida | toLocaleDate }}
        </template>

        <template v-slot:item.pacchetto="{ item }">
          {{ item.product_name }}
        </template>

        <template v-slot:item.nome_cliente="{ item }">
          <span v-html="getItemName(item)" />
        </template>

        <template v-slot:item.prezzo="{ item }">
          <span v-html="getItemPrice(item)" />
        </template>

        <template v-slot:item.reset_interview_date="{ item }">
          {{ item.data_invio_msg_no_intervista | toLocaleDateTime }}
        </template>

        <template v-slot:item.reset_interview_working="{ item }">
          <v-tooltip
            v-if="
              $store.state.hasRight('73') &&
                item.lavorazione_invio_msg_no_intervista == 'Y'
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="removeInterviewWorking(item)"
                v-on="on"
                ><v-icon color="green">mdi-check</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-remove-interview-working-state")
            }}</span></v-tooltip
          >

          <v-tooltip
            v-if="
              $store.state.hasRight('73') &&
                item.lavorazione_invio_msg_no_intervista == 'N'
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="setInterviewWorking(item)"
                v-on="on"
                ><v-icon color="red">mdi-alert-circle-outline</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-set-interview-working-state")
            }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.send_reset_interview_link_wa="{ item }">
          <v-tooltip v-if="$store.state.hasRight('75')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="green"
                @click.stop="sendInterviewWALink(item)"
                v-on="on"
                ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
              ></template
            ><span>{{ $t("guests-action-interview-send-wa") }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.reset_interview_sent="{ item }">
          <v-tooltip
            v-if="
              $store.state.hasRight('74') &&
                !isNaN(Date.parse(item.data_invio_msg_no_intervista))
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="removeInterviewSentState(item)"
                v-on="on"
                ><v-icon color="green">mdi-check</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-remove-interview-sent-state")
            }}</span></v-tooltip
          >

          <v-tooltip
            v-if="
              $store.state.hasRight('74') &&
                isNaN(Date.parse(item.data_invio_msg_no_intervista))
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="setInterviewSentState(item)"
                v-on="on"
                ><v-icon color="red">mdi-alert-circle-outline</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-set-interview-sent-state")
            }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.payment_link="{ item }">
          <span v-html="buildPaymentLink(item)" />
          <span class="ml-2"
            ><v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn x-small v-on="on" text icon @click.stop="copyLink(item)"
                  ><v-icon color="gray">mdi-content-copy</v-icon></v-btn
                ></template
              ><span>{{ $t("guests-action-copy-link") }}</span></v-tooltip
            >
          </span>
        </template>

        <template v-slot:item.link_working="{ item }">
          <v-tooltip
            v-if="
              $store.state.hasRight('77') &&
                item.lavorazione_invio_link_pagamento == 'Y'
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="removeLinkWorking(item)"
                v-on="on"
                ><v-icon color="green">mdi-check</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-remove-link-working-state")
            }}</span></v-tooltip
          >

          <v-tooltip
            v-if="
              $store.state.hasRight('77') &&
                item.lavorazione_invio_link_pagamento == 'N'
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="setLinkWorking(item)"
                v-on="on"
                ><v-icon color="red">mdi-alert-circle-outline</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-set-link-working-state")
            }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.send_link_wa="{ item }">
          <v-tooltip v-if="$store.state.hasRight('79')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="green"
                @click.stop="sendPaymentWALink(item)"
                v-on="on"
                ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
              ></template
            ><span>{{ $t("guests-action-link-send-wa") }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.link_sent="{ item }">
          <v-tooltip
            v-if="
              $store.state.hasRight('78') &&
                !isNaN(Date.parse(item.data_invio_link_pagamento))
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="removeLinkSentState(item)"
                v-on="on"
                ><v-icon color="green">mdi-check</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-remove-link-sent-state")
            }}</span></v-tooltip
          >

          <v-tooltip
            v-if="
              $store.state.hasRight('78') &&
                isNaN(Date.parse(item.data_invio_link_pagamento))
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="setLinkSentState(item)"
                v-on="on"
                ><v-icon color="red">mdi-alert-circle-outline</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-set-link-sent-state")
            }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.videoediting_working="{ item }">
          <v-tooltip
            v-if="$store.state.hasRight('81') && item.lavorazione == 'Y'"
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="removeVideoEditingWorking(item)"
                v-on="on"
                ><v-icon color="green">mdi-check</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-remove-video-editing-working-state")
            }}</span></v-tooltip
          >

          <v-tooltip
            v-if="$store.state.hasRight('81') && item.lavorazione == 'N'"
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="setVideoEditingWorking(item)"
                v-on="on"
                ><v-icon color="red">mdi-alert-circle-outline</v-icon></v-btn
              ></template
            ><span>{{
              $t("guests-action-set-video-editing-working-state")
            }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.link_sent_date="{ item }">
          {{ item.data_invio_link_pagamento | toLocaleDateTime }}
        </template>

        <template v-slot:item.metodo_pagamento="{ item }">
          <v-icon color="orange" v-if="item.metodo_pagamento == 3"
            >mdi-bank-transfer</v-icon
          >
          <v-icon color="cyan" v-if="item.metodo_pagamento == 2"
            >mdi-paypal</v-icon
          >
          <v-icon color="purple" v-if="item.metodo_pagamento == 1"
            >mdi-credit-card</v-icon
          >
          <v-icon color="green" v-if="item.metodo_pagamento == 4"
            >mdi-currency-eur</v-icon
          >
          {{ item.nome_pagamento }}
        </template>

        <template v-slot:item.firstcall_outcomes="{ item }">
          <div v-if="item.fc_outcomes_count && item.fc_outcomes_count > 0">
            <span>{{ item.fc_outcomes_count }}</span
            ><span>
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      item.outcomes_count < 1 || !$store.state.hasRight('111')
                    "
                    x-small
                    text
                    icon
                    color="cyan"
                    @click.stop="outcomesResume(item)"
                    v-on="on"
                    ><v-icon color="green"
                      >mdi-information-outline</v-icon
                    ></v-btn
                  ></template
                ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
              >
            </span>
          </div>
        </template>

        <template v-slot:item.payment_form_completed="{ item }">
          <v-tooltip
            v-if="
              item.payment_form_completed == 'Y' && $store.state.hasRight('132')
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                @click.stop="resetPayment(item)"
                v-on="on"
                x-small
                text
                icon
                color="green"
                ><v-icon>mdi-check</v-icon></v-btn
              ></template
            ><span>{{ $t("firstcall-tbl-wizard-reset") }}</span></v-tooltip
          >

          <v-icon
            v-if="
              item.payment_form_completed == 'Y' &&
                !$store.state.hasRight('132')
            "
            >mdi-check</v-icon
          >

          <v-icon v-if="item.payment_form_completed != 'Y'" color="red"
            >mdi-alert-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.stato_pagamento="{ item }">
          <v-icon
            v-if="!$store.state.hasRight('67') && item.pagato == 'Y'"
            color="green"
            >mdi-check</v-icon
          >
          <v-icon
            v-if="!$store.state.hasRight('67') && item.pagato == 'N'"
            color="red"
            >mdi-alert-circle-outline</v-icon
          >

          <v-tooltip
            v-if="$store.state.hasRight('67') && item.pagato == 'Y'"
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="removePaymentValidation(item)"
                v-on="on"
                ><v-icon color="green">mdi-check</v-icon></v-btn
              ></template
            ><span>{{ $t("guests-action-remove-payment") }}</span></v-tooltip
          >

          <v-tooltip
            v-if="
              $store.state.hasRight('67') &&
                item.pagato == 'N' &&
                item.pacchetto &&
                item.metodo_pagamento
            "
            bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="validatePayment(item)"
                v-on="on"
                ><v-icon color="red">mdi-alert-circle-outline</v-icon></v-btn
              ></template
            ><span>{{ $t("guests-action-validate-payment") }}</span></v-tooltip
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import guestsManager from "../apis/guests";
import usersManager from "../apis/users";
import firstCall from "../apis/firstcall";

import staticData from "../apis/static";
import config from "../config";
import helpers from "../services/helpers";

import messageBox from "../components/MessageBox";
import GuestEditor from "../components/backend/GuestEditor";
import PaymentEditor from "../components/backend/PaymentEditor";
import GuestOutcomeEditor from "../components/backend/GuestOutcomeEditor";
import OutcomesResume from "../components/firstcall/OutcomesResume";

export default {
  data() {
    return {
      views: [],
      products_versions: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      searchOperatorInput: null,
      searchCoordinatorInput: null,
      searchSpeakerInput: null,

      operator: null,
      speaker: null,
      coordinator: null,

      guests: [],
      operators: [],
      coordinators: [],
      speakers: [],
      users: [],

      flt_outcomes: [],
      flt_schedulers: [],

      flt_locales: [],
      flt_radios: [],
      flt_types: [],
      flt_programs : [],

      guest_locales: [],
      guest_radios: [],
      guest_types: [],

      flt_users: {},

      isLoadingOperator: false,
      isLoadingCoordinator: false,
      isLoadingSpeaker: false,

      loading: false,

      totalGuests: 0,
      perPage: staticData.defaultPerPage,

      options: {
        sortBy: ["ID"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },

      headers: [],
      filter: this.$route.query.id ? "OSP-" + this.$route.query.id : null,
      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        outcome: null,
        coordinator: null,
        speaker: null,
        operator: null,
        scheduler: null,
        view: this.$route.query.id ? 2 : 0,
        type: null,
        locale: null,
        radio: null,
        program : null
      },

      previousView: 0,
      fetchingData: false,
      fetchingFilters: true,
      fetchingFCOutcomes: true,
      guestEditorDialog: false,
      paymentEditorDialog: false,
      guestOutcomeDialog: false,
      outcomesResumeDialog: false,
      fcoutcomes: [],

      paymentPickerDialog: false,
      paymentValidationInProgress: false,
      paymentPickerDate: new Date().toISOString().substr(0, 10)
    };
  },

  components: {
    messageBox,
    GuestEditor,
    PaymentEditor,
    GuestOutcomeEditor,
    OutcomesResume
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  mounted() {
    this.views = [
      { text: this.$t("guests-view-pending-interview-incompletes"), value: 9 },
      { text: this.$t("guests-view-pending-interview"), value: 0 },
      { text: this.$t("guests-view-completed-interview"), value: 1 },
      { text: this.$t("guests-view-all-interviews"), value: 2 }
    ];

    if (this.$store.state.hasRight("76")) {
      this.views = [
        ...this.views,

        { text: this.$t("guests-view-pending-payment-link"), value: 3 },
        { text: this.$t("guests-view-payment-link-done"), value: 4 }
      ];
    }

    if (this.$store.state.hasRight("65")) {
      this.views = [
        ...this.views,

        { text: this.$t("guests-view-pending-payment"), value: 5 },
        { text: this.$t("guests-view-pending-completed"), value: 6 }
      ];
    }

    if (this.$store.state.hasRight("72")) {
      this.views = [
        ...this.views,

        { text: this.$t("guests-view-pending-change-interview"), value: 7 },
        { text: this.$t("guests-view-change-interview-completed"), value: 8 }
      ];
    }

    this.computeHeaders();

    this.flt_outcomes = [{ id: null, name: this.$t("gbl-all-male") }];

    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers
    ];

    this.operators = [];
    this.speakers = [];
    this.coordinators = [];

    this.fetchUsers()
      .then(res => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map(x => {
          this.flt_users[x.id] = x;
        });
        this.fetchingFilters = false;
        this.fetchingData = false;
        this.fcoutcomes = [];
        this.fetchFCOutcomes(() => {
          this.fetchingFCOutcomes = false;
        }).catch(err => {
          console.log(err);
          this.fetchingFCOutcomes = false;
        });
      })
      .catch(err => {
        this.fetchingData = false;
        this.fetchingFilters = false;
        console.log(err);
      });
  },

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then(res => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchCoordinatorInput(val) {
      clearTimeout(this._searchCoordinatorInput);
      if (val) {
        this._searchCoordinatorInput = setTimeout(() => {
          this.isLoadingCoordinator = true;
          this.fetchUsers(val)
            .then(res => {
              this.coordinators = res.items;
              this.isLoadingCoordinator = false;
            })
            .catch(() => {
              this.isLoadingCoordinator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchSpeakerInput(val) {
      clearTimeout(this._searchSpeakerInput);
      if (val) {
        this._searchSpeakerInput = setTimeout(() => {
          this.isLoadingSpeaker = true;
          this.fetchUsers(val)
            .then(res => {
              this.speakers = res.items;
              this.isLoadingSpeaker = false;
            })
            .catch(() => {
              this.isLoadingSpeaker = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      }
    },

    speaker: {
      handler() {
        this.filters.speaker = this.speaker ? this.speaker.id : null;
      }
    },

    coordinator: {
      handler() {
        this.filters.coordinator = this.coordinator
          ? this.coordinator.id
          : null;
      }
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadGuests(true);
        }, config.searchInputsFastTimeout);
      },
      deep: true
    },

    filters: {
      handler() {
        this.guests = [];
        this.totalGuests = 0;
        this.computeHeaders();
        if (this.previousView != this.filters.view) {
          this.previousView = this.filters.view;
          this.options = {
            ...this.options,
            page: 1,
            sortBy: ["ID"],
            sortDesc: [true]
          };
        } else {
          clearTimeout(this._debounceTimer);
          this.options = {
            ...this.options,
            page: 1,
            sortBy: ["ID"],
            sortDesc: [true]
          };
        }
      },

      deep: true
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true]
        };
      }
    }
  },

  methods: {
    outcomesResume(item) {
      this.$refs.outcomesResumeDialog.setItem(item.ID);
      this.outcomesResumeDialog = true;
    },

    fetchFCOutcomes() {
      this.fetchingFCOutcomes = true;
      return new Promise((resolve, reject) => {
        firstCall
          .fetchOutcomes()
          .then(result => {
            this.fetchingFCOutcomes = false;
            this.fcoutcomes = result.outcomes;
            resolve(result);
          })
          .catch(err => {
            this.fetchingFCOutcomes = false;
            reject(err);
          });
      });
    },

    buildPaymentLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      let shortLink = helpers.buildPaymentShortLinkURL(item);
      return "<a target='_blank' href='" + link + "'>" + shortLink + "</a>";
    },

    sendPaymentWALink(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+39") != 0) phoneNumber = "+39" + phoneNumber;

      const link = document.createElement("a");
      const nome_ospite =
        item.nome_intervistato_1 +
        (item.nome_intervistato_2 ? ", " + item.nome_intervistato_2 : "");
      let text = config.emails.paymentLinkMessage;

      if (parseInt(this.$store.state.frontend.skip_manual_link_send) == 1) {
        text = config.emails.paymentAutoLinkMessage;
        if (item.id_radio == 16)
          text = config.emails.paymentAutoLinkMessageRRTS;
        else if (item.id_radio == 18) {
          text = config.emails.paymentAutoLinkMessageSart;
        }
      }

      let nome_redazione = "Redazione OnAir";
      if (item.id_radio == 15) nome_redazione = "Redazione Radio Regione";
      text = text.replaceAll("%REDAZIONE%", nome_redazione);
      text = text.replaceAll("%NOMEOSPITE%", nome_ospite);
      text = text.replaceAll("%NOMERADIO%", item.nome_radio);
      text = text.replaceAll("%NOMEOPE%", this.$store.state.user.first_name);
      text = text.replaceAll(
        "%LINKFORMPAGAMENTO%",
        helpers.buildPaymentLinkURL(item)
      );
      let link_demo = item.url_radio + "/demo/demo_on_air.mp4";
      let link_pizzul = item.url_radio + "/media/bruno_pizzul.mp4";
      text = text.replaceAll("%LINKDEMO%", link_demo);
      text = text.replaceAll("%LINKPIZZUL%", link_pizzul);

      link.setAttribute(
        "href",
        "https://api.whatsapp.com/send?phone=" +
          encodeURIComponent(phoneNumber) +
          "&text=" +
          encodeURIComponent(text)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    copyLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      navigator.clipboard.writeText(link).then(
        () => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied"),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        },
        err => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied-error" + ":" + err),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        }
      );
    },

    setLinkWorking(item) {
      guestsManager
        .setLinkWorking(item.ID)
        .then(res => {
          if (res.data.responseData)
            item.lavorazione_invio_link_pagamento = "Y";
          // Update
          else {
            this.$refs.msgBox
              .show(
                this.$t("guests-operation-busy"),
                this.$t("guests-set-link-working-title"),
                true
              )
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(err => {
          this.$refs.msgBox
            .show(err, this.$t("guests-set-link-working-title"), true)
            .then(() => {})
            .catch(() => {});
        });
    },

    removeLinkWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-remove-link-working-confirm"),
          this.$t("guests-remove-link-working-title")
        )
        .then(() => {
          guestsManager
            .removeLinkWorking(item.ID)
            .then(res => {
              if (res.data.responseData)
                item.lavorazione_invio_link_pagamento = "N";
              // Update
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-remove-link-working-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("guests-remove-link-working-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    setVideoEditingWorking(item) {
      guestsManager
        .setVideoEditingWorking(item.id_ospite)
        .then(res => {
          if (res.data.responseData) item.lavorazione = "Y";
          // Update
          else {
            this.$refs.msgBox
              .show(
                this.$t("guests-operation-busy"),
                this.$t("guests-set-video-editing-working-title"),
                true
              )
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(err => {
          this.$refs.msgBox
            .show(err, this.$t("guests-set-video-editing-working-title"), true)
            .then(() => {})
            .catch(() => {});
        });
    },

    removeVideoEditingWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-remove-video-editing-working-confirm"),
          this.$t("guests-remove-video-editing-working-title")
        )
        .then(() => {
          guestsManager
            .removeVideoEditingWorking(item.id_ospite)
            .then(res => {
              if (res.data.responseData) item.lavorazione = "N";
              // Update
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-video-editing-link-working-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(
                  err,
                  this.$t("guests-video-editing-link-working-title"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    setLinkSentState(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-set-link-state-confirm"),
          this.$t("guests-set-link-state-title")
        )
        .then(() => {
          guestsManager
            .setLinkSentState(item.ID)
            .then(res => {
              if (res.data.responseData)
                item.data_invio_link_pagamento = new Date().toISOString();
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-set-link-state-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("guests-set-link-state-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    removeLinkSentState(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-remove-link-state-confirm"),
          this.$t("guests-remove-link-state-title")
        )
        .then(() => {
          guestsManager
            .removeLinkSentState(item.ID)
            .then(res => {
              if (res.data.responseData)
                item.data_invio_link_pagamento = "0000-00-00 00:00:00";
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-remove-link-state-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("guests-remove-link-state-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    sendInterviewWALink(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+39") != 0) phoneNumber = "+39" + phoneNumber;

      const link = document.createElement("a");
      const nome_ospite =
        item.nome_intervistato_1 +
        (item.nome_intervistato_2 ? ", " + item.nome_intervistato_2 : "");

      let text = config.emails.interviewNoAnswer;
      if (item.esito == 4) text = config.emails.interviewToBeSentAgain;

      let nome_redazione = "Redazione OnAir";
      if (item.id_radio == 15) nome_redazione = "Redazione Radio Regione";
      if (item.id_radio == 16) nome_redazione = "RadioRegioneTalkShow";
      text = text.replaceAll("%REDAZIONE%", nome_redazione);
      text = text.replaceAll("%NOMEOSPITE%", nome_ospite);
      text = text.replaceAll("%NOMERADIO%", item.nome_radio);

      link.setAttribute(
        "href",
        "https://api.whatsapp.com/send?phone=" +
          encodeURIComponent(phoneNumber) +
          "&text=" +
          encodeURIComponent(text)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    setInterviewWorking(item) {
      guestsManager
        .setInterviewWorking(item.ID)
        .then(res => {
          if (res.data.responseData)
            item.lavorazione_invio_msg_no_intervista = "Y";
          // Update
          else {
            this.$refs.msgBox
              .show(
                this.$t("guests-operation-busy"),
                this.$t("guests-set-interview-working-title"),
                true
              )
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(err => {
          this.$refs.msgBox
            .show(err, this.$t("guests-set-interview-working-title"), true)
            .then(() => {})
            .catch(() => {});
        });
    },

    removeInterviewWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-remove-interview-working-confirm"),
          this.$t("guests-remove-interview-working-title")
        )
        .then(() => {
          guestsManager
            .removeInterviewWorking(item.ID)
            .then(res => {
              if (res.data.responseData)
                item.lavorazione_invio_msg_no_intervista = "N";
              // Update
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-remove-interview-working-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(
                  err,
                  this.$t("guests-remove-interview-working-title"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    setInterviewSentState(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-set-interview-state-confirm"),
          this.$t("guests-set-interview-state-title")
        )
        .then(() => {
          guestsManager
            .setInterviewSentState(item.ID)
            .then(res => {
              if (res.data.responseData)
                item.data_invio_msg_no_intervista = new Date().toISOString();
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-set-interview-state-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("guests-set-interview-state-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    removeInterviewSentState(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-remove-interview-state-confirm"),
          this.$t("guests-remove-interview-state-title")
        )
        .then(() => {
          guestsManager
            .removeInterviewSentState(item.ID)
            .then(res => {
              if (res.data.responseData)
                item.data_invio_msg_no_intervista = "0000-00-00 00:00:00";
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("guests-operation-busy"),
                    this.$t("guests-remove-interview-state-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("guests-remove-interview-state-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    getItemName(item) {
      let name = "";
      if (item.nome && item.nome.length > 0) name = item.nome;
      if (item.cognome && item.cognome.length > 0) name += " " + item.cognome;
      if (name.length < 1) name = item.nome_intervistato_1;
      if (name.length < 1) name = item.nome_intervistato_2;

      if (
        item.ragione_sociale &&
        item.ragione_sociale.length &&
        item.tipo_utente != 1
      ) {
        if (name.length > 0) name += "<br/>";
        name +=
          "<span class='rs-bold'>Ragione sociale: </span>" +
          item.ragione_sociale;
      }

      return name;
    },

    getItemPrice(item) {
      if (!item || !item.price) return "";
      return (
        this.$options.filters.toCurrencySymbol({
          value: item.price,
          currency: item.currency
        }) +
        " (" +
        this.$options.filters.toCurrencySymbol({
          value: item.price * item.vat,
          currency: item.currency
        }) +
        ")"
      );
    },

    computeHeaders() {
      if (this.filters.view <= 2) {
        // Guests modo
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-activity"),
            align: "center",
            sortable: true,
            value: "attivita"
          },
         
          {
            text: this.$t("guests-tbl-type"),
            align: "left",
            sortable: true,
            value: "nome_tipologia"
          },
          {
            text: this.$t("guests-tbl-radio"),
            align: "center",
            sortable: true,
            value: "id_radio"
          },
           {
            text: this.$t("guests-tbl-program"),
            align: "center",
            sortable: true,
            value: "id_programma"
          },
          {
            text: this.$t("guests-tbl-fc"),
            align: "left",
            sortable: true,
            value: "firstcall"
          },
          {
            text: this.$t("guests-tbl-operator"),
            align: "left",
            sortable: true,
            value: "operatore"
          },

          {
            text: this.$t("guests-tbl-coordination"),
            align: "left",
            sortable: true,
            value: "coordinamento"
          },

          {
            text: this.$t("guests-tbl-speaker"),
            align: "left",
            sortable: true,
            value: "speaker"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },
          {
            text: this.$t("guests-tbl-interview-time"),
            align: "center",
            sortable: true,
            value: "orario_intervista"
          },

          {
            text: this.$t("guests-tbl-source-scheduler"),
            align: "center",
            sortable: true,
            value: "palinsesto_origine"
          },
          {
            text: this.$t("guests-tbl-outcome"),
            align: "center",
            sortable: true,
            value: "esito_nome"
          }
        ];

        if (
          (this.filters.view == 1 || this.filters.view == 2) &&
          this.filters.type == 2
        ) {
          this.headers = [
            ...this.headers,
            {
              text: this.$t("guests-tbl-fc-outcome"),
              align: "center",
              sortable: false,
              value: "firstcall_outcomes"
            }
          ];
        }

        if (
          (this.filters.view == 1 || this.filters.view == 2) &&
          this.filters.type == 1
        ) {
          this.headers = [
            ...this.headers,

            {
              text: this.$t("guests-tbl-payment-outcome"),
              align: "center",
              sortable: false,
              value: "pagato"
            }
          ];
        }

        this.headers = [
          ...this.headers,

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 3) {
        // Payment link
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },
          {
            text: this.$t("guests-tbl-source-scheduler"),
            align: "center",
            sortable: true,
            value: "palinsesto_origine"
          },

          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-phone"),
            align: "left",
            sortable: true,
            value: "telefono"
          },
          {
            text: this.$t("guests-tbl-link"),
            align: "left",
            sortable: false,
            value: "payment_link"
          },
          {
            text: this.$t("guests-tbl-working"),
            align: "center",
            sortable: false,
            value: "link_working"
          },
          {
            text: this.$t("guests-tbl-wa"),
            align: "center",
            sortable: false,
            value: "send_link_wa"
          },
          {
            text: this.$t("guests-tbl-linkset"),
            align: "center",
            sortable: false,
            value: "link_sent"
          },

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 4) {
        // Payment link
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },
          {
            text: this.$t("guests-tbl-source-scheduler"),
            align: "center",
            sortable: true,
            value: "palinsesto_origine"
          },
          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-phone"),
            align: "left",
            sortable: true,
            value: "telefono"
          },
          {
            text: this.$t("guests-tbl-link"),
            align: "left",
            sortable: false,
            value: "payment_link"
          },
          {
            text: this.$t("guests-tbl-linkset-date"),
            align: "center",
            sortable: false,
            value: "link_sent_date"
          },

          {
            text: this.$t("guests-tbl-working"),
            align: "center",
            sortable: false,
            value: "link_working"
          },
          {
            text: this.$t("guests-tbl-wa"),
            align: "center",
            sortable: false,
            value: "send_link_wa"
          },
          {
            text: this.$t("guests-tbl-linkset"),
            align: "center",
            sortable: false,
            value: "link_sent"
          },

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 10) {
        // Payment link
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },
          {
            text: this.$t("guests-tbl-source-scheduler"),
            align: "center",
            sortable: true,
            value: "palinsesto_origine"
          },
          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-phone"),
            align: "left",
            sortable: true,
            value: "telefono"
          },
          {
            text: this.$t("guests-tbl-link"),
            align: "left",
            sortable: false,
            value: "payment_link"
          },

          {
            text: this.$t("guests-tbl-wa"),
            align: "center",
            sortable: false,
            value: "send_link_wa"
          },

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 5) {
        // Payment status
        if (this.filters.type == 2) {
          this.headers = [
            {
              text: this.$t("guests-tbl-code"),
              align: "left",
              sortable: true,
              value: "ID"
            },
            {
              text: this.$t("guests-tbl-interview-date"),
              align: "center",
              sortable: true,
              value: "data_intervista"
            },
            {
              text: this.$t("guests-tbl-source-scheduler"),
              align: "center",
              sortable: true,
              value: "palinsesto_origine"
            },
            {
              text: this.$t("guests-tbl-order-date"),
              align: "center",
              sortable: true,
              value: "data_inserimento"
            },

            {
              text: this.$t("guests-tbl-name"),
              align: "left",
              sortable: true,
              value: "nome_cliente"
            },
            {
              text: this.$t("guests-tbl-packet-version"),
              align: "left",
              sortable: true,
              value: "pacchetto"
            },
            {
              text: this.$t("guests-tbl-invoice-company"),
              align: "left",
              sortable: true,
              value: "nome_azienda"
            },
            {
              text: this.$t("guests-tbl-total"),
              align: "left",
              sortable: true,
              value: "prezzo"
            },

            {
              text: this.$t("guests-tbl-payment-method"),
              align: "left",
              sortable: true,
              value: "metodo_pagamento"
            },
            {
              text: this.$t("guests-tbl-payment-procedure-completed"),
              align: "center",
              sortable: true,
              value: "payment_form_completed"
            },

            {
              text: this.$t("guests-tbl-payment-state"),
              align: "center",
              sortable: false,
              value: "stato_pagamento"
            },

            { text: "", align: "center", sortable: false, value: "actions" }
          ];
        } else {
          this.headers = [
            {
              text: this.$t("guests-tbl-code"),
              align: "left",
              sortable: true,
              value: "ID"
            },
            {
              text: this.$t("guests-tbl-interview-date"),
              align: "center",
              sortable: true,
              value: "data_intervista"
            },
            {
              text: this.$t("guests-tbl-order-date"),
              align: "center",
              sortable: true,
              value: "data_inserimento"
            },

            {
              text: this.$t("guests-tbl-name"),
              align: "left",
              sortable: true,
              value: "nome_cliente"
            },
            {
              text: this.$t("guests-tbl-packet-version"),
              align: "left",
              sortable: true,
              value: "pacchetto"
            },

            {
              text: this.$t("guests-tbl-total"),
              align: "left",
              sortable: true,
              value: "prezzo"
            },

            {
              text: this.$t("guests-tbl-payment-method"),
              align: "left",
              sortable: true,
              value: "metodo_pagamento"
            },

            {
              text: this.$t("guests-tbl-payment-state"),
              align: "center",
              sortable: false,
              value: "stato_pagamento"
            },

            { text: "", align: "center", sortable: false, value: "actions" }
          ];
        }
      } else if (this.filters.view == 6) {
        // Payment status
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },

          {
            text: this.$t("guests-tbl-payment-date"),
            align: "center",
            sortable: true,
            value: "data_pagamento"
          },
          {
            text: this.$t("guests-tbl-validation-date"),
            align: "center",
            sortable: true,
            value: "data_convalida"
          },
          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_cliente"
          },
          {
            text: this.$t("guests-tbl-packet-version"),
            align: "left",
            sortable: true,
            value: "pacchetto"
          },
          {
            text: this.$t("guests-tbl-invoice-company"),
            align: "left",
            sortable: true,
            value: "nome_azienda"
          },
          {
            text: this.$t("guests-tbl-total"),
            align: "left",
            sortable: true,
            value: "prezzo"
          },

          {
            text: this.$t("guests-tbl-payment-method"),
            align: "left",
            sortable: true,
            value: "metodo_pagamento"
          },
          {
            text: this.$t("guests-tbl-payment-state"),
            align: "center",
            sortable: false,
            value: "stato_pagamento"
          },

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 7) {
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },
          {
            text: this.$t("guests-tbl-interview-time"),
            align: "center",
            sortable: true,
            value: "orario_intervista"
          },

          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-activity"),
            align: "center",
            sortable: true,
            value: "attivita"
          },

          {
            text: this.$t("guests-tbl-phone"),
            align: "left",
            sortable: true,
            value: "telefono"
          },
          {
            text: this.$t("guests-tbl-outcome"),
            align: "center",
            sortable: true,
            value: "esito_nome"
          },
          {
            text: this.$t("guests-tbl-reset-interview-working"),
            align: "center",
            sortable: false,
            value: "reset_interview_working"
          },
          {
            text: this.$t("guests-tbl-reset-interview-wa"),
            align: "center",
            sortable: false,
            value: "send_reset_interview_link_wa"
          },
          {
            text: this.$t("guests-tbl-reset-interview-set"),
            align: "center",
            sortable: false,
            value: "reset_interview_sent"
          },

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 8) {
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-interview-date"),
            align: "center",
            sortable: true,
            value: "data_intervista"
          },
          {
            text: this.$t("guests-tbl-interview-time"),
            align: "center",
            sortable: true,
            value: "orario_intervista"
          },

          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-activity"),
            align: "center",
            sortable: true,
            value: "attivita"
          },
          {
            text: this.$t("guests-tbl-phone"),
            align: "left",
            sortable: true,
            value: "telefono"
          },
          {
            text: this.$t("guests-tbl-outcome"),
            align: "center",
            sortable: true,
            value: "esito_nome"
          },
          {
            text: this.$t("guests-tbl-reset-interview-date"),
            align: "center",
            sortable: false,
            value: "reset_interview_date"
          },
          {
            text: this.$t("guests-tbl-reset-interview-working"),
            align: "center",
            sortable: false,
            value: "reset_interview_working"
          },
          {
            text: this.$t("guests-tbl-reset-interview-wa"),
            align: "center",
            sortable: false,
            value: "send_reset_interview_link_wa"
          },
          {
            text: this.$t("guests-tbl-reset-interview-set"),
            align: "center",
            sortable: false,
            value: "reset_interview_sent"
          },

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      } else if (this.filters.view == 9) {
        // Guests modo
        this.headers = [
          {
            text: this.$t("guests-tbl-code"),
            align: "left",
            sortable: true,
            value: "ID"
          },
          {
            text: this.$t("guests-tbl-name"),
            align: "left",
            sortable: true,
            value: "nome_intervistato_1"
          },
          {
            text: this.$t("guests-tbl-type"),
            align: "left",
            sortable: true,
            value: "nome_tipologia"
          },
          {
            text: this.$t("guests-tbl-radio"),
            align: "center",
            sortable: true,
            value: "id_radio"
          },

          {
            text: this.$t("guests-tbl-outcome"),
            align: "center",
            sortable: true,
            value: "esito_nome"
          }
        ];

        this.headers = [
          ...this.headers,

          { text: "", align: "center", sortable: false, value: "actions" }
        ];
      }
    },

    addGuest() {
      let guest = {
        ID: 0,
        archivio: "N",
        archivio_lavorazione: "N",
        archivio_ordine: "N",
        archivio_scheda: "N",
        argomento: "",
        attivita: "",
        blocco_link_modifica: "N",
        cancellato_redattore: "N",
        completato: "N",
        coordinamento: 0,
        data_accettazione_fattura: "0000-00-00",
        data_cancellazione: "0000-00-00",
        data_convalida: "0000-00-00",
        data_inserimento: "0000-00-00",
        data_intervista: new Date(Date.now()).toString(),
        data_invio_link_pagamento: "0000-00-00",
        data_invio_msg_no_intervista: "0000-00-00",
        data_lavorazione: "0000-00-00",
        data_modifica: "0000-00-00",
        data_pagamento: "0000-00-00",
        email: "",
        contact_email: "",
        esito: 1,
        facebook: "",
        fattura: "",
        fatturato: "N",
        id_excel: 0,
        id_programma: null,
        id_radio: null,
        id_territorio: null,
        indirizzo: "",
        instagram: "",
        invio_link_podcast: "N",
        lavorazione: "N",
        lavorazione_invio_link_pagamento: "N",
        lavorazione_invio_link_podcast: "N",
        lavorazione_invio_msg_no_intervista: "N",
        link_demo_intervista: "",
        locale: null,
        metodo_pagamento: 0,
        no_archivio_auto: "N",
        nome_coordinamento: "",
        nome_intervistato_1: "",
        nome_intervistato_2: "",
        nome_intervistato_3: "",
        nome_operatore: "",
        codice_coordinamento: "",
        codice_operatore: "",
        codice_speaker: "",
        operatore: 0,
        pacchetto: 0,
        pagato: "N",
        posizione: 0,
        qualifica_intervistato_1: "",
        qualifica_intervistato_2: "",
        qualifica_intervistato_3: "",
        regione: 0,
        sigla_codice: "OSP",
        sito: "",
        speaker: 0,
        telefono: "",
        tipologia: null,
        valido: "Y",
        youtube: "",
        metadata: {
          nome_intervistato: "N",
          qualifica: "N",
          attivita: "N",
          argomento: "N",
          telefono: "N",
          email: "N",
          indirizzo: "N",
          facebook: "N",
          sito: "N",
          instagram: "N",
          youtube: "N"
        },
        notes: { motivo_rifiuto: "", note: "" }
      };
      if (this.$store.state.user.locked_type)
        guest.tipologia = parseInt(
          this.$store.state.user.locked_type.split(",")[0]
        );
      if (this.$store.state.user.locked_radio)
        guest.id_radio = parseInt(
          this.$store.state.user.locked_radio.split(",")[0]
        );
      if (this.$store.state.user.locked_locale)
        guest.locale = this.$store.state.user.locked_locale.split(",")[0];

      this.$refs.guestEditor.setModalGuest(guest, false);
      this.guestEditorDialog = true;
    },

    editGuest(item) {
      this.$refs.guestEditor.setModalGuest(item, true);
      this.guestEditorDialog = true;
    },

    onGuestConfigured(res) {
      if (res.editingGuest) {
        if (
          res.ref.esito != res.item.esito &&
          (res.item.esito == 2 || res.item.esito == 8)
        ) {
          res.item.set_interview_date_now = true;
        }
        guestsManager
          .editGuest(res.item)
          .then(() => {
            this.reloadGuests(true);
            this.guestEditorDialog = false;
          })
          .catch(err => {
            console.log(err);
            this.guestEditorDialog = false;
          });
      } else {
        guestsManager
          .addGuest(res.item)
          .then(() => {
            this.reloadGuests(true);
            this.guestEditorDialog = false;
          })
          .catch(err => {
            console.log(err);
            this.guestEditorDialog = false;
          });
      }
    },

    showPdf(item) {
      usersManager
        .keepAlive()
        .then(() => {
          guestsManager
            .downloadPDF(
              item.id_ospite ? item.id_ospite : item.ID,
              this.$store.state.user.locale,
              this.filters.view >= 5 && this.filters.view <= 6 ? true : false
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    editGuestPayment(item) {
      // Fetch products versions
      guestsManager
        .fetchProductVersions(item)
        .then(res => {
          this.products_versions = res.filter(x => {
            if (
              x.locked_territorio &&
              !x.locked_territorio
                .toString()
                .split(",")
                .includes(item.id_territorio.toString())
            )
              return false;
            if (
              x.locked_radio &&
              !x.locked_radio
                .toString()
                .split(",")
                .includes(item.id_radio.toString())
            )
              return false;
            return true;
          });

          this.$refs.paymentEditor.setModalPayment(
            item.id_ospite ? item.id_ospite : item.ID,
            item.pacchetto,
            item.metodo_pagamento,
            item.tipologia
          );
          this.paymentEditorDialog = true;
        })
        .catch(err => {
          console.log(err);
        });
    },

    onPaymentConfigured(res) {
      guestsManager
        .editPayment(res)
        .then(() => {
          this.reloadGuests(true);
          this.paymentEditorDialog = false;
        })
        .catch(err => {
          console.log(err);
          this.paymentEditorDialog = false;
        });
    },

    onGuestOutcomeConfigured(item) {
      guestsManager
        .outcomeGuest(item)
        .then(() => {
          this.reloadGuests(true);
          this.guestOutcomeDialog = false;
        })
        .catch(err => {
          console.log(err);
          this.guestOutcomeDialog = false;
        });
    },

    outcomeGuest(item) {
      this.$refs.guestOutcomeEditor.setModalGuest(item);
      this.guestOutcomeDialog = true;
    },

    sendPaymentMail(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-send-payment-mail-confirm"),
          this.$t("guests-send-payment-mail-title")
        )
        .then(() => {
          guestsManager
            .sendPaymentMail(item.id_ospite ? item.id_ospite : item.ID)
            .then(() => {
              this.$refs.msgBox
                .show(
                  this.$t("gbl-operation-completed"),
                  this.$t("gbl-operation-title"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("gbl-operation-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    sendPrePaymentMail(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-send-payment-mail-confirm"),
          this.$t("guests-send-payment-mail-title")
        )
        .then(() => {
          guestsManager
            .sendPrePaymentMail(item.id_ospite ? item.id_ospite : item.ID)
            .then(() => {
              this.$refs.msgBox
                .show(
                  this.$t("gbl-operation-completed"),
                  this.$t("gbl-operation-title"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("gbl-operation-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    sendPrePaymentWA(item) {
      guestsManager
        .fetchPrePaymentMail(item.id_ospite ? item.id_ospite : item.ID)
        .then(res => {
          let body = res.body
            .replaceAll("<BR>", "\r\n")
            .replaceAll("<br>", "\r\n")
            .replaceAll("</b>", "")
            .replaceAll("<b>", "")
            .replaceAll("<i>", "")
            .replaceAll("</i>", "")
            .replaceAll('<a href="', "")
            .replaceAll("qui</a>", "")
            .replaceAll('">cliccando', "");

          let phoneNumber = item.telefono;
          if (phoneNumber.indexOf("+39") != 0)
            phoneNumber = "+39" + phoneNumber;

          const link = document.createElement("a");
          let text = body;
          link.setAttribute(
            "href",
            "https://api.whatsapp.com/send?phone=" +
              encodeURIComponent(phoneNumber) +
              "&text=" +
              encodeURIComponent(text)
          );
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(err => {
          this.$refs.msgBox
            .show(err, this.$t("gbl-operation-title"), true)
            .then(() => {})
            .catch(() => {});
        });
    },

    confirmValidatePayment() {
      this.paymentPickerDialog = false;
      this.paymentValidationInProgress = true;
      guestsManager
        .validatePayment(
          this.paymentPickerItem.id_ospite
            ? this.paymentPickerItem.id_ospite
            : this.paymentPickerItem.ID,
          this.paymentPickerDate
        )
        .then(() => {
          this.paymentValidationInProgress = false;
          this.paymentPickerItem = null;
          this.$refs.msgBox
            .show(
              this.$t("gbl-operation-completed"),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {
              this.reloadGuests(true);
            })
            .catch(() => {});
        })
        .catch(err => {
          this.paymentValidationInProgress = false;
          this.paymentPickerItem = null;
          this.$refs.msgBox
            .show(err, this.$t("gbl-operation-title"), true)
            .then(() => {})
            .catch(() => {});
        });
    },

    validatePayment(item) {
      this.paymentPickerDialog = true;
      this.paymentPickerItem = item;
    },

    removePaymentValidation(item) {
      this.$refs.msgBox
        .show(
          this.$t("guests-send-remove-payment-confirm"),
          this.$t("guests-send-remove-payment-title")
        )
        .then(() => {
          guestsManager
            .removePaymentValidation(item.id_ospite ? item.id_ospite : item.ID)
            .then(() => {
              this.$refs.msgBox
                .show(
                  this.$t("gbl-operation-completed"),
                  this.$t("gbl-operation-title"),
                  true
                )
                .then(() => {
                  this.reloadGuests(true);
                })
                .catch(() => {});
            })
            .catch(err => {
              this.$refs.msgBox
                .show(err, this.$t("gbl-operation-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },

    deleteGuest(item) {
      this.$refs.msgBox
        .show(this.$t("guests-delete-confirm"), this.$t("guests-delete-title"))
        .then(() => {
          guestsManager
            .removeGuest(item.id_ospite ? item.id_ospite : item.ID)
            .then(() => {
              this.reloadGuests(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    voidGuest(item) {
      this.$refs.msgBox
        .show(this.$t("guests-void-confirm"), this.$t("guests-void-title"))
        .then(() => {
          guestsManager
            .voidGuest(item.id_ospite ? item.id_ospite : item.ID)
            .then(() => {
              this.reloadGuests(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    row_classes(item) {
      if (item.tipologia == 1) {
        if (
          item.esito == 6 &&
          (this.filters.view == 0 || this.filters.view == 2)
        ) {
          return "orange--text text--darken-3";
        } else if (
          item.esito != 1 &&
          (this.filters.view == 0 || this.filters.view == 2)
        ) {
          return "blue--text";
        } else {
          return null;
        }
      } else {
        if (
          item.esito == 7 &&
          (this.filters.view == 0 || this.filters.view == 2)
        ) {
          return "orange--text text--darken-3";
        }
      }
    },

    reloadGuests: function(showLoader) {
      this.guests = [];
      this.totalGuests = 0;
      this.fetchGuests(showLoader)
        .then(data => {
          this.guests = data.items;
          this.totalGuests = data.totalCount;
        })
        .catch(err => {
          if (err) console.log(err);
        });
    },

    fetchUsers(filter) {
      if (this.$store.state.outcomes) {
        let filtered_outcomes = this.$store.state.outcomes.filter(x => {
          if (x.filter_visible) {
            return true;
          } else {
            return false;
          }
        });
        this.flt_outcomes = [
          { id: null, name: this.$t("gbl-all-male") },
          ...filtered_outcomes
        ];
      }

      if (this.$store.state.tr_locales) {
        let locked_locales = this.$store.state.user.locked_locale
          ? this.$store.state.user.locked_locale.split(",")
          : [];
        this.guest_locales = [
          ...this.$store.state.tr_locales.filter(x => {
            if (locked_locales.length < 1) return true;
            else return locked_locales.includes(x.code);
          })
        ];

        this.flt_locales = [
          { code: null, name: this.$t("gbl-all-male") },
          this.guest_locales
        ];
      }


      if (this.$store.state.radios) {
        let locked_radios = this.$store.state.user.locked_radio
          ? this.$store.state.user.locked_radio.split(",").map(x => {
              return parseInt(x);
            })
          : [];
        this.guest_radios = [
          ...this.$store.state.radios.filter(x => {
            if (locked_radios.length < 1) return true;
            else return locked_radios.includes(x.id);
          })
        ];

        this.flt_radios = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.guest_radios
        ];
      }

      if (this.$store.state.schedulers) {
        this.flt_schedulers = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.schedulers
        ];
      }

       if (this.$store.state.programs) {
        this.flt_programs = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.programs
        ];
      }


      if (this.$store.state.types) {
        let locked_types = this.$store.state.user.locked_type
          ? this.$store.state.user.locked_type.split(",").map(x => {
              return parseInt(x);
            })
          : [];

        this.guest_types = [
          ...this.$store.state.types.filter(x => {
            if (locked_types.length < 1) return true;
            else return locked_types.includes(x.id);
          })
        ];

        this.flt_types = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.guest_types
        ];
      }

      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchGuests(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        guestsManager
          .fetchGuests(ctx)
          .then(result => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    resetPayment(item) {
      this.$refs.msgBox
        .show(this.$t("reset-payment-warning"), this.$t("reset-payment-title"))
        .then(() => {
          guestsManager
            .resetPayment(item.id_ospite)
            .then(() => {
              this.reloadGuests(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style>
.rs-bold {
  font-weight: 600;
}

a {
  color: #0044a3 !Important;
}
</style>
