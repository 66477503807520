<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />
      <ImportCsv ref="importCSV" @uploadCompleted="csvUploadCompleted" />
      <ImportCsvResults ref="importCSVResults" />

      <voipCaller
        ref="voipCaller"
        @callClosed="voipCallClosed"
        @callCompleted="voipCallCompleted"
        :enableOutcome="false"
      />
      <setList ref="setListDialog" />
      <setCategory ref="setCategoryDialog" />

      <EditorialOutcomesResume
        ref="outcomesResumeDialog"
        v-model="outcomesResumeDialog"
      />
      <EditorialContactOutcomeEditor
        ref="contactEditor"
        v-model="contactEditorDialog"
        @contactRelocated="onContactRelocated"
        @contactMoved="onContactMoved"
        @contactUpdated="onContactUpdated"
        :updateMode="true"
        :radios="radios"
        :types="types"
        :programs="$store.state.programs"
        :territories_radios="$store.state.territories_radios"
        :prefills="[]"
      />
      <v-flex md12>
        <base-material-card
          color="primary"
          icon="mdi-phone"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("editorial-contacts-manager") }}
            </div>
          </template>
          <div class="vertical-spacer" />

          <v-row>
            <v-col>
              <v-btn
                class="mb-2"
                :disabled="fetchingFilters || loading"
                v-on:click="importCSV()"
                color="primary"
                >{{ $t("editorial-contacts-import-csv") }}</v-btn
              ></v-col
            ></v-row
          >

          <v-row dense justify="center">
            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.list_id"
                outlined
                item-text="name"
                item-value="id"
                :items="lists"
                :label="$t('editorial-filter-list')"
                hide-details
              />
            </v-col>

            <v-col>
              <v-autocomplete
                :disabled="fetchingFilters"
                clearable
                dense
                v-model="operator"
                :items="operators"
                :loading="isLoadingOperator"
                :search-input.sync="searchOperatorInput"
                cache-items
                outlined
                item-text="name"
                item-value="id"
                :label="$t('editorial-filter-operator')"
                return-object
                hide-no-data
              ></v-autocomplete>
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.ope_status"
                outlined
                item-text="name"
                item-value="id"
                :items="ope_statuses"
                :label="$t('editorial-filter-op')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.nation_id"
                outlined
                item-text="name"
                item-value="id"
                :items="nations"
                :label="$t('editorial-filter-nation')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.region_id"
                outlined
                item-text="name"
                item-value="id"
                :items="regions"
                :label="$t('editorial-filter-region')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.province_id"
                outlined
                item-text="name"
                item-value="id"
                :items="provinces"
                :label="$t('editorial-filter-province')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.town_id"
                outlined
                item-text="name"
                item-value="id"
                :items="towns"
                :label="$t('editorial-filter-town')"
                hide-details
              />
            </v-col>
            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.source_id"
                outlined
                item-text="name"
                item-value="id"
                :items="sources"
                :label="$t('editorial-filter-source')"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col class="text-center">
              <v-text-field
                :disabled="fetchingFilters"
                :value="filter"
                @change="v => (filter = v)"
                append-icon="mdi-magnify"
                :label="$t('gbl-search')"
                hide-details
                single-line
                outlined
                dense
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.outcome"
                outlined
                item-text="name"
                item-value="id"
                :items="flt_outcomes"
                :label="$t('editorial-filter-outcomes')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.suboutcome"
                outlined
                item-text="name"
                item-value="id"
                :items="flt_suboutcomes"
                :label="$t('editorial-filter-suboutcomes')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.working_state"
                outlined
                item-text="name"
                item-value="id"
                :items="working_states"
                :label="$t('editorial-contacts-working-states')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.type_id"
                outlined
                item-text="name"
                item-value="id"
                :items="types"
                :label="$t('editorial-filter-type')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.category_id"
                outlined
                item-text="name"
                item-value="id"
                :items="categories"
                :label="$t('editorial-filter-category')"
                hide-details
              />
            </v-col>
            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.outcomes_time"
                outlined
                item-text="name"
                item-value="id"
                :items="outcomes_times"
                :label="$t('editorial-filter-outcomes-time')"
                hide-details
              />
            </v-col>

            <v-col class="text-center">
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.outcomes_amount"
                outlined
                item-text="name"
                item-value="id"
                :items="outcomes_amounts"
                :label="$t('editorial-filter-outcomes-amounts')"
                hide-details
              />
            </v-col>
          </v-row>

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="contacts"
            :options.sync="options"
            :server-items-length="totalContacts"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
            show-select
            v-model="selectedContacts"
            item-key="id"
          >
            <template v-slot:item.category_testuale="{ item }">
              <span
                v-if="item.category_testuale"
                v-html="item.category_testuale.split('|||').join('<br/>')"
              />
            </template>

            <template v-slot:item.last_assignment_date="{ item }">
              {{ item.last_assignment_date | toLocaleDate }}
            </template>

            <template v-slot:item.mobile="{ item }">
              {{
                item.mobile
                  ? $t("editorial-contacts-tbl-mobile-phone")
                  : $t("editorial-contacts-tbl-fixed-phone")
              }}
            </template>

            <template v-slot:item.source_name="{ item }">
              {{ $t(item.source_name) }}
            </template>

            <template v-slot:item.name="{ item }">
              <div v-if="item.name && item.business_name">
                <p>{{ item.name }}</p>
                <span style="font-weight:700">{{ item.business_name }}</span>
              </div>

              <div v-if="item.name && !item.business_name">
                {{ item.name }}
              </div>

              <div v-if="item.business_name && !item.name">
                <span style="font-weight:700">{{ item.business_name }}</span>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange"
                    @click.stop="setList(item)"
                    :disabled="
                      !$store.state.hasRight('185') ||
                        (!item.list_container && item.list_id != null)
                    "
                    v-on="on"
                    ><v-icon>mdi-clipboard-list-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("editorial-set-list") }}</span></v-tooltip
              >

              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="primary"
                    @click.stop="editContact(item)"
                    :disabled="!$store.state.hasRight('185')"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("editorial-edit-contact") }}</span></v-tooltip
              >

              <v-tooltip v-if="item.url" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="success"
                    @click.stop="openLink(item)"
                    v-on="on"
                    ><v-icon>mdi-link</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("editorial-contacts-tbl-action-open")
                }}</span></v-tooltip
              >

              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    text
                    icon
                    color="green"
                    :disabled="!deviceReady || !$store.state.hasRight('180')"
                    v-on="on"
                    ><v-icon>mdi-phone</v-icon></v-btn
                  >
                </template>
                <v-list>
                  <v-list-item
                    @click="callContact(item, item.contact_phone)"
                    v-if="item.contact_phone"
                  >
                    <v-list-item-title>{{
                      item.contact_phone
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="callContact(item, item.additional_phone)"
                    v-if="item.additional_phone"
                  >
                    <v-list-item-title>{{
                      item.additional_phone
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !$store.state.hasRight('165') || item.outcomes_count > 0
                    "
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="deleteItem(item)"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("editorial-contacts-tbl-action-remove")
                }}</span></v-tooltip
              >
            </template>
            <template v-slot:item.outcomes_count="{ item }">
              <span v-if="item.outcomes_count && item.outcomes_count > 0">{{
                item.outcomes_count
              }}</span
              ><span>
                <v-tooltip
                  v-if="item.outcomes_count && item.outcomes_count > 0"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      :disabled="item.outcomes_count < 1"
                      x-small
                      text
                      icon
                      color="cyan"
                      @click.stop="outcomesResume(item)"
                      v-on="on"
                      ><v-icon color="green"
                        >mdi-information-outline</v-icon
                      ></v-btn
                    ></template
                  ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                ></span
              >
            </template>
          </v-data-table>

          <v-btn
            :disabled="
              fetchingFilters || loading || selectedContacts.length < 1
            "
            v-on:click="batchSetList()"
            color="primary"
            >{{ $t("editorial-contacts-batch-set-list") }}</v-btn
          >
          <v-btn
            :disabled="
              fetchingFilters || loading || selectedContacts.length < 1
            "
            v-on:click="batchSetCategory()"
            color="primary"
            >{{ $t("editorial-contacts-batch-set-category") }}</v-btn
          >

          <v-btn
            :disabled="
              fetchingFilters || loading || selectedContacts.length < 1
            "
            v-on:click="batchDeleteAssignments()"
            color="primary"
            >{{ $t("editorial-contacts-batch-remove-assignments") }}</v-btn
          >

          <v-btn
            :disabled="
              fetchingFilters || loading || selectedContacts.length < 1
            "
            v-on:click="batchDelete()"
            color="primary"
            >{{ $t("editorial-contacts-batch-remove") }}</v-btn
          >
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import config from "../config";
import staticData from "../apis/static";
import editorial from "../apis/editorial";
import voip from "../apis/voip";

import EditorialOutcomesResume from "../components/editorial/EditorialOutcomesResume";
import EditorialContactOutcomeEditor from "../components/editorial/EditorialContactOutcomeEditor";
import messageBox from "../components/MessageBox";
import setList from "../components/editorial/SetList";
import setCategory from "../components/editorial/SetCategory";
import voipCaller from "../components/VoipCaller";
import ImportCsv from "@/components/editorial/ImportCsv";
import ImportCsvResults from "@/components/editorial/ImportCSVResults";

import { Device } from "@twilio/voice-sdk";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      selectedContacts: [],
      contacts: [],
      totalContacts: 0,
      loading: false,
      options: {
        sortBy: ["contact_phone"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",
      fetchingFilters: false,
      nations: [],
      lists: [
        { id: null, name: this.$t("gbl-all-female") },
        { id: 0, name: this.$t("gbl-none-female") }
      ],
      def_outcomes: [],
      def_outcomes_by_id: {},
      list_outcomes: [],
      regions: [{ id: null, name: this.$t("gbl-all-female") }],
      provinces: [{ id: null, name: this.$t("gbl-all-female") }],
      towns: [{ id: null, name: this.$t("gbl-all-female") }],
      sources: [{ id: null, name: this.$t("gbl-all-female") }],
      types: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 1, name: this.$t("editorial-contacts-tbl-mobile-phone") },
        { id: 2, name: this.$t("editorial-contacts-tbl-fixed-phone") }
      ],
      working_states: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 1, name: this.$t("editorial-contacts-filter-processed") },
        { id: 2, name: this.$t("editorial-contacts-filter-available") }
      ],
      outcomes_amounts: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 0, name: "0" },
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 7, name: "6" },
        { id: 8, name: "7" },
        { id: 9, name: "8" },
        { id: 10, name: "9" }
      ],

      outcomes_times: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 1, name: "1+" },
        { id: 2, name: "2+" },
        { id: 3, name: "3+" },
        { id: 4, name: "4+" },
        { id: 5, name: "5+" },
        { id: 10, name: "10+" },
        { id: 15, name: "15+" },
        { id: 20, name: "20+" },
        { id: 25, name: "25+" },
        { id: 30, name: "30+" },
        { id: 60, name: "60+" },
        { id: 90, name: "90+" },
        { id: 120, name: "120+" },
        { id: 150, name: "150+" }
      ],

      categories: [],
      radios: [],
      programs: [],
      ope_statuses: [],
      flt_outcomes: [],
      flt_suboutcomes: [{ id: null, name: this.$t("gbl-all-male") }],

      filters: {
        operator: null,
        ope_status: null,
        nation_id: 86,
        region_id: null,
        province_id: null,
        town_id: null,
        category: null,
        category_id: null,
        outcomes_amount: null,
        outcomes_time: null,
        type_id: null,
        source_id: null,
        working_state: null,
        list_id: null,
        outcome: null,
        suboutcome: null
      },
      isLoadingOperator: false,

      operator: null,
      operators: [],
      searchOperatorInput: null,
      outcomesResumeDialog: false,
      contactEditorDialog: false,

      device: null,
      deviceReady: false
    };
  },

  components: {
    messageBox,
    setList,
    setCategory,
    EditorialOutcomesResume,
    EditorialContactOutcomeEditor,
    voipCaller,
    ImportCsv,
    ImportCsvResults
  },

  mounted() {
    this.ope_statuses = [
      { id: null, name: this.$t("gbl-all-male") },
      { id: 1, name: this.$t("editorial-filter-ban-status-active") },
      { id: 2, name: this.$t("editorial-filter-ban-status-banned") }
    ];

    this.headers = [
      {
        text: this.$t("editorial-contacts-tbl-code"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("editorial-contacts-tbl-phone"),
        align: "center",
        sortable: true,
        value: "contact_phone"
      },
      {
        text: this.$t("editorial-contacts-tbl-email"),
        align: "center",
        sortable: true,
        value: "email"
      },
      {
        text: this.$t("editorial-contacts-tbl-source"),
        align: "center",
        sortable: false,
        value: "source_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-list"),
        align: "center",
        sortable: false,
        value: "list_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-mobile"),
        align: "center",
        sortable: false,
        value: "mobile"
      },

      {
        text: this.$t("editorial-contacts-tbl-category"),
        align: "left",
        sortable: false,
        value: "category_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-textual-category"),
        align: "left",
        sortable: false,
        value: "category_testuale"
      },

      {
        text: this.$t("editorial-contacts-tbl-region"),
        align: "left",
        sortable: false,
        value: "region_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-province"),
        align: "left",
        sortable: false,
        value: "province_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-city"),
        align: "left",
        sortable: false,
        value: "town_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-name"),
        align: "left",
        sortable: false,
        value: "name"
      },

      {
        text: this.$t("editorial-contacts-tbl-assignments"),
        align: "center",
        sortable: true,
        value: "assignments_count"
      },
      {
        text: this.$t("editorial-contacts-tbl-last-assignment"),
        align: "center",
        sortable: true,
        value: "assignment_name"
      },
      {
        text: this.$t("editorial-contacts-tbl-last-assignment"),
        align: "center",
        sortable: true,
        value: "last_assignment_date"
      },

      {
        text: this.$t("editorial-contacts-tbl-outcomes"),
        align: "left",
        sortable: true,
        value: "outcomes_count"
      },
      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    if (this.$store.state.radios) {
      let locked_radios = this.$store.state.user.locked_radio
        ? this.$store.state.user.locked_radio.split(",").map(x => {
            return parseInt(x);
          })
        : [];
      this.radios = [
        ...this.$store.state.radios.filter(x => {
          if (locked_radios.length < 1) return true;
          else return locked_radios.includes(x.id);
        })
      ];
    }

    navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
      voip
        .getToken()
        .then(token => {
          this.device = new Device(token.token, {
            appName: "Masterboard",
            debug: false,
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
            logLevel: 5,
            closeProtection: true,
            allowIncomingWhileBusy: false,
            region: "us1"
          });

          this.device.on("registered", () => {
            const speakerDevices = this.device.audio.speakerDevices.get();
            const ringtoneDevices = this.device.audio.ringtoneDevices.get();

            const speakerId = speakerDevices.values().next().value.deviceId;
            const ringtoneId = ringtoneDevices.values().next().value.deviceId;
            this.device.audio.speakerDevices.set(speakerId);
            this.device.audio.ringtoneDevices.set(ringtoneId);
            this.deviceReady = true;
          });

          this.device.on("error", function(error) {
            console.log("Twilio.Device Error: " + error.message);
            this.deviceReady = false;
          });

          this.device.register();
        })
        .catch(err => {
          console.log(err);
        });
    });

    this.fetchSources()
      .then(() => {
        this.fetchLists()
          .then(res => {
            this.lists = [
              { id: null, name: this.$t("gbl-all-female") },
              { id: 0, name: this.$t("gbl-none-female") },
              ...res
            ];

            this.fetchCategories(this.filters.nation_id)
              .then(() => {
                this.fetchNations()
                  .then(() => {
                    this.fetchRegions(this.filters.nation_id)
                      .then(() => {
                        this.fetchOutcomes()
                          .then(() => {
                            this.flt_outcomes = [
                              { id: null, name: this.$t("gbl-all-male") },
                              ...this.def_outcomes
                            ];
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  })
                  .catch(err => {
                    console.log(err);
                  });
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  },

  watch: {
    "filters.outcome": {
      handler() {
        this.filters.suboutcome = null;
        this.flt_suboutcomes = [{ id: null, name: this.$t("gbl-all-male") }];
        if (this.filters.outcome) {
          const outcome = this.def_outcomes_by_id[this.filters.outcome];

          if (outcome.sub) {
            this.flt_suboutcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...outcome.sub.map(x => {
                return { id: x.id, name: x.name };
              })
            ];
          }
        }
        this.reloadContacts(true);
      }
    },

    options: {
      handler() {
        this.reloadContacts(true);
      },
      deep: true
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then(res => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    "filters.outcomes_amount": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.outcomes_time": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.list_id": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.ope_status": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.suboutcome": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.nation_id": {
      handler() {
        this.fetchRegions(this.filters.nation_id)
          .then(() => {
            this.fetchCategories(this.filters.nation_id)
              .then(() => {
                this.currentPage = 1;
                this.reloadContacts(true);
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    "filters.region_id": {
      handler() {
        this.fetchProvinces(this.filters.nation_id, this.filters.region_id)
          .then(() => {
            this.currentPage = 1;
            this.reloadContacts(true);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    "filters.province_id": {
      handler() {
        this.fetchTowns(
          this.filters.nation_id,
          this.filters.region_id,
          this.filters.province_id
        )
          .then(() => {
            this.currentPage = 1;
            this.reloadContacts(true);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    "filters.town_id": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.source_id": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.working_state": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.category_id": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    },

    "filters.type_id": {
      handler() {
        this.currentPage = 1;
        this.reloadContacts(true);
      }
    }
  },

  methods: {
    csvUploadCompleted(res) {
      this.$refs.importCSVResults
        .show(res)
        .then(() => {})
        .catch(() => {});
    },

    importCSV() {
      this.$refs.importCSV
        .show()
        .then(res => {
          console.log(res);
        })
        .catch(() => {});
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        editorial
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    onContactRelocated(item) {
      editorial
        .reassignContact(item)
        .then(() => {
          this.contactEditorDialog = false;
        })
        .catch(err => {
          console.log(err);
          this.contactEditorDialog = false;
        });
    },

    onContactMoved() {
      this.contactEditorDialog = false;
    },

    onContactUpdated(item) {
      editorial
        .updateContact(item)
        .then(() => {
          this.contactEditorDialog = false;
          this.reloadContacts(true);
        })
        .catch(err => {
          console.log(err);
          this.contactEditorDialog = false;
        });
    },

    fetchSources() {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.filters.source_id = null;
        this.sources = [{ id: null, name: this.$t("gbl-all-female") }];

        editorial
          .fetchSources()
          .then(sources => {
            this.fetchingFilters = false;

            this.sources = sources.map(x => {
              x.name = this.$t(x.name);
              return x;
            });

            this.sources = [
              { id: null, name: this.$t("gbl-all-female") },
              ...this.sources
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingFilters = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchLists() {
      this.fetchingFilters = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchListsNames()
          .then(result => {
            this.fetchingFilters = false;

            resolve(result);
          })
          .catch(err => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchOutcomes()
          .then(result => {
            this.fetchingOutcomes = false;
            this.def_outcomes = result.outcomes;
            this.def_outcomes_by_id = {};
            for (let x in this.def_outcomes) {
              this.def_outcomes[x].name = this.$t(this.def_outcomes[x].name);
              this.def_outcomes_by_id[
                this.def_outcomes[x].id
              ] = this.def_outcomes[x];
              if (this.def_outcomes[x].sub) {
                for (let y in this.def_outcomes[x].sub) {
                  this.def_outcomes[x].sub[y].name = this.$t(
                    this.def_outcomes[x].sub[y].name
                  );
                }
              }
            }

            resolve();
          })
          .catch(err => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    outcomesResume(item) {
      this.$refs.outcomesResumeDialog.setItem(this.def_outcomes, item.id);
      this.outcomesResumeDialog = true;
    },

    fetchCategories(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.filters.category_id = null;
        this.categories = [{ id: null, name: this.$t("gbl-all-female") }];
        editorial
          .fetchCategories(nation_id)
          .then(categories => {
            this.fetchingFilters = false;
            this.categories = [
              { id: null, name: this.$t("gbl-all-female") },
              ...categories
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingFilters = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchNations() {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.nations = [];
        this.filters.region_id = null;
        this.filters.province_id = null;
        this.filters.town_id = null;

        editorial
          .fetchNations()
          .then(nations => {
            this.fetchingFilters = false;
            this.nations = nations.map(x => {
              x.name = this.$t(x.name);
              return x;
            });
            resolve();
          })
          .catch(err => {
            this.fetchingFilters = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.regions = [{ id: null, name: this.$t("gbl-all-female") }];
        this.filters.region_id = null;
        this.filters.province_id = null;
        this.filters.town_id = null;
        if (nation_id) {
          editorial
            .fetchRegions(nation_id)
            .then(regions => {
              this.fetchingFilters = false;
              this.regions = [
                { id: null, name: this.$t("gbl-all-female") },
                ...regions
              ];
              if (regions.length < 1 && this.region_id) {
                this.fetchProvinces(this.nation_id, this.region_id)
                  .then(() => {
                    resolve();
                  })
                  .catch(err => {
                    reject(err);
                  });
              } else {
                resolve();
              }
            })
            .catch(err => {
              this.fetchingFilters = false;
              console.log(err);
              reject(err);
            });
        } else {
          this.fetchingFilters = false;
          resolve();
        }
      });
    },

    fetchProvinces(nation_id, region_id) {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.provinces = [{ id: null, name: this.$t("gbl-all-female") }];
        this.filters.province_id = null;
        this.filters.town_id = null;
        if (nation_id && region_id) {
          editorial
            .fetchProvinces(nation_id, region_id)
            .then(provinces => {
              this.fetchingFilters = false;
              this.provinces = [
                { id: null, name: this.$t("gbl-all-female") },
                ...provinces
              ];
              resolve();
            })
            .catch(err => {
              this.fetchingFilters = false;
              console.log(err);
              reject(err);
            });
        } else {
          this.fetchingFilters = false;
          resolve();
        }
      });
    },

    fetchTowns(nation_id, region_id, province_id) {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.filters.town_id = null;
        this.towns = [{ id: null, name: this.$t("gbl-all-female") }];
        if (nation_id && region_id && province_id) {
          editorial
            .fetchTowns(nation_id, province_id)
            .then(towns => {
              this.fetchingFilters = false;
              this.towns = [
                { id: null, name: this.$t("gbl-all-female") },
                ...towns
              ];
              resolve();
            })
            .catch(err => {
              this.fetchingFilters = false;
              console.log(err);
              reject(err);
            });
        } else {
          this.fetchingFilters = false;
          resolve();
        }
      });
    },

    reloadContacts: function(showLoader) {
      this.contacts = [];
      this.totalContacts = 0;
      this.fetchContacts(showLoader)
        .then(data => {
          this.contacts = data.items;
          this.totalContacts = data.totalCount;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchContacts(showLoader) {
      if (showLoader) this.loading = true;
      this.fetchingFilters = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        editorial
          .fetchContacts(ctx)
          .then(result => {
            if (showLoader) this.loading = false;
            this.fetchingFilters = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            this.fetchingFilters = false;
            console.log(err);
            reject();
          });
      });
    },

    batchSetCategory() {
      this.$refs.setCategoryDialog
        .show(this.filters.nation_id)
        .then(res => {
          let ids = [];
          for (let n = 0; n < this.selectedContacts.length; n++) {
            ids.push(this.selectedContacts[n].id);
          }

          editorial
            .changeContactsCategory(this.filters.nation_id, ids, res)
            .then(() => {
              this.reloadContacts(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    batchSetList() {
      this.$refs.setListDialog
        .show(this.selectedContacts[0].list_id)
        .then(res => {
          let ids = [];
          for (let n = 0; n < this.selectedContacts.length; n++) {
            ids.push(this.selectedContacts[n].id);
          }

          editorial
            .changeContactsList(this.filters.nation_id, ids, res)
            .then(() => {
              this.reloadContacts(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    batchDeleteAssignments() {
      this.$refs.msgBox
        .show(
          this.$t("editorial-contacts-remove-assignment-confirm"),
          this.$t("editorial-contacts-remove-assignment-title")
        )
        .then(() => {
          let ids = [];
          for (let n = 0; n < this.selectedContacts.length; n++) {
            ids.push(this.selectedContacts[n].id);
          }

          editorial
            .removeContactsAssignments(this.filters.nation_id, ids)
            .then(() => {
              this.reloadContacts(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    batchDelete() {
      this.$refs.msgBox
        .show(
          this.$t("editorial-contacts-remove-contact-confirm"),
          this.$t("editorial-contacts-remove-contact-title")
        )
        .then(() => {
          let ids = [];
          for (let n = 0; n < this.selectedContacts.length; n++) {
            ids.push(this.selectedContacts[n].id);
          }

          editorial
            .removeContacts(this.filters.nation_id, ids)
            .then(() => {
              this.reloadContacts(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("editorial-contacts-remove-contact-confirm"),
          this.$t("editorial-contacts-remove-contact-title")
        )
        .then(() => {
          editorial
            .removeContact(this.filters.nation_id, item.id)
            .then(() => {
              this.reloadContacts(true);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    openLink(item) {
      const link = document.createElement("a");
      link.setAttribute("href", item.url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    async callContact(item, phone) {
      this.$refs.voipCaller.startCall(item, this.device, phone);
    },

    editContact(item) {
      this.$refs.contactEditor.setModalContact([], item).then(() => {
        this.contactEditorDialog = true;
      });
    },

    setList(item) {
      this.$refs.setListDialog
        .show(item.list_id)
        .then(res => {
          editorial
            .setItemList(item.id, res)
            .then(() => {
              this.reloadContacts();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    voipCallClosed(/*event*/) {
      // This is an aborted call
      //console.log('Aborted:' , event.id,event.contact_phone);
    },

    voipCallCompleted(/*event*/) {
      // This is a completed call
      //console.log('Completed:' , event.id,event.contact_phone);
    }
  }
};
</script>
