import axios from 'axios';
import config from '../config';

let calls = new Object({
  fetchCalls: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + '/calls/fetch',
        data: {ctx: ctx},
        method: 'POST',
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.calls,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeCall: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + '/calls/delete',
        data: {id: id},
        method: 'POST',
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchStreamingData: id => {
    return new Promise((resolve, reject) => {
      resolve(id);
      reject();
    });
  },

  fetchGroupsResults: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + '/calls/results',
        data: {},
        method: 'POST',
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchUsers: filter => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + '/calls/users',
        data: {filter: filter},
        method: 'POST',
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.users
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOperators: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/calls/operators",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.operators,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setOpGroup: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/auth/setOpGroup",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

});

export default calls;
