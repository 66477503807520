<template>
  <v-card class="ma-1">
    <v-toolbar color="gray" dark>
      <v-app-bar-nav-icon
        ><v-icon large>mdi-speaker</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">{{
        $t("mediaaudio-title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-form v-on:submit.prevent="formSearch()">
        <audioFilters
          :filters="filters"
          :streamDates="false"
          :showDateType="true"
          :fetchingData="fetchingData || loadingMore"
          @filtersReady="filtersReady"
          :streamStates="true"
          :waState="true"
          :mailState="true"
          :purchaseState="true"
        />
      </v-form>

      <v-row v-if="fetchingData && !loadingMore"
        ><v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <SearchResults
        v-if="searchResults && !fetchingFilters"
        :audios="searchResults"
        :search="true"
        :loadingMore="loadingMore"
        :dateTypes="filters.dateTypes"
        @loadMore="loadMore"
    /></v-card-text>
  </v-card>
</template>
<script>
import SearchResults from "../components/audio/AudioSearchResults";
import audioManager from "../apis/audio.js";
import audioFilters from "../components/audio/AudioFilters";
import config from "../config";

export default {
  data() {
    return {
      filters: {
        filter: null,
        region: null,
        category: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        dateTypes: 2,
        speakers: null,
        purchased: null,
        radio: null,
        locale: null,
        streamStates : null,
        waState : null,
        mailState : null
      },

      perPage: 12,
      currentPage: 1,
      fetchingData: false,
      fetchingFilters: false,
      searchResults: null,
      loadingMore: false,
    };
  },

  name: "Home",

  components: {
    SearchResults,
    audioFilters,
  },

  mounted() {
    this.fetchingFilters = true;
  },

  watch: {
    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.currentPage = 1;
          this.submitSearch();
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },
  },

  computed: {
    appBarHeight: function() {
      return this.$vuetify.breakpoint.mobile ? 300 : 522;
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  methods: {
    filtersReady: function() {
      this.fetchingFilters = false;
      this.currentPage = 1;
      this.submitSearch();
    },

    resetStartDate: function() {
      this.filters.filterByCustomDateStart = null;
    },

    resetEndDate: function() {
      this.filters.filterByCustomDateEnd = null;
    },

    resetSearch: function() {
      this.filters.filter = null;
      this.searchResults = null;
      this.loadingMore = false;
    },

    formSearch: function() {
      this.currentPage = 1;
      this.loadingMore = false;
      this.submitSearch(false);
    },

    submitSearch: function(concat) {
      const opts = {
        filter: this.filters.filter,
        filters: this.filters,
        perPage: this.perPage,
        currentPage: this.currentPage,
      };
      if (!concat) this.searchResults = null;

      this.fetchingData = true;
      audioManager
        .search(opts)
        .then((res) => {
          this.fetchingData = false;
          if (!res.items.length) {
            if (concat) {
              this.loadingMore = false;
            } else {
              this.searchResults = res;
            }
          } else {
            if (!concat) this.searchResults = res;
            else {
              this.searchResults.items = this.searchResults.items.concat(
                res.items
              );
            }
            this.loadingMore = false;
          }
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });
    },

    loadMore: function() {
      this.currentPage = this.currentPage + 1;
      this.loadingMore = true;

      this.submitSearch(true);
    },

    fetchCategories: function() {
      return new Promise((resolve, reject) => {
        this.fetchingCategories = true;
        audioManager
          .fetchCategories()
          .then((res) => {
            this.categories = [
              { cat_name: this.$t("gbl-all-female"), id: null },
              ...res,
            ];
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions: function() {
      return new Promise((resolve, reject) => {
        audioManager
          .fetchRegions()
          .then((res) => {
            this.regions = [
              { reg_name: this.$t("gbl-all-female"), id: null },
              ...res,
            ];
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchSpeakers: function() {
      return new Promise((resolve, reject) => {
        audioManager
          .fetchSpeakers()
          .then((res) => {
            let inverted_speakers = res.map((x) => {
              let names = x.speaker_name.split(" ");
              if (names.length >= 2) {
                let name = names[0];
                names.shift();
                names.push(name);
              }
              names = names.map((x) => {
                return x.trim();
              });
              names = names.filter((x) => {
                return x.length > 0 ? true : false;
              });

              x.speaker_name = names.join(" ");
              return x;
            });

            inverted_speakers = inverted_speakers.sort((x, y) => {
              return x.speaker_name.localeCompare(y.speaker_name);
            });

            this.speakers = [
              { speaker_name: this.$t("gbl-all-male"), speaker_id: null },
              ...inverted_speakers,
            ];
            resolve(this.speakers);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>
