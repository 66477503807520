<template>
  <div>
    <GuestEditor
      ref="guestEditor"
      v-model="guestEditorDialog"
      @guestConfigured="onGuestConfigured"
      :vcoutcomes="$store.state.vcoutcomes"
      :livesoutcomes="$store.state.livesoutcomes"
      :programs="$store.state.programs"
      :categories="$store.state.categories"
      :regions="$store.state.regions"
      :radios="$store.state.radios"
      :schedulers="$store.state.schedulers"
      :territories_radios="$store.state.territories_radios"
      :filtered_users="flt_users"
      :users="users"
      :locales="$store.state.tr_locales"
      :types="$store.state.types"
    />
    <messageBox ref="msgBox" />
    <AudioPlayer ref="audioPlayerDialog" v-model="audioPlayerDialog" />

    <OutcomesResume
      ref="outcomesResumeDialog"
      v-model="outcomesResumeDialog"
      :outcomes="outcomes"
    />

    <FirstCallGuestOutcomeEditor
      ref="guestOutcomeEditor"
      v-model="guestOutcomeDialog"
      @outcomeConfigured="onGuestOutcomeConfigured"
      :outcomes="outcomes"
      :filteredOutcomes="[5, 6]"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-phone</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("firstcall-lastattempt-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row justify="center">
          <v-col v-if="fetchingOutcomes || fetchingData">
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingGuests"
              dense
              v-model="filters.view"
              outlined
              item-text="text"
              item-value="value"
              :items="views"
              :label="$t('firstcall-filter-type')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-text-field
              :disabled="fetchingGuests"
              :value="filter"
              @change="(v) => (filter = v)"
              append-icon="mdi-magnify"
              :label="$t('gbl-search')"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
        </v-row>

        <v-card v-if="!fetchingOutcomes && !fetchingData" class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("firstcall-lastattempt-guests")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="guests"
              :options.sync="options"
              :server-items-length="totalGuests"
              :loading="fetchingGuests"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.interview_date="{ item }">
                {{ item.interview_date | toLocaleDate }}
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="
                          item.outcomes_count < 1 ||
                            !$store.state.hasRight('124')
                        "
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  >
                </span>
              </template>

              <template v-slot:item.time_elapsed="{ item }">
                <span>
                  {{ item.data_invio_link_pagamento | toLocaleDateTime }}</span
                >
                -
                <span style="font-weight:800">{{
                  formatElapsed(item.time_elapsed)
                }}</span>
              </template>

              <template v-slot:item.send_link_wa="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click.stop="sendWA(item)"
                      v-on="on"
                      ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                    ></template
                  ><span>{{ $t("firstcall-action-wa") }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:160px">
                  <v-tooltip v-if="$store.state.hasRight('127')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="!item.path"
                        x-small
                        text
                        icon
                        v-on="on"
                        color="purple"
                        @click.stop="playCall(item)"
                        ><v-icon>mdi-phone</v-icon></v-btn
                      ></template
                    ><span>{{ $t("firstcall-action-listen") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('128')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="!item.path"
                        x-small
                        text
                        icon
                        color="success"
                        v-on="on"
                        @click.stop="downloadCall(item)"
                        ><v-icon>mdi-arrow-down</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("firstcall-action-download")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('123')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.last_outcome_id == 5"
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="outcomeGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-target</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("firstcall-action-outcome")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('58')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="showPdf(item)"
                        v-on="on"
                        ><v-icon>mdi-file-pdf-outline</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-pdf") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('61')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="gray"
                        @click.stop="editGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-edit") }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="filters.view == 0 && $store.state.hasRight('129')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.last_outcome_id == 5"
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="archiveLastAttemptGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-archive</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("firstcall-action-archive")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="filters.view == 1 && $store.state.hasRight('129')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.last_outcome_id == 5"
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="unarchiveLastAttemptGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-archive</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("firstcall-action-unarchive")
                    }}</span></v-tooltip
                  >
                </div>
              </template>

              <template v-slot:item.pagato="{ item }">
                <v-icon v-if="item.pagato == 'Y'" color="green"
                  >mdi-check</v-icon
                >

                <v-icon v-if="item.pagato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.anagrafica_completato="{ item }">
                <v-icon
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      !$store.state.hasRight('126')
                  "
                  color="green"
                  >mdi-check</v-icon
                >

                <v-tooltip
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      $store.state.hasRight('126')
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn  @click.stop="resetPayment(item)" v-on="on" x-small text icon color="green"
                      ><v-icon>mdi-check</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-tbl-wizard-reset")
                  }}</span></v-tooltip
                >

                <v-icon v-if="item.anagrafica_completato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.payment_link="{ item }">
                <span v-html="buildPaymentLink(item)" />
                <span class="ml-2"
                  ><v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        v-on="on"
                        text
                        icon
                        @click.stop="copyLink(item)"
                        ><v-icon color="gray">mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-copy-link") }}</span></v-tooltip
                  >
                </span>
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.working_state="{ item }">
                <v-tooltip
                  v-if="$store.state.hasRight('125') && item.working_state"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="error"
                      @click.stop="removeCallWorking(item)"
                      v-on="on"
                      ><v-icon color="green">mdi-check</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-action-remove-call-working-state")
                  }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="$store.state.hasRight('125') && !item.working_state"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="error"
                      @click.stop="setCallWorking(item)"
                      v-on="on"
                      ><v-icon color="red"
                        >mdi-alert-circle-outline</v-icon
                      ></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-action-set-call-working-state")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import guestsManager from "../apis/guests";
import usersManager from "../apis/users";
import localStorageService from "../services/localStorageService";
import config from "../config";
import staticData from "../apis/static";
import firstCall from "../apis/firstcall";
import AudioPlayer from "../components/firstcall/AudioPlayer";
import OutcomesResume from "../components/firstcall/OutcomesResume";
import GuestEditor from "../components/backend/GuestEditor";
import FirstCallGuestOutcomeEditor from "../components/firstcall/FirstCallGuestOutcomeEditor";
import helpers from "../services/helpers";
import messageBox from "../components/MessageBox";
import moment from "moment";
import duration from "moment-duration-format";
import axios from "axios";
duration(moment);

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      guests: [],
      fetchingGuests: false,
      totalGuests: 0,
      outcomes: [],
      perPage: staticData.defaultPerPage,

      options: {
        sortBy: ["time_elapsed"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      filters: {
        view: 0,
      },

      filter: null,

      views: [
        { value: 0, text: "In lavorazione" },
        { value: 1, text: "Archiviati" },
      ],
      headers: [],

      fetchingData: false,
      fetchingOutcomes: true,
      audioPlayerDialog: false,
      outcomesResumeDialog: false,
      guestOutcomeDialog: false,
      users: [],
      flt_users: {},
      guestEditorDialog: false,
    };
  },

  name: "FirstcallManage",

  components: {
    messageBox,
    AudioPlayer,
    OutcomesResume,
    FirstCallGuestOutcomeEditor,
    GuestEditor,
  },

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("firstcall-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("firstcall-tbl-name"),
        align: "left",
        sortable: true,
        value: "nome_intervistato_1",
      },
      {
        text: this.$t("firstcall-tbl-phone"),
        align: "left",
        sortable: true,
        value: "telefono",
      },
      {
        text: this.$t("firstcall-tbl-interview-date"),
        align: "left",
        sortable: true,
        value: "interview_date",
      },

      {
        text: this.$t("firstcall-tbl-scheduler"),
        align: "left",
        sortable: true,
        value: "nome_palinsesto",
      },
      {
        text: this.$t("firstcall-tbl-link"),
        align: "left",
        sortable: false,
        value: "payment_link",
      },

      {
        text: this.$t("firstcall-tbl-wa"),
        align: "center",
        sortable: false,
        value: "send_link_wa",
      },
      {
        text: this.$t("firstcall-tbl-wizard-completed"),
        align: "center",
        sortable: false,
        value: "anagrafica_completato",
      },
      {
        text: this.$t("firstcall-tbl-payment-completed"),
        align: "center",
        sortable: false,
        value: "pagato",
      },
      {
        text: this.$t("firstcall-tbl-lastattempt-working"),
        align: "center",
        sortable: false,
        value: "working_state",
      },
      {
        text: this.$t("firstcall-tbl-outcome-counts"),
        align: "center",
        sortable: false,
        value: "outcomes_count",
      },
      {
        text: this.$t("firstcall-tbl-outcome"),
        align: "center",
        sortable: false,
        value: "last_outcome",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];

    this.fetchingData = true;
    this.fetchUsers()
      .then((res) => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map((x) => {
          this.flt_users[x.id] = x;
        });
        this.fetchOutcomes()
          .then((res) => {
            this.outcomes = res.outcomes;
            this.fetchingData = false;
          })
          .catch((err) => {
            this.fetchingData = false;
            console.log(err);
          });
      })
      .catch((err) => {
        this.fetchingData = false;
        console.log(err);
      });
  },

  watch: {
    filters: {
      handler() {
        this.fetchLastAttemptGuests();
      },
      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true],
        };
      },
    },

    options: {
      handler() {
        this.fetchLastAttemptGuests();
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    editGuest(item) {
      this.$refs.guestEditor.setModalGuest(item, true);
      this.guestEditorDialog = true;
    },

    onGuestConfigured(res) {
      if (res.ref.esito != res.item.esito && res.item.esito == 2) {
        res.item.set_interview_date_now = true;
      }
      guestsManager
        .editGuest(res.item)
        .then(() => {
          this.fetchLastAttemptGuests();
          this.guestEditorDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.guestEditorDialog = false;
        });
    },

    showPdf(item) {
      usersManager
        .keepAlive()
        .then(() => {
          guestsManager
            .downloadPDF(
              item.id_ospite ? item.id_ospite : item.ID,
              this.$store.state.user.locale,
              false,
              item.anagrafica_completato == "Y" ? true : false
            )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    archiveLastAttemptGuest(item) {
      firstCall
        .archiveLastAttemptGuest(item.assignment_id)
        .then(() => {
          this.fetchLastAttemptGuests();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    unarchiveLastAttemptGuest(item) {
      firstCall
        .unarchiveLastAttemptGuest(item.assignment_id)
        .then(() => {
          this.fetchLastAttemptGuests();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    outcomeGuest(item) {
      this.$refs.guestOutcomeEditor.setModalGuest({
        ID: item.ID,
        outcome: 1,
        assignment_id: item.assignment_id,
      });
      this.guestOutcomeDialog = true;
    },

    onGuestOutcomeConfigured(item) {
      firstCall
        .outcomeGuest(item, 3)
        .then(() => {
          this.fetchLastAttemptGuests();
          this.guestOutcomeDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.guestOutcomeDialog = false;
        });
    },

    outcomesResume(item) {
      this.$refs.outcomesResumeDialog.setItem(item.ID);
      this.outcomesResumeDialog = true;
    },

    sendWA(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+39") != 0) phoneNumber = "+39" + phoneNumber;

      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "https://api.whatsapp.com/send?phone=" + encodeURIComponent(phoneNumber)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    downloadCall(item) {
      let id = item.ID;
      const token = localStorageService.getAccessToken();
      let tokens = item.path.split("/");
      let filename = tokens.pop();
      const url =
        config.apiEndPoint +
        "/firstcall/download/" +
        id +
        "?token=" +
        encodeURIComponent(token);

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    playCall(item) {
      this.$refs.audioPlayerDialog.setPodcast(item);
      this.audioPlayerDialog = true;
    },

    resetPayment(item) {
      this.$refs.msgBox
        .show(
          this.$t("reset-payment-warning"),
          this.$t("reset-payment-title")
        )
        .then(() => {
          firstCall
            .resetPayment(item)
            .then((res) => {
              if (res.data.responseData) item.anagrafica_completato = "N";
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    formatElapsed(time) {
      const moment_time = moment.duration(time, "seconds");
      return moment_time.format("d[d] hh:mm:ss");
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    copyLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      navigator.clipboard.writeText(link).then(
        () => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied"),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        },
        (err) => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied-error" + ":" + err),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        }
      );
    },

    buildPaymentLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      let shortLink = helpers.buildPaymentShortLinkURL(item);
      return "<a target='_blank' href='" + link + "'>" + shortLink + "</a>";
    },

    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        firstCall
          .fetchOutcomes()
          .then((result) => {
            this.fetchingOutcomes = false;
            this.outcomes = result.outcomes;
            resolve(result);
          })
          .catch((err) => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    fetchLastAttemptGuests() {
      clearTimeout(this._updateTimestamps);
      this.fetchingGuests = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstCall
          .fetchLastAttemptGuests(ctx)
          .then((result) => {
            this.guests = result.items;
            this.totalGuests = result.totalCount;
            this.fetchingGuests = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    setCallWorking(item) {
      firstCall
        .setLastAttemptCallWorking(item.assignment_id)
        .then((res) => {
          if (res.data.responseData) item.working_state = true;
          // Update
          else {
            this.$refs.msgBox
              .show(
                this.$t("firstcall-operation-busy"),
                this.$t("firstcall-set-lastattempt-call-working-title"),
                true
              )
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch((err) => {
          this.$refs.msgBox
            .show(err, this.$t("firstcall-set-lastattempt-call-working-title"), true)
            .then(() => {})
            .catch(() => {});
        });
    },

    removeCallWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("firstcall-remove-lastattempt-call-working-confirm"),
          this.$t("firstcall-lastattempt-call-working-title")
        )
        .then(() => {
          firstCall
            .removeLastAttemptCallWorking(item.assignment_id)
            .then((res) => {
              if (res.data.responseData) item.working_state = false;
              // Update
              else {
                this.$refs.msgBox
                  .show(
                    this.$t("firstcall-operation-busy"),
                    this.$t("firstcall-lastattempt-call-working-title"),
                    true
                  )
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch((err) => {
              this.$refs.msgBox
                .show(err, this.$t("firstcall-lastattempt-call-working-title"), true)
                .then(() => {})
                .catch(() => {});
            });
        })
        .catch(() => {});
    },
  },
};
</script>
