var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"justify-center":"","id":"login","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","light":"","max-width":"100%","width":"400"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" "+_vm._s(_vm.$t('login-title'))+" ")],1)]},proxy:true}])},[_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"container-logo-small-pages"},[_c('img',{attrs:{"src":require("../assets/vv.png"),"alt":"OnAir Logo"}})]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-form',{on:{"submit":function($event){return _vm.login()}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"color":"secondary","prepend-icon":"mdi-email","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-8",attrs:{"autocomplete":"","color":"secondary","prepend-icon":"mdi-lock-outline","append-icon":_vm.password_view ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.password_view ? 'password' : 'text',"error-messages":errors,"success":valid,"required":""},on:{"click:append":function () { return (_vm.password_view = !_vm.password_view); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),(!_vm.logging)?_c('v-btn',{attrs:{"type":"submit","color":"primary"},on:{"click":function($event){return _vm.login()}}},[_vm._v(_vm._s(_vm.$t('login-button')))]):_vm._e()],1)]}}])}),(_vm.logging)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}}):_vm._e(),(_vm.loginError)?_c('div',[_c('div',{staticStyle:{"height":"10px"}}),_c('v-alert',{attrs:{"type":"error","dense":"","dark":"","dismissible":""}},[_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.loginError)}})])],1):_vm._e(),_c('p',{staticStyle:{"margin-top":"20px"}},[_c('router-link',{attrs:{"to":"/recovery"}},[_vm._v(_vm._s(_vm.$t('login-lost-password')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }