<template>
  <div>
    <GuestEditor
      ref="guestEditor"
      v-model="guestEditorDialog"
      @guestConfigured="onGuestConfigured"
      :vcoutcomes="$store.state.vcoutcomes"
      :livesoutcomes="$store.state.livesoutcomes"
      :programs="$store.state.programs"
      :categories="$store.state.categories"
      :regions="$store.state.regions"
      :radios="$store.state.radios"
      :schedulers="$store.state.schedulers"
      :territories_radios="$store.state.territories_radios"
      :filtered_users="flt_users"
      :users="users"
      :locales="$store.state.tr_locales"
      :types="$store.state.types"
    />

    <messageBox ref="msgBox" />
    <FirstCallGuestOutcomeEditor
      ref="guestOutcomeEditor"
      v-model="guestOutcomeDialog"
      @outcomeConfigured="onGuestOutcomeConfigured"
      :outcomes="outcomes"
      :filteredOutcomes="[6]"
    />

    <FirstCallGuestOutcomeEditor
      ref="pendingGuestOutcomeEditor"
      v-model="pendingGuestOutcomeDialog"
      @outcomeConfigured="onPendingGuestOutcomeConfigured"
      :outcomes="outcomes"
      :filteredOutcomes="[6]"
    />

    <OutcomesResume
      ref="outcomesResumeDialog"
      v-model="outcomesResumeDialog"
      :outcomes="outcomes"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-cellphone-sound</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("firstcall-recovery-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col
            v-if="
              changingWorkingState ||
                fetchingWorkingState ||
                fetchingOutcomes ||
                fetchingData
            "
          >
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>

          <v-col
            v-if="
              !workingState &&
                !changingWorkingState &&
                !fetchingWorkingState &&
                !fetchingOutcomes &&
                !fetchingData
            "
          >
            <v-btn @click="startWorking" color="success">{{
              $t("firstcall-start")
            }}</v-btn>
          </v-col>
          <v-col
            v-if="
              workingState &&
                !changingWorkingState &&
                !fetchingWorkingState &&
                !fetchingOutcomes &&
                !fetchingData
            "
          >
            <v-btn @click="stopWorking" color="warning">{{
              $t("firstcall-stop")
            }}</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <base-material-card
              color="green"
              icon="mdi-phone"
              :title="$t('firstcall-op-dashboard-totalcalls')"
            >
              <v-card-text>
                <v-divider />
                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-totalguests") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalGuests }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-todayguests") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayGuests }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-todaypast") }}
                  </v-col>
                  <v-col>
                    {{ statistics.pastGuests }}
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <base-material-card
              color="primary"
              icon="mdi-target"
              :title="$t('firstcall-op-dashboard-todayoutcomes')"
            >
              <v-card-text>
                <v-divider />
                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-yes") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesYes }} /
                    {{ statistics.todayOutcomesYesPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-listen") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesListen }} /
                    {{ statistics.todayOutcomesListenPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-maybe") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesMaybe }} /
                    {{ statistics.todayOutcomesMaybePerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-no") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesNo }} /
                    {{ statistics.todayOutcomesNoPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-nr") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesNoAnswer }} /
                    {{ statistics.todayOutcomesNoAnswerPerc }}
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <base-material-card
              color="orange"
              icon="mdi-target"
              :title="$t('firstcall-op-dashboard-totaloutcomes')"
            >
              <v-card-text>
                <v-divider />
                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-yes") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalOutcomesYes }} /
                    {{ statistics.totalOutcomesYesPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-listen") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalOutcomesListen }} /
                    {{ statistics.totalOutcomesListenPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-maybe") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalOutcomesMaybe }} /
                    {{ statistics.totalOutcomesMaybePerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-no") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalOutcomesNo }} /
                    {{ statistics.totalOutcomesNoPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("firstcall-op-dashboard-outcome-nr") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalOutcomesNoAnswer }} /
                    {{ statistics.totalOutcomesNoAnswerPerc }}
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>

          <v-col cols="12" sm="12" lg="12">
            <base-material-card
              color="error"
              icon="mdi-car-turbocharger"
              :title="$t('firstcall-op-dashboard-conversion')"
            >
              <v-card-text>
                <v-divider />
                <v-container>
                  <v-row>
                    <v-col> </v-col>
                    <v-col>
                      <v-icon color="green">mdi-checkbox-blank-circle</v-icon
                      >{{ $t("firstcall-op-dashboard-conversion-total") }}
                    </v-col>
                    <v-col>
                      <v-icon color="brown">mdi-checkbox-blank-circle</v-icon
                      >{{ $t("firstcall-op-dashboard-conversion-single") }}
                    </v-col>
                    <v-col>
                      <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
                      {{ $t("firstcall-op-dashboard-conversion-triple") }}
                    </v-col>
                    <v-col>
                      <v-icon color="purple">mdi-checkbox-blank-circle</v-icon
                      >{{ $t("firstcall-op-dashboard-conversion-allsocial") }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon color="orange">mdi-checkbox-blank-circle</v-icon>
                      {{ $t("firstcall-op-dashboard-conversion-opencycle") }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthOpenCycle }} /
                      {{ statistics.openCyclePayingGuests }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthOpenCycleSingle }} /
                      {{ statistics.openCyclePayingGuestsSingle }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthOpenCycleTriple }} /
                      {{ statistics.openCyclePayingGuestsTriple }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthOpenCycleAllSocial }} /
                      {{ statistics.openCyclePayingGuestsAllSocial }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon color="yellow">mdi-checkbox-blank-circle</v-icon>
                      {{ $t("firstcall-op-dashboard-conversion-closecycle") }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthCloseCycle }} /
                      {{ statistics.closeCyclePayingGuests }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthCloseCycleSingle }} /
                      {{ statistics.closeCyclePayingGuestsSingle }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthCloseCycleTriple }} /
                      {{ statistics.closeCyclePayingGuestsTriple }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionMonthCloseCycleAllSocial }} /
                      {{ statistics.closeCyclePayingGuestsAllSocial }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon color="cyan">mdi-checkbox-blank-circle</v-icon
                      >{{ $t("firstcall-op-dashboard-conversion-prevmonth") }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionPastMonth }} /
                      {{ statistics.previousMonthPayingGuests }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionPastMonthSingle }} /
                      {{ statistics.previousMonthPayingGuestsSingle }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionPastMonthTriple }} /
                      {{ statistics.previousMonthPayingGuestsTriple }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionPastMonthAllSocial }} /
                      {{ statistics.previousMonthPayingGuestsAllSocial }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon color="lime">mdi-checkbox-blank-circle</v-icon>
                      {{ $t("firstcall-op-dashboard-conversion-today") }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionToday }} /
                      {{ statistics.todayPayingGuest }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionTodaySingle }} /
                      {{ statistics.todayPayingGuestSingle }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionTodayTriple }} /
                      {{ statistics.todayPayingGuestTriple }}
                    </v-col>
                    <v-col>
                      {{ statistics.conversionTodayAllSocial }} /
                      {{ statistics.todayPayingGuestAllSocial }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon color="green">mdi-checkbox-blank-circle</v-icon>
                      {{ $t("firstcall-op-dashboard-conversion-legend-total") }}

                      <v-icon color="brown">mdi-checkbox-blank-circle</v-icon>
                      {{
                        $t("firstcall-op-dashboard-conversion-legend-single")
                      }}

                      <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
                      {{
                        $t("firstcall-op-dashboard-conversion-legend-triple")
                      }}

                      <v-icon color="purple">mdi-checkbox-blank-circle</v-icon>
                      {{
                        $t("firstcall-op-dashboard-conversion-legend-allsocial")
                      }}
                      <v-icon color="orange">mdi-checkbox-blank-circle</v-icon>
                      {{
                        $t("firstcall-op-dashboard-conversion-legend-opencycle")
                      }}
                      <v-icon color="yellow">mdi-checkbox-blank-circle</v-icon>
                      {{
                        $t(
                          "firstcall-op-dashboard-conversion-legend-closecycle"
                        )
                      }}

                      <v-icon color="cyan">mdi-checkbox-blank-circle</v-icon>

                      {{
                        $t("firstcall-op-dashboard-conversion-legend-prevmonth")
                      }}
                      <v-icon color="lime">mdi-checkbox-blank-circle</v-icon>
                      {{ $t("firstcall-op-dashboard-conversion-legend-today") }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>

        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("firstcall-today")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text
            v-if="!fetchingOutcomes && !fetchingWorkingState && !fetchingData"
          >
            <v-data-table
              dense
              hide-default-footer
              :must-sort="true"
              :headers="headers"
              :items="guests"
              :options.sync="options"
              :server-items-length="totalGuests"
              :loading="fetchingGuests"
              item-key="id"
            >
              <template v-slot:item.interview_date="{ item }">
                {{ item.interview_date | toLocaleDate }}
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>

              <template v-slot:item.time_elapsed="{ item }">
                <span>
                  {{ item.data_invio_link_pagamento | toLocaleDateTime }}</span
                >
                -
                <span style="font-weight:800">{{ formatElapsed(item) }}</span>
              </template>

              <template v-slot:item.send_link_wa="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click.stop="sendWA(item)"
                      v-on="on"
                      ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                    ></template
                  ><span>{{ $t("firstcall-action-wa") }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:80px">
                  <v-tooltip
                    v-if="
                      $store.state.hasRight('101') && item.last_outcome_id != 5
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="outcomeGuest(item)"
                        ><v-icon>mdi-target</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-outcome") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('58')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="showPdf(item)"
                        v-on="on"
                        ><v-icon>mdi-file-pdf-outline</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-pdf") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('61')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="gray"
                        @click.stop="editGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-edit") }}</span></v-tooltip
                  >
                </div>
              </template>

              <template v-slot:item.pagato="{ item }">
                <v-icon v-if="item.pagato == 'Y'" color="green"
                  >mdi-check</v-icon
                >

                <v-icon v-if="item.pagato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.anagrafica_completato="{ item }">
                <v-icon
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      !$store.state.hasRight('104')
                  "
                  color="green"
                  >mdi-check</v-icon
                >

                <v-tooltip
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      $store.state.hasRight('104')
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      v-on="on"
                      @click.stop="resetPayment(item)"
                      ><v-icon>mdi-check</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-tbl-wizard-reset")
                  }}</span></v-tooltip
                >

                <v-icon v-if="item.anagrafica_completato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.payment_link="{ item }">
                <span v-html="buildPaymentLink(item)" />
                <span class="ml-2"
                  ><v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        v-on="on"
                        text
                        icon
                        @click.stop="copyLink(item)"
                        ><v-icon color="gray">mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-copy-link") }}</span></v-tooltip
                  >
                </span>
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <div style="height:30px" />
        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("firstcall-past")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text
            v-if="!fetchingOutcomes && !fetchingWorkingState && !fetchingData"
          >
            <v-data-table
              dense
              hide-default-footer
              :must-sort="true"
              :headers="pendingHeaders"
              :items="pendingGuests"
              :options.sync="pendingOptions"
              :server-items-length="totalPendingGuests"
              :loading="fetchingPendingGuests"
              item-key="id"
            >
              <template v-slot:item.interview_date="{ item }">
                {{ item.interview_date | toLocaleDate }}
              </template>

              <template v-slot:item.send_link_wa="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click.stop="sendWA(item)"
                      v-on="on"
                      ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                    ></template
                  ><span>{{ $t("firstcall-action-wa") }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>

              <template v-slot:item.pagato="{ item }">
                <v-icon v-if="item.pagato == 'Y'" color="green"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.pagato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.anagrafica_completato="{ item }">
                <v-icon
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      !$store.state.hasRight('104')
                  "
                  color="green"
                  >mdi-check</v-icon
                >

                <v-tooltip
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      $store.state.hasRight('104')
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn v-on="on" x-small text icon color="green"
                      ><v-icon>mdi-check</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-tbl-wizard-reset")
                  }}</span></v-tooltip
                >

                <v-icon v-if="item.anagrafica_completato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.removal_in="{ item }">
                {{ formatRemovingIn(item) }}
              </template>

              <template v-slot:item.payment_link="{ item }">
                <span v-html="buildPaymentLink(item)" />

                <span class="ml-2"
                  ><v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        v-on="on"
                        text
                        icon
                        @click.stop="copyLink(item)"
                        ><v-icon color="gray">mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-copy-link") }}</span></v-tooltip
                  >
                </span>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:80px">
                  <v-tooltip v-if="$store.state.hasRight('101')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.last_outcome_id == 5"
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="outcomePendingGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-target</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("firstcall-action-outcome")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('58')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="showPdf(item)"
                        v-on="on"
                        ><v-icon>mdi-file-pdf-outline</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-pdf") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('61')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="gray"
                        @click.stop="editGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-edit") }}</span></v-tooltip
                  >
                </div>
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import guestsManager from "../apis/guests";
import usersManager from "../apis/users";
import firstCall from "../apis/firstcall";
import OutcomesResume from "../components/firstcall/OutcomesResume";
import GuestEditor from "../components/backend/GuestEditor";
import helpers from "../services/helpers";
import messageBox from "../components/MessageBox";
import FirstCallGuestOutcomeEditor from "../components/firstcall/FirstCallGuestOutcomeEditor";
import moment from "moment";
import duration from "moment-duration-format";
duration(moment);

export default {
  data() {
    return {
      guestsDuration: 10,
      guests: [],
      pendingGuests: [],
      fetchingGuests: false,
      totalGuests: 0,
      totalPendingGuests: 0,
      fetchingPendingGuests: false,
      workingState: false,
      fetchingWorkingState: true,
      fetchingOutcomes: true,
      changingWorkingState: false,
      guestOutcomeDialog: false,
      pendingGuestOutcomeDialog: false,
      outcomesResumeDialog: false,
      outcomes: [],
      guestEditorDialog: false,

      options: {
        sortBy: ["ID"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },

      pendingOptions: {
        sortBy: ["ID"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],
      pendingHeaders: [],
      fetchingData: false,
      users: [],
      flt_users: {},

      statistics: {
        todayGuests: "...",
        pastGuests: "...",
        totalGuests: "...",
        totalOutcomes: "...",
        todayOutcomes: "...",
        pastTodayOutcomes: "...",
        todayOutcomesYes: "...",
        todayOutcomesListen: "...",
        todayOutcomesMaybe: "...",
        todayOutcomesNo: "...",
        todayOutcomesNoAnswer: "...",
        todayOutcomesYesPerc: "...",
        todayOutcomesListenPerc: "...",
        todayOutcomesMaybePerc: "...",
        todayOutcomesNoPerc: "...",
        todayOutcomesNoAnswerPerc: "...",

        totalOutcomesYes: "...",
        totalOutcomesListen: "...",
        totalOutcomesMaybe: "...",
        totalOutcomesNo: "...",
        totalOutcomesNoAnswer: "...",
        totalOutcomesYesPerc: "...",
        totalOutcomesListenPerc: "...",
        totalOutcomesMaybePerc: "...",
        totalOutcomesNoPerc: "...",
        totalOutcomesNoAnswerPerc: "...",

        conversionMonthOpenCycle: "...",
        conversionMonthCloseCycle: "...",
        conversionPastMonth: "...",
        conversionToday: "...",

        conversionMonthOpenCycleSingle: "...",
        conversionMonthOpenCycleTriple: "...",
        conversionMonthOpenCycleAllSocial: "...",

        conversionMonthCloseCycleSingle: "...",
        conversionMonthCloseCycleTriple: "...",
        conversionMonthCloseCycleAllSocial: "...",

        conversionPastMonthSingle: "...",
        conversionPastMonthTriple: "...",
        conversionPastMonthAllSocial: "...",

        conversionTodaySingle: "...",
        conversionTodayTriple: "...",
        conversionTodayAllSocial: "...",

        openCyclePayingGuests: "...",
        openCyclePayingGuestsSingle: "...",
        openCyclePayingGuestsTriple: "...",
        openCyclePayingGuestsAllSocial: "...",

        closeCyclePayingGuests: "...",
        closeCyclePayingGuestsSingle: "...",
        closeCyclePayingGuestsTriple: "...",
        closeCyclePayingGuestsAllSocial: "...",

        previousMonthPayingGuests: "...",
        previousMonthPayingGuestsSingle: "...",
        previousMonthPayingGuestsTriple: "...",
        previousMonthPayingGuestsAllSocial: "...",

        todayPayingGuest: "...",
        todayPayingGuestSingle: "...",
        todayPayingGuestTriple: "...",
        todayPayingGuestAllSocial: "...",
      },
    };
  },

  name: "Firstcall",

  components: {
    messageBox,
    FirstCallGuestOutcomeEditor,
    OutcomesResume,
    GuestEditor,
  },

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("firstcall-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("firstcall-tbl-name"),
        align: "left",
        sortable: true,
        value: "nome_intervistato_1",
      },
      {
        text: this.$t("firstcall-tbl-phone"),
        align: "left",
        sortable: true,
        value: "telefono",
      },
      {
        text: this.$t("firstcall-tbl-interview-date"),
        align: "left",
        sortable: true,
        value: "interview_date",
      },
      {
        text: this.$t("firstcall-tbl-scheduler"),
        align: "left",
        sortable: true,
        value: "nome_palinsesto",
      },
      {
        text: this.$t("firstcall-tbl-link"),
        align: "left",
        sortable: false,
        value: "payment_link",
      },
      {
        text: this.$t("firstcall-tbl-link-time-elapsed"),
        align: "center",
        sortable: true,
        value: "time_elapsed",
      },
      {
        text: this.$t("firstcall-tbl-wa"),
        align: "center",
        sortable: false,
        value: "send_link_wa",
      },
      {
        text: this.$t("firstcall-tbl-wizard-completed"),
        align: "center",
        sortable: false,
        value: "anagrafica_completato",
      },
      {
        text: this.$t("firstcall-tbl-payment-completed"),
        align: "center",
        sortable: false,
        value: "pagato",
      },

      {
        text: this.$t("firstcall-tbl-outcome-counts"),
        align: "center",
        sortable: false,
        value: "outcomes_count",
      },
      {
        text: this.$t("firstcall-tbl-outcome"),
        align: "center",
        sortable: false,
        value: "last_outcome",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];

    this.pendingHeaders = [
      {
        text: this.$t("firstcall-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("firstcall-tbl-name"),
        align: "left",
        sortable: true,
        value: "nome_intervistato_1",
      },
      {
        text: this.$t("firstcall-tbl-phone"),
        align: "left",
        sortable: true,
        value: "telefono",
      },
      {
        text: this.$t("firstcall-tbl-interview-date"),
        align: "left",
        sortable: true,
        value: "interview_date",
      },
      {
        text: this.$t("firstcall-tbl-scheduler"),
        align: "left",
        sortable: true,
        value: "nome_palinsesto",
      },
      {
        text: this.$t("firstcall-tbl-link"),
        align: "left",
        sortable: false,
        value: "payment_link",
      },
      {
        text: this.$t("firstcall-tbl-link-time-before-removal"),
        align: "center",
        sortable: true,
        value: "removal_in",
      },

      {
        text: this.$t("firstcall-tbl-wa"),
        align: "center",
        sortable: false,
        value: "send_link_wa",
      },
      {
        text: this.$t("firstcall-tbl-wizard-completed"),
        align: "center",
        sortable: false,
        value: "anagrafica_completato",
      },

      {
        text: this.$t("firstcall-tbl-outcome-counts"),
        align: "center",
        sortable: false,
        value: "outcomes_count",
      },
      {
        text: this.$t("firstcall-tbl-outcome"),
        align: "center",
        sortable: false,
        value: "last_outcome",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];

    this.fetchingData = true;
    this.fetchUsers()
      .then((res) => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map((x) => {
          this.flt_users[x.id] = x;
        });
        this.fetchOutcomes()
          .then((res) => {
            this.outcomes = res.outcomes;
            this.fetchRecoveryWorkingState()
              .then(() => {
                this.fetchingData = false;
              })
              .catch((err) => {
                this.fetchingData = false;
                console.log(err);
              });
          })
          .catch((err) => {
            this.fetchingData = false;
            console.log(err);
          });
      })
      .catch((err) => {
        this.fetchingData = false;
        console.log(err);
      });
  },

  watch: {
    options: {
      handler() {
        this.fetchGuests();
      },
      deep: true,
    },

    pendingOptions: {
      handler() {
        this.fetchPendingGuests();
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    formatRemovingIn(item) {
      let date = moment(item.data_invio_link_pagamento);
      let elapsed_date = moment(date).add(item.time_elapsed, "seconds");
      let end_date = moment(date).add(this.guestsDuration, "days");
      let time_left = moment.duration(end_date.diff(elapsed_date));
      return time_left.format("d[d] hh:mm:ss");
    },

    formatElapsed(item) {
      if (!item.last_outcome_time) {
        const moment_time = moment.duration(item.time_elapsed, "seconds");
        return moment_time.format("d[d] hh:mm:ss");
      } else {
        let date_link = moment(item.data_invio_link_pagamento);
        let date_outcome = moment(item.last_outcome_time);
        let elapsed = moment.duration(date_outcome.diff(date_link));
        return elapsed.format("d[d] hh:mm:ss");
      }
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    editGuest(item) {
      this.$refs.guestEditor.setModalGuest(item, true);
      this.guestEditorDialog = true;
    },

    onGuestConfigured(res) {
      if (res.ref.esito != res.item.esito && res.item.esito == 2) {
        res.item.set_interview_date_now = true;
      }
      guestsManager
        .editGuest(res.item)
        .then(() => {
          this.fetchGuests();
          this.fetchPendingGuests();
          this.guestEditorDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.guestEditorDialog = false;
        });
    },

    showPdf(item) {
      usersManager
        .keepAlive()
        .then(() => {
          guestsManager
            .downloadPDF(
              item.id_ospite ? item.id_ospite : item.ID,
              this.$store.state.user.locale,
              false,
              item.anagrafica_completato == "Y" ? true : false
            )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendWA(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+39") != 0) phoneNumber = "+39" + phoneNumber;

      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "https://api.whatsapp.com/send?phone=" + encodeURIComponent(phoneNumber)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    outcomesResume(item) {
      this.$refs.outcomesResumeDialog.setItem(item.ID);
      this.outcomesResumeDialog = true;
    },

    resetPayment(item) {
      this.$refs.msgBox
        .show(this.$t("reset-payment-warning"), this.$t("reset-payment-title"))
        .then(() => {
          firstCall
            .resetPayment(item)
            .then((res) => {
              if (res.data.responseData) item.anagrafica_completato = "N";
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    resetPendingPayment(item) {
      this.$refs.msgBox
        .show(this.$t("reset-payment-warning"), this.$t("reset-payment-title"))
        .then(() => {
          firstCall
            .resetPayment(item)
            .then((res) => {
              if (res.data.responseData) item.anagrafica_completato = "N";
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    outcomeGuest(item) {
      this.$refs.guestOutcomeEditor.setModalGuest({
        ID: item.ID,
        outcome: 1,
        assignment_id: item.assignment_id,
      });
      this.guestOutcomeDialog = true;
    },

    outcomePendingGuest(item) {
      this.$refs.pendingGuestOutcomeEditor.setModalGuest({
        ID: item.ID,
        outcome: 1,
        assignment_id: item.assignment_id,
      });
      this.pendingGuestOutcomeDialog = true;
    },

    onGuestOutcomeConfigured(item) {
      firstCall
        .outcomeGuest(item, 1)
        .then(() => {
          this.fetchGuests();
          this.guestOutcomeDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.guestOutcomeDialog = false;
        });
    },

    onPendingGuestOutcomeConfigured(item) {
      firstCall
        .outcomeGuest(item, 1)
        .then(() => {
          this.fetchPendingGuests();
          this.pendingGuestOutcomeDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.pendingGuestOutcomeDialog = false;
        });
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    copyLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      navigator.clipboard.writeText(link).then(
        () => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied"),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        },
        (err) => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied-error" + ":" + err),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        }
      );
    },

    buildPaymentLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      let shortLink = helpers.buildPaymentShortLinkURL(item);
      return "<a target='_blank' href='" + link + "'>" + shortLink + "</a>";
    },

    collectStatistics(statistics) {
      this.statistics.todayGuests = statistics.todayGuests;
      this.statistics.pastGuests = statistics.pastGuests;
      this.statistics.totalGuests = statistics.totalGuests;

      this.statistics.totalOutcomes = statistics.totalOutcomes;
      this.statistics.todayOutcomes = statistics.todayOutcomes;
      this.statistics.pastTodayOutcomes = statistics.pastTodayOutcomes;

      this.statistics.todayOutcomesYes = statistics.todayOutcomesYes;
      this.statistics.todayOutcomesListen = statistics.todayOutcomesListen;
      this.statistics.todayOutcomesMaybe = statistics.todayOutcomesMaybe;
      this.statistics.todayOutcomesNo = statistics.todayOutcomesNo;
      this.statistics.todayOutcomesNoAnswer = statistics.todayOutcomesNoAnswer;

      this.statistics.todayOutcomesYesPerc =
        statistics.todayOutcomesYesPerc.toFixed(2) + " %";
      this.statistics.todayOutcomesListenPerc =
        statistics.todayOutcomesListenPerc.toFixed(2) + " %";
      this.statistics.todayOutcomesMaybePerc =
        statistics.todayOutcomesMaybePerc.toFixed(2) + " %";
      this.statistics.todayOutcomesNoPerc =
        statistics.todayOutcomesNoPerc.toFixed(2) + " %";
      this.statistics.todayOutcomesNoAnswerPerc =
        statistics.todayOutcomesNoAnswerPerc.toFixed(2) + " %";

      this.statistics.totalOutcomesYes = statistics.totalOutcomesYes;
      this.statistics.totalOutcomesListen = statistics.totalOutcomesListen;
      this.statistics.totalOutcomesMaybe = statistics.totalOutcomesMaybe;
      this.statistics.totalOutcomesNo = statistics.totalOutcomesNo;
      this.statistics.totalOutcomesNoAnswer = statistics.totalOutcomesNoAnswer;

      this.statistics.totalOutcomesYesPerc =
        statistics.totalOutcomesYesPerc.toFixed(2) + " %";
      this.statistics.totalOutcomesListenPerc =
        statistics.totalOutcomesListenPerc.toFixed(2) + " %";
      this.statistics.totalOutcomesMaybePerc =
        statistics.totalOutcomesMaybePerc.toFixed(2) + " %";
      this.statistics.totalOutcomesNoPerc =
        statistics.totalOutcomesNoPerc.toFixed(2) + " %";
      this.statistics.totalOutcomesNoAnswerPerc =
        statistics.totalOutcomesNoAnswerPerc.toFixed(2) + " %";

      this.statistics.conversionMonthOpenCycle =
        statistics.conversionMonthOpenCycle.toFixed(2) + " %";
      this.statistics.conversionMonthCloseCycle =
        statistics.conversionMonthCloseCycle.toFixed(2) + " %";
      this.statistics.conversionPastMonth =
        statistics.conversionPastMonth.toFixed(2) + " %";
      this.statistics.conversionToday =
        statistics.conversionToday.toFixed(2) + " %";

      this.statistics.conversionMonthOpenCycleSingle =
        statistics.conversionMonthOpenCycleSingle.toFixed(2) + " %";
      this.statistics.conversionMonthOpenCycleTriple =
        statistics.conversionMonthOpenCycleTriple.toFixed(2) + " %";
      this.statistics.conversionMonthOpenCycleAllSocial =
        statistics.conversionMonthOpenCycleAllSocial.toFixed(2) + " %";

      this.statistics.conversionMonthCloseCycleSingle =
        statistics.conversionMonthCloseCycleSingle.toFixed(2) + " %";
      this.statistics.conversionMonthCloseCycleTriple =
        statistics.conversionMonthCloseCycleTriple.toFixed(2) + " %";
      this.statistics.conversionMonthCloseCycleAllSocial =
        statistics.conversionMonthCloseCycleAllSocial.toFixed(2) + " %";

      this.statistics.conversionPastMonthSingle =
        statistics.conversionPastMonthSingle.toFixed(2) + " %";
      this.statistics.conversionPastMonthTriple =
        statistics.conversionPastMonthTriple.toFixed(2) + " %";
      this.statistics.conversionPastMonthAllSocial =
        statistics.conversionPastMonthAllSocial.toFixed(2) + " %";

      this.statistics.conversionTodaySingle =
        statistics.conversionTodaySingle.toFixed(2) + " %";
      this.statistics.conversionTodayTriple =
        statistics.conversionTodayTriple.toFixed(2) + " %";
      this.statistics.conversionTodayAllSocial =
        statistics.conversionTodayAllSocial.toFixed(2) + " %";

      this.statistics.openCyclePayingGuests = statistics.totalOutcomesNoAnswer;
      this.statistics.openCyclePayingGuestsSingle =
        statistics.openCyclePayingGuestsSingle;
      this.statistics.openCyclePayingGuestsTriple =
        statistics.openCyclePayingGuestsTriple;
      this.statistics.openCyclePayingGuestsAllSocial =
        statistics.openCyclePayingGuestsAllSocial;

      this.statistics.closeCyclePayingGuests =
        statistics.closeCyclePayingGuests;
      this.statistics.closeCyclePayingGuestsSingle =
        statistics.closeCyclePayingGuestsSingle;
      this.statistics.closeCyclePayingGuestsTriple =
        statistics.closeCyclePayingGuestsTriple;
      this.statistics.closeCyclePayingGuestsAllSocial =
        statistics.closeCyclePayingGuestsAllSocial;

      this.statistics.previousMonthPayingGuests =
        statistics.previousMonthPayingGuests;
      this.statistics.previousMonthPayingGuestsSingle =
        statistics.previousMonthPayingGuestsSingle;
      this.statistics.previousMonthPayingGuestsTriple =
        statistics.previousMonthPayingGuestsTriple;
      this.statistics.previousMonthPayingGuestsAllSocial =
        statistics.previousMonthPayingGuestsAllSocial;

      this.statistics.todayPayingGuest = statistics.todayPayingGuest;
      this.statistics.todayPayingGuestSingle =
        statistics.todayPayingGuestSingle;
      this.statistics.todayPayingGuestTriple =
        statistics.todayPayingGuestTriple;
      this.statistics.todayPayingGuestAllSocial =
        statistics.todayPayingGuestAllSocial;
    },

    startWorking() {
      this.changingWorkingState = true;
      return new Promise((resolve, reject) => {
        firstCall
          .startRecoveryWorkingSession()
          .then((result) => {
            this.workingState = result.workingState;
            this.changingWorkingState = false;
            this.guestsDuration = result.guestsDuration;
            this.collectStatistics(result.statistics);
            this.fetchGuests()
              .then(() => {
                this.fetchPendingGuests()
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.workingState = false;
            this.changingWorkingState = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    confirmStopWorking() {
      this.changingWorkingState = true;
      return new Promise((resolve, reject) => {
        firstCall
          .stopRecoveryWorkingSession()
          .then((result) => {
            this.workingState = result.workingState;
            this.changingWorkingState = false;
            this.guestsDuration = result.guestsDuration;
            this.collectStatistics(result.statistics);
            this.fetchGuests()
              .then(() => {
                this.fetchPendingGuests()
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.workingState = false;
            this.changingWorkingState = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    stopWorking() {
      let canStopWorking = true;
      for (let n = 0; n < this.guests.length; n++) {
        let guest = this.guests[n];
        if (
          !guest.last_outcome ||
          guest.last_outcome_user_id != this.$store.state.user.id
        ) {
          canStopWorking = false;
          break;
        }
      }

      if (!canStopWorking) {
        this.$refs.msgBox
          .show(
            this.$t("firstcall-stop-working-warning"),
            this.$t("firstcall-stop-working-title")
          )
          .then(() => {
            this.confirmStopWorking();
          })
          .catch(() => {});
      } else {
        this.confirmStopWorking();
      }
    },

    fetchRecoveryWorkingState() {
      this.fetchingWorkingState = true;
      return new Promise((resolve, reject) => {
        firstCall
          .fetchRecoveryWorkingState()
          .then((result) => {
            this.fetchingWorkingState = false;
            this.workingState = result.workingState;
            this.guestsDuration = result.guestsDuration;
            this.collectStatistics(result.statistics);
            resolve(result);
          })
          .catch((err) => {
            this.fetchingWorkingState = false;
            reject(err);
          });
      });
    },

    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        firstCall
          .fetchOutcomes()
          .then((result) => {
            this.fetchingOutcomes = false;
            this.outcomes = result.outcomes;
            resolve(result);
          })
          .catch((err) => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    fetchGuests() {
      clearTimeout(this._updateTimestamps);
      this.fetchingGuests = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstCall
          .fetchRecovery(ctx)
          .then((result) => {
            this.guests = result.guests;
            this.totalGuests = result.guests.length;
            this.fetchingGuests = false;
            this.setupElapsedTimer();
            resolve();
          })

          .catch((err) => {
            this.fetchingGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    setupElapsedTimer() {
      clearTimeout(this._updateTimestamps);
      this._updateTimestamps = setTimeout(() => {
        for (let n = 0; n < this.guests.length; n++) {
          let guest = this.guests[n];
          if (!guest.last_outcome_time) guest.time_elapsed++;
        }
        for (let n = 0; n < this.pendingGuests.length; n++) {
          let guest = this.pendingGuests[n];
          guest.time_elapsed++;
        }

        this.setupElapsedTimer();
      }, 1000);
    },

    fetchPendingGuests() {
      this.fetchingPendingGuests = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstCall
          .fetchPendingRecovery(ctx)
          .then((result) => {
            this.pendingGuests = result.pendingGuests;
            this.totalPendingGuests = result.pendingGuests.length;
            this.fetchingPendingGuests = false;
            resolve();
          })
          .catch((err) => {
            this.fetchingPendingGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>
