<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    max-height="400"
    :value="openDialog"
  >
    <SetString ref="setItemText" />
    <MessageBox ref="msgBox" />
    <input
      type="file"
      id="fileUpload"
      ref="file"
      v-on:change="handleImport()"
      hidden
    />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-card class="pa-0 ">
            <v-toolbar color="orange" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-key</v-icon>
              </v-app-bar-nav-icon>

              <v-toolbar-title class="white--text">{{$t('scraper-keywords-title')}}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row class="text-center" align="start" justify="center">
                <v-col class="text-left">
                  <v-list
                    v-if="!loadingKeywords"
                    style="max-height: 400px"
                    class="overflow-y-auto"
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model="selectedKeyword"
                      color="primary"
                    >
                      <template v-for="item in keywords">
                        <v-list-item :key="item.id">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.v }}</v-list-item-title>
                            <v-list-item-subtitle
                              >{{$t('scraper-keywords-total')}}
                              {{ item.count }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-row class="text-center" align="start" justify="center">
                    <v-col>
                      <v-progress-circular
                        v-if="loadingKeywords"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="light-blue"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-list
                    v-if="!loadingKeywordsItems"
                    style="max-height: 400px"
                    class="overflow-y-auto"
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model="selectedKeywordItem"
                      color="primary"
                    >
                      <template v-for="item in keywordsItems">
                        <v-list-item :key="item.id">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{
                              item.v
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-row class="text-center" align="start" justify="center">
                    <v-col>
                      <v-progress-circular
                        v-if="loadingKeywordsItems"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="light-blue"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="text-center" align="center" justify="center">
                <v-col>
                  <v-btn
                    :disabled="loadingKeywords"
                    v-on:click="addGroup()"
                    class="mr-2 white--text"
                    color="orange"
                    ><v-icon>mdi-database-plus</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="loadingKeywords || selectedKeyword == null"
                    v-on:click="removeGroup()"
                    class="mr-2 white--text"
                    color="red"
                    ><v-icon>mdi-database-minus</v-icon></v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    :disabled="selectedKeyword == null || loadingKeywordsItems"
                    v-on:click="addItem()"
                    class="mr-2 white--text"
                    color="orange"
                    ><v-icon>mdi-key-plus</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="
                      selectedKeyword == null ||
                        loadingKeywordsItems ||
                        selectedKeywordItem == null
                    "
                    v-on:click="removeItem()"
                    class="mr-2 white--text"
                    color="red"
                    ><v-icon>mdi-key-minus</v-icon></v-btn
                  >

                  <v-btn
                    :disabled="selectedKeyword == null || loadingKeywordsItems"
                    v-on:click="importItems()"
                    class="mr-2 white--text"
                    color="purple"
                    ><v-icon>mdi-file-import</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import keywordsManager from "../../apis/scraper/keywords";
import SetString from "../SetString.vue";
import MessageBox from "../MessageBox";

export default {
  data() {
    return {
      dialogResolve: null,
      dialogReject: null,
      openDialog: false,
      loadingKeywords: true,
      loadingKeywordsItems: false,
      keywords: [],
      keywordsItems: [],
      selectedKeyword: null,
      selectedKeywordItem: null,
      importFile: null,
    };
  },

  components: {
    SetString,
    MessageBox,
  },

  watch: {
    selectedKeyword: {
      handler() {
        this.selectedKeywordItem = null;
        if (this.selectedKeyword != null) {
          let index = this.selectedKeyword;
          let item = this.keywords[index];
          this.fetchKeywordsItems(item);
        } else this.keywordsItems = [];
      },
    },
  },

  methods: {
    show() {
      return new Promise((resolve, reject) => {
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.selectedKeyword = null;
        this.fetchKeywords();
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve();
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },

    fetchKeywords() {
      this.loadingKeywords = true;
      this.keywordsItems = [];
      keywordsManager
        .fetchKeywords()
        .then((res) => {
          this.keywords = res.items;
          this.loadingKeywords = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingKeywords = false;
        });
    },

    fetchKeywordsItems(item) {
      this.loadingKeywordsItems = true;
      keywordsManager
        .fetchKeywordsItems(item.id)
        .then((res) => {
          this.keywordsItems = res.items;
          this.loadingKeywordsItems = false;
          item.count = res.items.length;
        })
        .catch((err) => {
          console.log(err);
          this.loadingKeywordsItems = false;
        });
    },


   

    addGroup() {
      this.$refs.setItemText
        .show(
          this.$t('scraper-keywords-addgroup'),
          this.$t('scraper-keywords-groupname'),
          this.$t('scraper-keywords-insertgroupname'),
          "",
          null,
          true,
          false
        )
        .then((res) => {
          let keywordName = res.value;
          keywordsManager.addKeyword(keywordName).then(() => {
            this.fetchKeywords();
          });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    removeGroup() {
      if (this.selectedKeyword != null) {
        let keyword = this.keywords[this.selectedKeyword];
        this.$refs.msgBox
          .show(
            this.$t('scraper-keywords-deletegroup'),
            this.$t('scraper-keywords-confirmdeletegroup')
          )
          .then(() => {
            keywordsManager.removeKeyword(keyword.id).then(() => {
              this.fetchKeywords();
            });
          })
          .catch(() => {});
      }
    },

    addItem() {
      this.$refs.setItemText
        .show(
          this.$t('scraper-keywords-addkeyword'),
          this.$t('scraper-keywords-keywordname'),
          this.$t('scraper-keywords-insertkeywordname'),
          "",
          null,
          true,
          false
        )
        .then((res) => {
          let keyword = this.keywords[this.selectedKeyword];
          let keywordName = res.value;
          keywordsManager.addKeywordItem(keywordName, keyword.id).then(() => {
            let index = this.selectedKeyword;
            let item = this.keywords[index];
            this.fetchKeywordsItems(item);
          });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    removeItem() {
      if (this.selectedKeywordItem != null) {
        let keyword = this.keywordsItems[this.selectedKeywordItem];
        this.$refs.msgBox
          .show(
            this.$t('scraper-keywords-deletekeyword'),
            this.$t('scraper-keywords-confirmdeletekeyword')
          )
          .then(() => {
            keywordsManager.removeKeywordItem(keyword.id).then(() => {
              let index = this.selectedKeyword;
              let item = this.keywords[index];
              this.fetchKeywordsItems(item);
            });
          })
          .catch(() => {});
      }
    },

    importItems() {
      document.getElementById("fileUpload").click();
    },

    handleImport() {
      this.importFile = this.$refs.file.files[0];
      let index = this.selectedKeyword;
      let item = this.keywords[index];
      let formData = new FormData();
      formData.append("id", item.id);
      formData.append("file", this.importFile);
      keywordsManager.importItems(formData).then(() => {
        let index = this.selectedKeyword;
        let item = this.keywords[index];
        this.fetchKeywordsItems(item);
        this.$refs.file.value = null;
      });
    },
  },
};
</script>
