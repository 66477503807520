<template>
 <v-dialog persistent overlay-opacity="0.6" max-width="800" :value="openDialog"> <!-- @input is required to emit emit input if clicked outside unless we use persistent -->           
  <v-container fill-height fluid grid-list-xl> 
    <v-layout justify-center wrap>
       <v-flex md12>
        <base-material-card class="pa-0 " color="primary">
        <template v-slot:heading>
            <v-row class="pa-0">
              <v-col align="center" class="pa-0 card-title-text" style="font-size: 25px">{{title}}</v-col>
              <v-col cols=1 class="pa-0"><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col>
            </v-row>
          </template>
          <v-card-text>
              <span style='font-size:20px;'>{{text}}</span>
          </v-card-text>
           <v-btn width="120" @click="confirm()" :color="okvariant">{{ $t('msgbox-ok') }}</v-btn>
            <v-btn width="120" @click="dismiss()"  v-if="!okonly" :color="cancelvariant">{{$t('msgbox-cancel')}}</v-btn>
        </base-material-card>
      </v-flex>
  </v-layout>
  </v-container>
</v-dialog>
</template>

<script>
export default {
  data() {
      return {
           title : '',
           text : '',
           okvariant : 'primary',
           cancelvariant : 'secondary',
           okonly : false,
           openDialog : false,
           dialogResolve : null,
           dialogReject : null
       }
    },

    methods : {
        show(text,title,okonly=false, okvariant='primary',cancelvariant='secondary' ) {
            return new Promise((resolve,reject) => {
                this.value = true;
                this.title = title;
                this.text = text;
                this.okvariant = okvariant;
                this.cancelvariant = cancelvariant;
                this.okonly = okonly;
                this.dialogResolve = resolve;
                this.dialogReject = reject;
                this.openDialog = true;
            });
        },

        hide() {
           this.openDialog = false;
           this.dialogReject();
        },

        confirm() {
            this.openDialog = false;
            this.dialogResolve();
        },

        dismiss() {
            this.hide();
        },

        closeDialog() {
            this.hide();
        }
        
    }
}
</script>