<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <SetString ref="setTemplateName" />

    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large
                    >mdi-account-multiple-plus-outline</v-icon
                  ></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("editorial-lists-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-progress-circular
                v-if="fetchingData"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="light-blue"
              ></v-progress-circular>

              <v-row dense v-if="!fetchingData">
                <v-col>
                  <v-text-field
                    :label="$t('editorial-add-list-name')"
                    dense
                    outlined
                    v-model="list.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense v-if="!fetchingData">
                <v-col>
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-list-type')"
                    v-model="list.container"
                    :items="containers"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="!fetchingData">
                <v-col>
                  <v-checkbox
                    dense
                    :label="$t('editorial-list-not-deletable')"
                    v-model="list.not_deletable"
                  ></v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox
                    v-if="list.container"
                    dense
                    :label="$t('editorial-list-empty')"
                    v-model="list.empty"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="!fetchingData && list.container == 1 && !list.empty"
              >
                <v-col>
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-list-relocation')"
                    v-model="list.relocation"
                    :items="relocations"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="!fetchingData && !list.empty">
                <v-col>
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-add-list-lists')"
                    v-model="list.source_list_id"
                    :items="lists"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="!fetchingData && !list.empty">
                <v-col>
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-add-list-excluded-list')"
                    v-model="list.excluded_list_id"
                    :items="excluded_lists"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="!fetchingData">
                <v-col>
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-add-list-nation')"
                    v-model="list.nation_id"
                    :items="nations"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-add-list-region')"
                    v-model="list.region_id"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="!fetchingData && !list.empty">
                <v-col>
                  <v-text-field
                    :label="
                      $t('editorial-add-list-list-outcomes-maximum-contacts')
                    "
                    dense
                    outlined
                    v-model="list.maximum_contacts"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense v-if="!fetchingData && !list.empty">
                <v-col>
                  <v-autocomplete
                    v-model="list.categories"
                    :items="categories"
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    :label="$t('editorial-add-list-categories')"
                    item-text="name"
                    item-value="id"
                    multiple
                    :disabled="fetchingData"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense v-if="!fetchingData && !list.empty"
                ><v-col>{{
                  $t("editorial-add-list-captured-outcomes")
                }}</v-col></v-row
              >
              <v-divider></v-divider>

              <v-row dense v-if="!fetchingData && !list.empty"
                ><v-col>
                  <div
                    class="mt-2"
                    dense
                    v-for="item in list.source_outcomes"
                    :key="item.id"
                  >
                    <v-row class="mt-2" dense>
                      <v-col cols="12">
                        <v-chip color="primary" class="mr-1">
                          {{ item.name }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-menu
                          v-model="start_day_menu[item.id]"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              v-model="item.outcome_start_day"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :label="
                                $t('editorial-add-list-list-outcomes-start-day')
                              "
                              outlined
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.outcome_start_day"
                            @input="start_day_menu[item.id] = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-menu
                          v-model="end_day_menu[item.id]"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              v-model="item.outcome_end_day"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :label="
                                $t('editorial-add-list-list-outcomes-end-day')
                              "
                              outlined
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.outcome_end_day"
                            @input="end_day_menu[item.id] = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="item.maximum_outcomes"
                          :label="$t('editorial-add-list-max-outcomes')"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                      <v-col cols="1">
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              small
                              text
                              icon
                              @click.stop="removeCapturedOutcome(item)"
                              v-on="on"
                              ><v-icon color="danger">mdi-minus</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("editorial-add-list-remove-captured-outcome")
                          }}</span></v-tooltip
                        ></v-col
                      >
                    </v-row>
                  </div>
                </v-col></v-row
              >

              <v-divider class="mb-4"></v-divider>

              <v-row
                dense
                v-if="!fetchingData && outcomes.length > 0 && !list.empty"
              >
                <v-col cols="11">
                  <v-select
                    class="minfield"
                    outlined
                    dense
                    :label="$t('editorial-add-list-add-captured-outcome')"
                    v-model="selected_outcome"
                    :items="outcomes"
                    item-text="name"
                    item-value="id"
                    return-object
                  >
                  </v-select>
                </v-col>
                <v-col cols="1">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="!selected_outcome"
                        small
                        text
                        icon
                        color="primary"
                        @click.stop="addCapturedOutcome()"
                        v-on="on"
                        ><v-icon color="primary">mdi-plus</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-add-list-add-captured-outcome")
                    }}</span></v-tooltip
                  >
                </v-col>
              </v-row>

              <v-row>
                <v-col class="mt-2">
                  <v-btn
                    width="120"
                    :disabled="
                      (list.source_outcomes.length < 1 && !list.empty) ||
                        !list.name
                    "
                    v-on:click="confirm()"
                    color="cyan"
                    >{{ $t("gbl-ok") }}</v-btn
                  >

                  <v-btn width="120" v-on:click="cancel()" color="gray">{{
                    $t("gbl-cancel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorial from "../../apis/editorial";
import SetString from "../../components/SetString";

export default {
  data() {
    return {
      fetchingData: false,

      list: {
        id: null,
        source_list_id: null,
        excluded_list_id: null,
        nation_id: 86,
        region_id: null,
        categories: null,
        source_outcomes: [],
        available_outcomes: [],
        container: 1,
        relocation: 0,
        not_deletable: false,
        empty: false
      },
      selected_outcome: null,
      outcomes: [],
      nations: [],
      categories: [],
      regions: [],
      lists: [],
      excluded_lists: [],
      list_outcome_name: null,
      list_outcome_action: null,
      list_outcome_action_delta: 0,
      list_outcome_actions: [],
      start_day_menu: {},
      end_day_menu: {},
      templateMenu: null,
      list_templates: [],
      containers: [],
      relocations: []
    };
  },

  props: ["value"],

  components: {
    SetString
  },

  mounted() {
    this.containers = [
      { id: 0, name: this.$t("editorial-add-list-derived-list") },
      { id: 1, name: this.$t("editorial-add-list-container-list") }
    ];

    (this.relocations = [
      { id: 1, name: this.$t("editorial-add-list-relocation-enabled") },
      { id: 0, name: this.$t("editorial-add-list-relocation-disabled") }
    ]),
      this.loadContents();
  },

  watch: {
    "list.nation_id": {
      handler() {
        this.fetchRegions(this.list.nation_id)
          .then(() => {
            this.fetchCategories(this.list.nation_id)
              .then(() => {})
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    "list.source_list_id": {
      handler() {
        this.fetchOutcomes(this.list.source_list_id);
      }
    }
  },

  methods: {
    loadContents() {
      return new Promise(resolve => {
        this.fetchNations().then(() => {
          this.fetchRegions(this.list.nation_id).then(() => {
            this.fetchCategories(this.list.nation_id).then(() => {
              this.fetchLists().then(() => {
                this.fetchOutcomes(this.list.source_list_id);
                resolve();
              });
            });
          });
        });
      });
    },

    removeOutcome(item) {
      this.list.available_outcomes.splice(item, 1);
    },

    addCapturedOutcome() {
      this.selected_outcome.outcome_end_day = null;
      this.selected_outcome.outcome_start_day = null;
      this.selected_outcome.maximum_outcomes = 0;
      this.list.source_outcomes.push(this.selected_outcome);
      this.selected_outcome = null;
      this.fetchOutcomes(this.list.source_list_id);
    },

    removeCapturedOutcome(item) {
      this.list.source_outcomes = this.list.source_outcomes.filter(x => {
        return x.id == item.id ? false : true;
      });
      this.fetchOutcomes(this.list.source_list_id);
    },

    addListOutcome() {
      let outcome = {
        name: this.list_outcome_name,
        action: this.list_outcome_action,
        delta: this.list_outcome_action_delta
      };

      this.list.available_outcomes.push(outcome);
      this.list_outcome_name = null;
      this.list_outcome_action = null;
      this.list_outcome_action_delta = 0;
    },

    fetchOutcomes(source_list_id) {
      this.fetchingData = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchListOutcomes(source_list_id)
          .then(result => {
            let filtered = [];
            for (let x in result) {
              if (result[x].translate) result[x].name = this.$t(result[x].name);
              delete result[x].translate;
              if (
                this.list.source_outcomes.filter(e => e.id == result[x].id)
                  .length == 0
              )
                filtered.push(result[x]);
            }
            this.fetchingData = false;
            this.outcomes = [...filtered];

            resolve();
          })
          .catch(err => {
            this.fetchingData = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchLists() {
      this.fetchingData = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchListsNames({ filters: { archived: false } })
          .then(result => {
            this.fetchingData = false;
            this.lists = [
              { id: null, name: this.$t("editorial-add-list-default-list") },
              ...result
            ];

            this.excluded_lists = [
              { id: null, name: this.$t("gbl-none-female") },
              ...result
            ];

            resolve();
          })
          .catch(err => {
            this.fetchingData = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchCategories(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingData = true;
        this.categories = [{ id: null, name: this.$t("gbl-all-female") }];
        editorial
          .fetchCategories(nation_id)
          .then(categories => {
            this.fetchingData = false;
            this.categories = [
              { id: null, name: this.$t("gbl-all-female") },
              ...categories
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingData = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchNations() {
      return new Promise((resolve, reject) => {
        this.fetchingData = true;
        this.nations = [];
        this.list.region_id = null;

        editorial
          .fetchNations()
          .then(nations => {
            this.fetchingData = false;
            this.nations = nations.map(x => {
              x.name = this.$t(x.name);
              return x;
            });
            resolve();
          })
          .catch(err => {
            this.fetchingData = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingData = true;
        this.regions = [{ id: null, name: this.$t("gbl-all-female") }];
        this.list.region_id = null;
        if (nation_id) {
          editorial
            .fetchRegions(nation_id)
            .then(regions => {
              this.fetchingData = false;
              this.regions = [
                { id: null, name: this.$t("gbl-all-female") },
                ...regions
              ];
              resolve();
            })
            .catch(err => {
              this.fetchingData = false;
              console.log(err);
              reject(err);
            });
        } else {
          this.fetchingData = false;
          resolve();
        }
      });
    },

    confirm: function() {
      this.$emit("onListCreated", this.list);
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    setItem(item) {
      this.list = item;
      this.loadContents();
    }
  }
};
</script>
