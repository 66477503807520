import axios from "axios";
import config from "../../config";

let zones = new Object({
  fetchZones: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.zones,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchZonesItems: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/fetchItems",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.zonesItems,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addZone: (zone) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/add",
        data: { zone: zone },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.zone);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeZone: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addZoneItem: (zone, parent) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/addItem",
        data: { zone: zone, parent: parent },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.zone);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeZoneItem: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/deleteItem",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  importItems: (formData) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/zones/importItems",
        data : formData,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default zones;
