<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0 " color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col
                  align="center"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("editorial-set-list-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <span v-if="!fetchingLists">{{
                $t("editorial-set-list-text")
              }}</span>
              <v-select
                item-text="name"
                item-value="id"
                :items="lists"
                v-if="!fetchingLists"
                outlined
                dense
                v-model="item_list_id"
              />
              <v-progress-circular
                v-if="fetchingLists"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="light-blue"
              ></v-progress-circular>
            </v-card-text>
            <v-btn
              :disabled="fetchingLists"
              width="120"
              @click="confirm()"
              color="primary"
              >{{ $t("msgbox-ok") }}</v-btn
            >
            <v-btn
              :disabled="fetchingLists"
              width="120"
              @click="dismiss()"
              color="secondary"
              >{{ $t("msgbox-cancel") }}</v-btn
            >
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorialManager from "../../apis/editorial";

export default {
  data() {
    return {
      lists: [],
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      fetchingLists: false,
      item_list_id: null
    };
  },

  methods: {
    show(list_id) {
      return new Promise((resolve, reject) => {
        this.value = true;
        this.item_list_id = list_id;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.fetchingLists = true;
        editorialManager
          .fetchListsNames({ filters: { archived: false, container: 1 } })
          .then(res => {
            this.lists = [{ id: null, name: this.$t("gbl-none-female") }, ...res];
            this.fetchingLists = false;
          })
          .catch(err => {
            this.fetchingLists = false;
            console.log(err);
          });
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve(this.item_list_id);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>
