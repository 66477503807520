<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="1024"
    max-height="400"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-card class="pa-0 ">
            <v-toolbar color="primary" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-database-import</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title class="white--text"
                >Risultati importazione</v-toolbar-title
              >

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row class="text-left" align="start" justify="center">
                <v-col>
                  <base-material-stats-card
                    color="error"
                    icon="mdi-cellphone-erase"
                    :title="$t('editorial-import-result-duplicates')"
                    :value="results.existing_phones"
                  />
                </v-col>

                <v-col>
                  <base-material-stats-card
                    color="success"
                    icon="mdi-cellphone-basic"
                    :title="$t('editorial-import-result-valid')"
                    :value="results.new_phones"
                  />
                </v-col>

               
              </v-row>
              <v-row class="text-left" align="start" justify="center">
                <v-col>
                  <base-material-stats-card
                    color="orange"
                    icon="mdi-layers-search-outline"
                    :title="$t('editorial-import-result-valid-lines')"
                    :value="results.valid_lines"
                  />
                </v-col>

                <v-col>
                  <base-material-stats-card
                    color="error"
                    icon="mdi-layers-search-outline"
                    :title="$t('editorial-import-result-invalid-lines')"
                    :value="results.invalid_lines"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogResolve: null,
      dialogReject: null,
      openDialog: false,
      results: {
        existing_phones: "0",
        new_phones: "0",
        valid_lines: "0",
        invalid_lines: "0"
      },
    };
  },

  methods: {
    show(results) {
      return new Promise((resolve, reject) => {
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.results = results;
        this.results.existing_phones = this.results.existing_phones.toString();
        this.results.new_phones = this.results.new_phones.toString();
        this.results.valid_lines = this.results.valid_lines.toString();
        this.results.invalid_lines = this.results.invalid_lines.toString();
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve();
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
