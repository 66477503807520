<template>
  <div>
    <v-row dense class="text-center">
      <v-col>
        <v-progress-circular
          v-if="fetchingFilters"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="light-blue"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row dense v-if="!fetchingFilters" justify="center">
      <v-col v-if="purchaseState">
        <v-select
          dense
          v-model="filters.purchased"
          outlined
          item-text="text"
          item-value="value"
          :items="states"
          :label="$t('mediaaudio-filter-purchased')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>

      <v-col v-if="streamStates">
        <v-select
          dense
          v-model="filters.streamStates"
          outlined
          item-text="text"
          item-value="value"
          :items="streams_states"
          :label="$t('mediaaudio-filter-stream-state')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>

      <v-col v-if="waState">
        <v-select
          dense
          v-model="filters.waState"
          outlined
          item-text="text"
          item-value="value"
          :items="wa_states"
          :label="$t('mediaaudio-filter-wa-state')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>

      <v-col v-if="mailState">
        <v-select
          dense
          v-model="filters.mailState"
          outlined
          item-text="text"
          item-value="value"
          :items="mail_states"
          :label="$t('mediaaudio-filter-mail-state')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>

      <v-col
        v-if="
          !$store.state.hasRight('145') ||
            $store.state.isAdmin() ||
            $store.state.hasRight('147')
        "
      >
        <v-select
          dense
          v-model="filters.speakers"
          outlined
          item-text="speaker_name"
          item-value="speaker_id"
          :items="speakers"
          :label="$t('mediaaudio-filter-speaker')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>

      <v-col v-if="(!$store.state.user.locked_locale || $store.state.user.locked_locale.length < 1)">
        <v-select
          dense
          v-model="filters.region"
          outlined
          item-text="reg_name"
          item-value="id"
          :items="regions"
          :label="$t('mediaaudio-filter-region')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>

      <v-col>
        <v-select
          dense
          v-model="filters.category"
          outlined
          item-text="cat_name"
          item-value="id"
          :items="categories"
          :label="$t('mediaaudio-filter-category')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>
    </v-row>

    <v-row dense v-if="!fetchingFilters" justify="center">
    <v-col class="text-center">
        <v-text-field
          dense
          outlined
          :label="$t('mediaaudio-filter-search')"
          :disabled="fetchingData"
          :value="filters.filter"
          @change="(v) => (filters.filter = v)"
          clearable
          hide-details
        ></v-text-field>
      </v-col>
      <v-col v-if="(!$store.state.user.locked_radio || $store.state.user.locked_radio.length < 1)">
        <v-select
          dense
          outlined
          v-model="filters.radio"
          item-text="name"
          item-value="id"
          :items="flt_radios"
          :label="$t('mediaaudio-filter-radio')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>
      <v-col v-if="(!$store.state.user.locked_locale || $store.state.user.locked_locale.length < 1)">
        <v-select
          dense
          v-model="filters.locale"
          outlined
          item-text="name"
          item-value="code"
          :items="flt_locales"
          :label="$t('mediaaudio-filter-locale')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>
      
      <v-col v-if="showDateType">
        <v-select
          dense
          v-model="filters.dateTypes"
          outlined
          item-text="text"
          item-value="value"
          :items="dateTypes"
          :label="$t('mediaaudio-filter-datetype')"
          :disabled="fetchingData"
          hide-details
        />
      </v-col>
      <v-col>
        <v-dialog
          ref="dialog_start_date"
          v-model="date_start_modal"
          :return-value.sync="filters.filterByCustomDateStart"
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              outlined
              v-model="start_date_formatted"
              :label="$t('mediaaudio-filter-startdate')"
              :disabled="fetchingData"
              readonly
              v-on="on"
              clearable
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              $refs.dialog_start_date.save(filters.filterByCustomDateStart)
            "
            v-model="filters.filterByCustomDateStart"
            scrollable
          >
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col>
        <v-dialog
          ref="dialog_end_date"
          v-model="date_end_modal"
          :return-value.sync="filters.filterByCustomDateEnd"
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              outlined
              v-model="end_date_formatted"
              :label="$t('mediaaudio-filter-enddate')"
              :disabled="fetchingData"
              readonly
              clearable
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            @input="$refs.dialog_end_date.save(filters.filterByCustomDateEnd)"
            v-model="filters.filterByCustomDateEnd"
            scrollable
          >
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import audioManager from "../../apis/audio.js";

export default {
  data() {
    return {
      date_start_modal: false,
      date_end_modal: false,
      flt_radios: [],
      flt_locales: [],
      fetchingFilters: false,
      categories: [],
      regions: [],
      speakers: [],
      states: [
        { text: this.$t("gbl-all-male"), value: null },
        {
          text: this.$t("mediaaudio-filter-purchase-state-purchased"),
          value: true,
        },
        {
          text: this.$t("mediaaudio-filter-purchase-state-notpurchased"),
          value: false,
        },
      ],

      streams_states: [
        { text: this.$t("gbl-all-male"), value: null },
       
        {
          text: this.$t("mediaaudio-filter-streams-programmed"),
          value: 2,
        },
        {
          text: this.$t("mediaaudio-filter-streams-streamed"),
          value: 3,
        },
      ],

      wa_states: [
        { text: this.$t("gbl-all-male"), value: null },
        {
          text: this.$t("mediaaudio-filter-streams-wa-not-sent"),
          value: 1,
        },
        {
          text: this.$t("mediaaudio-filter-streams-wa-sent"),
          value: 2,
        },
        {
          text: this.$t("mediaaudio-filter-streams-wa-delivered"),
          value: 3,
        },
        {
          text: this.$t("mediaaudio-filter-streams-wa-read"),
          value: 4,
        },
        {
          text: this.$t("mediaaudio-filter-streams-wa-errored"),
          value: 5,
        },
      ],

      mail_states: [
        { text: this.$t("gbl-all-male"), value: null },
        {
          text: this.$t("mediaaudio-filter-streams-mail-not-sent"),
          value: 1,
        },
        {
          text: this.$t("mediaaudio-filter-streams-mail-sent"),
          value: 2,
        },
         {
          text: this.$t("mediaaudio-filter-streams-mail-delivered"),
          value: 3,
        },
         {
          text: this.$t("mediaaudio-filter-streams-mail-bounce"),
          value: 4,
        },

      ],

      dateTypes: [
        {
          text: this.$t("mediaaudio-filter-purchase-datetype-interview"),
          value: 1,
        },
        {
          text: this.$t("mediaaudio-filter-purchase-datetype-loading"),
          value: 2,
        },
      ],
    };
  },

  mounted() {
    if (this.streamDates) {
      this.dateTypes = [
        ...this.dateTypes,
        {
          text: this.$t("mediaaudio-filter-purchase-datetype-planned-stream"),
          value: 3,
        },
        {
          text: this.$t("mediaaudio-filter-purchase-datetype-effective-stream"),
          value: 4,
        },
      ];
    }
    this.fetchingFilters = true;

    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];
    this.flt_locales = [
      { code: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.tr_locales,
    ];

    this.fetchCategories()
      .then(() => {
        this.fetchRegions()
          .then(() => {
            this.fetchSpeakers()
              .then(() => {
                this.fetchingFilters = false;
                this.$emit("filtersReady");
              })
              .catch((err) => {
                console.log(err);
                this.fetchingFilters = false;
              });
          })
          .catch((err) => {
            console.log(err);
            this.fetchingFilters = false;
          });
      })
      .catch((err) => {
        console.log(err);
        this.fetchingFilters = false;
      });
  },

  props: {
    filters: Object,
    streamDates: Boolean,
    fetchingData: Boolean,
    showDateType: Boolean,
    streamStates: Boolean,
    waState: Boolean,
    mailState: Boolean,
    purchaseState: Boolean,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  methods: {
    resetStartDate: function() {
      this.filters.filterByCustomDateStart = null;
    },

    resetEndDate: function() {
      this.filters.filterByCustomDateEnd = null;
    },

    fetchCategories: function() {
      return new Promise((resolve, reject) => {
        this.fetchingCategories = true;
        audioManager
          .fetchCategories()
          .then((res) => {
            this.categories = [
              { cat_name: this.$t("gbl-all-female"), id: null },
              ...res,
            ];
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions: function() {
      return new Promise((resolve, reject) => {
        audioManager
          .fetchRegions()
          .then((res) => {
            this.regions = [
              { reg_name: this.$t("gbl-all-female"), id: null },
              ...res,
            ];
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchSpeakers: function() {
      return new Promise((resolve, reject) => {
        audioManager
          .fetchSpeakers()
          .then((res) => {
            let inverted_speakers = res.map((x) => {
              let names = x.speaker_name.split(" ");
              if (names.length >= 2) {
                let name = names[0];
                names.shift();
                names.push(name);
              }
              names = names.map((x) => {
                return x.trim();
              });
              names = names.filter((x) => {
                return x.length > 0 ? true : false;
              });

              x.speaker_name = names.join(" ");
              return x;
            });

            inverted_speakers = inverted_speakers.sort((x, y) => {
              return x.speaker_name.localeCompare(y.speaker_name);
            });

            this.speakers = [
              { speaker_name: this.$t("gbl-all-male"), speaker_id: null },
              ...inverted_speakers,
            ];
            resolve(this.speakers);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>
