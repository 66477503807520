<template>
 <v-dialog persistent overlay-opacity="0.6" max-width="800" :value="openDialog"> <!-- @input is required to emit emit input if clicked outside unless we use persistent -->           
  <v-container fill-height fluid grid-list-xl> 
    <v-layout justify-center wrap>
       <v-flex md12>
        <base-material-card class="pa-0 " color="primary">
        <template v-slot:heading>
            <v-row class="pa-0">
              <v-col align="center" class="pa-0 card-title-text" style="font-size: 25px">{{title}}</v-col> 
            </v-row>
            
          </template>
          <v-card-text>
              <span style='font-size:20px;'>{{text}}</span>
              <div style='height:10px'/>
              <v-progress-linear :value="progress"></v-progress-linear>
          </v-card-text>
        </base-material-card>
      </v-flex>
  </v-layout>
  </v-container>
</v-dialog>
</template>

<script>
export default {
  data() {
      return {
           title : '',
           text : '',
           progress : 0,
           openDialog : false,
       }
    },

    methods : {
        show(text,title) {
            this.value = true;
            this.title = title;
            this.text = text;
            this.openDialog = true;
        },

        hide() {
           this.openDialog = false;
        },

        setProgress(progress) {
          this.progress = progress;
        },

        setStatus(status) {
            this.text = status;

        }
        
    }
}
</script>