import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let guests = {
   
  resetPayment: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/paymentReset",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchMetadata: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/meta",
        data: { id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            metadata: resp.data.responseData.metadata,
            notes: resp.data.responseData.notes,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchGuestsCancelToken: axios.CancelToken.source(),

  fetchGuests: (ctx) => {
    guests.fetchGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/guests/fetch",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: guests.fetchGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchUsers: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/users",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.users,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeGuest: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  voidGuest: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/void",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendPaymentMail: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/sendPaymentMail",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPrePaymentMail: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/fetchPrePaymentMail",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          let data = JSON.parse(res.data.responseData);
          resolve({ body : data.body,
                    subject : data.subject});
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendPrePaymentMail: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/sendPrePaymentMail",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
         resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removePaymentValidation: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/removePaymentValidation",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  validatePayment: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/validatePayment",
        data: { id: id, date: date },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFilters: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/filters",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  fetchPayments: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/payment",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editPayment: (payload) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/updatePayment",
        data: { payload: payload },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchProductVersions: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/fetchProductVersions",
        data: { tipologia: item.tipologia, locale: item.locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  downloadPDF: (id, locale, payment_data) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/guests/guest-details-" +
          encodeURIComponent(id) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&id=" +
          encodeURIComponent(id) +
          "&p=" +
          encodeURIComponent(payment_data) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  outcomeGuest: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/outcome",
        data: item,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editGuest: (guest) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/update",
        data: { guest: guest },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addGuest: (guest) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/add",
        data: { guest: guest },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setLinkWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/setLinkWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeLinkWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/removeLinkWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setVideoEditingWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/setVideoEditingWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeVideoEditingWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/removeVideoEditingWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setLinkSentState: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/setLinkSentState",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeLinkSentState: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/removeLinkSentState",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setInterviewWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/setInterviewWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeInterviewWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/removeInterviewWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setInterviewSentState: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/setInterviewSentState",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeInterviewSentState: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/guests/removeInterviewSentState",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}

export default guests;
