import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let editorial = new Object({
  logWhatsapp: contact => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/logWhatsapp",
        data: { contact: contact },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  parseCSV: (formData, progressCallback) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.apiEndPoint + "/editorial/parseCSV", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressCallback.bind(this)
        })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchEditorialStatistics: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchEditorialStatistics",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.items,
            totalCount: resp.data.responseData.count,
            stats: resp.data.responseData.stats
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchEditorialOutcomeStatistics: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchEditorialOutcomeStatistics",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  sendInfoMail: (id, region_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/sendInfoMail",
        data: { id: id, region_id: region_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setItemList: (id, list_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/setItemList",
        data: { id: id, list_id: list_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  checkPhones: context => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/checkPhones",
        data: { context: context },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBlackLists: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchBlackLists",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addBlackList: list => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/addBlackList",
        data: { list: list },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.items);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeBlackList: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/removeBlackList",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.items);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchListOutcomes: parent_list_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchListOutcomes",
        data: { parent_list_id: parent_list_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.items);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchListsNames: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchListsNames",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.items);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchMoveListsNames: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchMoveListsNames",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.items);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchContacts: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchContacts",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.items,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchSources: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/sources",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.sources);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPrefixes: nation_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchPrefixes",
        data: { nation_id: nation_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.prefixes);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchRegions: nation_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchRegions",
        data: { nation_id: nation_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.regions);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchNations: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchNations",
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.nations);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchProvinces: (nation_id, region_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchProvinces",
        data: { nation_id: nation_id, region_id: region_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.provinces);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTowns: (nation_id, province_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchTowns",
        data: { nation_id: nation_id, province_id: province_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.towns);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchUsers: filter => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/users",
        data: { filter: filter },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.users
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchCategories: (nation_id, filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/categories",
        data: { nation_id: nation_id, filter: filter },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.categories);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  mergeLists: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/mergeLists",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchEditorialLists: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchEditorialLists",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.items,
            totalCount: resp.data.responseData.count,
            cleanContacts: resp.data.responseData.cleanContacts
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  archiveList: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/archiveList",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  unarchiveList: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/unarchiveList",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addEditorialList: list => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/addEditorialList",
        data: { list: list },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeEditorialList: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/removeEditorialList",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOutcomes: all => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchOutcomes",
        data: { all: all },
        method: "POST"
      })
        .then(resp => {
          resolve({
            outcomes: resp.data.responseData.outcomes
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteOutcome: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/deleteOutcome",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  missingContact: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/missingContact",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  recoverContact: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/recoverContact",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  detachAssignment: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/detachAssignment",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  reassignAssignment: (assignment_id, operator_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/reassignAssignment",
        data: { assignment_id: assignment_id, operator_id: operator_id },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  reassignOutcome: (outcomeId, userId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/reassignOutcome",
        data: { outcomeId: outcomeId, userId: userId },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changeOutcome: (id, outcome) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/changeOutcome",
        data: { id: id, outcome: outcome },
        method: "POST"
      })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchContactOutcome: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchContactOutcomes",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve({
            outcomes: resp.data.responseData.outcomes
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchWorkingState: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/fetchWorkingState",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchEditorialTodayCancelToken: new axios.CancelToken.source(),

  fetchEditorialToday: ctx => {
    return new Promise((resolve, reject) => {
      editorial.fetchEditorialTodayCancelToken.cancel();
      axios(
        {
          url: config.apiEndPoint + "/editorial/fetchEditorialToday",
          data: { ctx: ctx },
          method: "POST"
        },
        { cancelToken: editorial.fetchEditorialTodayCancelToken }
      )
        .then(resp => {
          resolve({
            items: resp.data.responseData.contacts,
            totalCount: resp.data.responseData.count,
            statistics: resp.data.responseData.statistics
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchAssignedContactsCancelToken: new axios.CancelToken.source(),

  fetchAssignedContacts: ctx => {
    return new Promise((resolve, reject) => {
      editorial.fetchAssignedContactsCancelToken.cancel();
      axios(
        {
          url: config.apiEndPoint + "/editorial/fetchAssignedContacts",
          data: { ctx: ctx },
          method: "POST"
        },
        { cancelToken: editorial.fetchAssignedContactsCancelToken }
      )
        .then(resp => {
          resolve({
            items: resp.data.responseData.contacts,
            totalCount: resp.data.responseData.count,
            statistics: resp.data.responseData.statistics
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchYesterdayCancelToken: new axios.CancelToken.source(),

  fetchEditorialYesterday: ctx => {
    editorial.fetchYesterdayCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/editorial/fetchEditorialYesterday",
          data: { ctx: ctx },
          method: "POST"
        },
        { cancelToken: editorial.fetchYesterdayCancelToken }
      )
        .then(resp => {
          resolve({
            yesterdayContacts: resp.data.responseData.yesterdayContacts,
            count: resp.data.responseData.count
          });
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchArchiveCancelToken: new axios.CancelToken.source(),

  fetchArchiveContacts: ctx => {
    editorial.fetchArchiveCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/editorial/fetchArchiveContacts",
          data: { ctx: ctx },
          method: "POST"
        },
        { cancelToken: editorial.fetchArchiveCancelToken }
      )
        .then(resp => {
          resolve({
            archiveContacts: resp.data.responseData.archiveContacts,
            totalItems: resp.data.responseData.totalItems
          });
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  startWorkingSession: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/startWorkingSession",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  stopWorkingSession: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/stopWorkingSession",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  acquireContact: phone => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/acquireContact",
        data: { phone: phone },
        method: "POST"
      })
        .then(() => {
          resolve({});
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  acquireMailContact: email => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/acquireContact",
        data: { email: email },
        method: "POST"
      })
        .then(() => {
          resolve({});
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setContactRecallTime: (
    assignment_id,
    last_outcome_item_id,
    recall_date,
    recall_time,
    guest_id
  ) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/setContactRecallTime",
        data: {
          assignment_id: assignment_id,
          last_outcome_item_id: last_outcome_item_id,
          recall_time: recall_time,
          recall_date: recall_date,
          guest_id: guest_id
        },
        method: "POST"
      })
        .then(() => {
          resolve({});
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setContactInterviewTime: (
    assignment_id,
    last_outcome_item_id,
    interview_date,
    interview_time,
    guest_id
  ) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/setContactInterviewTime",
        data: {
          assignment_id: assignment_id,
          last_outcome_item_id: last_outcome_item_id,
          interview_date: interview_date,
          interview_time: interview_time,
          guest_id: guest_id
        },
        method: "POST"
      })
        .then(() => {
          resolve({});
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  acquireExistingContact: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/acquireExistingContact",
        data: { contact_id: id },
        method: "POST"
      })
        .then(() => {
          resolve({});
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  outcomeContact: (contact, group) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/outcomeContact",
        data: {
          swap: contact.swap,
          contact: {
            priority: contact.item.priority,
            email: contact.item.email,
            assignment_id: contact.item.assignment_id,
            id: contact.item.id,
            category_id: contact.item.category_id,
            region_id: contact.item.region_id,
            town_id: contact.item.town_id,
            province_id: contact.item.province_id,
            address: contact.item.address,
            additional_phone: contact.item.additional_phone,
            contact_phone: contact.item.contact_phone,
            business_name: contact.item.business_name,
            name: contact.item.name,
            url: contact.item.url,
            outcome_id: contact.item.outcome.id,
            outcome_action: contact.item.outcome.action,
            suboutcome_id: contact.item.suboutcome
              ? contact.item.suboutcome.id
              : null,
            notes: contact.item.notes,
            id_radio: contact.item.id_radio,
            id_type: contact.item.id_type,
            id_program: contact.item.id_program,
            id_territory: contact.item.id_territory,
            interview_date: contact.item.interview_date,
            interview_time: contact.item.interview_time,
            recall_date: contact.item.recall_date,
            recall_time: contact.item.recall_time,
            callSid: contact.item.callSid,
            rating: contact.item.rating
          },
          group: group
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateContact: contact => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/updateContact",
        data: {
          contact: {
            id: contact.item.id,
            category_id: contact.item.category_id,
            region_id: contact.item.region_id,
            town_id: contact.item.town_id,
            province_id: contact.item.province_id,
            address: contact.item.address,
            contact_phone: contact.item.contact_phone,
            additional_phone: contact.item.additional_phone,
            business_name: contact.item.business_name,
            name: contact.item.name,
            url: contact.item.url,
            email: contact.item.email,
            mobile: contact.item.mobile
          }
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  reassignContact: (contact, group) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/reassignContact",
        data: {
          contact: {
            assignment_id: contact.item.assignment_id,
            id: contact.item.id,
            category_id: contact.item.category_id,
            region_id: contact.item.region_id,
            town_id: contact.item.town_id,
            province_id: contact.item.province_id,
            address: contact.item.address,
            additional_phone: contact.item.additional_phone,
            business_name: contact.item.business_name,
            name: contact.item.business_name,
            url: contact.item.url
          },
          group: group
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  moveContact: (contact_id, target_list) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/moveContact",
        data: {
          contact: {
            id: contact_id,
            target_list: target_list
          }
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  searchContact: phone => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/searchContact",
        data: {
          phone: phone
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  searchContactMail: mail => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/searchContactMail",
        data: {
          mail: mail
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addEditorialOverride: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/addEditorialOverride",
        data: { item: item },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeEditorialOverride: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/removeEditorialOverride",
        data: { item: item },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  downloadExcelStatistics: (locale, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/editorial/downloadExcelStatistics?locale=" +
          encodeURIComponent(locale) +
          "&token=" +
          encodeURIComponent(token) +
          "&filters=" +
          encodeURIComponent(JSON.stringify(filters))
      );
      link.setAttribute("target", "_blank");
      let filename = "editorial_excel_statistics_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadExcelGlobalStatistics: (locale, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/editorial/downloadExcelGlobalStatistics?locale=" +
          encodeURIComponent(locale) +
          "&token=" +
          encodeURIComponent(token) +
          "&filters=" +
          encodeURIComponent(JSON.stringify(filters))
      );
      link.setAttribute("target", "_blank");
      let filename = "editorial_excel_statistics_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  removeContact: (nation_id, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/removeContact",
        data: { nation_id: nation_id, id: id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeContacts: (nation_id, contacts) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/removeContacts",
        data: { nation_id: nation_id, contacts: contacts },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeContactsAssignments: (nation_id, contacts) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/removeContactsAssignments",
        data: { nation_id: nation_id, contacts: contacts },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changeContactsList: (nation_id, contacts, list_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/changeContactsList",
        data: { nation_id: nation_id, contacts: contacts, list_id: list_id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changeContactsCategory: (nation_id, contacts, category_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/editorial/changeContactsCategory",
        data: {
          nation_id: nation_id,
          contacts: contacts,
          category_id: category_id
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default editorial;
