import axios from "axios";
import config from "../../config";

let keywords = new Object({
  fetchKeywords: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.keywords,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchKeywordsItems: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/fetchItems",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.keywordsItems,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addKeyword: (keyword) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/add",
        data: { keyword: keyword },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.keyword);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeKeyword: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addKeywordItem: (keyword, parent) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/addItem",
        data: { keyword: keyword, parent: parent },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.keyword);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeKeywordItem: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/deleteItem",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  importItems: (formData) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/keywords/importItems",
        data : formData,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default keywords;
