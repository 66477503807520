<template>
  <div>
    <v-dialog
      overlay-opacity="0.6"
      max-width="1600"
      persistent
      :value="videoDialog"
    >
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <v-card class="ma-0 pa-2">
              <v-toolbar>
                <v-app-bar-nav-icon
                  ><v-icon color="green" large>mdi-video</v-icon>
                </v-app-bar-nav-icon>

                <v-toolbar-title>{{
                  $t("mediavideo-preview")
                }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon @click="closeDialog" large>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <div
                class="player-container"
                v-if="getVideoSource"
                :key="videoKey"
              >
                <vue-core-video-player
                  ref="videoPlayerRef"
                  @error="videoErrorHandler"
                  :core="HLSCore"
                  :src="getVideoSource"
                  :autoplay="false"
                ></vue-core-video-player>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <messageBox ref="msgBox" />

    <v-row dense justify="center">
      <v-col v-for="p in videos.items" :key="p.id">
        <v-card elevation="3" min-width="200">
          <v-img
            class="video_preview"
            @click="playVideo(p)"
            height="250"
            :src="getVideoThumbnail(p)"
          ></v-img>
          <v-card-title>
            <v-row dense>
              <v-col class="text-center">
                {{ p.name ? capitalize(p.name.replace(",", "")) : '' }}
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col class="text-left">
                <b>OSP-{{ p.guest_id }}</b>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="text-left">
                <b>{{ $t("mediavideo-speaker") }}</b>
                {{
                  p.speaker_name != null && p.speaker_name.length > 0
                    ? capitalize(p.speaker_name.replace(",", ""))
                    : $t("mediavideo-speaker-unspecified")
                }}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="text-left">
                <b>{{
                  dateTypes == 1
                    ? $t("mediavideo-interview-label")
                    : $t("mediavideo-loading-label")
                }}</b>
                {{
                  dateTypes == 1
                    ? formatDate(p.interview_date)
                    : formatDate(p.insert_date)
                }}
              </v-col> </v-row
            ><v-row dense>
              <v-col class="text-left">
                <b>{{ $t("mediavideo-length") }}</b> {{ getDuration(p) }}
              </v-col>
            </v-row>

            <v-row class="elevation-4" style="min-width:350px;">
              <v-col
                v-if="$store.state.hasRight('2')"
                class="text-left"
                cols="2"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="green"
                      @click.stop="playVideo(p)"
                      v-on="on"
                      ><v-icon>mdi-play</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediavideo-play") }}</span></v-tooltip
                >
              </v-col>
              <v-col
                v-if="$store.state.hasRight('4')"
                class="text-center"
                cols="2"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="orange"
                      @click.stop="showPath(p)"
                      v-on="on"
                      ><v-icon>mdi-folder</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediavideo-show-path") }}</span></v-tooltip
                >
              </v-col>
              <v-col
                v-if="$store.state.hasRight('3')"
                class="text-right"
                cols="2"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="purple"
                      @click.stop="getSourceVideo(p)"
                      v-on="on"
                      ><v-icon>mdi-download</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediavideo-download") }}</span></v-tooltip
                >
              </v-col>
              <v-col
                v-if="$store.state.hasRight('137')"
                class="text-right"
                cols="3"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="red"
                      @click.stop="deleteVideo(p)"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediavideo-remove") }}</span></v-tooltip
                >
              </v-col>
              <v-col
                v-if="
                  p.allowed_to_stream &&
                    !p.planned_stream &&
                    !p.stream_time &&
                    $store.state.hasRight('136')
                "
                class="text-right"
                cols="2"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="red"
                      @click.stop="disableStream(p)"
                      v-on="on"
                      ><v-icon>mdi-radio-tower</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediavideo-disable-stream") }}</span></v-tooltip
                >
              </v-col>
              <v-col
                v-if="
                  p.allowed_to_stream &&
                    p.planned_stream &&
                    !p.stream_time &&
                    $store.state.hasRight('136')
                "
                class="text-right"
                cols="2"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn small text icon color="gray" v-on="on"
                      ><v-icon>mdi-radio-tower</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("mediavideo-disable-stream-already-in-schedule")
                  }}</span></v-tooltip
                >
              </v-col>

              <v-col
                v-if="!p.allowed_to_stream && $store.state.hasRight('136')"
                class="text-right"
                cols="2"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="green"
                      @click.stop="enableStream(p)"
                      v-on="on"
                      ><v-icon>mdi-radio-tower</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediavideo-enable-stream") }}</span></v-tooltip
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-if="
        search &&
          videos.items.length < videos.totalCount &&
          videos.items.length != 0
      "
      class="text-center pa-2 load-more"
    >
      <v-btn v-if="!loadingMore" @click="loadMore()" color="red" dark>{{
        $t("mediavideo-loadmore")
      }}</v-btn>
      <v-progress-circular
        v-if="loadingMore"
        :indeterminate="true"
        :rotate="0"
        :size="32"
        :width="4"
        color="light-blue"
      ></v-progress-circular>
    </div>

    <v-row v-if="videos.items.length == 0" align="center" justify="center">
      <v-col class="text-center" cols="12">
        <h2 v-if="search">
          {{ $t("mediavideo-search-no-results") }}
        </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "../../config";
import HLSCore from "../../plugins/playcore-hls";
import Vue from "vue";
import localStorageService from "../../services/localStorageService";
import messageBox from "../../components/MessageBox.vue";
import videoManager from "../../apis/video";

export default {
  data() {
    return {
      videoDialog: false,
      HLSCore: HLSCore,
      videoKey: 0,
      currentVideo: null,
    };
  },

  components: {
    messageBox,
  },

  props: {
    videos: Object,
    search: Boolean,
    allowEditing: Boolean,
    loadingMore: Boolean,
    dateTypes: Number,
  },

  computed: {
    getVideoSource() {
      const token = localStorageService.getAccessToken();

      if (this.currentVideo) {
        let url =
          config.apiEndPoint +
          "/videos/video/" +
          this.currentVideo.id +
          "/" +
          +this.currentVideo.id +
          ".m3u8" +
          "?token=" +
          encodeURIComponent(token);
        return url;
      } else return null;
    },
  },

  methods: {
    deleteVideo: function(p) {
      this.$refs.msgBox
        .show(
          this.$t("fileview-remove-file-text"),
          this.$t("fileview-remove-file-title")
        )
        .then(() => {
          videoManager
            .deleteMedia(p.id)
            .then(() => {
              const index = this.videos.items.indexOf(p);
              if (index > -1) {
                this.videos.items.splice(index, 1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    disableStream: function(p) {
      videoManager
        .setStreamState(p.id, 0)
        .then(() => {
          p.allowed_to_stream = 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    enableStream: function(p) {
      videoManager
        .setStreamState(p.id, 1)
        .then(() => {
          p.allowed_to_stream = 1;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDuration(p) {
      if (!p.duration) return this.$t("gbl-none-female");
      let minutes = Math.floor(p.duration / 60);
      let seconds = Math.floor(p.duration % 60).toString();
      if (seconds.length < 2) seconds = "0" + seconds;
      return minutes + ":" + seconds;
    },

    videoErrorHandler: function(e) {
      console.log(e);
    },

    formatDate: function(date) {
      if (date) {
        var formatter = new Intl.DateTimeFormat("it-IT", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        });
        let formatted_date = date;

        try {
          formatted_date = formatter.format(Date.parse(date));
        } catch (err) {
          console.log(err);
        }
        return formatted_date;
      }
    },

    capitalize: function(n) {
      let split = n.split(" ");
      let names = [];
      for (let n = 0; n < split.length; n++) {
        let lower = split[n].toLowerCase();
        if (lower.length)
          lower = lower[0].toUpperCase() + lower.substr(1, lower.length - 1);
        names.push(lower);
      }

      return names.join(" ");
    },

    getVideoThumbnail: function(p) {
      return config.apiEndPoint + "/videos/thumbs/" + p.id;
    },

    getSourceVideo: function(p) {
      videoManager
        .validateSource(p.id)
        .then((res) => {
          if (res != "InvalidObjectState") {
            const token = localStorageService.getAccessToken();
            const link = document.createElement("a");
            link.setAttribute(
              "href",
              config.apiEndPoint +
                "/videos/source/" +
                p.id +
                "?token=" +
                encodeURIComponent(token)
            );
            link.setAttribute("target", "_blank");
            let filename = p.name + "_" + "_" + p.email + ".mp4";
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            this.$refs.msgBox
              .show(
                this.$t("mediavideo-frozen-file-deice"),
                this.$t("mediavideo-frozen-file-title")
              )
              .then(() => {
                videoManager
                  .defrostSource(p.id)
                  .then((res) => {
                    if (res && res.code == "RestoreAlreadyInProgress") {
                      this.$refs.msgBox.show(
                        this.$t("mediavideo-frozen-file-defrost-inprogress"),
                        this.$t("mediavideo-frozen-file-defrost"),
                        true
                      );
                    } else if (!res) {
                      this.$refs.msgBox.show(
                        this.$t("mediavideo-frozen-file-defrost-success"),
                        this.$t("mediavideo-frozen-file-defrost"),
                        true
                      );
                    } else {
                      this.$refs.msgBox.show(
                        this.$t("mediavideo-frozen-file-defrost-error"),
                        this.$t("mediavideo-frozen-file-defrost"),
                        true
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    this.$refs.msgBox.show(
                      this.$t("mediavideo-frozen-file-defrost-error"),
                      this.$t("mediavideo-frozen-file-defrost"),
                      true
                    );
                  });
              })
              .catch(() => {});
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadMore: function() {
      this.$emit("loadMore");
    },

    playVideo: function(p) {
      this.currentVideo = p;
      this.videoKey += 1;
      this.videoDialog = true;
      Vue.nextTick(() => {
        const player = this.$refs.videoPlayerRef;
        player.play();
      });
    },

    showPath: function(p) {
      this.$refs.msgBox
        .show(p.path, this.$t("mediavideo-file-path"), true)
        .then(() => {})
        .catch(() => {});
    },

    closeDialog: function() {
      this.currentVideo = null;
      Vue.nextTick(() => {
        this.videoDialog = false;
        this.videoKey += 1;
      });
    },
  },
};
</script>

<style>
.podcast-search-item {
  font-size: 18px;
  font-weight: bold;
}

.search-thumbnail {
  border-radius: 15%;
}

.load-more {
  margin-bottom: 60px;
}

.video_preview {
  cursor: pointer;
}
</style>
