<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />

      <div style="height:10px;"></div>
      <v-flex md12>
        <base-material-card
          color="primary"
          icon="mdi-home"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("editorial-imports-title") }}
            </div>
          </template>
          <v-divider class="mt-3" />

          <v-data-table
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="imports"
            :options.sync="options"
            :server-items-length="importsCount"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('182')"
                    x-small
                    text
                    icon
                    color="red"
                    @click.stop="deleteImport(item)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>{{ $t("editorial-import-delete") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.user_id="{ item }">
              <v-avatar
                size="36px"
                v-if="checkGravatar(item) && getGravatar(item)"
              >
                <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
              </v-avatar>

              <v-avatar
                v-else-if="getProfileLetters(item)"
                color="green"
                size="36px"
              >
                <span class="profile-text">{{ getProfileLetters(item) }}</span>
              </v-avatar>
              {{ item.first_name }} {{ item.last_name }}
            </template>

            <template v-slot:item.moment="{ item }">
              {{ item.moment | toLocaleDateTime }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import tasksManager from "../apis/scraper/tasks";
import messageBox from "../components/MessageBox";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      headers: [
        {
          value: "user_id",
          sortable: true,
          text: this.$t("editorial-import-tbl-user"),
        },
        {
          value: "name",
          sortable: true,
          text: this.$t("editorial-import-tbl-name"),
        },
        {
          value: "moment",
          sortable: true,
          text: this.$t("editorial-import-tbl-date"),
        },

        {
          value: "total_contacts",
          sortable: true,
          text: this.$t("editorial-import-tbl-contacts"),
        },
        {
          value: "parsed_mobiles",
          sortable: true,
          text: this.$t("editorial-import-tbl-parsed-mobiles"),
        },
        {
          value: "parsed_wired",
          sortable: true,
          text: this.$t("editorial-import-tbl-parsed-wired"),
        },
        {
          value: "imported_mobiles",
          sortable: true,
          text: this.$t("editorial-import-tbl-imported-mobiles"),
        },
        {
          value: "imported_wired",
          sortable: true,
          text: this.$t("editorial-import-tbl-imported-wired"),
        },
        {
          value: "duplicated",
          sortable: true,
          text: this.$t("editorial-import-tbl-duplicates"),
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
      options: {
        sortBy: ["name"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },

      perPage: 50,

      loading: false,
      importsCount: 0,
      imports: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetchImports();
      },
    },
  },

  components: {
    messageBox,
  },

  mounted() {
    this.fetchImports();
  },

  methods: {
    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    fetchImports() {
      this.loading = true;
      this.imports = [];
      this.importsCount = 0;
      let ctx = Object.assign({}, this.options);
      ctx.filters = this.filters;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.perPage = this.perPage;
      ctx.currentPage = this.currentPage;
      tasksManager
        .fetchImports(ctx)
        .then((result) => {
          this.importsCount = result.totalCount;
          this.imports = result.items;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    deleteImport(item) {
      this.$refs.msgBox
        .show(
          this.$t("editorial-import-remove-import-text"),
          this.$t("editorial-import-remove-import-title")
        )
        .then(() => {
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "editorial-import-remove-wait"
          );

          tasksManager
            .deleteImport(item.id)
            .then(() => {
              this.$store.state.global_overlay = false;
              this.fetchImports();
            })
            .catch((err) => {
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
