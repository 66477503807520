<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    max-height="400"
    :value="openDialog"
  >
    <SetString ref="setItemText" />
    <MessageBox ref="msgBox" />
    <input
      type="file"
      id="fileUpload"
      ref="file"
      v-on:change="handleImport()"
      hidden
    />
   
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-card class="pa-0 ">
            <v-toolbar color="orange" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-key</v-icon>
              </v-app-bar-nav-icon>

              <v-toolbar-title class="white--text">{{$t('scraper-zones-title')}}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row class="text-center" align="start" justify="center">
                <v-col class="text-left">
                  <v-list
                    v-if="!loadingZones"
                    style="max-height: 400px"
                    class="overflow-y-auto"
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model="selectedZone"
                      color="primary"
                    >
                      <template v-for="item in zones">
                        <v-list-item :key="item.id">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.v }}</v-list-item-title>
                            <v-list-item-subtitle
                              >{{$t('scraper-zones-total')}}
                              {{ item.count }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-row class="text-center" align="start" justify="center">
                    <v-col>
                      <v-progress-circular
                        v-if="loadingZones"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="light-blue"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-list
                    v-if="!loadingZonesItems"
                    style="max-height: 400px"
                    class="overflow-y-auto"
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model="selectedZoneItem"
                      color="primary"
                    >
                      <template v-for="item in zonesItems">
                        <v-list-item :key="item.id">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{
                              item.v
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-row class="text-center" align="start" justify="center">
                    <v-col>
                      <v-progress-circular
                        v-if="loadingZonesItems"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="light-blue"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="text-center" align="center" justify="center">
                <v-col>
                  <v-btn
                    :disabled="loadingZones"
                    v-on:click="addGroup()"
                    class="mr-2 white--text"
                    color="orange"
                    ><v-icon>mdi-database-plus</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="loadingZones || selectedZone == null"
                    v-on:click="removeGroup()"
                    class="mr-2 white--text"
                    color="red"
                    ><v-icon>mdi-database-minus</v-icon></v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    :disabled="selectedZone == null || loadingZonesItems"
                    v-on:click="addItem()"
                    class="mr-2 white--text"
                    color="orange"
                    ><v-icon>mdi-key-plus</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="
                      selectedZone == null ||
                        loadingZonesItems ||
                        selectedZoneItem == null
                    "
                    v-on:click="removeItem()"
                    class="mr-2 white--text"
                    color="red"
                    ><v-icon>mdi-key-minus</v-icon></v-btn
                  >

                  <v-btn
                    :disabled="selectedZone == null || loadingZonesItems"
                    v-on:click="importItems()"
                    class="mr-2 white--text"
                    color="purple"
                    ><v-icon>mdi-file-import</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import zonesManager from "../../apis/scraper/zones";
import SetString from "../SetString.vue";
import MessageBox from "../MessageBox";

export default {
  data() {
    return {
      dialogResolve: null,
      dialogReject: null,
      openDialog: false,
      loadingZones: true,
      loadingZonesItems: false,
      zones: [],
      zonesItems: [],
      selectedZone: null,
      selectedZoneItem: null,
      importFile: null,
    };
  },

  components: {
    SetString,
    MessageBox,
  },

  watch: {
    selectedZone: {
      handler() {
        this.selectedZoneItem = null;
        if (this.selectedZone != null) {
          let index = this.selectedZone;
          let item = this.zones[index];
          this.fetchZonesItems(item);
        } else this.zonesItems = [];
      },
    },
  },

  methods: {
    show() {
      return new Promise((resolve, reject) => {
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.selectedZone = null;
        this.fetchZones();
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve();
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },

    fetchZones() {
      this.loadingZones = true;
      this.zonesItems = [];
      zonesManager
        .fetchZones()
        .then((res) => {
          this.zones = res.items;
          this.loadingZones = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingZones = false;
        });
    },

    fetchZonesItems(item) {
      this.loadingZonesItems = true;
      zonesManager
        .fetchZonesItems(item.id)
        .then((res) => {
          this.zonesItems = res.items;
          this.loadingZonesItems = false;
          item.count = res.items.length;
        })
        .catch((err) => {
          console.log(err);
          this.loadingZonesItems = false;
        });
    },


    addGroup() {
      this.$refs.setItemText
        .show(
         this.$t('scraper-zones-addgroup'),
          this.$t('scraper-zones-groupname'),
         this.$t('scraper-zones-insertgroupname'),
          "",
          null,
          true,
          false
        )
        .then((res) => {
          let zoneName = res.value;
          zonesManager.addZone(zoneName).then(() => {
            this.fetchZones();
          });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    removeGroup() {
      if (this.selectedZone != null) {
        let zone = this.zones[this.selectedZone];
        this.$refs.msgBox
          .show(
            this.$t('scraper-zones-deletegroup'),
            this.$t('scraper-zones-confirmdeletegroup')
          )
          .then(() => {
            zonesManager.removeZone(zone.id).then(() => {
              this.fetchZones();
            });
          })
          .catch(() => {});
      }
    },

    addItem() {
      this.$refs.setItemText
        .show(
         this.$t('scraper-zones-addzone'),
          this.$t('scraper-zones-zonename'),
         this.$t('scraper-zones-insertzonename'),
          "",
          null,
          true,
          false
        )
        .then((res) => {
          let zone = this.zones[this.selectedZone];
          let zoneName = res.value;
          zonesManager.addZoneItem(zoneName, zone.id).then(() => {
            let index = this.selectedZone;
            let item = this.zones[index];
            this.fetchZonesItems(item);
          });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    removeItem() {
      if (this.selectedZoneItem != null) {
        let zone = this.zonesItems[this.selectedZoneItem];
        this.$refs.msgBox
          .show(
            this.$t('scraper-zones-deletezone'),
            this.$t('scraper-zones-confirmdeletezone')
          )
          .then(() => {
            zonesManager.removeZoneItem(zone.id).then(() => {
              let index = this.selectedZone;
              let item = this.zones[index];
              this.fetchZonesItems(item);
            });
          })
          .catch(() => {});
      }
    },

    importItems() {
      document.getElementById("fileUpload").click();
    },

    handleImport() {
      this.importFile = this.$refs.file.files[0];
      let index = this.selectedZone;
      let item = this.zones[index];
      let formData = new FormData();
      formData.append("id", item.id);
      formData.append("file", this.importFile);
      zonesManager.importItems(formData).then(() => {
        let index = this.selectedZone;
        let item = this.zones[index];
        this.fetchZonesItems(item);
        this.$refs.file.value = null;
      });
    },
  },
};
</script>
