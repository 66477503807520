const LocalStorageService = (function(){
    
    function _setToken(tokenObj) {
      localStorage.setItem('access_token', tokenObj.access_token);
      localStorage.setItem('refresh_token', tokenObj.refresh_token);
    }
    
    function _setAvatar(avatar) {
      if (avatar) localStorage.setItem('local_avatar', avatar);
      else localStorage.removeItem('local_avatar');
    }
    
    function _getAvatar() {
      return localStorage.getItem('local_avatar');
    }

    function _setNotifications(notifications) {
      if (notifications) localStorage.setItem('local_notifications', notifications);
      else localStorage.removeItem('local_notifications');
    }
    
    function _getNotifications() {
      return localStorage.getItem('local_notifications');
    }

    function _setLocale(locale) {
      if (locale) localStorage.setItem('locale', locale);
      else localStorage.removeItem('locale');
    }
    
    function _getLocale() {
      let locale = localStorage.getItem('locale');
      if (locale) return locale;
      else return "it";
    }

    function _getAccessToken() {
      return localStorage.getItem('access_token');
    }

    function _getRefreshToken() {
      return localStorage.getItem('refresh_token');
    }

    function _clearToken() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }

    function _clearData() {
      localStorage.removeItem('local_data');
    }
   
    function _getLocalData() {
      let data = {};
      try {
        let localData = localStorage.getItem('local_data');
        data = JSON.parse(localData);
      }
      
      catch(err) {
        data = {};
      }
      return data;
    }

    function _setLocalData(localData) {
      localStorage.setItem('local_data', JSON.stringify(localData));
    }

    return {
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken,
      clearData : _clearData,
      getAvatar : _getAvatar,
      setAvatar : _setAvatar,
      getNotifications : _getNotifications,
      setNotifications : _setNotifications,
      setLocalData : _setLocalData,
      getLocalData : _getLocalData,
      getLocale : _getLocale,
      setLocale : _setLocale
    }
   })();
   
   export default LocalStorageService;