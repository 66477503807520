var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('messageBox',{ref:"msgBox"}),_c('div',{staticStyle:{"height":"10px"}}),_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-home","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(" "+_vm._s(_vm.$t("editorial-imports-title"))+" ")])]},proxy:true}])},[_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.imports,"options":_vm.options,"server-items-length":_vm.importsCount,"loading":_vm.loading,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.state.hasRight('182'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteImport(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("editorial-import-delete")))])])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [(_vm.checkGravatar(item) && _vm.getGravatar(item))?_c('v-avatar',{attrs:{"size":"36px"}},[_c('v-img',{attrs:{"src":_vm.getGravatar(item),"alt":_vm.getUsername(item)}})],1):(_vm.getProfileLetters(item))?_c('v-avatar',{attrs:{"color":"green","size":"36px"}},[_c('span',{staticClass:"profile-text"},[_vm._v(_vm._s(_vm.getProfileLetters(item)))])]):_vm._e(),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.moment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.moment))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }