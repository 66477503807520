<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="700"
    :value="openDialog"
    @input="closeDialog()"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-phone</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("voip-caller-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-row v-if="inCall"
                ><v-col
                  ><v-icon large>mdi-phone-in-talk</v-icon>
                  <v-row
                    ><v-col>
                      {{
                        this.item ? $t("voip-caller-calling") + this.phone : ""
                      }}</v-col
                    ></v-row
                  ></v-col
                ></v-row
              >

              <v-row v-if="connecting"
                ><v-col
                  ><v-icon color="secondary" large> mdi-phone-voip</v-icon>
                  <v-row
                    ><v-col>
                      {{
                        this.item
                          ? $t("voip-caller-connecting") + this.phone
                          : ""
                      }}</v-col
                    ></v-row
                  ></v-col
                ></v-row
              >

              <v-row v-if="!inCall && !connecting"
                ><v-col
                  ><v-icon color="green" large> mdi-phone-voip</v-icon>
                  <v-row
                    ><v-col>
                      {{
                        this.item
                          ? $t("voip-caller-terminated", {
                              num: this.phone
                            })
                          : ""
                      }}</v-col
                    ></v-row
                  ></v-col
                ></v-row
              >

              <v-row v-if="inCall && !callCompleted"
                ><v-col> {{ this.elapsedSeconds }}</v-col>
              </v-row>

              <v-row v-if="callCompleted"
                ><v-col>
                  Durata totale:
                  {{ this.elapsedSeconds }}</v-col
                >
              </v-row>

              <v-row>
                <v-col>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        text
                        color="danger"
                        @click.stop="hangUp(item)"
                        :disabled="!call || callCompleted"
                        v-on="on"
                        ><v-icon x-large color="red">mdi-phone-hangup</v-icon
                        >{{ $t("voip-caller-hangup") }}</v-btn
                      ></template
                    ><span>{{ $t("voip-caller-hangup") }}</span></v-tooltip
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
String.prototype.toHHMMSS = function() {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export default {
  data() {
    return {
      item: null,
      phone: null,
      device: null,
      openDialog: false,
      outcomed: false,
      call: null,
      callStartTime: null,
      inCall: false,
      connecting: false,
      aborted: false,
      callCompleted: false,
      elapsedSeconds: "00:00:00"
    };
  },

  props: ["value", "enableOutcome"],

  mounted() {
    setInterval(() => {
      this.computeElapsedTime();
    }, 1000);
  },

  methods: {
    computeElapsedTime() {
      if (this.callStartTime && this.inCall) {
        this.elapsedSeconds = (Date.now() / 1000 - this.callStartTime)
          .toString()
          .toHHMMSS();
      }
    },

    hangUp() {
      if (this.call) {
        this.call.disconnect();
        this.call = null;
      }
    },

    closeDialog() {
      if (this.call) {
        this.call.disconnect();
      } else this.aborted = true;
      this.$emit("callClosed", this.item);
      this.resetModal();
    },

    outcomeItem() {
      this.$emit("outcome", this.item);
    },

    acceptDialog() {
      if (this.call) {
        this.call.disconnect();
      }
      this.$emit("callCompleted", this.item);
      this.resetModal();
    },

    resetModal() {
      this.call = null;
      this.connecting = false;
      this.device = null;
      this.item = null;
      this.phone = null;
      this.inCall = true;
      this.callCompleted = false;
      this.callStartTime = null;
      this.openDialog = false;
      this.aborted = false;
      this.elapsedSeconds = "00:00:00";
    },

    async startCall(item, device, phone) {
      this.outcomed = false;
      this.item = item;
      this.phone = phone;
      this.device = device;
      this.openDialog = true;
      this.connecting = true;
      this.inCall = false;
      this.callCompleted = false;
      this.aborted = false;
      var params = { To: this.phone, ContactId: this.item ? item.id : 0 };
      this.call = await this.device.connect({ params });
      this.call.on("accept", () => {
        if (this.aborted) {
          this.call.disconnect();
        } else {
          this.connecting = false;
          this.inCall = true;
          let callSid = this.call.parameters
            ? this.call.parameters.CallSid
            : null;
          if (this.item) this.item.callSid = callSid;
          if (!this.outcomed && this.item) this.outcomeItem(this.item);
          this.outcomed = true;
          this.callCompleted = false;
          this.callStartTime = Date.now() / 1000;
        }
      });
      this.call.on("disconnect", () => {
        if (this.aborted) {
          this.call.disconnect();
          if (this.item) this.$emit("callClosed", this.item);
          this.resetModal();
        }

        this.inCall = false;
        this.callCompleted = true;
        let callSid = this.call.parameters
          ? this.call.parameters.CallSid
          : null;
        this.item.callSid = callSid;
        if (!this.outcomed && this.item) this.outcomeItem(this.item);
        this.outcomed = true;
        this.connecting = false;
        this.aborted = false;
      });
      this.call.on("cancel", () => {
        if (this.aborted) {
          this.call.disconnect();
          if (this.item) this.$emit("callClosed", this.item);
          this.resetModal();
        }
        this.inCall = false;
        this.callCompleted = false;
        this.connecting = false;
        this.aborted = false;
      });
    }
  }
};
</script>
