<template>
  <div>
    <messageBox ref="msgBox" />
    <VoipAudioPlayer ref="audioPlayerDialog" v-model="audioPlayerDialog" />

    <v-card class="ma-4">
      <v-toolbar color="primary" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-phone</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("voiprec-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row class="text-center">
        <v-col>
          <v-progress-circular
            v-if="fetchingFilters"
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <div style="margin:15px">
        <v-row v-if="!fetchingFilters" justify="center">
          <v-col class="text-center">
            <v-text-field
              dense
              outlined
              :label="$t('voiprec-filter-name')"
              :disabled="loading"
              :value="filter"
              @change="v => (filter = v)"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('voiprec-filter-operator')"
              return-object
              :disabled="loading"
              hide-no-data
            ></v-autocomplete>
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="loading"
              dense
              v-model="filters.outcome"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_outcomes"
              :label="$t('editorial-filter-outcomes')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="loading"
              dense
              v-model="filters.suboutcome"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_suboutcomes"
              :label="$t('editorial-filter-suboutcomes')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="loading"
              dense
              v-model="filters.rating"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_ratings"
              :label="$t('editorial-filter-rating')"
              hide-details
            />
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('voiprec-filter-datestart')"
                  :disabled="loading"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('voiprec-filter-dateend')"
                  :disabled="loading"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
      <div class="vertical-spacer" />
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <base-material-stats-card
            color="primary"
            icon="mdi-phone"
            :title="$t('voiprec-total-duration')"
            :value="computedDuration"
            sub-icon="mdi-calendar"
          />
        </v-col>
      </v-row>
      <v-data-table
        v-if="!fetchingFilters"
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="calls"
        :options.sync="options"
        :server-items-length="totalCalls"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip v-if="$store.state.hasRight('46')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="success"
                @click.stop="downloadCall(item)"
                v-on="on"
                ><v-icon>mdi-arrow-down</v-icon></v-btn
              ></template
            ><span>{{ $t("voiprec-action-download") }}</span></v-tooltip
          >

          <v-tooltip v-if="$store.state.hasRight('24')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="purple"
                @click.stop="playCall(item)"
                v-on="on"
                ><v-icon>mdi-phone</v-icon></v-btn
              ></template
            ><span>{{ $t("voiprec-action-listen") }}</span></v-tooltip
          >

          <v-tooltip v-if="$store.state.hasRight('44')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="deleteItem(item)"
                v-on="on"
                ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
              ></template
            ><span>{{ $t("voiprec-action-delete") }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.start_time="{ item }">
          {{ item.start_time | toLocaleDateTime }}
        </template>

        <template v-slot:item.end_time="{ item }">
          {{ item.end_time | toLocaleDateTime }}
        </template>

        <template v-slot:item.call_duration="{ item }">
          {{ formatDuration(item) }}
        </template>

        <template v-slot:item.contact_phone="{ item }">
          {{ item.contact_phone }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import voip from "../apis/voip";
import config from "../config";
import localStorageService from "../services/localStorageService";

import editorial from "../apis/editorial";
import staticData from "../apis/static";
import AvatarServices from "../services/avatars";
import messageBox from "../components/MessageBox";
import VoipAudioPlayer from "../components/recorder/VoipAudioPlayer";
import axios from "axios";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      results: [],
      calls: [],

      totalDuration: 0,
      searchOperatorInput: null,
      operator: null,
      operators: [],
      isLoadingOperator: false,

      loading: false,
      totalCalls: 0,
      perPage: staticData.defaultPerPage,
      options: {
        sortBy: ["start_time"],
        sortDesc: [true],
        itemsPerPage: 50,
        currentPage: 1
      },
      headers: [],
      filter: null,
      filters: {
        operator: null,
        group: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        rating : null,
        outcome: null,
        suboutcome: null
      },

      fetchingData: false,
      fetchingFilters: true,

      audioPlayerDialog: false,

      flt_outcomes: [],
      flt_suboutcomes: [{ id: null, name: this.$t("gbl-all-male") }],
      flt_ratings: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" }
      ]
    };
  },

  components: {
    messageBox,
    VoipAudioPlayer
  },

  computed: {
    computedDuration() {
      if (!this.totalDuration) return null;

      let format = "";
      let seconds = parseInt(Math.floor(this.totalDuration));
      let hours = Math.floor(seconds / 60 / 60);
      if (hours < 1) hours = 0;
      seconds -= 60 * 60 * hours;
      let minutes = Math.floor(seconds / 60);
      seconds -= 60 * minutes;
      if (seconds < 1) seconds = 1;
      if (hours > 0) format += hours + " " + this.$t("gbl-hours") + " ";
      if (minutes > 0 && minutes.toString().length < 2) format += "0";
      if (minutes > 0) format += minutes + " " + this.$t("gbl-minutes") + " ";
      else format += "00:";
      if (seconds.toString().length < 2) format += "0";
      format += seconds;
      format += " " + this.$t("gbl-seconds");

      return format;
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("voiprec-tbl-operator"),
        align: "left",
        sortable: true,
        value: "nome"
      },
      {
        text: this.$t("voiprec-tbl-code"),
        align: "left",
        sortable: true,
        value: "ope_code"
      },

      {
        text: this.$t("voiprec-tbl-date"),
        align: "center",
        sortable: true,
        value: "start_time"
      },
      {
        text: this.$t("voiprec-tbl-date"),
        align: "center",
        sortable: true,
        value: "end_time"
      },
      {
        text: this.$t("voiprec-tbl-length"),
        align: "center",
        sortable: true,
        value: "call_duration"
      },

      {
        text: this.$t("voiprec-tbl-number"),
        align: "center",
        sortable: true,
        value: "contact_phone"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    this.operators = [];

    this.fetchOutcomes()
      .then(() => {
        this.flt_outcomes = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.def_outcomes
        ];
        this.fetchingFilters = false;
        this.fetchingData = false;
        this.fetchingData = false;
      })
      .catch(err => {
        this.fetchingFilters = false;
        this.fetchingData = false;
        console.log(err);
      });
  },

  watch: {
    "filters.outcome": {
      handler() {
        this.filters.suboutcome = null;
        this.flt_suboutcomes = [{ id: null, name: this.$t("gbl-all-male") }];
        if (this.filters.outcome) {
          const outcome = this.def_outcomes_by_id[this.filters.outcome];

          if (outcome.sub) {
            this.flt_suboutcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...outcome.sub.map(x => {
                return { id: x.id, name: x.name };
              })
            ];
          }
        }
      }
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then(res => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      }
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadCalls();
        }, config.searchInputsFastTimeout);
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadCalls();
        }, config.searchInputsFastTimeout);
      },

      deep: true
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadCalls();
      },

      deep: true
    }
  },

  methods: {
    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchOutcomes()
          .then(result => {
            this.fetchingOutcomes = false;
            this.def_outcomes = result.outcomes;
            this.def_outcomes_by_id = {};
            for (let x in this.def_outcomes) {
              this.def_outcomes[x].name = this.$t(this.def_outcomes[x].name);
              this.def_outcomes_by_id[
                this.def_outcomes[x].id
              ] = this.def_outcomes[x];
              if (this.def_outcomes[x].sub) {
                for (let y in this.def_outcomes[x].sub) {
                  this.def_outcomes[x].sub[y].name = this.$t(
                    this.def_outcomes[x].sub[y].name
                  );
                }
              }
            }

            resolve();
          })
          .catch(err => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    reloadCalls: function() {
      this.calls = [];
      this.totalCalls = 0;
      this.fetchCalls()
        .then(data => {
          this.calls = data.items;
          this.totalCalls = data.totalCount;
          this.totalDuration = data.duration;
        })
        .catch(err => {
          console.log(err);
        });
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    formatDuration: function(item) {
      let duration = item.duration;
      let format = "";
      let seconds = parseInt(Math.floor(duration));
      let hours = Math.floor(seconds / 60 / 60);
      if (hours < 1) hours = 0;
      seconds -= 60 * 60 * hours;
      let minutes = Math.floor(seconds / 60);
      seconds -= 60 * minutes;
      if (seconds < 1) seconds = 1;
      if (hours > 0) format += hours + ":";
      if (minutes > 0 && minutes.toString().length < 2) format += "0";
      if (minutes > 0) format += minutes + ":";
      else format += "00:";
      if (seconds.toString().length < 2) format += "0";
      format += seconds;
      return format;
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        voip
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchCalls() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        voip
          .fetchCalls(ctx)
          .then(result => {
            this.loading = false;
            resolve(result);
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("callrec-delete-confirm"),
          this.$t("callrec-delete-title")
        )
        .then(() => {
          voip
            .removeCall(item.id)
            .then(() => {
              this.reloadCalls();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    downloadCall(p) {
      const token = localStorageService.getAccessToken();
      let tokens = p.path.split("/");
      let filename = tokens.pop();
      const url =
        config.apiEndPoint +
        "/voip/download/" +
        p.id +
        "?token=" +
        encodeURIComponent(token);

      axios
        .get(url, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    playCall(item) {
      this.$refs.audioPlayerDialog.setCall(item);
      this.audioPlayerDialog = true;
    }
  }
};
</script>
