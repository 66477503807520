<template>
  <div>
    <v-dialog
      overlay-opacity="0.6"
      width="600"
      :value="interviewDatePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="interviewDatePickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-date-picker
                      landscape
                      v-model="interviewPickerDate"
                    ></v-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmChangeInterviewDate()"
                color="green"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="interviewDatePickerDialog = false"
                color="primary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog overlay-opacity="0.6" width="600" :value="recallDatePickerDialog">
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="recallDatePickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-date-picker
                      landscape
                      v-model="recallPickerDate"
                    ></v-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmChangeRecallDate()"
                color="green"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="recallDatePickerDialog = false"
                color="primary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog overlay-opacity="0.6" width="600" :value="recallTimePickerDialog">
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="recallTimePickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text style="min-height:200px;">
                <v-row>
                  <v-col>
                    <vue-timepicker
                      :minute-interval="5"
                      v-model="recallPickerTime"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmChangeRecallTime()"
                color="green"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="recallTimePickerDialog = false"
                color="primary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      width="600"
      persistent
      :value="interviewTimePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-time") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="interviewTimePickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <vue-timepicker
                      :minute-interval="5"
                      v-model="interviewPickerTime"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmChangeInterviewTime()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="interviewTimePickerDialog = false"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <messageBox ref="msgBox" />
    <EditorialContactOutcomeEditor
      ref="contactOutcomeEditor"
      v-model="contactOutcomeDialog"
      @outcomeConfigured="onContactOutcomeConfigured"
      @contactRelocated="onContactRelocated"
      @contactMoved="onContactMoved"
      :updateMode="false"
      :radios="radios"
      :types="types"
      :programs="$store.state.programs"
      :territories_radios="$store.state.territories_radios"
      :prefills="outcomes_prefills"
    />

    <EditorialContactOutcomeEditor
      ref="yesterdayContactOutcomeEditor"
      v-model="yesterdayContactOutcomeDialog"
      @outcomeConfigured="onYesterdayPendingContactOutcomeConfigured"
      @contactRelocated="onContactRelocated"
      @contactMoved="onContactMoved"
      :updateMode="false"
      :radios="radios"
      :types="types"
      :programs="$store.state.programs"
      :territories_radios="$store.state.territories_radios"
      :prefills="outcomes_prefills"
    />

    <EditorialContactOutcomeEditor
      ref="archiveContactOutcomeEditor"
      v-model="archiveContactOutcomeDialog"
      @outcomeConfigured="onArchiveContactOutcomeConfigured"
      @contactRelocated="onContactRelocated"
      @contactMoved="onContactMoved"
      :updateMode="false"
      :radios="radios"
      :types="types"
      :programs="$store.state.programs"
      :territories_radios="$store.state.territories_radios"
      :prefills="outcomes_prefills"
    />

    <EditorialOutcomesResume
      ref="outcomesResumeDialog"
      v-model="outcomesResumeDialog"
    />

    <voipCaller
      ref="voipCaller"
      @callClosed="voipCallClosed"
      @callCompleted="voipCallCompleted"
      :enableOutcome="outcomeEnabled"
      @outcome="voipCallOutcome"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-cellphone-sound</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("editorial-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-progress-circular
              v-if="acquiringContact || acquiringMailContact"
              :indeterminate="true"
              :rotate="0"
              :size="24"
              :width="3"
              color="light-blue"
            ></v-progress-circular>

            <v-row>
              <v-col>
                <v-text-field
                  v-if="$store.state.hasRight('186')"
                  :disabled="localCallInProgress"
                  v-model="freeCallNumber"
                  :label="$t('editorial-free-call')"
                  hide-details
                  single-line
                  outlined
                  dense
                  style="margin: auto; max-width: 400px;"
                  ><template slot="append-outer">
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          :disabled="
                            localCallInProgress ||
                              !freeCallNumber ||
                              !deviceReady
                          "
                          small
                          text
                          icon
                          color="orange"
                          @click.stop="onFreeCall()"
                          v-on="on"
                          ><v-icon>mdi-phone</v-icon></v-btn
                        ></template
                      ><span>{{ $t("editorial-free-call") }}</span></v-tooltip
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-6 mb-6">
              <v-col>
                <v-text-field
                  v-if="
                    !workingState &&
                      !changingWorkingState &&
                      !fetchingWorkingState &&
                      !fetchingOutcomes &&
                      !acquiringContact
                  "
                  :disabled="searchingContact"
                  v-model="checkContact"
                  :label="$t('editorial-check')"
                  hide-details
                  single-line
                  outlined
                  dense
                  style="margin: auto; max-width: 400px;"
                  ><template slot="append-outer">
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          v-if="!searchingContact && !acquiringContact"
                          :disabled="
                            !checkContact ||
                              !searchNumberIsValid ||
                              searchContactCompleted != null
                          "
                          small
                          text
                          icon
                          color="orange"
                          @click.stop="onSearchContact()"
                          v-on="on"
                          ><v-icon>mdi-magnify</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("editorial-contacts-search")
                      }}</span></v-tooltip
                    >
                    <v-progress-circular
                      v-if="searchingContact"
                      :indeterminate="true"
                      :rotate="0"
                      :size="24"
                      :width="3"
                      color="light-blue"
                    ></v-progress-circular>
                    <v-tooltip
                      bottom
                      v-if="!searchingContact && seachContactAssigned"
                      ><template v-slot:activator="{ on }"
                        ><v-icon v-on="on" color="red"
                          >mdi-account-circle</v-icon
                        ></template
                      ><span>{{
                        $t("editorial-contacts-already-processed")
                      }}</span></v-tooltip
                    >

                    <v-tooltip
                      bottom
                      v-if="
                        !searchingContact &&
                          !seachContactAssigned &&
                          !seachContactInQueue &&
                          searchContactCompleted &&
                          !acquiringContact
                      "
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          small
                          color="success"
                          @click.stop="acquireContact()"
                          v-on="on"
                          ><v-icon>mdi-account-convert</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("editorial-contacts-acquire-new-contact")
                      }}</span></v-tooltip
                    >
                    <v-tooltip
                      bottom
                      v-if="
                        !searchingContact &&
                          !seachContactAssigned &&
                          seachContactInQueue &&
                          searchContactCompleted &&
                          !acquiringContact
                      "
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          small
                          text
                          icon
                          color="orange"
                          @click.stop="acquireExistingContact()"
                          v-on="on"
                          ><v-icon>mdi-account-convert</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("editorial-contacts-acquire-scraped-contact")
                      }}</span></v-tooltip
                    >

                    <v-tooltip
                      bottom
                      v-if="
                        !searchingContact &&
                          seachContactInQueue &&
                          searchContactOutcomes &&
                          !acquiringContact
                      "
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          class="ml-2"
                          small
                          text
                          icon
                          color="green"
                          @click.stop="outcomesSearchResume(searchContactId)"
                          v-on="on"
                          ><v-icon>mdi-target</v-icon
                          ><span>{{ searchContactOutcomes }}</span></v-btn
                        ></template
                      >
                      <span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-6 mb-6">
              <v-col>
                <v-text-field
                  v-if="
                    !workingState &&
                      !changingWorkingState &&
                      !fetchingWorkingState &&
                      !fetchingOutcomes &&
                      !acquiringMailContact
                  "
                  :disabled="searchingContactMail"
                  v-model="checkContactMail"
                  :label="$t('editorial-check-email')"
                  hide-details
                  single-line
                  outlined
                  dense
                  style="margin: auto; max-width: 400px;"
                  ><template slot="append-outer">
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          :disabled="
                            searchingContactMail ||
                              !checkContactMail ||
                              !searchMailIsValid ||
                              searchContactMailCompleted != null
                          "
                          small
                          text
                          icon
                          color="orange"
                          @click.stop="onSearchContactMail()"
                          v-on="on"
                          ><v-icon>mdi-magnify</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("editorial-contacts-search")
                      }}</span></v-tooltip
                    >
                    <v-progress-circular
                      v-if="searchingContactMail"
                      :indeterminate="true"
                      :rotate="0"
                      :size="24"
                      :width="3"
                      color="light-blue"
                    ></v-progress-circular>
                    <v-tooltip
                      bottom
                      v-if="!searchingContactMail && seachContactMailAssigned"
                      ><template v-slot:activator="{ on }"
                        ><v-icon v-on="on" color="red"
                          >mdi-account-circle</v-icon
                        ></template
                      ><span>{{
                        $t("editorial-contacts-already-processed")
                      }}</span></v-tooltip
                    >

                    <v-tooltip
                      bottom
                      v-if="
                        !searchingContactMail &&
                          !seachContactMailAssigned &&
                          !seachContactMailInQueue &&
                          searchContactMailCompleted &&
                          !acquiringMailContact
                      "
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          small
                          color="success"
                          @click.stop="acquireMailContact()"
                          v-on="on"
                          ><v-icon>mdi-account-convert</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("editorial-contacts-acquire-new-contact")
                      }}</span></v-tooltip
                    >
                    <v-tooltip
                      bottom
                      v-if="
                        !searchingContactMail &&
                          !seachContactAssigned &&
                          seachContactInQueue &&
                          searchContactMailCompleted &&
                          !acquiringtMailContact
                      "
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          small
                          text
                          icon
                          color="orange"
                          @click.stop="acquireExistingContact()"
                          v-on="on"
                          ><v-icon>mdi-account-convert</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("editorial-contacts-acquire-scraped-contact")
                      }}</span></v-tooltip
                    >

                    <v-tooltip
                      bottom
                      v-if="
                        !searchingContactMail &&
                          seachContactMailInQueue &&
                          searchContactMailOutcomes &&
                          !acquiringtMailContact
                      "
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          class="ml-2"
                          small
                          text
                          ico
                          color="green"
                          @click.stop="
                            outcomesSearchResume(searchContactMailId)
                          "
                          v-on="on"
                          ><v-icon>mdi-target</v-icon
                          ><span>{{ searchContactMailOutcomes }}</span></v-btn
                        ></template
                      >
                      <span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col></v-row
        >
        <v-row
          v-if="
            !searchingContact &&
              !searchContactCompleted &&
              !searchingContactMail &&
              !searchContactMailCompleted
          "
        >
          <v-col
            v-if="
              changingWorkingState ||
                fetchingWorkingState ||
                fetchingOutcomes ||
                fetchingData
            "
          >
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>

          <v-col
            class="text-center"
            v-if="
              !workingState &&
                !changingWorkingState &&
                !fetchingWorkingState &&
                !fetchingOutcomes
            "
          >
            <v-btn
              class="font-weight-black text-h5 mt-1 mb-1"
              @click="startWorking"
              color="success"
              :disabled="yesterdayContacts.length > 0"
              >{{ $t("editorial-start") }}</v-btn
            >
            <p
              class="mt-1 red white--text text-h5"
              v-if="yesterdayContacts.length > 0"
            >
              {{ $t("editorial-start-blocked") }}
            </p>
          </v-col>
          <v-col
            v-if="
              workingState &&
                !changingWorkingState &&
                !fetchingWorkingState &&
                !fetchingOutcomes &&
                !fetchingData
            "
          >
            <v-btn
              :disabled="!can_stop_working"
              class="font-weight-black text-h5 mt-1 mb-1"
              @click="stopWorking"
              color="warning"
              >{{ $t("editorial-stop") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <base-material-card
              color="green"
              icon="mdi-phone"
              :title="$t('editorial-op-dashboard-totalcalls')"
            >
              <v-card-text>
                <v-divider />
                <div style="height:10px" />

                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-totalcontacts") }}
                  </v-col>
                  <v-col>
                    {{ statistics.totalContacts }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-todaycontacts") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayContacts }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-comulative-outcomes-time") }}
                  </v-col>
                  <v-col>
                    {{ formatElapsed(statistics.todayOutcomesTime) }}
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <base-material-card
              color="primary"
              icon="mdi-target"
              :title="$t('editorial-op-dashboard-todayoutcomes')"
            >
              <v-card-text>
                <v-divider />
                <div style="height:10px" />
                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-outcome-yes") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesYes }} /
                    {{ statistics.todayOutcomesYesPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-outcome-no") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesNo }} /
                    {{ statistics.todayOutcomesNoPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-outcome-nr") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesNoAnswer }} /
                    {{ statistics.todayOutcomesNoAnswerPerc }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-outcome-discarded") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesDiscarded }} /
                    {{ statistics.todayOutcomesDiscardedPerc }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    {{ $t("editorial-op-dashboard-outcome-already-done") }}
                  </v-col>
                  <v-col>
                    {{ statistics.todayOutcomesAlready }} /
                    {{ statistics.todayOutcomesAlreadyPerc }}
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <base-material-card
              color="orange"
              icon="mdi-target"
              :title="$t('editorial-op-dashboard-month-stats')"
            >
              <v-card-text>
                <v-divider />
                <div style="height:10px" />
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        {{ $t("editorial-op-dashboard-monthoutcomes") }}
                      </v-col>
                      <v-col>{{ statistics.monthOutcomes }} </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        {{ $t("editorial-op-dashboard-to-be-recall") }}
                      </v-col>
                      <v-col>{{ statistics.toBeCalled }} </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        {{ $t("editorial-op-dashboard-interview-made") }}
                      </v-col>
                      <v-col>
                        {{ statistics.interviewsMade }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        {{ $t("editorial-op-dashboard-perc-missing") }}
                      </v-col>
                      <v-col> {{ statistics.missing }} </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        {{ $t("editorial-op-dashboard-num-products") }}
                      </v-col>
                      <v-col> {{ statistics.video }} </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        {{
                          $t("editorial-op-dashboard-comulative-outcomes-time")
                        }}
                      </v-col>
                      <v-col>
                        {{ formatElapsed(statistics.monthOutcomesTime) }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>

        <v-progress-circular
          v-if="fetchingOutcomes || fetchingWorkingState || fetchingData"
          :indeterminate="true"
          :rotate="0"
          :size="24"
          :width="3"
          color="light-blue"
        ></v-progress-circular>

        <v-card
          v-if="!fetchingOutcomes && !fetchingWorkingState && !fetchingData"
          class="ma-2"
        >
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1 font-weight-black text-h5">
              {{ $t("editorial-recall") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              id="yesterday_table"
              dense
              :must-sort="false"
              :headers="yesterdayHeaders"
              :items="yesterdayContacts"
              :items-per-page="perPage"
              :options.sync="yesterdayOptions"
              :disable-sort="true"
              :server-items-length="totalYesterdayContacts"
              :loading="fetchingYesterdayContacts"
              item-key="id"
              :item-class="getItemClass"
              :footer-props="dataTableFooterOptions"
            >
              <template v-slot:item.category_testuale="{ item }">
                <span
                  v-html="
                    item.category_testuale
                      ? item.category_testuale.split('|||').join('<br/>')
                      : ''
                  "
                />
              </template>
              <template v-slot:item.last_outcome_priority="{ item }">
                <v-icon
                  :color="
                    item.last_outcome_priority == 3
                      ? 'red'
                      : item.last_outcome_priority == 2
                      ? 'orange'
                      : 'green'
                  "
                  >mdi-circle</v-icon
                >{{
                  item.last_outcome_priority == 1
                    ? "Bassa"
                    : item.last_outcome_priority == 2
                    ? "Media"
                    : "Alta"
                }}
              </template>
              <template v-slot:item.recall_date="{ item }">
                {{ item.recall_date | toLocaleDate }}

                <v-tooltip v-if="item.last_outcome_id == 2" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeRecallDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-recall-date")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.recall_time="{ item }">
                {{ item.recall_time | toLocaleTime }}

                <v-tooltip v-if="item.last_outcome_id == 2" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeRecallTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-recall-time")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.interview_date="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleDate(item.interview_date)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-date")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.interview_time="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleTime(item.interview_time)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-time")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.nation="{ item }">
                {{ $t(item.nation) }}
              </template>

              <template v-slot:item.mobile="{ item }">
                <v-icon v-if="item.mobile" color="green">mdi-check</v-icon>
              </template>

              <template v-slot:item.source_name="{ item }">
                {{ $t(item.source_name) }}
              </template>

              <template v-slot:item.guest_id="{ item }">
                <router-link
                  v-if="item.guest_id"
                  :to="'/guests?id=' + item.guest_id"
                >
                  OSP-{{ item.guest_id }}
                </router-link>
              </template>
              <template v-slot:item.url="{ item }">
                <v-tooltip v-if="item.url" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="orange"
                      @click.stop="openLink(item)"
                      v-on="on"
                      ><v-icon>mdi-link</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-contacts-tbl-action-open")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.last_outcome="{ item }">
                <span class="font-weight-bold"
                  >{{ $t(item.last_outcome) }}<br />
                  <span class="font-weight-light">{{
                    $t(item.last_suboutcome)
                  }}</span></span
                >
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        v-if="$store.state.hasRight('162')"
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>
              <template v-slot:item.outcomes_elapsed="{ item }">
                <span :key="item.outcomes_elapsed">{{
                  formatElapsed(item.outcomes_elapsed)
                }}</span>
              </template>

              <template v-slot:item.call_actions="{ item }">
                <div style="min-width:64px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="
                          !deviceReady || !$store.state.hasRight('181')
                        "
                        x-small
                        text
                        icon
                        color="orange"
                        v-on="on"
                        ><v-icon>mdi-phone</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          callContact(
                            item,
                            outcomeYesterdayContact,
                            item.contact_phone
                          )
                        "
                        v-if="item.contact_phone"
                      >
                        <v-list-item-title>{{
                          item.contact_phone
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          callContact(
                            item,
                            outcomeYesterdayContact,
                            item.additional_phone
                          )
                        "
                        v-if="item.additional_phone"
                      >
                        <v-list-item-title>{{
                          item.additional_phone
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:128px">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="sendAutoMail(item)"
                        :disabled="!$store.state.hasRight('181') || !item.email"
                        v-on="on"
                        ><v-icon>mdi-mail</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-send-mail") }}</span></v-tooltip
                  >

                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="sendWA(item)"
                        :disabled="!$store.state.hasRight('181')"
                        v-on="on"
                        ><v-icon>mdi-whatsapp</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-send-wa") }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="
                      item.last_outcome_id != 1 && item.last_outcome_id != 3
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        v-on="on"
                        @click.stop="outcomeYesterdayContact(item)"
                        ><v-icon>mdi-target</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-action-outcome")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card
          v-if="!fetchingOutcomes && !fetchingWorkingState && !fetchingData"
          class="ma-2"
        >
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title
              class="white--text mt-1 font-weight-black text-h5"
              >{{ $t("editorial-today") }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              id="today_table"
              dense
              :must-sort="true"
              :headers="headers"
              :items="contacts"
              :items-per-page="perPage"
              :options.sync="options"
              :server-items-length="totalContacts"
              :loading="fetchingContacts"
              item-key="id"
              :item-class="getItemClass"
              :footer-props="dataTableFooterOptions"
            >
              <template v-slot:item.category_testuale="{ item }">
                <span
                  v-html="
                    item.category_testuale
                      ? item.category_testuale.split('|||').join('<br/>')
                      : ''
                  "
                />
              </template>

              <template v-slot:item.outcomes_elapsed="{ item }">
                <span :key="item.outcomes_elapsed">{{
                  formatElapsed(item.outcomes_elapsed)
                }}</span>
              </template>

              <template v-slot:item.recall_date="{ item }">
                {{ item.recall_date | toLocaleDate }}

                <v-tooltip v-if="item.last_outcome_id == 2" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeRecallDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-recall-date")
                  }}</span></v-tooltip
                >
              </template>
              <template v-slot:item.recall_time="{ item }">
                {{ item.recall_time | toLocaleTime }}
                <v-tooltip v-if="item.last_outcome_id == 2" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeRecallTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-recall-time")
                  }}</span></v-tooltip
                >
              </template>
              <template v-slot:item.interview_date="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleDate(item.interview_date)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-date")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.interview_time="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleTime(item.interview_time)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-time")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.nation="{ item }">
                {{ $t(item.nation) }}
              </template>

              <template v-slot:item.guest_id="{ item }">
                <router-link
                  v-if="item.guest_id"
                  :to="'/guests?id=' + item.guest_id"
                >
                  OSP-{{ item.guest_id }}
                </router-link>
              </template>

              <template v-slot:item.mobile="{ item }">
                <v-icon v-if="item.mobile" color="green">mdi-check</v-icon>
              </template>

              <template v-slot:item.source_name="{ item }">
                {{ $t(item.source_name) }}
              </template>

              <template v-slot:item.url="{ item }">
                <v-tooltip v-if="item.url" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="orange"
                      @click.stop="openLink(item)"
                      v-on="on"
                      ><v-icon>mdi-link</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-contacts-tbl-action-open")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>

              <template v-slot:item.last_outcome="{ item }">
                <span class="font-weight-bold"
                  >{{ $t(item.last_outcome) }}<br />
                  <span class="font-weight-light">{{
                    $t(item.last_suboutcome)
                  }}</span></span
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:64px">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="sendAutoMail(item)"
                        :disabled="!$store.state.hasRight('181') || !item.email"
                        v-on="on"
                        ><v-icon>mdi-mail</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-send-mail") }}</span></v-tooltip
                  >

                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="sendWA(item)"
                        :disabled="!$store.state.hasRight('181')"
                        v-on="on"
                        ><v-icon>mdi-whatsapp</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-send-wa") }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="
                      item.last_outcome_id != 1 && item.last_outcome_id != 3
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        v-on="on"
                        @click.stop="outcomeContact(item)"
                        ><v-icon>mdi-target</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-action-outcome")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
              <template v-slot:item.call_actions="{ item }">
                <div style="min-width:64px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="
                          !deviceReady || !$store.state.hasRight('181')
                        "
                        x-small
                        text
                        icon
                        color="orange"
                        v-on="on"
                        ><v-icon>mdi-phone</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          callContact(item, outcomeContact, item.contact_phone)
                        "
                        v-if="item.contact_phone"
                      >
                        <v-list-item-title>{{
                          item.contact_phone
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          callContact(
                            item,
                            outcomeContact,
                            item.additional_phone
                          )
                        "
                        v-if="item.additional_phone"
                      >
                        <v-list-item-title>{{
                          item.additional_phone
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <div style="height:30px" />
        <v-row dense>
          <v-col>
            <v-select
              :label="$t('editorial-filter-datetype')"
              outlined
              dense
              v-model="filters.date_type"
              item-text="name"
              item-value="id"
              :items="date_types"
              :disabled="fetchingContacts"
            />
          </v-col>
          <v-col>
            <v-select
              :disabled="fetchingContacts"
              dense
              v-model="filters.outcome"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_outcomes"
              :label="$t('editorial-filter-outcomes')"
              hide-details
            />
          </v-col>

          <v-col>
            <v-select
              :disabled="fetchingContacts"
              dense
              v-model="filters.suboutcome"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_suboutcomes"
              :label="$t('editorial-filter-suboutcomes')"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              :value="filters.filterByText"
              @change="v => (filters.filterByText = v)"
              :label="$t('gbl-search')"
              hide-details
              outlined
              dense
              single-line
            />
          </v-col>

          <v-col>
            <v-select
              outlined
              dense
              :label="$t('filters-period-hint')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
              :disabled="fetchingContacts"
            >
            </v-select>
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :disabled="fetchingContacts || filters.filterByPeriod != 9"
                  :label="$t('filters-startdate-hint')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :disabled="fetchingContacts || filters.filterByPeriod != 9"
                  :label="$t('filters-enddate-hint')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card
          v-if="!fetchingOutcomes && !fetchingWorkingState && !fetchingData"
          class="ma-2"
        >
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title
              class="white--text mt-1 font-weight-black text-h5"
              >{{ $t("editorial-archive") }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              id="archived_table"
              dense
              :must-sort="true"
              :headers="archiveHeaders"
              :items="archiveContacts"
              :items-per-page="perPage"
              :options.sync="archiveOptions"
              :server-items-length="totalArchiveContacts"
              :loading="fetchingArchiveContacts"
              item-key="outcome_id"
              :item-class="getItemClass"
              :footer-props="dataTableFooterOptions"
            >
              <template v-slot:item.time="{ item }">
                {{ item.event_day | toLocaleDate }}
                {{ item.event_time | toLocaleTime }}
              </template>

              <template v-slot:item.recall_date="{ item }">
                {{
                  item.last_outcome_id == item.final_outcome_id
                    ? $options.filters.toLocaleDate(item.recall_date)
                    : ""
                }}

                <v-tooltip
                  v-if="
                    item.last_outcome_id == 2 &&
                      item.last_outcome_id == item.final_outcome_id
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeRecallDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-recall-date")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.recall_time="{ item }">
                {{
                  item.last_outcome_id == item.final_outcome_id
                    ? $options.filters.toLocaleTime(item.recall_time)
                    : ""
                }}

                <v-tooltip v-if="item.last_outcome_id == 2" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeRecallTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-recall-time")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.interview_date="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleDate(item.interview_date)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-date")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.interview_time="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleTime(item.interview_time)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-time")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.nation="{ item }">
                {{ $t(item.nation) }}
              </template>

              <template v-slot:item.mobile="{ item }">
                <v-icon v-if="item.mobile" color="green">mdi-check</v-icon>
              </template>

              <template v-slot:item.source_name="{ item }">
                {{ $t(item.source_name) }}
              </template>

              <template v-slot:item.guest_id="{ item }">
                <router-link
                  v-if="item.guest_id"
                  :to="'/guests?id=' + item.guest_id"
                >
                  OSP-{{ item.guest_id }}
                </router-link>
              </template>
              <template v-slot:item.url="{ item }">
                <v-tooltip v-if="item.url" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="orange"
                      @click.stop="openLink(item)"
                      v-on="on"
                      ><v-icon>mdi-link</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-contacts-tbl-action-open")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.last_outcome="{ item }">
                <span class="font-weight-bold"
                  >{{ $t(item.last_outcome) }}<br />
                  <span class="font-weight-light">{{
                    $t(item.last_suboutcome)
                  }}</span></span
                >
              </template>

              <template v-slot:item.call_actions="{ item }">
                <div style="min-width:64px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="
                          !deviceReady || !$store.state.hasRight('181')
                        "
                        x-small
                        text
                        icon
                        color="orange"
                        v-on="on"
                        ><v-icon>mdi-phone</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          callContact(
                            item,
                            outcomeYesterdayContact,
                            item.contact_phone
                          )
                        "
                        v-if="item.contact_phone"
                      >
                        <v-list-item-title>{{
                          item.contact_phone
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          callContact(
                            item,
                            outcomeYesterdayContact,
                            item.additional_phone
                          )
                        "
                        v-if="item.additional_phone"
                      >
                        <v-list-item-title>{{
                          item.additional_phone
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:128px">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="sendAutoMail(item)"
                        :disabled="!$store.state.hasRight('181') || !item.email"
                        v-on="on"
                        ><v-icon>mdi-mail</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-send-mail") }}</span></v-tooltip
                  >

                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="sendWA(item)"
                        :disabled="!$store.state.hasRight('181')"
                        v-on="on"
                        ><v-icon>mdi-whatsapp</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-send-wa") }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="
                      item.last_outcome_id != 1 &&
                        item.last_outcome_id != 3 &&
                        item.final_outcome_id != 1 &&
                        item.final_outcome_id != 3
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        v-on="on"
                        @click.stop="outcomeArchiveContact(item)"
                        ><v-icon>mdi-target</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-action-outcome")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import staticData from "../apis/static";
import moment from "moment";
import duration from "moment-duration-format";
import editorial from "../apis/editorial";
import voip from "../apis/voip";

import messageBox from "../components/MessageBox";
import voipCaller from "../components/VoipCaller";
import EditorialContactOutcomeEditor from "../components/editorial/EditorialContactOutcomeEditor";
import EditorialOutcomesResume from "../components/editorial/EditorialOutcomesResume";
import userManager from "../apis/users";

import { Device } from "@twilio/voice-sdk";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

duration(moment);

export default {
  data() {
    return {
      perPage: staticData.defaultPerPage,

      contacts: [],
      yesterdayContacts: [],
      archiveContacts: [],

      totalContacts: 0,
      totalYesterdayContacts: 0,
      totalArchiveContacts: 0,

      fetchingContacts: false,
      fetchingYesterdayContacts: false,
      fetchingArchiveContacts: false,

      workingState: false,
      fetchingWorkingState: true,
      fetchingOutcomes: true,
      changingWorkingState: false,

      contactOutcomeDialog: false,
      yesterdayContactOutcomeDialog: false,
      archiveContactOutcomeDialog: false,

      outcomesResumeDialog: false,

      checkContact: null,
      checkContactMail: null,

      searchingContact: false,
      searchContactCompleted: null,

      searchingContactMail: false,
      searchContactMailCompleted: null,

      seachContactAssigned: false,
      seachContactInQueue: null,
      searchContactOutcomes: null,
      searchContactId: null,
      acquiringContact: false,

      seachContactMailAssigned: false,
      seachContactMailInQueue: null,
      searchContactMailOutcomes: null,
      searchContactMailId: null,
      acquiringMailContact: false,

      recallDatePickerDialog: false,
      recallPickerDate: new Date().toISOString().substr(0, 10),
      recallDatePickerItem: null,
      recallTimePickerDialog: false,
      recallPickerTime: "00:00",
      recallTimePickerItem: null,

      interviewDatePickerDialog: false,
      interviewPickerDate: new Date().toISOString().substr(0, 10),
      interviewDatePickerItem: null,
      interviewTimePickerDialog: false,
      interviewPickerTime: "00:00",
      interviewTimePickerItem: null,

      outcomes_prefills: {},
      def_outcomes: [],
      list_outcomes: [],
      users: [],
      flt_users: {},

      radios: [],
      programs: [],
      types: [],

      deviceReady: false,
      localCallInProgress: false,
      freeCallNumber: null,
      device: null,
      outcomeEnabled: true,

      options: {
        sortBy: ["ID"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },

      yesterdayOptions: {
        itemsPerPage: 50,
        page: 1
      },

      archiveOptions: {
        sortBy: ["ID"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },

      statistics: {
        todayContacts: "...",
        totalContacts: "...",
        todayOutcomesTime: "...",

        totalOutcomes: "...",
        todayOutcomes: "...",
        pastTodayOutcomes: "...",
        todayOutcomesYes: "...",
        todayOutcomesNo: "...",
        todayOutcomesNoAnswer: "...",
        todayOutcomesYesPerc: "...",
        todayOutcomesNoPerc: "...",
        todayOutcomesNoAnswerPerc: "...",
        todayOutcomesDiscarded: "...",
        todayOutcomesDiscardedPerc: "...",
        todayOutcomesAlready: "...",
        todayOutcomesAlreadyPerc: "...",

        totalOutcomesYes: "...",
        totalOutcomesNo: "...",
        totalOutcomesNoAnswer: "...",
        totalOutcomesYesPerc: "...",
        totalOutcomesNoPerc: "...",
        totalOutcomesNoAnswerPerc: "...",

        monthOutcomes: "...",
        monthOutcomesTime: "...",
        toBeCalled: "...",
        interviewsMade: "...",
        missing: "...",
        video: "...",
        workingTime: "..."
      },

      contactsDuration: 15,

      headers: [],
      yesterdayHeaders: [],
      archiveHeaders: [],

      dataTableFooterOptions: staticData.dataTableFooterOptions,
      fetchingData: false,

      flt_outcomes: [],
      flt_suboutcomes: [{ id: null, name: this.$t("gbl-all-male") }],

      filters: {
        date_type: 0,
        outcome: null,
        suboutcome: null,
        filterByPeriod: 6,
        filterByText: null
      },

      date_types: [
        { id: 0, name: this.$t("editorial-filter-date-outcome") },
        { id: 2, name: this.$t("editorial-filter-date-assignment") }
      ],

      date_start_modal: false,
      date_end_modal: false
    };
  },

  name: "Editorial",

  components: {
    messageBox,
    EditorialContactOutcomeEditor,
    EditorialOutcomesResume,
    voipCaller,
    VueTimepicker
  },

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("editorial-tbl-code"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("editorial-tbl-category"),
        align: "left",
        sortable: true,
        value: "category"
      },
      {
        text: this.$t("editorial-tbl-category-textual"),
        align: "left",
        sortable: true,
        value: "category_testuale"
      },

      {
        text: this.$t("editorial-tbl-province"),
        align: "left",
        sortable: true,
        value: "province"
      },
      {
        text: this.$t("editorial-tbl-city"),
        align: "left",
        sortable: true,
        value: "town"
      },
      {
        text: this.$t("editorial-tbl-email"),
        align: "left",
        sortable: true,
        value: "email"
      },
      {
        text: this.$t("editorial-tbl-phone"),
        align: "left",
        sortable: true,
        value: "contact_phone"
      },
      { text: "", align: "center", sortable: false, value: "call_actions" },
      {
        text: this.$t("editorial-tbl-additional-phone"),
        align: "left",
        sortable: true,
        value: "additional_phone"
      },
      {
        text: this.$t("editorial-tbl-business-name"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("editorial-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("editorial-tbl-url"),
        align: "left",
        sortable: true,
        value: "url"
      },

      {
        text: this.$t("editorial-tbl-outcome-counts"),
        align: "center",
        sortable: false,
        value: "outcomes_count"
      },
      {
        text: this.$t("editorial-tbl-outcome-elapsed"),
        align: "center",
        sortable: false,
        value: "outcomes_elapsed"
      },

      {
        text: this.$t("editorial-tbl-outcome"),
        align: "center",
        sortable: false,
        value: "last_outcome"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    this.yesterdayHeaders = [
      {
        text: this.$t("editorial-tbl-code"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("editorial-tbl-category"),
        align: "left",
        sortable: true,
        value: "category"
      },
      {
        text: this.$t("editorial-tbl-priority"),
        align: "left",
        sortable: true,
        value: "last_outcome_priority"
      },
      {
        text: this.$t("editorial-tbl-category-textual"),
        align: "left",
        sortable: true,
        value: "category_testuale"
      },

      {
        text: this.$t("editorial-tbl-province"),
        align: "left",
        sortable: true,
        value: "province"
      },
      {
        text: this.$t("editorial-tbl-city"),
        align: "left",
        sortable: true,
        value: "town"
      },
      {
        text: this.$t("editorial-tbl-email"),
        align: "left",
        sortable: true,
        value: "email"
      },
      {
        text: this.$t("editorial-tbl-phone"),
        align: "left",
        sortable: true,
        value: "contact_phone"
      },
      { text: "", align: "center", sortable: false, value: "call_actions" },
      {
        text: this.$t("editorial-tbl-additional-phone"),
        align: "left",
        sortable: true,
        value: "additional_phone"
      },
      {
        text: this.$t("editorial-tbl-business-name"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("editorial-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("editorial-tbl-url"),
        align: "left",
        sortable: true,
        value: "url"
      },

      {
        text: this.$t("editorial-tbl-recall-date"),
        align: "center",
        sortable: false,
        value: "recall_date"
      },
      {
        text: this.$t("editorial-tbl-recall-time"),
        align: "center",
        sortable: false,
        value: "recall_time"
      },

      {
        text: this.$t("editorial-tbl-outcome-elapsed"),
        align: "center",
        sortable: true,
        value: "outcomes_elapsed"
      },
      {
        text: this.$t("editorial-tbl-outcome-counts"),
        align: "center",
        sortable: true,
        value: "outcomes_count"
      },
      {
        text: this.$t("editorial-tbl-outcome"),
        align: "center",
        sortable: false,
        value: "last_outcome"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    this.archiveHeaders = [
      {
        text: this.$t("editorial-tbl-code"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("editorial-tbl-category"),
        align: "left",
        sortable: true,
        value: "category"
      },

      {
        text: this.$t("editorial-tbl-time"),
        align: "left",
        sortable: true,
        value: "time"
      },

      {
        text: this.$t("editorial-tbl-city"),
        align: "left",
        sortable: true,
        value: "town"
      },
      {
        text: this.$t("editorial-tbl-email"),
        align: "left",
        sortable: true,
        value: "email"
      },
      {
        text: this.$t("editorial-tbl-phone"),
        align: "left",
        sortable: true,
        value: "contact_phone"
      },
      { text: "", align: "center", sortable: false, value: "call_actions" },
      {
        text: this.$t("editorial-tbl-additional-phone"),
        align: "left",
        sortable: true,
        value: "additional_phone"
      },
      {
        text: this.$t("editorial-tbl-business-name"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("editorial-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("editorial-tbl-url"),
        align: "left",
        sortable: true,
        value: "url"
      },

      {
        text: this.$t("editorial-tbl-recall-date"),
        align: "center",
        sortable: false,
        value: "recall_date"
      },
      {
        text: this.$t("editorial-tbl-recall-time"),
        align: "center",
        sortable: false,
        value: "recall_time"
      },
      {
        text: this.$t("editorial-tbl-interview-date"),
        align: "center",
        sortable: false,
        value: "interview_date"
      },
      {
        text: this.$t("editorial-tbl-interview-time"),
        align: "center",
        sortable: false,
        value: "interview_time"
      },

      {
        text: this.$t("editorial-tbl-outcome"),
        align: "center",
        sortable: false,
        value: "last_outcome"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    if (this.$store.state.radios) {
      let locked_radios = this.$store.state.user.locked_radio
        ? this.$store.state.user.locked_radio.split(",").map(x => {
            return parseInt(x);
          })
        : [];
      this.radios = [
        ...this.$store.state.radios.filter(x => {
          if (locked_radios.length < 1) return true;
          else return locked_radios.includes(x.id);
        })
      ];
    }

    if (this.$store.state.types) {
      let locked_types = this.$store.state.user.locked_type
        ? this.$store.state.user.locked_type.split(",").map(x => {
            return parseInt(x);
          })
        : [];

      this.types = [
        ...this.$store.state.types.filter(x => {
          if (locked_types.length < 1) return true;
          else return locked_types.includes(x.id);
        })
      ];
    }

    this.fetchingData = true;

    userManager
      .getEditorialPrefill()
      .then(async res => {
        this.outcomes_prefills = res;

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            voip
              .getToken()
              .then(token => {
                this.device = new Device(token.token, {
                  appName: "Masterboard",
                  debug: false,
                  codecPreferences: ["opus", "pcmu"],
                  fakeLocalDTMF: true,
                  enableRingingState: true,
                  logLevel: 5,
                  closeProtection: true,
                  allowIncomingWhileBusy: false,
                  region: "us1"
                });

                this.device.on("registered", () => {
                  const speakerDevices = this.device.audio.speakerDevices.get();
                  const ringtoneDevices = this.device.audio.ringtoneDevices.get();

                  const speakerId = speakerDevices.values().next().value
                    .deviceId;
                  const ringtoneId = ringtoneDevices.values().next().value
                    .deviceId;
                  this.device.audio.speakerDevices.set(speakerId);
                  this.device.audio.ringtoneDevices.set(ringtoneId);
                  this.deviceReady = true;
                });

                this.device.on("error", function(error) {
                  console.log("Twilio.Device Error: " + error.message);
                  this.deviceReady = false;
                });

                this.device.register();
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
        this.fetchOutcomes()
          .then(() => {
            this.flt_outcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...this.def_outcomes
            ];
            this.fetchWorkingState()
              .then(res => {
                this.contactsDuration = res.contactsDuration;
                this.collectStatistics(res.statistics);
                this.workingState = res.workingState;
                this.fetchingData = false;
              })
              .catch(err => {
                console.log(err);
                this.fetchingData = false;
              });

            this.fetchingData = false;
          })
          .catch(err => {
            this.fetchingData = false;
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  },

  watch: {
    "filters.outcome": {
      handler() {
        this.filters.suboutcome = null;
        this.flt_suboutcomes = [{ id: null, name: this.$t("gbl-all-male") }];
        if (this.filters.outcome) {
          const outcome = this.def_outcomes_by_id[this.filters.outcome];

          if (outcome.sub) {
            this.flt_suboutcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...outcome.sub.map(x => {
                return { id: x.id, name: x.name };
              })
            ];
          }
        }
      }
    },

    options: {
      handler() {
        this.fetchEditorialToday();
      },
      deep: true
    },

    yesterdayOptions: {
      handler() {
        this.fetchYesterdayContacts();
      },
      deep: true
    },

    archiveOptions: {
      handler() {
        this.fetchArchiveContacts();
      },
      deep: true
    },

    filters: {
      handler() {
        this.fetchArchiveContacts();
      },
      deep: true
    },

    checkContact: {
      handler() {
        let regexp = /^(\+\d{2})\d{8,14}$/;
        this.searchNumberIsValid = regexp.test(this.checkContact);
        this.searchingContact = false;
        this.searchContactCompleted = null;
        this.searchContactOutcomes = null;
        this.searchContactId = null;
        this.seachContactAssigned = false;
        this.seachContactInQueue = null;
      }
    },

    checkContactMail: {
      handler() {
        let regexpmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        this.searchMailIsValid = regexpmail.test(this.checkContactMail);
        this.searchingContactMail = false;
        this.searchContactMailCompleted = null;
        this.searchContactMailOutcomes = null;
        this.searchContactMailId = null;
        this.seachContactMailAssigned = false;
        this.seachContactMailInQueue = null;
      }
    }
  },

  computed: {
    can_stop_working: {
      get: function() {
        for (let n = 0; n < this.contacts.length; n++) {
          let contact = this.contacts[n];
          if (
            !contact.last_outcome ||
            contact.last_outcome_user_id != this.$store.state.user.id
          ) {
            return false;
          }
        }
        return true;
      }
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  methods: {
    onFreeCall() {
      this.localCallInProgress = true;
      this.$refs.voipCaller.startCall(null, this.device, this.freeCallNumber);
    },

    formatElapsed(time) {
      if (time) {
        const moment_time = moment.duration(time, "seconds");
        return moment_time.format("d[d] hh:mm:ss");
      } else {
        return null;
      }
    },
    setupElapsedTimer() {
      clearTimeout(this._updateTimestamps);
      this._updateTimestamps = setTimeout(() => {
        for (let x = 0; x < this.contacts.length; x++) {
          let contact = this.contacts[x];
          let dates = contact.last_outcome_time
            ? contact.last_outcome_time.split(",")
            : [];
          let diff = Date.now() / 1000 - contact.assignment_timestamp;
          if (dates.length == 1) diff = dates[0] - contact.assignment_timestamp;
          else if (dates.length > 1) diff = dates[1] - dates[0];
          this.$set(contact, "outcomes_elapsed", diff);
        }

        for (let x = 0; x < this.yesterdayContacts.length; x++) {
          let contact = this.yesterdayContacts[x];
          let dates = contact.last_outcome_time
            ? contact.last_outcome_time.split(",")
            : [];
          let diff = Date.now() / 1000 - contact.assignment_timestamp;
          if (dates.length == 1) diff = dates[0] - contact.assignment_timestamp;
          else if (dates.length > 1) diff = dates[1] - dates[0];
          this.$set(contact, "outcomes_elapsed", diff);
        }

        this.setupElapsedTimer();
      }, 1000);
    },

    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    getItemClass(item) {
      if (item.missing_counts > 0) return "missing-guest";
      else return null;
    },

    confirmChangeInterviewDate() {
      this.interviewDatePickerDialog = false;

      editorial
        .setContactInterviewTime(
          this.interviewDatePickerItem.assignment_id,
          this.interviewDatePickerItem.last_outcome_item_id,
          this.interviewPickerDate,
          this.interviewDatePickerItem.interview_time,
          this.interviewDatePickerItem.guest_id
        )
        .then(() => {
          this.fetchYesterdayContacts();
          this.fetchArchiveContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    confirmChangeRecallDate() {
      this.recallDatePickerDialog = false;
      editorial
        .setContactRecallTime(
          this.recallDatePickerItem.assignment_id,
          this.recallDatePickerItem.last_outcome_item_id,
          this.recallPickerDate,
          this.recallDatePickerItem.recall_time,
          this.recallDatePickerItem.guest_id
        )
        .then(() => {
          this.fetchYesterdayContacts();
          this.fetchArchiveContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    confirmChangeRecallTime() {
      this.recallTimePickerDialog = false;
      editorial
        .setContactRecallTime(
          this.recallTimePickerItem.assignment_id,
          this.recallTimePickerItem.last_outcome_item_id,
          this.$options.filters.toDatePickerDate(
            this.recallTimePickerItem.recall_date
          ),
          this.recallPickerTime,
          this.recallTimePickerItem.guest_id
        )
        .then(() => {
          this.fetchYesterdayContacts();
          this.fetchArchiveContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    confirmChangeInterviewTime() {
      this.interviewTimePickerDialog = false;
      editorial
        .setContactInterviewTime(
          this.interviewTimePickerItem.assignment_id,
          this.interviewTimePickerItem.last_outcome_item_id,
          this.$options.filters.toDatePickerDate(
            this.interviewTimePickerItem.interview_date
          ),
          this.interviewPickerTime,
          this.interviewTimePickerItem.guest_id
        )
        .then(() => {
          this.fetchYesterdayContacts();
          this.fetchArchiveContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    changeInterviewDate(item) {
      this.interviewDatePickerDialog = true;
      if (item.interviewDate)
        this.interviewPickerDate = this.$options.filters.toDatePickerDate(
          item.interviewDate
        );
      else this.interviewPickerDate = new Date().toISOString().substr(0, 10);
      this.interviewDatePickerItem = item;
    },

    changeRecallDate(item) {
      this.recallDatePickerDialog = true;
      if (item.recallDate)
        this.recallPickerDate = this.$options.filters.toDatePickerDate(
          item.recallDate
        );
      else this.recallPickerDate = new Date().toISOString().substr(0, 10);
      this.recallDatePickerItem = item;
    },

    changeRecallTime(item) {
      this.recallTimePickerDialog = true;
      this.recallPickerDate = "08:00";
      this.recallTimePickerItem = item;
    },

    changeInterviewTime(item) {
      this.interviewTimePickerDialog = true;
      if (item.interviewTime) this.interviewTime = item.interviewTime;
      else this.interviewTime = "00:00";
      this.interviewTimePickerItem = item;
    },

    acquireContact() {
      this.acquiringContact = true;
      editorial
        .acquireContact(this.searchContactCompleted)
        .then(() => {
          this.acquiringContact = false;
          this.searchingContact = false;
          this.searchContactCompleted = null;
          this.searchContactOutcomes = null;
          this.searchContactId = null;
          this.seachContactAssigned = false;
          this.seachContactInQueue = null;
          this.fetchEditorialToday();
        })
        .catch(err => {
          console.log(err);
        });
    },

    acquireMailContact() {
      this.acquiringMailContact = true;
      editorial
        .acquireMailContact(this.searchContactMailCompleted)
        .then(() => {
          this.acquiringMailContact = false;
          this.searchingContactMail = false;
          this.searchContactMailCompleted = null;
          this.searchContactMailCompleted = null;
          this.searchContactMailOutcomes = null;
          this.searchContactMailId = null;
          this.seachContactMailAssigned = false;
          this.seachContactMailInQueue = null;
          this.fetchEditorialToday();
        })
        .catch(err => {
          console.log(err);
        });
    },

    acquireExistingContact() {
      this.acquiringContact = true;
      editorial
        .acquireExistingContact(this.seachContactInQueue)
        .then(() => {
          this.acquiringContact = false;
          this.searchingContact = false;
          this.searchingContactMail = false;
          this.searchContactCompleted = null;
          this.searchContactMailCompleted = null;
          this.searchContactOutcomes = null;
          this.searchContactId = null;
          this.seachContactAssigned = false;
          this.seachContactInQueue = null;
          this.fetchEditorialToday();
        })
        .catch(err => {
          console.log(err);
        });
    },

    onSearchContact() {
      this.searchingContact = false;
      this.seachContactInQueue = null;
      this.searchContactCompleted = null;
      this.searchContactOutcomes = null;
      this.searchContactId = null;
      this.acquiringContact = false;
      editorial
        .searchContact(this.checkContact)
        .then(res => {
          this.searchingContact = false;
          if (!res.contact && !res.assignment) {
            this.seachContactInQueue = false;
            this.seachContactAssigned = false;
            this.searchContactCompleted = this.checkContact;
          } else {
            if (res.contact) this.seachContactInQueue = res.contact;
            else this.seachContactInQueue = null;
            if (res.assignment) this.seachContactAssigned = true;
            else this.seachContactAssigned = false;
            if (res.contact && !res.assignment)
              this.searchContactCompleted = this.checkContact;
            else this.searchContactCompleted = null;
            if (res.outcomes) this.searchContactOutcomes = res.outcomes;
            if (res.contact) this.searchContactId = res.contact;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    onSearchContactMail() {
      this.searchingContact = false;
      this.searchContactMailCompleted = null;
      this.searchingContactMail = true;
      this.searchContactMail = false;
      this.seachContactMailAssigned = false;
      this.seachContactMailInQueue = null;
      this.searchContactMailCompleted = null;
      this.searchContactMailOutcomes = null;
      this.searchContactMailId = null;
      this.acquiringMailContact = false;
      editorial
        .searchContactMail(this.checkContactMail)
        .then(res => {
          this.searchingContactMail = false;
          if (!res.contact && !res.assignment) {
            this.seachContactInQueue = false;
            this.seachContactAssigned = false;
            this.searchContactMailCompleted = this.checkContactMail;
          } else {
            if (res.contact) this.seachContactMailInQueue = res.contact;
            else this.seachContactMailInQueue = null;
            if (res.assignment) this.seachContactMailAssigned = true;
            else this.seachContactMailAssigned = false;
            if (res.contact && !res.assignment)
              this.searchContactMailCompleted = this.checkContactMail;
            else this.searchContactMailCompleted = null;
            if (res.outcomes) this.searchContactMailOutcomes = res.outcomes;
            if (res.contact) this.searchContactMailId = res.contact;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    openLink(item) {
      const link = document.createElement("a");
      link.setAttribute("href", item.url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    outcomeNewContact() {
      let contact = {
        nation_id: 86,
        region_id: null,
        province_id: null,
        town_id: null
      };
      this.$refs.contactOutcomeEditor
        .setModalContact(this.def_outcomes, contact)
        .then(() => {
          this.contactOutcomeDialog = true;
        });
    },

    outcomeContact(item) {
      this.$refs.contactOutcomeEditor
        .setModalContact(this.def_outcomes, item)
        .then(() => {
          this.contactOutcomeDialog = true;
        });
    },

    outcomeArchiveContact(item) {
      this.$refs.archiveContactOutcomeEditor
        .setModalContact(this.def_outcomes, item)
        .then(() => {
          this.archiveContactOutcomeDialog = true;
        });
    },

    outcomeYesterdayContact(item) {
      this.$refs.yesterdayContactOutcomeEditor
        .setModalContact(this.def_outcomes, item)
        .then(() => {
          this.yesterdayContactOutcomeDialog = true;
        });
    },

    onContactOutcomeConfigured(item) {
      editorial
        .outcomeContact(item, 0)
        .then(() => {
          this.fetchEditorialToday()
            .then(() => {
              this.contactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchYesterdayContacts()
            .then(() => {
              this.yesterdayContactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchArchiveContacts()
            .then(() => {
              this.archiveContactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
          this.contactOutcomeDialog = false;
        });
    },

    onYesterdayPendingContactOutcomeConfigured(item) {
      editorial
        .outcomeContact(item, 0)
        .then(() => {
          this.fetchEditorialToday()
            .then(() => {
              this.contactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchYesterdayContacts()
            .then(() => {
              this.yesterdayContactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchArchiveContacts()
            .then(() => {
              this.archiveContactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
          this.yesterdayContactOutcomeDialog = false;
        });
    },

    onArchiveContactOutcomeConfigured(item) {
      editorial
        .outcomeContact(item, 0)
        .then(() => {
          //this.$refs.voipCaller.closeDialog();
          this.fetchEditorialToday()
            .then(() => {
              this.contactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchYesterdayContacts()
            .then(() => {
              this.yesterdayContactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchArchiveContacts()
            .then(() => {
              this.archiveContactOutcomeDialog = false;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
          this.archiveContactOutcomeDialog = false;
        });
    },

    onContactRelocated(item) {
      editorial
        .reassignContact(item)
        .then(() => {
          this.contactOutcomeDialog = false;
          this.archiveContactOutcomeDialog = false;
          this.yesterdayContactOutcomeDialog = false;
          this.confirmStopWorking();
        })
        .catch(err => {
          console.log(err);
          this.contactOutcomeDialog = false;
          this.archiveContactOutcomeDialog = false;
          this.yesterdayContactOutcomeDialog = false;
        });
    },

    onContactMoved() {
      this.contactOutcomeDialog = false;
      this.yesterdayContactOutcomeDialog = false;
      this.$refs.voipCaller.closeDialog();
      this.confirmStopWorking();
    },

    outcomesResume(item) {
      if (item.list_id && this.list_outcomes.length > 0) {
        this.fetchListOutcomes(item.list_id).then(() => {
          this.$refs.outcomesResumeDialog.setItem(
            this.list_outcomes.length > 0
              ? this.list_outcomes
              : this.def_outcomes,
            item.id
          );
          this.outcomesResumeDialog = true;
        });
      } else {
        this.$refs.outcomesResumeDialog.setItem(this.def_outcomes, item.id);
        this.outcomesResumeDialog = true;
      }
    },

    outcomesSearchResume(id) {
      this.$refs.outcomesResumeDialog.setItem(this.def_outcomes, id);
      this.outcomesResumeDialog = true;
    },

    collectStatistics(statistics) {
      if (statistics) {
        this.statistics.todayContacts = statistics.todayContacts;
        this.statistics.totalContacts = statistics.totalContacts;
        this.statistics.todayOutcomesTime = statistics.todayOutcomesTime;

        this.statistics.totalOutcomes = statistics.totalOutcomes;
        this.statistics.todayOutcomes = statistics.todayOutcomes;

        this.statistics.todayOutcomesYes = statistics.todayOutcomesYes;
        this.statistics.todayOutcomesNo = statistics.todayOutcomesNo;
        this.statistics.todayOutcomesNoAnswer =
          statistics.todayOutcomesNoAnswer;
        this.statistics.todayOutcomesDiscarded =
          statistics.todayOutcomesDiscarded;
        this.statistics.todayOutcomesAlready = statistics.todayOutcomesAlready;

        this.statistics.todayOutcomesYesPerc =
          statistics.todayOutcomesYesPerc.toFixed(2) + " %";
        this.statistics.todayOutcomesNoPerc =
          statistics.todayOutcomesNoPerc.toFixed(2) + " %";
        this.statistics.todayOutcomesNoAnswerPerc =
          statistics.todayOutcomesNoAnswerPerc.toFixed(2) + " %";
        this.statistics.todayOutcomesDiscardedPerc =
          statistics.todayOutcomesDiscardedPerc.toFixed(2) + " %";
        this.statistics.todayOutcomesAlreadyPerc =
          statistics.todayOutcomesAlreadyPerc.toFixed(2) + " %";

        this.statistics.monthOutcomes = statistics.monthOutcomes;
        this.statistics.toBeCalled = statistics.toBeCalled;
        this.statistics.interviewsMade = statistics.interviewsMade;
        this.statistics.missing = statistics.missing;
        this.statistics.video = statistics.video;
        this.statistics.monthOutcomesTime = statistics.monthOutcomesTime;

        let seconds = statistics.workingTime;
        let hours = Math.floor(seconds / 60 / 60);
        seconds -= hours * 60 * 60;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        this.statistics.workingTime = hours + ":" + minutes + ":" + seconds;
      }
    },

    startWorking() {
      this.changingWorkingState = true;
      return new Promise((resolve, reject) => {
        editorial
          .startWorkingSession()
          .then(res => {
            this.contactsDuration = res.contactsDuration;
            //this.collectStatistics(res.statistics);
            this.workingState = res.workingState;
            this.changingWorkingState = false;
            this.fetchEditorialToday()
              .then(() => {
                this.fetchYesterdayContacts()
                  .then(() => {
                    resolve();
                  })
                  .catch(err => {
                    console.log(err);
                  });
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            this.workingState = false;
            this.changingWorkingState = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    confirmStopWorking() {
      this.changingWorkingState = true;

      return new Promise((resolve, reject) => {
        editorial
          .stopWorkingSession()
          .then(res => {
            this.workingState = res.workingState;
            this.contactsDuration = res.contactsDuration;
            this.collectStatistics(res.statistics);

            this.changingWorkingState = false;
            this.fetchEditorialToday()
              .then(() => {
                this.fetchYesterdayContacts()
                  .then(() => {
                    resolve();
                  })
                  .catch(err => {
                    console.log(err);
                  });
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            this.workingState = false;
            this.changingWorkingState = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    stopWorking() {
      if (!this.can_stop_working) {
        this.$refs.msgBox
          .show(
            this.$t("editorial-stop-working-warning"),
            this.$t("editorial-stop-working-title")
          )
          .then(() => {
            this.confirmStopWorking();
          })
          .catch(() => {});
      } else {
        this.confirmStopWorking();
      }
    },

    fetchWorkingState() {
      this.fetchingWorkingState = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchWorkingState()
          .then(result => {
            this.fetchingWorkingState = false;
            this.workingState = result.workingState;
            resolve(result);
          })
          .catch(err => {
            this.fetchingWorkingState = false;
            reject(err);
          });
      });
    },

    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchOutcomes()
          .then(result => {
            this.fetchingOutcomes = false;
            this.def_outcomes = result.outcomes;
            this.def_outcomes_by_id = {};
            for (let x in this.def_outcomes) {
              this.def_outcomes[x].name = this.$t(this.def_outcomes[x].name);
              this.def_outcomes_by_id[
                this.def_outcomes[x].id
              ] = this.def_outcomes[x];
              if (this.def_outcomes[x].sub) {
                for (let y in this.def_outcomes[x].sub) {
                  this.def_outcomes[x].sub[y].name = this.$t(
                    this.def_outcomes[x].sub[y].name
                  );
                }
              }
            }

            resolve();
          })
          .catch(err => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    fetchListOutcomes(list_id) {
      return new Promise((resolve, reject) => {
        editorial
          .fetchListOutcomes(list_id)
          .then(result => {
            this.list_outcomes = result;
            resolve(result.outcomes);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    fetchEditorialToday() {
      clearTimeout(this._updateTimestamps);
      this.fetchingContacts = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        editorial
          .fetchEditorialToday(ctx)
          .then(result => {
            this.contacts = result.items;
            this.totalContacts = result.totalItems;
            this.fetchingContacts = false;
            this.setupElapsedTimer();
            resolve();
          })

          .catch(err => {
            this.fetchingContacts = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    fetchYesterdayContacts() {
      this.fetchingYesterdayContacts = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.yesterdayOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        editorial
          .fetchEditorialYesterday(ctx)
          .then(result => {
            this.yesterdayContacts = result.yesterdayContacts;
            this.totalYesterdayContacts = result.totalItems;
            this.fetchingYesterdayContacts = false;
            resolve();
          })

          .catch(err => {
            this.fetchingYesterdayContacts = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    sendAutoMail(item) {
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t("editorial-sending-mail");
      editorial
        .sendInfoMail(item.id, item.region_id)
        .then(() => {
          this.$store.state.global_overlay = false;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchArchiveContacts() {
      this.fetchingArchiveContacts = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.archiveOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.filters = this.filters;
        editorial
          .fetchArchiveContacts(ctx)
          .then(result => {
            this.archiveContacts = result.archiveContacts;
            this.totalArchiveContacts = result.totalItems;
            this.fetchingArchiveContacts = false;
            resolve();
          })

          .catch(err => {
            this.fetchingArchiveContacts = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    sendWA(item) {
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "editorial-open-wa",
        item.contact_phone
      );
      editorial
        .logWhatsapp(item.id)
        .then(() => {
          const link = document.createElement("a");
          link.setAttribute(
            "href",
            "https://api.whatsapp.com/send?phone=" +
              encodeURIComponent(item.contact_phone)
          );
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          this.$store.state.global_overlay = false;
          link.remove();
        })

        .catch(err => {
          this.fetchingArchiveContacts = false;
          if (err) console.log(err);
        });
    },

    async callContact(item, outcomeFunc, phone) {
      if (item.last_outcome_id != 1 && item.last_outcome_id != 3)
        this.outcomeEnabled = true;
      else this.outcomeEnabled = false;

      item.outcomeFunc = outcomeFunc;
      this.$refs.voipCaller.startCall(item, this.device, phone);
    },

    voipCallClosed(/*event*/) {
      this.localCallInProgress = false;
      // This is an aborted call
      //console.log("Aborted:", event.id, event.contact_phone);
    },

    voipCallCompleted(/*event*/) {
      this.localCallInProgress = false;
      // This is a completed call
      //console.log("Completed:", event.id, event.contact_phone);
    },

    voipCallOutcome(event) {
      if (event && event.outcomeFunc && event.last_outcome_id != 1)
        event.outcomeFunc(event);
      this.localCallInProgress = false;
    }
  }
};
</script>

<style>
.missing-guest {
  background-color: orange;
}
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background-color: #0d9fea;
}

.vue__time-picker {
  font-size: 20px;
}
</style>
