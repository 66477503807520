<template>
  <div>
    <messageBox ref="msgBox" />

    <ListsEditor
      ref="editorialListEditor"
      v-model="editorialListEditorDialog"
      @onListCreated="onListCreated"
    />

    <mergeLists
      ref="mergeListsEditor"
      v-model="mergeListsEditorDialog"
      @onListMerged="onListMerged"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-contacts</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("editorial-lists-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row class="text-center">
          <v-col cols="2">
            <v-select
              :disabled="fetchingEditorialLists"
              dense
              v-model="filters.archived"
              outlined
              item-text="value"
              item-value="id"
              :items="flt_archived"
              :label="$t('editorial-filter-archived')"
              hide-details
            />
          </v-col>
        </v-row>

        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              getTitleBar()
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <span>
              {{ $t("editorial-lists-out-of-list-contacts") }}
              <b>{{ cleanContacts }}</b>
            </span>

            <v-btn
              v-if="$store.state.hasRight('164')"
              icon
              @click="mergeEditorialLists()"
            >
              <v-icon>mdi-merge</v-icon>
            </v-btn>
            <v-btn
              v-if="$store.state.hasRight('164')"
              icon
              @click="addEditorialList()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="editorialLists"
              :options.sync="editorialOptions"
              :server-items-length="totalEditorialLists"
              :loading="fetchingEditorialLists"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.type="{ item }">
                {{
                  item.container == 1
                    ? $t("editorial-lists-tbl-type-container")
                    : $t("editorial-lists-tbl-type-derived")
                }}
              </template>

              <template v-slot:item.source_list="{ item }">
                {{
                  item.source_list ? item.source_list : $t("gbl-none-female")
                }}
              </template>

              <template v-slot:item.sources_outcomes="{ item }">
                <ul class="text-left">
                  <li v-for="i in getSourceOutcomes(item)" :key="i">
                    {{ i }}
                  </li>
                </ul>
              </template>
              <template v-slot:item.outcomes="{ item }">
                <ul class="text-left">
                  <li v-for="i in getOutcomes(item)" :key="i">
                    {{ i }}
                  </li>
                </ul>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip
                  v-if="$store.state.hasRight('164') && !filters.archived"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="orange"
                      v-on="on"
                      @click.stop="archiveList(item)"
                      ><v-icon>mdi-archive-arrow-down-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("editorial-lists-archive") }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="$store.state.hasRight('164') && filters.archived"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      v-on="on"
                      @click.stop="unarchiveList(item)"
                      ><v-icon>mdi-archive-arrow-up-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("editorial-lists-unarchive") }}</span></v-tooltip
                >
                <v-tooltip v-if="$store.state.hasRight('164')" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      :disabled="
                        item.assigned_contacts > 0 || item.list_references > 0 || item.not_deletable == 1
                      "
                      x-small
                      text
                      icon
                      color="red"
                      v-on="on"
                      @click.stop="removeEditorialList(item)"
                      ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("editorial-lists-remove") }}</span></v-tooltip
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import editorial from "../apis/editorial";
import ListsEditor from "../components/editorial/ListsEditor";
import messageBox from "../components/MessageBox";
import MergeLists from "../components/editorial/MergeLists";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      editorialLists: [],
      cleanContacts: null,
      totalEditorialLists: 0,
      fetchingEditorialLists: false,
      perPage: staticData.defaultPerPage,

      editorialOptions: {
        sortBy: ["id"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1
      },

      filters: {
        archived: false
      },

      headers: [],
      flt_archived: [],
      editorialListEditorDialog: false,
      mergeListsEditorDialog: false
    };
  },

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("editorial-lists-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("editorial-lists-tbl-nome"),
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("editorial-lists-tbl-type"),
        align: "center",
        sortable: true,
        value: "type"
      },
      {
        text: this.$t("editorial-lists-tbl-parent"),
        align: "center",
        sortable: true,
        value: "source_list"
      },

      {
        text: this.$t("editorial-lists-tbl-num-contacts"),
        align: "center",
        sortable: true,
        value: "num_contacts"
      },
      {
        text: this.$t("editorial-lists-tbl-free-contacts"),
        align: "center",
        sortable: true,
        value: "free_contacts"
      },

      {
        text: this.$t("editorial-lists-tbl-source-outcomes"),
        align: "center",
        sortable: true,
        value: "sources_outcomes"
      },

      {
        text: this.$t("editorial-lists-tbl-outcomes"),
        align: "center",
        sortable: true,
        value: "outcomes"
      },

      {
        text: this.$t("editorial-lists-tbl-nation"),
        align: "center",
        sortable: true,
        value: "nation"
      },

      {
        text: this.$t("editorial-lists-tbl-region"),
        align: "center",
        sortable: true,
        value: "region"
      },

      {
        text: this.$t("editorial-lists-tbl-used"),
        align: "center",
        sortable: true,
        value: "list_references"
      },

      { text: "", align: "right", sortable: false, value: "actions" }
    ];

    this.flt_archived = [
      { id: false, value: this.$t("editorial-filter-list-not-archived") },
      { id: true, value: this.$t("editorial-filter-list-archived") }
    ];
  },

  components: {
    ListsEditor,
    messageBox,
    MergeLists
  },

  watch: {
    editorialOptions: {
      handler() {
        this.fetchEditorialLists();
      },
      deep: true
    },

    filters: {
      handler() {
        this.fetchEditorialLists();
      },
      deep: true
    }
  },

  computed: {},

  methods: {
    getTitleBar() {
      return this.filters.archived
        ? this.$t("editorial-lists-archived")
        : this.$t("editorial-lists");
    },

    getSourceOutcomes(item) {
      if (!item.sources_outcomes) return [];
      let items = item.sources_outcomes.split(",").map(x => {
        return this.$t(x);
      });
      return items;
    },

    getOutcomes(item) {
      if (!item.outcomes) return [];
      let items = item.outcomes.split(",");
      return items;
    },

    removeEditorialList: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("editorial-list-action-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "editorial-lists-removing-list"
          );
          editorial
            .removeEditorialList(item.id)
            .then(() => {
              this.$store.state.global_overlay = false;
              this.fetchEditorialLists();
            })

            .catch(err => {
              this.$store.state.global_overlay = false;
              if (err) console.log(err);
            });
        })
        .catch(() => {});
    },

    mergeEditorialLists() {
      this.$refs.mergeListsEditor.reset();
      this.mergeListsEditorDialog = true;
    },

    onListMerged(ev) {
      this.mergeListsEditorDialog = false;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "editorial-lists-building-list"
      );
      editorial
        .mergeLists(ev)
        .then(() => {
          this.$store.state.global_overlay = false;
          this.fetchEditorialLists();
        })
        .catch(err => {
          console.log(err);
          this.$store.state.global_overlay = false;
        });
    },

    addEditorialList() {
      this.$refs.editorialListEditor.setItem({
        nation_id: 86,
        region_id: null,
        id: null,
        categories: null,
        source_list_id: null,
        source_outcomes: [],
        available_outcomes: [],
        maximum_contacts: 1000,
        excluded_list_id: null,
        container: 1,
        relocation: 0
      });
      this.editorialListEditorDialog = true;
    },

    fetchEditorialLists() {
      this.fetchingEditorialLists = true;
      this.editorialLists = [];
      this.totalEditorialLists = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.editorialOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        editorial
          .fetchEditorialLists(ctx)
          .then(result => {
            this.editorialLists = result.items;
            this.totalEditorialLists = result.totalCount;
            this.cleanContacts = result.cleanContacts;
            this.fetchingEditorialLists = false;
            resolve();
          })

          .catch(err => {
            this.fetchingEditorialLists = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    archiveList(item) {
      editorial
        .archiveList(item.id)
        .then(() => {
          this.fetchEditorialLists();
        })
        .catch(err => {
          console.log(err);
        });
    },

    unarchiveList(item) {
      editorial
        .unarchiveList(item.id)
        .then(() => {
          this.fetchEditorialLists();
        })
        .catch(err => {
          console.log(err);
        });
    },

    onListCreated(item) {
      this.editorialListEditorDialog = false;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "editorial-lists-building-list"
      );

      editorial
        .addEditorialList(item)
        .then(() => {
          this.fetchEditorialLists();
          this.$store.state.global_overlay = false;
        })
        .catch(err => {
          console.log(err);
          this.$store.state.global_overlay = false;
        });
    }
  }
};
</script>
