<template>
  <div>
    <messageBox ref="msgBox" />

    <v-card class="ma-4">
      <v-toolbar color="#0044a3">
        <v-icon color="white" large class="mr-2">mdi-chart-areaspline</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("editorial-stats-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <div style="margin:15px">
        <v-row dense justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-operator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-select
              :label="$t('editorial-filter-source-radio')"
              outlined
              dense
              v-model="filters.radio"
              item-text="name"
              item-value="id"
              :items="flt_radios"
            />
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col>
            <v-select
              :label="$t('editorial-filter-viewtype')"
              outlined
              dense
              v-model="filters.moneyMode"
              item-text="name"
              item-value="id"
              :items="moneyModes"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$t('editorial-filter-datetype')"
              outlined
              dense
              v-model="filters.date_type"
              item-text="name"
              item-value="id"
              :items="date_types"
            />
          </v-col>

          <v-col>
            <v-select
              class="minfield"
              outlined
              dense
              :label="$t('filters-period-hint')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
              :disabled="fetchingData || loading"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingData || loading || filters.filterByPeriod != 9
                  "
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('guests-filter-datestart')"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingData || loading || filters.filterByPeriod != 9
                  "
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('guests-filter-dateend')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Nuovi contatti"
            :value="getTotalAssignments"
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Nuovi contatti"
            :value="getTotalAssignmentsTiming"
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          /> </v-col
        ><v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Ora produttiva"
            :value="getTotalTiming"
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          /> </v-col
        ><v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Totale Euro"
            :value="getTotalMoney"
            sub-icon="mdi-euro"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>
      </v-row>
      <v-row
        ><v-col>
          <v-card>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Esito primario
                    </th>
                    <th class="text-left">
                      Esito secondario
                    </th>
                    <th class="text-center">
                      Conteggio esiti nuovi
                    </th>
                    <th class="text-center">
                      Conteggio esiti richiamo
                    </th>
                    <th class="text-center">
                      Conteggio totale esiti
                    </th>
                    <th class="text-center">
                      Durata esiti nuovi
                    </th>
                    <th class="text-center">
                      Durata esiti richiamo
                    </th>
                    <th class="text-center">
                      Durata esiti totali
                    </th>
                    <th class="text-center">
                      Euro esiti nuovi
                    </th>
                    <th class="text-center">
                      Euro esiti richiamo
                    </th>
                    <th class="text-center">
                      Euro esiti totali
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="x in rows" :key="x[0].toString()">
                    <td class="text-left">{{ $t(x[1]) }}</td>
                    <td class="text-left">{{ $t(x[2]) }}</td>
                    <td class="text-center">{{ x[3] }}</td>
                    <td class="text-center">{{ x[4] }}</td>
                    <td class="text-center">{{ x[5] }}</td>
                    <td class="text-center">
                      {{ x[6] ? formatDuration(parseInt(x[6])) : "" }}
                    </td>
                    <td class="text-center">
                      {{ x[7] ? formatDuration(parseInt(x[7])) : "" }}
                    </td>
                    <td class="text-center">
                      {{ x[6] || x[7] ? formatDuration(parseInt(x[8])) : "" }}
                    </td>
                    <td class="text-center">
                      {{ x[12] ? formatCurrency(parseFloat(x[12])) : "" }}
                    </td>
                    <td class="text-center">
                      {{ x[13] ? formatCurrency(parseFloat(x[13])) : "" }}
                    </td>
                    <td class="text-center">
                      {{
                        x[12] || x[13] ? formatCurrency(parseFloat(x[14])) : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card></v-col
        >
      </v-row>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import config from "../config";
import guestManager from "../apis/guests";
import editorial from "../apis/editorial";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      rows: [],

      date_types: [
        { id: 0, name: this.$t("editorial-filter-date-outcome") },
        { id: 1, name: this.$t("editorial-filter-date-assignment") }
      ],

      moneyModes: [
        { id: null, name: this.$t("editorial-filter-money-both") },
        { id: true, name: this.$t("editorial-filter-money-money") },
        { id: false, name: this.$t("editorial-filter-money-time") }
      ],

      date_start_modal: false,
      date_end_modal: false,

      searchOperatorInput: null,

      operator: null,
      operators: [],
      isLoadingOperator: false,
      loading: false,
      perPage: staticData.defaultPerPage,
      assignments: 0,
      flt_schedulers: [],
      flt_radios: [],

      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByPeriod: 12,
        moneyMode: null,
        operator: null,
        date_type: 0
      },

      fetchingData: false
    };
  },

  components: {
    messageBox
  },

  computed: {
    getTotalAssignmentsTiming: {
      get: function() {
        let total = 240 * this.assignments;
        return this.formatDuration(total);
      }
    },

    getTotalAssignments: {
      get: function() {
        return this.assignments.toString();
      }
    },

    getTotalTiming: {
      get: function() {
        let total = 0;
        for (let x in this.rows) {
          if (this.rows[x][6] || this.rows[x][7]) total += this.rows[x][8];
        }
        total += this.getTotalAssignments * 60 * 4;
        return this.formatDuration(total);
      }
    },

    getTotalMoney: {
      get: function() {
        let total = 0;
        for (let x in this.rows) {
          if (this.rows[x][12] || this.rows[x][13]) total += this.rows[x][14];
        }
        return this.formatCurrency(total);
      }
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  mounted() {
    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios
    ];

    this.operators = [];
    this.fetchEditorialOutcomeStatistics();
  },

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then(res => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.new_id : null;
      }
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchEditorialOutcomeStatistics();
        }, config.searchInputsFastTimeout);
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchEditorialOutcomeStatistics();
        }, config.searchInputsFastTimeout);
      },

      deep: true
    }
  },

  methods: {
    formatDuration: function(item) {
      let duration = item;

      let format = "";
      let seconds = parseInt(Math.floor(duration));
      let hours = Math.floor(seconds / 60 / 60);
      if (hours < 1) hours = 0;
      seconds -= 60 * 60 * hours;
      let minutes = Math.floor(seconds / 60);
      seconds -= 60 * minutes;
      if (seconds < 1) seconds = 0;
      if (hours > 0) format += hours + ":";
      if (minutes > 0 && minutes.toString().length < 2) format += "0";
      if (minutes > 0) format += minutes + ":";
      else format += "00:";
      if (seconds.toString().length < 2) format += "0";
      format += seconds;
      return format;
    },

    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("it", {
        style: "currency",
        currency: "eur",
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },

    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestManager
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchEditorialOutcomeStatistics() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.filters = this.filters;
        editorial
          .fetchEditorialOutcomeStatistics(ctx)
          .then(result => {
            result.items = result.items[0];
            this.assignments = result.assignments;
            let outcome_values = {};
            for (let x in result.items) {
              outcome_values[x] = result.items[x];
            }
            let outcomes = {};
            for (let x in result.outcomes) {
              outcomes[result.outcomes[x].id] = result.outcomes[x];
              outcomes[result.outcomes[x].id].count = 0;
              outcomes[result.outcomes[x].id].count_recall = 0;
              outcomes[result.outcomes[x].id].euro = 0;
              outcomes[result.outcomes[x].id].euro_recall = 0;
            }
            for (let x in result.suboutcomes) {
              let outcome_id = result.suboutcomes[x].outcome_id;
              if (outcomes[outcome_id]) {
                if (!outcomes[outcome_id].childs) {
                  outcomes[outcome_id].childs = [];
                  outcomes[outcome_id].timing = 0;
                  outcomes[outcome_id].timing_recall = 0;
                  outcomes[outcome_id].euro = 0;
                  outcomes[outcome_id].euro_recall = 0;
                }

                outcomes[outcome_id].count +=
                  outcome_values["suboutcome_" + result.suboutcomes[x].id];
                outcomes[outcome_id].count_recall +=
                  outcome_values[
                    "suboutcome_recall_" + result.suboutcomes[x].id
                  ];
                result.suboutcomes[x].count =
                  outcome_values["suboutcome_" + result.suboutcomes[x].id];
                result.suboutcomes[x].count_recall =
                  outcome_values[
                    "suboutcome_recall_" + result.suboutcomes[x].id
                  ];
                result.suboutcomes[x].euro =
                  outcome_values[
                    "suboutcome_value_" + result.suboutcomes[x].id
                  ];
                result.suboutcomes[x].euro_recall =
                  outcome_values[
                    "suboutcome_value_recall_" + result.suboutcomes[x].id
                  ];
                outcomes[outcome_id].childs.push(result.suboutcomes[x]);
                if (!outcomes[outcome_id].timing)
                  outcomes[outcome_id].timing = 0;
                if (!outcomes[outcome_id].timing_recall)
                  outcomes[outcome_id].timing_recall = 0;
                if (!outcomes[outcome_id].euro) outcomes[outcome_id].euro = 0;
                if (!outcomes[outcome_id].euro_recall)
                  outcomes[outcome_id].euro_recall = 0;

                if (outcome_values["suboutcome_" + result.suboutcomes[x].id])
                  outcomes[outcome_id].timing +=
                    result.suboutcomes[x].timing *
                    outcome_values["suboutcome_" + result.suboutcomes[x].id];

                if ("suboutcome_recall_" + result.suboutcomes[x].id)
                  outcomes[outcome_id].timing_recall +=
                    result.suboutcomes[x].timing_recall *
                    outcome_values[
                      "suboutcome_recall_" + result.suboutcomes[x].id
                    ];
                if (outcome_values["suboutcome_" + result.suboutcomes[x].id])
                  outcomes[outcome_id].euro +=
                    outcome_values[
                      "suboutcome_value_" + result.suboutcomes[x].id
                    ];

                if ("suboutcome_recall_" + result.suboutcomes[x].id)
                  outcomes[outcome_id].euro_recall +=
                    outcome_values[
                      "suboutcome_value_recall_" + result.suboutcomes[x].id
                    ];
              }
            }

            this.rows = [];
            let count = 0;
            for (let x in outcomes) {
              this.rows.push([
                count++,
                outcomes[x].name,
                "",
                outcomes[x].count ? outcomes[x].count : 0,
                outcomes[x].count_recall ? outcomes[x].count_recall : 0,
                outcomes[x].count + outcomes[x].count_recall,
                "",
                "",
                outcomes[x].timing_recall && outcomes[x].timing
                  ? outcomes[x].timing_recall + outcomes[x].timing
                  : 0
              ]);

              for (let y in outcomes[x].childs) {
                this.rows.push([
                  count++,
                  "",
                  outcomes[x].childs[y].name,
                  outcomes[x].childs[y].count ? outcomes[x].childs[y].count : 0,
                  outcomes[x].childs[y].count_recall
                    ? outcomes[x].childs[y].count_recall
                    : 0,
                  outcomes[x].childs[y].count_recall +
                    outcomes[x].childs[y].count,
                  outcomes[x].childs[y].timing
                    ? outcomes[x].childs[y].timing * outcomes[x].childs[y].count
                    : 0,
                  outcomes[x].childs[y].timing_recall
                    ? outcomes[x].childs[y].timing_recall *
                      outcomes[x].childs[y].count_recall
                    : 0,
                  outcomes[x].childs[y].timing &&
                  outcomes[x].childs[y].timing_recall
                    ? outcomes[x].childs[y].timing *
                        outcomes[x].childs[y].count +
                      outcomes[x].childs[y].timing_recall *
                        outcomes[x].childs[y].count_recall
                    : 0,
                  outcomes[x].childs[y].timing
                    ? outcomes[x].childs[y].timing * outcomes[x].childs[y].count
                    : 0,
                  outcomes[x].childs[y].timing_recall
                    ? outcomes[x].childs[y].timing_recall *
                      outcomes[x].childs[y].count_recall
                    : 0,
                  outcomes[x].childs[y].timing &&
                  outcomes[x].childs[y].timing_recall
                    ? outcomes[x].childs[y].timing *
                        outcomes[x].childs[y].count +
                      outcomes[x].childs[y].timing_recall *
                        outcomes[x].childs[y].count_recall
                    : 0,
                  outcomes[x].childs[y].euro,
                  outcomes[x].childs[y].euro_recall,
                  outcomes[x].childs[y].euro + outcomes[x].childs[y].euro_recall
                ]);
              }
            }

            this.loading = false;
            resolve();
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    downloadExcelFile() {
      editorial.downloadExcelGlobalStatistics(
        this.$store.state.user.locale,
        this.filters
      );
    }
  }
};
</script>

<style>
.rs-bold {
  font-weight: 600;
}

a {
  color: #0044a3 !Important;
}
</style>
