<template>
  <div>
    <messageBox ref="msgBox" />
    <AudioPlayer ref="audioPlayerDialog" v-model="audioPlayerDialog" />

    <v-row dense justify="center">
      <v-col v-for="p in audios.items" :key="p.id">
        <v-card elevation="3" min-width="200">
          <v-card-title>
            <v-toolbar color="gray" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-speaker</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title class="white--text"
                >{{ p.name ? capitalize(p.name.replace(",", "")) : '' }} </v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col class="text-left">
                <b>OSP-{{ p.guest_id }}</b>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="text-left">
                <b>{{ $t("mediaaudio-speaker") }}</b>
                {{
                  p.speaker_name != null && p.speaker_name.length > 0
                    ? capitalize(p.speaker_name.replace(",", ""))
                    : $t("mediaaudio-speaker-unspecified")
                }}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="text-left">
                <b>{{
                  dateTypes == 1
                    ? $t("mediaaudio-interview-label")
                    : $t("mediaaudio-loading-label")
                }}</b>
                {{
                  dateTypes == 1
                    ? formatDate(p.interview_date)
                    : formatDate(p.insert_date)
                }}
              </v-col> </v-row
            ><v-row dense>
              <v-col class="text-left">
                <b>{{ $t("mediaaudio-length") }}</b> {{ getDuration(p) }}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="text-left">
                <b>{{ $t("mediaaudio-planned-stream") }}</b>
                {{
                  p.allowed_to_stream ? (p.planned_stream
                    ? $options.filters.toLocaleDateTime(p.planned_stream)
                    : $t("mediaaudio-planned-stream-none")) : $t('mediaaudio-planned-stream-disabled')
                }}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="text-left">
                <b>{{ $t("mediaaudio-effective-stream") }}</b>
                {{
                  p.allowed_to_stream ? (p.stream_time
                    ? $options.filters.toLocaleDateTime(p.stream_time)
                    : $t("mediaaudio-effective-stream-none")) : $t('mediaaudio-planned-stream-disabled')
                }}
              </v-col>
            </v-row>

            <v-row class="elevation-4" style="min-width:350px;">
              <v-col v-if="$store.state.hasRight('6')">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="green"
                      @click.stop="playAudio(p)"
                      v-on="on"
                      ><v-icon>mdi-play</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediaaudio-play") }}</span></v-tooltip
                >
              </v-col>
              <v-col v-if="$store.state.hasRight('8')">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="orange"
                      @click.stop="showPath(p)"
                      v-on="on"
                      ><v-icon>mdi-folder</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediaaudio-show-path") }}</span></v-tooltip
                >
              </v-col>
              <v-col v-if="$store.state.hasRight('7')">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="purple"
                      @click.stop="getSourceAudio(p)"
                      v-on="on"
                      ><v-icon>mdi-download</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediaaudio-download") }}</span></v-tooltip
                >
              </v-col>

              <v-col v-if="$store.state.hasRight('135')">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="red"
                      @click.stop="deleteAudio(p)"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediaaudio-remove") }}</span></v-tooltip
                >
              </v-col>

              <v-col
                v-if="$store.state.hasRight('154') && canScheduleBeRemoved(p)"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="cyan"
                      @click.stop="deleteScheduling(p)"
                      v-on="on"
                      ><v-icon>mdi-nuke</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("mediaaudio-delete-scheduler")
                  }}</span></v-tooltip
                >
              </v-col>

              <v-col
                v-if="
                  $store.state.hasRight('155') &&
                    p.allowed_to_stream &&
                    scheduledDeltaNotReached(p)
                "
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn small text icon color="gray" v-on="on"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("mediaaudio-reprogram-stream-disabled")
                  }}</span></v-tooltip
                >
              </v-col>

              <v-col
                v-if="
                  $store.state.hasRight('155') &&
                    p.allowed_to_stream &&
                    canBeRescheduled(p)
                "
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="green"
                      @click.stop="rescheduleAudio(p)"
                      v-on="on"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("mediaaudio-reprogram-stream")
                  }}</span></v-tooltip
                >
              </v-col>

              <v-col
                v-if="
                  p.allowed_to_stream &&
                    !p.planned_stream &&
                    !p.stream_time &&
                    $store.state.hasRight('134')
                "
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="red"
                      @click.stop="disableStream(p)"
                      v-on="on"
                      ><v-icon>mdi-radio-tower</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediaaudio-disable-stream") }}</span></v-tooltip
                >
              </v-col>
              <v-col
                v-if="
                  p.allowed_to_stream &&
                    p.planned_stream &&
                    !p.stream_time &&
                    $store.state.hasRight('134')
                "
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn small text icon color="gray" v-on="on"
                      ><v-icon>mdi-radio-tower</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("mediaaudio-disable-stream-already-in-schedule")
                  }}</span></v-tooltip
                >
              </v-col>

              <v-col
                v-if="!p.allowed_to_stream && $store.state.hasRight('134')"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      small
                      text
                      icon
                      color="green"
                      @click.stop="enableStream(p)"
                      v-on="on"
                      ><v-icon>mdi-radio-tower</v-icon></v-btn
                    ></template
                  ><span>{{ $t("mediaaudio-enable-stream") }}</span></v-tooltip
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-if="
        search &&
          audios.items.length < audios.totalCount &&
          audios.items.length != 0
      "
      class="text-center pa-2 load-more"
    >
      <v-btn v-if="!loadingMore" @click="loadMore()" color="gray" dark>{{
        $t("mediaaudio-loadmore")
      }}</v-btn>
      <v-progress-circular
        v-if="loadingMore"
        :indeterminate="true"
        :rotate="0"
        :size="32"
        :width="4"
        color="light-blue"
      ></v-progress-circular>
    </div>

    <v-row v-if="audios.items.length == 0" align="center" justify="center">
      <v-col class="text-center" cols="12">
        <h2 v-if="search">
          {{ $t("mediaaudio-search-no-results") }}
        </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DateTime as LuxonDateTime } from "luxon";
import config from "../../config";
import Vue from "vue";
import localStorageService from "../../services/localStorageService";
import messageBox from "../../components/MessageBox.vue";
import AudioPlayer from "../../components/audio/AudioPlayer";
import audioManager from "../../apis/audio";

export default {
  data() {
    return {
      audioPlayerDialog: false,
      audioKey: 0,
      currentAudio: null,
    };
  },

  components: {
    messageBox,
    AudioPlayer,
  },

  props: {
    audios: Object,
    search: Boolean,
    allowEditing: Boolean,
    loadingMore: Boolean,
    dateTypes: Number,
  },

  methods: {
    scheduledDeltaNotReached(p) {
      const date1 = LuxonDateTime.fromISO(p.interview_date);
      const date2 = LuxonDateTime.utc();
      const diff = date1.diff(date2, ["days"]).toObject().days;
      if (diff > -parseInt(this.$store.state.frontend.audio_stream_delta_days))
        return true;
      else return false;
    },

    canBeRescheduled(p) {
      return (
        !this.scheduledDeltaNotReached(p) &&
        !p.force_reprogram &&
        !(p.planned_stream && !p.stream_time)
      );
    },

    canScheduleBeRemoved(p) {
      return p.planned_stream && !p.stream_time;
    },

    getDuration(p) {
      if (!p.duration) return this.$t("gbl-none-female");
      let minutes = Math.floor(p.duration / 60);
      let seconds = Math.floor(p.duration % 60).toString();
      if (seconds.length < 2) seconds = "0" + seconds;
      return minutes + ":" + seconds;
    },

    formatDate: function(date) {
      var formatter = new Intl.DateTimeFormat("it-IT", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      let formatted_date = date;

      try {
        formatted_date = formatter.format(Date.parse(date));
      } catch (err) {
        console.log(err);
      }
      return formatted_date;
    },

    capitalize: function(n) {
      let split = n.split(" ");
      let names = [];
      for (let n = 0; n < split.length; n++) {
        let lower = split[n].toLowerCase();
        if (lower.length)
          lower = lower[0].toUpperCase() + lower.substr(1, lower.length - 1);
        names.push(lower);
      }

      return names.join(" ");
    },

    deleteAudio: function(p) {
      this.$refs.msgBox
        .show(
          this.$t("fileview-remove-file-text"),
          this.$t("fileview-remove-file-title")
        )
        .then(() => {
          audioManager
            .deleteMedia(p.id)
            .then(() => {
              const index = this.audios.items.indexOf(p);
              if (index > -1) {
                this.audios.items.splice(index, 1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    rescheduleAudio: function(p) {
      audioManager
        .rescheduleStream(p.id)
        .then(() => {
          p.stream_time = null;
          p.planned_stream = null;

          this.$refs.msgBox
            .show(
              this.$t("mediaaudio-reprogram-stream-success"),
              this.$t("mediaaudio-reprogram-stream"),
              true
            )
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteScheduling: function(p) {
      audioManager
        .deleteStreamScheduling(p.id)
        .then(() => {
          p.stream_time = null;
          p.planned_stream = null;
          p.allowed_to_stream = null;
          this.$refs.msgBox
            .show(
              this.$t("mediaaudio-disable-stream-schedule-success"),
              this.$t("mediaaudio-disable-stream-schedule"),
              true
            )
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },

    disableStream: function(p) {
      audioManager
        .setStreamState(p.id, 0)
        .then(() => {
          p.allowed_to_stream = 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    enableStream: function(p) {
      audioManager
        .setStreamState(p.id, 1)
        .then(() => {
          p.allowed_to_stream = 1;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSourceAudio: function(p) {
      const token = localStorageService.getAccessToken();
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/audio/source/" +
          p.id +
          "?token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      let filename = p.name + "_" + "_" + p.email + ".mp4";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    loadMore: function() {
      this.$emit("loadMore");
    },

    playAudio: function(p) {
      this.currentAudio = p;
      this.audioKey += 1;
      this.audioPlayerDialog = true;
      this.$refs.audioPlayerDialog.setPodcast(p);
    },

    showPath: function(p) {
      this.$refs.msgBox
        .show(p.path, "Percorso file", true)
        .then(() => {})
        .catch(() => {});
    },

    closeDialog: function() {
      this.currentAudio = null;
      Vue.nextTick(() => {
        this.audioPlayerDialog = false;
        this.audioKey += 1;
      });
    },
  },
};
</script>

<style>
.podcast-search-item {
  font-size: 18px;
  font-weight: bold;
}

.search-thumbnail {
  border-radius: 15%;
}

.load-more {
  margin-bottom: 60px;
}
</style>
