let global_locals = {
  payment_link_address: {
    it: "codiceidentificativo",
    en: "identitycode",
    cz: "identifikacnikod",
    fr: "identitycode",
  },
};

exports.strings = global_locals;
