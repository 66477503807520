<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    max-height="400"
    :value="openDialog"
  >
    <setString ref="setString" />
    <msgBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-card class="pa-0 ">
            <v-toolbar color="primary" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-database-import</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title class="white--text">{{
                $t("editorial-import-prefs-title")
              }}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text v-if="fetchingNations">
              <v-row class="text-center" align="start" justify="center">
                <v-col>
                  <v-progress-circular
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="!fetchingNations">
              <v-row dense class="text-center" align="start" justify="center">
                <v-col>
                  <v-select
                    dense
                    v-model="nation_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="nations"
                    :label="$t('editorial-import-prefs-nation')"
                    :disabled="
                      fetchingNations || csvProcessing || uploadInProgress
                    "
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    :disabled="csvProcessing || uploadInProgress"
                    dense
                    hide-details
                    v-model="move_existing_to_list"
                    :label="$t('editorial-import-prefs-existing-list')"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-select
                    dense
                    v-model="existing_list_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="lists"
                    :label="$t('editorial-import-prefs-target-existing-list')"
                    :disabled="
                      fetchingData ||
                        csvProcessing ||
                        uploadInProgress ||
                        !move_existing_to_list
                    "
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    :disabled="csvProcessing || uploadInProgress"
                    dense
                    hide-details
                    v-model="move_new_to_list"
                    :label="$t('editorial-import-prefs-new-list')"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-select
                    dense
                    v-model="new_list_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="lists"
                    :label="$t('editorial-import-prefs-target-new-list')"
                    :disabled="
                      fetchingData ||
                        csvProcessing ||
                        uploadInProgress ||
                        !move_new_to_list
                    "
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row dense class="text-center" align="start" justify="center">
                <v-col>
                  <v-file-input
                    outlined
                    dense
                    hide-details
                    :disabled="
                      uploadInProgress || csvProcessing || fetchingData
                    "
                    v-model="file"
                    accept="*"
                    :label="$t('fileupload-select')"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row v-if="uploadInProgress"
                ><v-col cols="11"
                  ><v-progress-linear
                    striped
                    style="height:24px"
                    v-model="uploadProgressPerc"/></v-col
                ><v-col cols="1">
                  <v-progress-circular
                    v-if="csvProcessing"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row dense class="text-center" align="start" justify="center">
                <v-col>
                  <v-btn
                    :disabled="
                      csvProcessing || uploadInProgress || fetchingData
                    "
                    color="green"
                    v-on:click="submitFile()"
                    >{{ $t("editorial-import-start") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorialManager from "../../apis/editorial.js";
import setString from "../../components/SetString";
import msgBox from "../../components/MessageBox";

export default {
  data() {
    return {
      dialogResolve: null,
      dialogReject: null,
      openDialog: false,
      fetchingNations: false,
      nation_id: 86,

      move_existing_to_list: true,
      move_new_to_list: true,
      new_list_id: 0,
      existing_list_id: 0,
      nations: [],
      lists: [],

      file: null,
      fileSize: 0,
      fileSizeMax: 100000000,
      uploadProgressPerc: 0,
      path: "",
      uploadInProgress: false,
      csvProcessing: false,
      fetchingData: false
    };
  },

  components: {
    setString,
    msgBox
  },

  watch: {
    nation_id: {
      handler() {}
    }
  },

  methods: {
    fileUploadProgress(result) {
      this.fileSizeMax = result.total;
      this.fileSize = result.loaded;
      this.uploadProgressPerc = (this.fileSize / this.fileSizeMax) * 100.0;
      if (this.fileSize == this.fileSizeMax) this.csvProcessing = true;
    },

    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("path", this.path);
      formData.append("nation_id", this.nation_id);
      formData.append("move_existing_to_list", this.move_existing_to_list);
      formData.append("move_new_to_list", this.move_new_to_list);
      formData.append("new_list_id", this.new_list_id);
      formData.append("existing_list_id", this.existing_list_id);

      this.uploadInProgress = true;
      this.csvProcessing = false;
      editorialManager
        .parseCSV(formData, this.fileUploadProgress)
        .then(res => {
          this.uploadInProgress = false;
          this.csvProcessing = false;
          this.$emit("uploadCompleted", res);
          this.closeDialog();
        })
        .catch(err => {
          this.uploadInProgress = false;
          this.csvProcessing = false;
          console.log(err);
        });
    },

    fetchLists() {
      this.fetchingData = true;
      editorialManager
        .fetchListsNames({ filters: { archived: false } })
        .then(res => {
          this.lists = [{ id: 0, name: this.$t("gbl-none-female") }, ...res];
          this.fetchingData = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingData = false;
        });
    },

    fetchNations() {
      this.fetchingNations = true;
      this.region_id = null;
      this.province_id = null;
      this.town_id = null;

      editorialManager
        .fetchNations()
        .then(nations => {
          this.fetchingNations = false;
          this.nations = nations.map(x => {
            x.name = this.$t(x.name);
            return x;
          });
        })
        .catch(err => {
          this.fetchingNations = false;
          console.log(err);
        });
    },

    show() {
      return new Promise((resolve, reject) => {
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.nation_id = 86;
        this.fetchLists();
        this.fetchNations();
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve({
        nation_id: this.nation_id,
        region_id: this.region_id,
        town_id: this.town_id,
        province_id: this.province_id,
        category_id: this.category_id,
        region_use_prefix: this.region_use_prefix,
        towns_use_prefix: this.towns_use_prefix,
        provinces_use_prefix: this.provinces_use_prefix
      });
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>
