import axios from "axios";
import config from "../../config";
import localStorageService from "../../services/localStorageService";

let tasks = new Object({
  deleteImport: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/deleteImport",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchImports: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/fetchImports",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.imports,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addCategory: (category, nation_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/addCategory",
        data: { category: category, nation_id: nation_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.id);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCategories: (nation_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/fetchCategories",
        data: { nation_id: nation_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.categories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadTask: (id, import_name, options, progressCallback) => {
    return new Promise((resolve, reject) => {
      progressCallback(null);
      axios({
        url: config.apiEndPoint + "/tasks/uploadTask",
        data: { id: id, import_name: import_name, options: options },
        method: "POST",
        onDownloadProgress: (progressEvent) => {
          progressCallback(progressEvent.currentTarget.response);
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFilters: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/filters",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.filters,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTasks: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.tasks,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUsers: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/users",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.users,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addTask: (task) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/add",
        data: { task: task },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateTask: (task) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/update",
        data: { task: task },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeTask: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  archiveTask: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/archiveTask",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  unarchiveTask: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/unarchiveTask",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rebootUnprocessed: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/rebootUnprocessed",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reboot: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tasks/reboot",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadResults: (id) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/tasks/downloadResults?id=" +
          id +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      let filename = "risultati_task_" + id + "csv";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadExcelResults: (id) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/tasks/downloadExcelResults?id=" +
          id +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      let filename = "risultati_task_" + id + "xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
});

export default tasks;
