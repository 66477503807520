<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="800" :value="value">
    <v-card class="ma-0 pa-0">
      <v-toolbar color="orange" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-speaker</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title class="white--text">{{ $t('callrec-player-title') }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon @click="closeDialog" large>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row class="podcast-search-item body-1" align="center">
          <v-col class="text-left">
            <v-row dense
              ><v-col class="font-weight-bold">{{ $t('callrec-player-operator') }}</v-col></v-row
            >
            <v-row dense
              ><v-col>{{ call ? call.operator : '' }}</v-col></v-row
            >
          </v-col>

          <v-col class="text-left">
            <v-row dense
              ><v-col class="font-weight-bold">{{ $t('callrec-player-number') }}</v-col></v-row
            >
            <v-row dense
              ><v-col>{{ call ? call.phone : '' }}</v-col></v-row
            >
          </v-col>

          <v-col class="text-left">
            <v-row dense><v-col class="font-weight-bold">{{ $t('callrec-player-outcome') }}</v-col></v-row>
            <v-row dense
              ><v-col>{{
                call ? resultToText(call.call_result) : ''
              }}</v-col></v-row
            >
          </v-col>
        </v-row>

        <v-row
          v-if="call"
          class="text-center podcast-search-item body-1"
          align="center"
          justify="center"
        >
          <v-col class="body-1"> </v-col>
        </v-row>

        <v-row
          v-if="audioError"
          class="text-center red--text podcast-search-item body-1"
          align="center"
          justify="center"
        >
          <v-col class="body-2 font-weight-bold">
            <h2>{{ audioError }}</h2>
          </v-col>
        </v-row>

        <v-row
          v-if="initializing"
          class="text-center podcast-search-item body-1"
          align="center"
          justify="center"
          ><v-col>
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row
          v-if="playingSound && !initializing"
          class="text-center podcast-search-item body-1"
          align="center"
          justify="center"
          >
          <v-col>
            <v-btn v-if="!paused" x-small icon @click="onStop()"><v-icon>mdi-pause</v-icon></v-btn>
            <v-btn v-if="paused" x-small icon @click="onPlay()"><v-icon>mdi-play</v-icon></v-btn>
            </v-col>
            <v-col cols=9><v-progress-linear
              @change="onChangeSeek"
              :value="soundProgress"
              color="black"
              style="height:15px; cursor: pointer;"
            ></v-progress-linear>
            </v-col>
            <v-col cols=2>{{ getDuration(currentPosition) }} /
            {{ getDuration(currentDuration) }}
          </v-col>
     
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from '../../config';
import {Howl} from 'howler';

export default {
  data() {
    return {
      call: null,
      initializing: false,
      audioError: null,
      playingSound: null,
      playingSoundUpdated: null,
      soundProgress: 0,
      currentPosition: 0,
      currentDuration: 0,
      paused : false
    };
  },

  props: {
    value: Boolean,
    results: Array,
  },

  components: {},

  methods: {
    onChangeSeek(percent) {
      let position = this.currentDuration / 100.0 * percent;
      this.playingSound.seek(position)

    },

     onPlay() {
      if (this.paused) {
        this.playingSound.play();
        this.paused = false;
      }

    },

    onStop() {
       if (!this.paused) {
        this.playingSound.pause();
        this.paused = true;
      }
      
    },


    resultToText(result) {
      return this.results[this.call.operator_group][result];
    },

    setCall: function(item) {
      this.call = item;
      this.paused = false;
      this.audioError = null;
      this.initializing = true;
      if (this.playingSoundUpdated) {
        clearInterval(this.playingSoundUpdated);
        this.playingSoundUpdated = null;
      }
      // Preparing the audio streaming

      let path = this.getStreamingPath(this.call);
      this.playingSound = new Howl({
        src: [path],
        format: ['mp3'],
        loop: true,
        xhr: {
          method: 'POST',
        },
      });

      // Clear listener after first call.
      this.playingSound.once('loaderror', (id, err) => {
        this.initializing = false;
        this.audioError = err;
        this.playingSound = null;
      });

      this.playingSound.once('playerror', (id, err) => {
        this.initializing = false;
        this.audioError = err;
        this.playingSound = null;
      });

      if (this.playingSound.state() == 'loaded') {
        this.initializing = false;
        this.currentDuration = this.playingSound.duration();
        this.playingSound.play();
        this.playingSoundUpdated = setInterval(() => {
          this.updateSound();
        }, 100);
      } else {
        this.playingSound.once('load', () => {
          this.initializing = false;
          this.currentDuration = this.playingSound.duration();
          this.playingSound.play();
          this.playingSoundUpdated = setInterval(() => {
            this.updateSound();
          }, 100);
        });
      }
    },

    updateSound() {
      if (this.playingSound.playing()) {
        this.currentPosition = this.playingSound.seek();
        this.soundProgress =
          (this.currentPosition / this.currentDuration) * 100;
      }
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.call = null;
      this.paused = false;
      this.audioError = null;
      this.initializing = true;
      if (this.playingSoundUpdated) {
        clearInterval(this.playingSoundUpdated);
        this.playingSoundUpdated = null;
      }
      if (this.playingSound) this.playingSound.stop();
      this.playingSound = null;
      this.$emit('input'); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    getDuration: function(s) {
      if (s) {
        let seconds = Math.floor(s);
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        return minutes + ':' + seconds.toString().padStart(2, '0');
      } else {
        return '0:00';
      }
    },

    getStreamingPath: function(p) {
      return config.apiEndPoint + '/calls/stream/' + p.id;
    },
  },
};
</script>

<style>
.img-center {
  margin: 0 auto;
  display: block;
}

.thumbnail {
  border-radius: 15%;
}
</style>
