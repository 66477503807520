import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt from "jsonwebtoken";
import config from "../config";
import localStorageService from "../services/localStorageService";
import rightsServices from "../services/rights";
import avatars from "../services/avatars";
import usersManager from "../apis/users";
import i18n from "../plugins/i18n";
import { Settings } from "luxon";

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en";

Vue.use(Vuex);
// Reload user information from the cached authToken
let localAuthUser = null;
let localAccessToken = localStorageService.getAccessToken();
let localAvatar = localStorageService.getAvatar();
let notificationObject = localStorageService.getNotifications();
let localLocale = localStorageService.getLocale();
let localNotifications = [];
try {
  localNotifications = notificationObject ? JSON.parse(notificationObject) : {};
} catch (err) {
  localNotifications = [];
}

if (!localLocale) {
  localLocale = getNavigatorLanguage()
    ? getNavigatorLanguage().substr(0, 2)
    : "it";
  i18n.locale = localLocale;
} else {
  i18n.locale = localLocale;
}

if (localAccessToken) {
  let decodedToken = jwt.decode(localAccessToken);
  if (decodedToken) {
    localAuthUser = decodedToken.user;
    localAuthUser.avatar = localAvatar;
    localAuthUser.notifications = localNotifications;
    localAuthUser.locale = localLocale;
  }

  i18n.locale = localLocale;
}

let localData = localStorageService.getLocalData();
if (localData && !localData.frontend) {
  localData.frontend = {
    audio_stream_delta_days: 15,
  };
}

if (!localData) localData = {};

if (localData.locales) {
  let loc = [];
  for (let n in localData.locales) {
    let l = Object.assign({}, localData.locales[n]);
    l.name = i18n.t("locales-" + l.code);
    loc.push(l);
  }
  localData.tr_locales = loc;
}

if (
  !localAuthUser ||
  !localData ||
  !localData.outcomes ||
  !localData.tr_locales ||
  !localData.radios ||
  !localData.types
) {
  localAuthUser = {
    gravatar: null,
    first_name: "",
    last_name: "",
    id: 0,
    rights: [],
    avatar: localAvatar,
    email: "",
    notifications: [],
    locale: getNavigatorLanguage() ? getNavigatorLanguage().substr(0, 2) : "it",
  };
  i18n.locale = localAuthUser.locale;
}

Settings.defaultLocale = i18n.locale;

export default new Vuex.Store({
  state: {
    global_overlay: null,
    global_overlay_text: null,
    drawer: null,
    loggingIn: false,
    user: {
      first_name: localAuthUser.first_name,
      last_name: localAuthUser.last_name,
      id: localAuthUser.id,
      rights: localAuthUser.rights,
      email: localAuthUser.email,
      avatar: localAvatar,
      gravatar: localAuthUser.gravatar,
      notifications: localAuthUser.notifications,
      locale: localAuthUser.locale,
      locked_radio: localAuthUser.locked_radio,
      locked_locale: localAuthUser.locked_locale,
      locked_type: localAuthUser.locked_type,
    },

    outcomes: localData.outcomes,
    vcoutcomes: localData.vcoutcomes,
    livesoutcomes: localData.livesoutcomes,
    regions: localData.regions,
    radios: localData.radios,
    schedulers: localData.schedulers,
    locales: localData.locales,
    types: localData.types,
    programs: localData.programs,
    categories: localData.categories,
    territories_radios: localData.territories_radios,
    anagrafica_types: localData.anagrafica_types,
    provinces: localData.provinces,
    countries: localData.countries,
    payment_methods: localData.payment_methods,
    frontend: localData.frontend,
    versions: localData.versions,
    tr_locales: localData.tr_locales,

    hasRight: function(right) {
      return rightsServices.hasRight(this.user.rights, right);
    },

    isAdmin: function() {
      return rightsServices.isAdmin(this.user.rights);
    },

    isSuperAdmin: function() {
      return rightsServices.isSuperAdmin(this.user.rights);
    },
  },

  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },

    loginStart: (state) => {
      state.loggingIn = true;
    },

    loginCompleted: (state, payload) => {
      if (payload.access_token && payload.refresh_token && payload.u) {
        localStorageService.setToken({
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
        });
        localStorageService.setAvatar(payload.avatar);
        localStorageService.setNotifications(
          JSON.stringify(payload.notifications)
        );
        state.user = payload.u;
        i18n.locale = state.user.locale;
        Settings.defaultLocale = i18n.locale;
        state.user.avatar = payload.avatar;
        state.user.notifications = payload.notifications;
        if (!state.user.locale)
          state.user.locale = getNavigatorLanguage()
            ? getNavigatorLanguage().substr(0, 2)
            : "it";
      } else {
        state.user = {};
        localStorageService.clearToken();
        localStorageService.clearData();
      }
      state.loggingIn = false;
    },

    logoutCompleted: (state) => {
      state.loggingIn = false;
      state.user = {};
      localStorageService.clearToken();
      localStorageService.clearData();
    },

    staticDataCompleted: (state, payload) => {
      let loc = [];
      for (let n in payload.locales) {
        let l = Object.assign({}, payload.locales[n]);
        l.name = i18n.t("locales-" + l.code);
        loc.push(l);
      }
      payload.tr_locales = loc;

      state.anagrafica_types = payload.anagrafica_types;
      state.categories = payload.categories;
      state.countries = payload.countries;
      state.frontend = payload.frontend;
      state.livesoutcomes = payload.livesoutcomes;
      state.locales = payload.locales;
      state.outcomes = payload.outcomes;
      state.payment_methods = payload.payment_methods;
      state.programs = payload.programs;
      state.provinces = payload.provinces;
      state.radios = payload.radios;
      state.regions = payload.regions;
      state.schedulers = payload.schedulers;
      state.territories_radios = payload.territories_radios;
      state.tr_locales = payload.tr_locales;
      state.types = payload.types;
      state.vcoutcomes = payload.vcoutcomes;

      localStorageService.setLocalData(payload);
    },
  },

  actions: {
    impersonate({ commit }, payload) {
      return new Promise((resolve /*, reject*/) => {
        commit("loginCompleted", {
          access_token: payload.authToken,
          refresh_token: payload.refreshToken,
          u: payload.user,
          avatar: null,
          notifications: [],
        });

        resolve();
      });
    },

    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("loginStart");
        axios({
          url: config.apiEndPoint + "/auth/login",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.responseError) {
              commit("loginCompleted", { error: resp.data.responseError });
              localStorageService.clearToken();
              localStorageService.clearData();
              reject(resp.data.responseError);
            } else {
              let accessToken = resp.data.responseData.authToken;
              let refreshToken = resp.data.responseData.refreshToken;
              let responseUser = resp.data.responseData.user;
              let notifications = resp.data.responseData.notifications;
              if (!accessToken || !user || !refreshToken) {
                commit("loginCompleted", {});
                localStorageService.clearToken();
                localStorageService.clearData();
                if (resp.data.responseError) reject(resp.data.responseError);
                else reject("Invalid response from server");
              } else {
                localStorageService.setNotifications(notifications);
                if (responseUser.gravatar == true) {
                  avatars
                    .fetchGravatar(responseUser.email)
                    .then((result) => {
                      let responseAvatar = result;
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: responseAvatar,
                        notifications: notifications,
                      });
                      resolve(resp);
                    })
                    .catch((err) => {
                      console.log(err);
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: null,
                        notifications: notifications,
                      });
                      resolve(resp);
                    });
                } else if (responseUser.gravatar == false) {
                  // Fetch user profile picture
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                    notifications: notifications,
                  });
                  usersManager
                    .fetchAvatar(responseUser.id, accessToken)
                    .then((result) => {
                      this.state.user.avatar = result.avatar;
                      localStorageService.setAvatar(this.state.user.avatar);
                      resolve(resp);
                    })
                    .catch((err) => {
                      console.log(err);
                      resolve(resp);
                    });
                } else {
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                    notifications: notifications,
                  });
                  resolve(resp);
                }
              }
            }
          })
          .catch((err) => {
            commit("loginCompleted", { error: err });
            localStorageService.clearToken();
            localStorageService.clearData();
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: config.apiEndPoint + "/auth/logout",
          data: {},
          method: "POST",
        })
          .then(function() {
            commit("logoutCompleted");
            resolve();
          })
          .catch(function(error) {
            commit("logoutCompleted");
            reject(error);
          });
      });
    },

    unauthorized({ commit }) {
      return new Promise((resolve) => {
        commit("logoutCompleted");
        resolve();
      });
    },

    fetchStaticData({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: config.apiEndPoint + "/db/static", method: "POST" })
          .then((resp) => {
            commit("staticDataCompleted", resp.data.responseData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
