<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    max-height="400"
    :value="openDialog"
  >
    <SetString ref="setItemText" />
    <MessageBox ref="msgBox" />
    <input
      type="file"
      id="fileUpload"
      ref="file"
      v-on:change="handleImport()"
      hidden
    />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-card class="pa-0 ">
            <v-toolbar color="purple" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-database-plus</v-icon>
              </v-app-bar-nav-icon>

              <v-toolbar-title class="white--text"
                >{{ $t("scraper-task-addtask") }}</v-toolbar-title
              >

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row class="text-center" align="start" justify="center">
                  <v-col class="text-left">
                    <v-row
                      v-if="loadingZones || loadingKeywords"
                      class="text-center"
                      align="start"
                      justify="center"
                    >
                      <v-col>
                        <v-progress-circular
                          :indeterminate="true"
                          :rotate="0"
                          :size="32"
                          :width="4"
                          color="light-blue"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row v-if="!loadingZones && !loadingKeywords">
                      <v-col>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            v-model="keyword"
                            outlined
                            :label="$t('scraper-addtask-keywords')"
                            item-text="v"
                            item-value="id"
                            :items="keywords"
                            :error-messages="errors"
                            :success="valid"
                            required
                            dense
                            return-object
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            v-model="zone"
                            dense
                            outlined
                            :label="$t('scraper-addtask-zones')"
                            item-text="v"
                            item-value="id"
                            :items="zones"
                            :error-messages="errors"
                            :success="valid"
                            required
                            return-object
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="!loadingZones && !loadingKeywords">
                  <v-col>
                    <v-select
                      v-model="hostname"
                      dense
                      outlined
                      :label="$t('scraper-addtask-host')"
                      item-text="n"
                      item-value="v"
                      :items="hosts"
                      required
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="exact_match"
                      dense
                      outlined
                      :label="$t('scraper-addtask-exactmatch')"
                      item-text="value"
                      item-value="id"
                      :items="matches"
                      required
                    />
                  </v-col>
                </v-row>

                <v-row v-if="!loadingZones && !loadingKeywords">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        v-model="priority"
                        outlined
                        :label="$t('scraper-addtask-priority')"
                        :items="priorities"
                        :error-messages="errors"
                        :success="valid"
                        required
                        item-text="v"
                        item-value="n"
                        dense
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="!loadingZones && !loadingKeywords">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        v-model="provider"
                        outlined
                        :label="$t('scraper-addtask-provider')"
                        :items="providers"
                        :error-messages="errors"
                        :success="valid"
                        required
                        item-text="v"
                        item-value="v"
                        dense
                        immediate
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row class="text-center" align="center" justify="center">
                  <v-col>
                    {{ $t("scraper-addtask-numiteractions") }}
                    {{ getIteractions }}
                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" justify="center">
                  <v-col>
                    <v-progress-circular
                      v-if="addingTask"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>

                    <v-btn
                      v-if="!addingTask"
                      :disabled="
                        loadingZones || loadingKeywords || !validated || invalid
                      "
                      v-on:click="addTask()"
                      class="mr-2 white--text"
                      color="purple"
                      ><v-icon>mdi-database-plus</v-icon>
                      {{ $t("scraper-addtask-add") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

import zonesManager from "../../apis/scraper/zones";
import keywordsManager from "../../apis/scraper/keywords";
import tasksManager from "../../apis/scraper/tasks";
import SetString from "../SetString.vue";
import MessageBox from "../MessageBox";

export default {
  data() {
    return {
      dialogResolve: null,
      dialogReject: null,
      openDialog: false,
      loadingZones: true,
      loadingKeywords: true,
      addingTask: false,
      zones: [],
      keywords: [],
      priority: 3,
      matches: [
        { id: true, value: this.$t("gbl-yes") },
        { id: false, value: this.$t("gbl-no") },
      ],

      hosts: [
        { v: "", n: this.$t('gbl-all-male') },
        { v: "SCRAPER-1", n: "SCRAPER-1" },
        { v: "SCRAPER-2", n: "SCRAPER-2" },
        { v: "SCRAPER-3", n: "SCRAPER-3" },
        { v: "SCRAPER-4", n: "SCRAPER-4" },
        { v: "SCRAPER-5", n: "SCRAPER-5" },
        { v: "SCRAPER-6", n: "SCRAPER-6" },
        { v: "SCRAPER-7", n: "SCRAPER-7" },
        { v: "SCRAPER-8", n: "SCRAPER-8" },
        { v: "SCRAPER-9", n: "SCRAPER-9" },
        { v: "SCRAPER-10", n: "SCRAPER-10" },
        { v: "SCRAPER-11", n: "SCRAPER-11" },
        { v: "SCRAPER-12", n: "SCRAPER-12" },
        { v: "SCRAPER-13", n: "SCRAPER-13" },
        { v: "SCRAPER-14", n: "SCRAPER-14" },
        { v: "DESKTOP-5TDS06O", n: "DESKTOP-5TDS06O" },
        { v: "DESKTOP-C4EHEGG", n: "DESKTOP-C4EHEGG" },
        { v: "SCRAPING", n: "SCRAPING" },
        { v: "DESKTOP-PCC80OV", n: "DESKTOP-PCC80OV" },
        { v: "DESKTOP-AK2QA3V", n: "DESKTOP-AK2QA3V" },
        { v: "DESKTOP-AHC94HV", n: "DESKTOP-AHC94HV" },
      ],

      priorities: [
        {
          v: this.$t("scraper-priority-realtime"),
          n: 5,
        },
        {
          v: this.$t("scraper-priority-high"),
          n: 4,
        },
        { v: this.$t("scraper-priority-normal"), n: 3 },
        { v: this.$t("scraper-priority-low"), n: 2 },
        { v: this.$t("scraper-priority-below"), n: 1 },
      ],

      providers: [
        {
          v: "facebook.com",
        },
        {
          v: "linkedin.com",
        },
        {
          v: "twitter.com",
        },
        {
          v: "instagram.com",
        },
        {
          v: "tumblr.com",
        },
        {
          v: "pinterest.com",
        },
        {
          v: "youtube.com",
        },
        {
          v: "xing.com",
        },
        {
          v: "flickr.com",
        },
        {
          v: "quora.com",
        },
        {
          v: "google.com",
        },
      ],

      provider: null,
      keyword: null,
      zone: null,
      exact_match: true,
      hostname: null,
      start_now: true,
      scheduled_start: null,
    };
  },

  components: {
    SetString,
    MessageBox,
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    getIteractions() {
      if (this.keyword && this.zone) {
        return this.keyword.count * this.zone.count;
      }
      return null;
    },
  },

  methods: {
    show() {
      return new Promise((resolve, reject) => {
        this.keyword = null;
        this.zone = null;
        this.priority = 3;
        this.provider = null;
        this.hostname = null;
        this.exact_match = true;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.fetchKeywords();
        this.fetchZones();
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve();
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },

    addTask() {
      this.addingTask = true;
      tasksManager
        .addTask({
          keyword: this.keyword,
          zone: this.zone,
          priority: this.priority,
          provider: this.provider,
          hostname: this.hostname,
          exact_match: this.exact_match,
        })
        .then((id) => {
          this.openDialog = false;
          this.dialogResolve(id);
          this.addingTask = false;
        })
        .catch((err) => {
          console.log(err);
          this.addingTask = false;
        });
    },

    fetchZones() {
      this.loadingZones = true;
      this.zonesItems = [];
      zonesManager
        .fetchZones()
        .then((res) => {
          this.zones = res.items;
          this.loadingZones = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingZones = false;
        });
    },

    fetchKeywords() {
      this.loadingKeywords = true;
      keywordsManager
        .fetchKeywords()
        .then((res) => {
          this.keywords = res.items;
          this.loadingKeywords = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingKeywords = false;
        });
    },
  },
};
</script>
