<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-target</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("gstoutcome-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form ref="gstoutcome-editor-form">
              <div style="height:10px;" />
              <v-row v-if="processing">
              <v-col>
              <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
            </v-col>
            </v-row>

              <v-row v-if="!processing">
                <v-col>
                  <v-select
                    hide-details
                    :label="$t('gstoutcome-hint-outcome')"
                    outlined
                    dense
                    v-model="guest.outcome"
                    prepend-icon="mdi-target"
                    item-text="name"
                    item-value="id"
                    :items="effectiveOutcomes"
                  />
                </v-col>
              </v-row>

              <v-row v-if="!processing">
                <v-col>
                  <v-textarea
                    v-model="guest.outcome_notes"
                    :label="$t('firstcall-outcome-notes-hint')"
                    outlined
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    :disabled="processing"
                    width="120"
                    v-on:click="confirm()"
                    color="cyan"
                    >{{ $t("gbl-ok") }}</v-btn
                  >
                  <v-btn
                    :disabled="processing"
                    width="120"
                    v-on:click="cancel()"
                    color="gray"
                    >{{ $t("gbl-cancel") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      guest: {
        outcome: 1,
        notes: null,
      },
      processing : false,
      effectiveOutcomes: []
    };
  },

  props: ["value", "outcomes", "filteredOutcomes"],

  mounted() {},

  watch: {},

  methods: {
    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.processing = true;
        this.$emit("outcomeConfigured", {
          item: this.guest,
        });
      });
    },

    setModalGuest(guest) {
      this.processing = false;
      this.guest = Object.assign({}, guest);
      if (this.filteredOutcomes) {
        this.effectiveOutcomes = this.outcomes.filter(
          (v) => !this.filteredOutcomes.includes(v.id)
        );
      } else {
        this.effectiveOutcomes = this.outcomes;
      }
    },
  },
};
</script>
