
import VueCoreVideoPlayer from './vue-core-video-player.vue'
import BaseVideoCore from './core/base'
import EVENTS from './constants/EVENTS'

const VueCoreVideoPlayerPlugin = {
  install (Vue/*, options = {}*/) {
 
    Vue.component('vue-core-video-player', VueCoreVideoPlayer)
  }
}

export default VueCoreVideoPlayerPlugin
export {
  BaseVideoCore,
  EVENTS
}
