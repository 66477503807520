<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item-group :item="profile()" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.items = [];
    this.items.push({
      icon: "mdi-view-dashboard",
      title: this.$t("sidebar-dashboard"),
      to: "/"
    });

    if (this.$store.state.hasRight("58"))
      this.items.push({
        icon: "mdi-card-text-outline",
        title: this.$t("sidebar-guests"),
        to: "/guests"
      });

    if (this.$store.state.hasRight("1") || this.$store.state.hasRight("5")) {
      let item = {
        icon: "mdi-video",
        title: this.$t("sidebar-media"),
        children: []
      };

      if (this.$store.state.hasRight("5"))
        item.children.push({
          title: this.$t("sidebar-media-audio"),
          to: "/media/audio"
        });
      if (this.$store.state.hasRight("156")) {
        item.children.push({
          title: this.$t("sidebar-media-audio-streamer"),
          to: "/media/audio-streamers"
        });
      }

      if (this.$store.state.hasRight("1"))
        item.children.push({
          title: this.$t("sidebar-media-video"),
          to: "/media/video"
        });

      this.items.push(item);
    }

    if (this.$store.state.hasRight("133")) {
      let editorial_items = [];

      editorial_items.push({
        title: this.$t("sidebar-editorial"),
        to: "/editorial"
      });

      if (this.$store.state.hasRight("164"))
        editorial_items.push({
          title: this.$t("sidebar-editorial-contacts"),
          to: "/editorial-contacts"
        });

      if (this.$store.state.hasRight("164"))
        editorial_items.push({
          title: this.$t("sidebar-editorial-manage"),
          to: "/editorial-manage"
        });

      if (this.$store.state.hasRight("164"))
        editorial_items.push({
          title: this.$t("sidebar-editorial-lists"),
          to: "/editorial-lists"
        });

      if (this.$store.state.hasRight("164"))
        editorial_items.push({
          title: this.$t("sidebar-editorial-blacklist"),
          to: "/editorial-blacklists"
        });

      if (this.$store.state.hasRight("165"))
        editorial_items.push({
          title: this.$t("sidebar-editorial-imports"),
          to: "/editorial-imports"
        });

      if (
        this.$store.state.hasRight("164") ||
        this.$store.state.hasRight("187")
      ) {
        editorial_items.push({
          title: this.$t("sidebar-editorial-statistics"),
          to: "/editorial-statistics"
        });
      }

      if (this.$store.state.hasRight("164")) {
        editorial_items.push({
          title: this.$t("sidebar-editorial-outcomes-statistics"),
          to: "/editorial-outcomes-statistics"
        });
      }

      let item = {
        icon: "mdi-account-search",
        title: this.$t("sidebar-editorial"),
        children: editorial_items
      };

      this.items.push(item);
    } else {
      if (this.$store.state.hasRight("133"))
        this.items.push({
          icon: "mdi-account-search",
          title: this.$t("sidebar-editorial"),
          to: "/editorial-work"
        });
    }

    let scheduler_items = [];

    if (this.$store.state.hasRight("82"))
      scheduler_items.push({
        title: this.$t("sidebar-schedule-templates"),
        to: "/scheduler-templates"
      });

    if (this.$store.state.hasRight("82"))
      scheduler_items.push({
        title: this.$t("sidebar-schedule-calendar"),
        to: "/scheduler-calendar"
      });

    if (this.$store.state.hasRight("82"))
      scheduler_items.push({
        title: this.$t("sidebar-schedule-detail"),
        to: "/scheduler-detail"
      });

    if (this.$store.state.hasRight("82"))
      scheduler_items.push({
        title: this.$t("sidebar-schedule-speakers-shifts"),
        to: "/scheduler-speakers-shifts"
      });

    if (scheduler_items.length == 1) {
      this.items.push({
        icon: "mdi-calendar-clock",
        title: scheduler_items[0].title,
        to: scheduler_items[0].to
      });
    } else if (scheduler_items.length > 1) {
      this.items.push({
        icon: "mdi-calendar-clock",
        title: this.$t("sidebar-schedule"),
        children: scheduler_items
      });
    }

    let first_call_items = [];

    if (this.$store.state.hasRight("82"))
      first_call_items.push({
        title: this.$t("sidebar-firstcall-first"),
        to: "/firstcall"
      });

    if (this.$store.state.hasRight("91"))
      first_call_items.push({
        title: this.$t("sidebar-firstcall-listen"),
        to: "/firstcall-listen"
      });

    if (this.$store.state.hasRight("99"))
      first_call_items.push({
        title: this.$t("sidebar-firstcall-recovery"),
        to: "/firstcall-recovery"
      });

    if (this.$store.state.hasRight("122"))
      first_call_items.push({
        title: this.$t("sidebar-firstcall-lastattempt"),
        to: "/firstcall-lastattempt"
      });

    if (this.$store.state.hasRight("107")) {
      first_call_items.push({
        title: this.$t("sidebar-firstcall-manage"),
        to: "/firstcall-manage"
      });

      first_call_items.push({
        title: this.$t("sidebar-firstcall-overrides"),
        to: "/firstcall-overrides"
      });
    }

    if (this.$store.state.hasRight("113"))
      first_call_items.push({
        title: this.$t("sidebar-firstcall-statistics"),
        to: "/firstcall-statistics"
      });

    if (first_call_items.length == 1) {
      this.items.push({
        icon: "mdi-cellphone-sound",
        title: first_call_items[0].title,
        to: first_call_items[0].to
      });
    } else if (first_call_items.length > 1) {
      this.items.push({
        icon: "mdi-cellphone-sound",
        title: this.$t("sidebar-firstcall"),
        children: first_call_items
      });
    }

    let speakers_items = [];

    if (this.$store.state.hasRight("142")) {
      speakers_items.push({
        title: this.$t("sidebar-speakers-outcomes"),
        to: "/speakers"
      });

      if (this.$store.state.hasRight("143"))
        speakers_items.push({
          title: this.$t("sidebar-speakers-statistics"),
          to: "/speakers-statistics"
        });
    }

    if (speakers_items.length > 0)
      this.items.push({
        icon: "mdi-microphone-settings",
        title: this.$t("sidebar-speakers"),
        children: speakers_items
      });

    let video_editing_items = [];

    if (this.$store.state.hasRight("149")) {
      video_editing_items.push({
        title: this.$t("sidebar-videoediting-audio-episodes"),
        to: "/audioepisodes"
      });

      if (this.$store.state.hasRight("80")) {
        video_editing_items.push({
          title: this.$t("sidebar-videoediting-working"),
          to: "/videoediting"
        });
      }

      if (this.$store.state.hasRight("179")) {
        video_editing_items.push({
          title: this.$t("sidebar-videoediting-manage"),
          to: "/videoediting-manager"
        });
      }
    }

    if (video_editing_items.length > 0)
      this.items.push({
        icon: "mdi-video-account",
        title: this.$t("sidebar-videoediting"),
        children: video_editing_items
      });

    if (this.$store.state.hasRight("19")) {
      let item = {
        icon: "mdi-phone",
        title: this.$t("sidebar-recorder"),
        children: [
          {
            title: this.$t("sidebar-recorder-recorder"),
            to: "/recorder"
          }
        ]
      };

      item.children.push({
        title: this.$t("sidebar-recorder-voip"),
        to: "/recorder-voip"
      });

      if (this.$store.state.hasRight("47")) {
        item.children.push({
          title: this.$t("sidebar-recorder-groups"),
          to: "/recorderManager"
        });
      }

      this.items.push(item);
    }

    if (this.$store.state.hasRight("26")) {
      let item = {
        icon: "mdi-target",
        title: this.$t("sidebar-scraper"),
        children: [
          {
            title: this.$t("sidebar-scraper-manage"),
            to: "/scraper"
          }
        ]
      };
      if (this.$store.state.hasRight("57")) {
        item.children.push({
          title: this.$t("sidebar-scraper-cloud-instances"),
          to: "/scraper-cloud"
        });
      }

      this.items.push(item);
    }

    if (this.$store.state.hasRight("36"))
      this.items.push({
        icon: "mdi-file-document-outline",
        title: this.$t("sidebar-filemanager"),
        to: "/filemanager"
      });

    /* if (this.$store.state.hasRight("31"))
      this.items.push({
        icon: "mdi-account",
        title: this.$t("sidebar-helper"),
        to: "/helper",
      });*/

    if (this.$store.state.hasRight("49"))
      this.items.push({
        icon: "mdi-bell",
        title: this.$t("sidebar-notifications"),
        to: "/notifications"
      });

    if (this.$store.state.hasRight("17")) {
      let item = {
        icon: "mdi-account-group",
        title: this.$t("sidebar-permissions"),
        children: [
          {
            title: this.$t("sidebar-manage-users"),
            to: "/permissions-users"
          },
          {
            title: this.$t("sidebar-manage-groups"),
            to: "/permissions-groups"
          }
        ]
      };

      this.items.push(item);
    }

    if (this.$store.state.isSuperAdmin())
      this.items.push({
        icon: "mdi-application",
        title: this.$t("sidebar-applications"),
        children: [
          {
            title: this.$t("sidebar-manage-applications"),
            to: "/permissions-applications"
          }
        ]
      });

    if (this.$store.state.isSuperAdmin())
      this.items.push({
        icon: "mdi-format-list-bulleted",
        title: this.$t("sidebar-logs"),
        to: "/logs"
      });
  },

  data: () => ({
    items: []
  }),

  computed: {
    ...mapState(["barColor"]),

    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },

    computedItems() {
      return this.items.map(this.mapItem);
    }
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    }
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      };
    },

    profile() {
      return {
        avatar: this.isLoggedIn() ? this.hasAvatar() : null,
        gravatar: this.isLoggedIn() ? this.hasGravatar() : null,
        avatarimg: this.isLoggedIn() ? this.getGravatar() : null,
        avatarletters: this.isLoggedIn() ? this.getProfileLetters() : null,
        group: "",
        title: this.$t("sidebar-profile"),
        children: [
          {
            to: "/profile",
            title: this.$t("sidebar-profile-settings")
          }
        ]
      };
    },

    isLoggedIn: function() {
      return this.$store.state.user;
    },

    hasAvatar: function() {
      return this.$store.state.user.avatar ? true : false;
    },

    hasGravatar: function() {
      return this.$store.state.user.gravatar ? true : false;
    },

    getGravatar: function() {
      return "data:image/jpeg;base64," + this.$store.state.user.avatar;
    },

    getUsername: function() {
      return this.$store.state.user.first_name;
    },

    getProfileLetters: function() {
      let user = this.$store.state.user;
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.theme--dark.v-sheet
  background-color: #363636

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
