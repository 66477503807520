<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>>mdi-bank-transfer</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("paymenteditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-row class="text-center">
              <v-col>
                <div style="height:10px;" />
                <v-progress-circular
                  v-if="fetchingPayment"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="light-blue"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form v-if="!fetchingPayment" ref="gsteditor-editor-form">
              <v-tabs
                color="cyan"
                v-model="tab"
                background-color="white"
                centered
                icons-and-text
                class="ma-1"
              >
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

                <v-tab v-if="tipologia == 2" href="#tab-1">
                  Dati fatturazione
                  <v-icon>mdi-card-text-outline</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Dettagli pagamento
                  <v-icon>mdi-cart</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <ValidationObserver ref="obs_1" v-slot="{ invalid, validated }">
                  <v-tab-item v-if="tipologia == 2" eager value="tab-1">
                    <div style="height:10px" />
                    <v-row>
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            hide-details
                            :label="$t('paymenteditor-hint-type')"
                            outlined
                            dense
                            v-model="payment.tipo_utente"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="users_types"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row v-if="payment.tipo_utente != 1">
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.ragione_sociale"
                            :label="$t('paymenteditor-hint-fiscalname')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row v-if="payment.tipo_utente == 1">
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.nome"
                            :label="$t('paymenteditor-hint-name')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <v-text-field
                          outlined
                          dense
                          v-model="payment.cognome"
                          :label="$t('paymenteditor-hint-surname')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.indirizzo"
                            :label="$t('paymenteditor-hint-address')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col> </v-row
                    ><v-row>
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.cap"
                            :label="$t('paymenteditor-hint-postalcode')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider> </v-col
                      ><v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.comune"
                            :label="$t('paymenteditor-hint-city')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          hide-details
                          :label="$t('paymenteditor-hint-province')"
                          outlined
                          dense
                          v-model="payment.provincia"
                          item-text="name"
                          item-value="sigla"
                          :items="provinces"
                        /> </v-col
                      ><v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            hide-details
                            :label="$t('paymenteditor-hint-nation')"
                            outlined
                            dense
                            v-model="payment.nazione"
                            item-text="name"
                            item-value="name"
                            :items="countries"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="payment.tipo_utente == 1">
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.codice_fiscale"
                            :label="
                              $t('paymenteditor-hint-fiscalcode-mandatory')
                            "
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider> </v-col
                      ><v-col v-if="payment.tipo_utente != 1">
                        <v-text-field
                          outlined
                          dense
                          v-model="payment.codice_fiscale"
                          :label="$t('paymenteditor-hint-fiscalcode')"
                        ></v-text-field> </v-col
                      ><v-col v-if="payment.tipo_utente != 1">
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="payment.partita_iva"
                            :label="$t('paymenteditor-hint-vat')"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col v-if="payment.tipo_utente != 1">
                        <v-text-field
                          outlined
                          dense
                          v-model="payment.codice_univoco"
                          :label="$t('paymenteditor-hint-uniquecode')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          outlined
                          dense
                          v-model="payment.pec_destinatario"
                          :label="$t('paymenteditor-hint-pec')"
                        ></v-text-field> </v-col
                      ><v-col>
                        <v-text-field
                          outlined
                          dense
                          v-model="payment.email_fatturazione"
                          :label="$t('paymenteditor-hint-email')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </ValidationObserver>

                <ValidationObserver ref="obs_2" v-slot="{ invalid, validated }">
                  <v-tab-item eager value="tab-2">
                    <div style="height:10px" />
                    <v-row>
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            hide-details
                            :label="$t('paymenteditor-hint-method')"
                            outlined
                            dense
                            v-model="metodo_pagamento"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="payment_methods"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ValidationProvider
                          immediate
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            hide-details
                            :label="$t('paymenteditor-hint-version')"
                            outlined
                            dense
                            v-model="pacchetto"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="versions"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-checkbox v-if="payment"
                          v-model="payment.fattura_no"
                          :label="$t('paymenteditor-hint-no-invoice')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <div style="height:10px" />
                  </v-tab-item>
                </ValidationObserver>
              </v-tabs-items>

              <v-row>
                <v-col>
                  <v-btn
                    v-if="!eventEmitted"
                    width="120"
                    v-on:click="confirm()"
                    color="cyan"
                    >{{ $t("gbl-ok") }}</v-btn
                  >
                  <v-progress-circular
                    v-if="eventEmitted"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                    class="ml-2 mr-2"
                  ></v-progress-circular>
                  <v-btn
                    v-if="!eventEmitted"
                    width="120"
                    v-on:click="cancel()"
                    color="gray"
                    >{{ $t("gbl-cancel") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import guestsManager from "../../apis/guests";

export default {
  data() {
    return {
      payment: null,
      tab: "tab-1",
      fetchingPayment: false,
      eventEmitted: false,
      pacchetto: null,
      tipologia: null,
      metodo_pagamento: null,
      id: null,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: [
    "value",
    "users_types",
    "provinces",
    "countries",
    "payment_methods",
    "versions",
  ],

  mounted() {},

  watch: {
    tab() {
      this.applyModalValidation();
    },
  },

  methods: {
    confirm: async function() {
      try {
        let res = await this.$refs.obs_1.validate();
        if (!res) this.tab = "tab-1";
        else {
          res = await this.$refs.obs_2.validate();
          if (!res) this.tab = "tab-2";
          else this.handleSubmit();
        }
      } catch (err) {
        console.log(err);
      }
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      // Normalize contents

      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.$refs.obs_1.reset();
        this.$refs.obs_2.reset();
        this.eventEmitted = true;
        this.$emit("paymentConfigured", {
          item: this.payment,
          pacchetto: this.pacchetto,
          metodo_pagamento: this.metodo_pagamento,
          tipologia: this.tipologia,
          id: this.id,
        });
      });
    },

    resetModal() {
      this.pacchetto = null;
      this.metodo_pagamento = null;
      this.fetchingPayment = false;
      this.eventEmitted = false;
      this.tipologia = null;
      this.payment = null;
      this.id = null;
    },

    applyModalValidation() {
      requestAnimationFrame(() => {
        if (this.$refs.obs) {
          this.$refs.obs_1.validate();
          this.$refs.obs_2.validate();
        }
      });
    },

    fetchPayment(id) {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchPayments(id)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setModalPayment(id, pacchetto, metodo_pagamento, tipologia) {
      this.resetModal();
      this.pacchetto = pacchetto;
      this.tipologia = tipologia;
      this.metodo_pagamento = metodo_pagamento;
      this.id = id;
      this.fetchingPayment = true;
      this.eventEmitted = false;
      this.tab = "tab-1";
      if (tipologia == 2) {
        this.fetchPayment(id)
          .then((res) => {
            this.payment = res;
            this.fetchingPayment = false;
            this.applyModalValidation();
          })
          .catch((err) => {
            console.log(err);
            this.closeDialog();
          });
      } else {
        this.fetchingPayment = false;
        this.applyModalValidation();
      }
    },
  },
};
</script>
