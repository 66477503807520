import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let firstCall = {
  groups: [
    { value: null, text: "Tutti" },
    { value: 0, text: "Prima chiamata" },
    { value: 1, text: "Recupero" },
    { value: 2, text: "Ascolta" },
    { value: 3, text: "Ultimo tentativo" },
  ],

  flt_groups: [
    { value: null, text: "Tutti" },
    { value: 0, text: "Prima chiamata" },
    { value: 1, text: "Recupero" },
  ],

  fetchUsers: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/users",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.users,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFirstCallStatistics: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchFirstCallStatistics",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.items,
            totalCount: resp.data.responseData.count,
            stats: resp.data.responseData.stats,
            guestBarrier: resp.data.responseData.guestBarrier,
            callBarrier: resp.data.responseData.callBarrier,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFirstCallAssignments: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchFirstCallAssignments",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.items,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRecoveryAssignments: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchRecoveryAssignments",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.items,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchGuests: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchGuests",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
            statistics: resp.data.responseData.statistics,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchListenGuests: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchListenGuests",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchLastAttemptGuests: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchLastAttemptGuests",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchOutcomes: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchOutcomes",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            outcomes: resp.data.responseData.outcomes,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDataFencing: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchDataFencing",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.data_fencing);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setDataFencing: (timestamp) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/setDataFencing",
        data: { timestamp: timestamp },
        method: "POST",
      })
        .then((resp) => {
          resolve({ timestamp: resp.data.responseData.data_fencing });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteOutcome: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/deleteOutcome",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  detachAssignment: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/detachAssignment",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reassignAssignment: (assignment_id, operator_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/reassignAssignment",
        data: { assignment_id: assignment_id, operator_id: operator_id },
        method: "POST",
      })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reassignOutcome: (outcomeId, userId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/reassignOutcome",
        data: { outcomeId: outcomeId, userId: userId },
        method: "POST",
      })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeOutcome: (id, outcome) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/changeOutcome",
        data: { id: id, outcome: outcome },
        method: "POST",
      })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchGuestOutcomes: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchGuestOutcomes",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            outcomes: resp.data.responseData.outcomes,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWorkingState: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchWorkingState",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRecoveryWorkingState: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/fetchRecoveryWorkingState",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFirstCallCancelToken: new axios.CancelToken.source(),

  fetchFirstCall: (guest_id) => {
    firstCall.fetchFirstCallCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/firstCall/fetchFirstCall",
          data: { id: guest_id },
          method: "POST",
        },
        { cancelToken: firstCall.fetchFirstCallCancelToken }
      )
        .then((resp) => {
          resolve({
            guests: resp.data.responseData.guests,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchYesterdayCancelToken: new axios.CancelToken.source(),

  fetchFirstCallYesterday: (guest_id) => {
    firstCall.fetchYesterdayCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/firstCall/fetchFirstCallYesterday",
          data: { id: guest_id },
          method: "POST",
        },
        { cancelToken: firstCall.fetchYesterdayCancelToken }
      )
        .then((resp) => {
          resolve({
            yesterdayGuests: resp.data.responseData.yesterdayGuests,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchPendingCancelToken: new axios.CancelToken.source(),

  fetchFirstCallPending: (guest_id) => {
    firstCall.fetchPendingCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/firstCall/fetchFirstCallPending",
          data: { id: guest_id },
          method: "POST",
        },
        { cancelToken: firstCall.fetchPendingCancelToken }
      )
        .then((resp) => {
          resolve({
            pendingGuests: resp.data.responseData.pendingGuests,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchRecoveryCancelToken: new axios.CancelToken.source(),

  fetchRecovery: (guest_id) => {
    firstCall.fetchRecoveryCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/firstCall/fetchRecovery",
          data: { id: guest_id },
          method: "POST",
        },
        { cancelToken: firstCall.fetchRecoveryCancelToken }
      )
        .then((resp) => {
          resolve({
            guests: resp.data.responseData.guests,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchPendingRecoveryCancelToken: new axios.CancelToken.source(),

  fetchPendingRecovery: (guest_id) => {
    firstCall.fetchPendingRecoveryCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/firstCall/fetchPendingRecovery",
          data: { id: guest_id },
          method: "POST",
        },
        { cancelToken: firstCall.fetchPendingRecoveryCancelToken }
      )
        .then((resp) => {
          resolve({
            pendingGuests: resp.data.responseData.pendingGuests,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  startWorkingSession: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/startWorkingSession",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  stopWorkingSession: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/stopWorkingSession",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  startRecoveryWorkingSession: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/startRecoveryWorkingSession",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  stopRecoveryWorkingSession: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/stopRecoveryWorkingSession",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            workingState: resp.data.responseData.workingState,
            statistics: resp.data.responseData.statistics,
            guestsDuration: resp.data.responseData.guestsDuration,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  outcomeGuest: (guest, group) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/outcomeGuest",
        data: { guest: guest.item, group: group },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetPayment: (guest) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/paymentReset",
        data: { guest: guest },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addFirstCallOverride: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/addFirstCallOverride",
        data: { item: item },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addRecoveryOverride: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/addRecoveryOverride",
        data: { item: item },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeFirstCallOverride: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/removeFirstCallOverride",
        data: { item: item },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeRecoveryOverride: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/removeRecoveryOverride",
        data: { item: item },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeExpiredOverrides: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/removeExpiredOverrides",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setListenCallWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/setListenCallWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeListenCallWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/removeListenCallWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  archiveListenGuest: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/archiveListenGuest",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  unarchiveListenGuest: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/unarchiveListenGuest",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setLastAttemptCallWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/setLastAttemptCallWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeLastAttemptCallWorking: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/removeLastAttemptCallWorking",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  archiveLastAttemptGuest: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/archiveLastAttemptGuest",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  unarchiveLastAttemptGuest: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/firstCall/unarchiveLastAttemptGuest",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadExcelStatistics: (locale, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/firstCall/downloadExcelStatistics?locale=" +
          encodeURIComponent(locale) +
          "&token=" +
          encodeURIComponent(token) +
          "&filters=" +
          encodeURIComponent(JSON.stringify(filters))
      );
      link.setAttribute("target", "_blank");
      let filename = "fc_excel_statistics_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
};

export default firstCall;
