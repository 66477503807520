var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"800","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-target")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("gstoutcome-title")))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('v-form',{ref:"gstoutcome-editor-form"},[_c('div',{staticStyle:{"height":"10px"}}),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('div',{staticStyle:{"height":"10px"}}),(_vm.fetchingMetadata)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}}):_vm._e()],1)],1),(!_vm.fetchingMetadata)?_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('gstoutcome-hint-outcome'),"outlined":"","dense":"","prepend-icon":"mdi-target","error-messages":errors,"success":valid,"item-text":"name","item-value":"id","items":_vm.computed_outcomes},model:{value:(_vm.guest.esito),callback:function ($$v) {_vm.$set(_vm.guest, "esito", $$v)},expression:"guest.esito"}})]}}],null,true)})],1)],1),(_vm.guest.esito == 2 || _vm.guest.esito == 8)?_c('v-row',[(_vm.$store.state.hasRight('120'))?_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-microphone","clearable":"","dense":"","items":_vm.speakers,"loading":_vm.isLoadingSpeakers,"search-input":_vm.searchSpeakerInput,"cache-items":"","outlined":"","item-text":"name","item-value":"id","label":_vm.$t('gsteditor-details-operators-speaker'),"return-object":"","hide-no-data":"","error-messages":errors,"success":valid},on:{"update:searchInput":function($event){_vm.searchSpeakerInput=$event},"update:search-input":function($event){_vm.searchSpeakerInput=$event}},model:{value:(_vm.guest_speaker),callback:function ($$v) {_vm.guest_speaker=$$v},expression:"guest_speaker"}})]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.guest.esito == 5)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"label":_vm.$t('gsteditor-hint-refuse-reason'),"outlined":"","rows":"4","error-messages":errors,"success":valid},model:{value:(_vm.guest.notes.motivo_rifiuto),callback:function ($$v) {_vm.$set(_vm.guest.notes, "motivo_rifiuto", $$v)},expression:"guest.notes.motivo_rifiuto"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"width":"120","disabled":invalid || !validated,"color":"cyan"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]),_c('v-btn',{attrs:{"width":"120","color":"gray"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])],1)],1)]}}],null,false,797382775)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }