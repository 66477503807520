<template>
  <v-card class="ma-1">
    <v-toolbar color="#0044a3" dark>
      <v-app-bar-nav-icon><v-icon large>mdi-video</v-icon> </v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">{{
        $t("mediavideo-title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-form v-on:submit.prevent="formSearch()">
        <v-row>
          <v-col>
            <v-progress-circular
              v-if="fetchingFilters"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-if="!fetchingFilters" dense class="text-center">
          <v-col>
            <v-select
              dense
              v-model="filters.purchased"
              outlined
              item-text="state_text"
              item-value="state_value"
              :items="states"
              :label="$t('mediavideo-filter-purchased')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>

          <v-col
            v-if="
              !$store.state.hasRight('144') ||
                $store.state.isAdmin() ||
                $store.state.hasRight('148')
            "
          >
            <v-select
              dense
              v-model="filters.speakers"
              outlined
              item-text="speaker_name"
              item-value="speaker_id"
              :items="speakers"
              :label="$t('mediavideo-filter-speaker')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>

          <v-col >
            <v-select
              dense
              v-model="filters.region"
              outlined
              item-text="reg_name"
              item-value="id"
              :items="regions"
              :label="$t('mediavideo-filter-region')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>

          <v-col>
            <v-select
              dense
              v-model="filters.category"
              outlined
              item-text="cat_name"
              item-value="id"
              :items="categories"
              :label="$t('mediavideo-filter-category')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row dense v-if="!fetchingFilters" justify="center">
          <v-col v-if="(!$store.state.user.locked_radio || $store.state.user.locked_locale.locked_radio < 1)">
            <v-select
              dense
              outlined
              v-model="filters.radio"
              item-text="name"
              item-value="id"
              :items="flt_radios"
              :label="$t('mediaaudio-filter-radio')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>
          <v-col v-if="(!$store.state.user.locked_locale || $store.state.user.locked_locale.length < 1)">
            <v-select
              dense
              v-model="filters.locale"
              outlined
              item-text="name"
              item-value="code"
              :items="flt_locales"
              :label="$t('mediaaudio-filter-locale')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-text-field
              dense
              outlined
              :label="$t('mediavideo-filter-search')"
              :disabled="fetchingData"
              :value="filter"
              @change="(v) => (filter = v)"
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              dense
              v-model="filters.dateTypes"
              outlined
              item-text="text"
              item-value="value"
              :items="dateTypes"
              :label="$t('mediavideo-filter-datetype')"
              :disabled="fetchingData"
              hide-details
            />
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('mediavideo-filter-startdate')"
                  :disabled="fetchingData"
                  readonly
                  v-on="on"
                  clearable
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('mediavideo-filter-enddate')"
                  :disabled="fetchingData"
                  readonly
                  clearable
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="fetchingData && !loadingMore"
        ><v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <SearchResults
        v-if="searchResults && !fetchingFilters"
        :videos="searchResults"
        :search="true"
        :loadingMore="loadingMore"
        :dateTypes="filters.dateTypes"
        @loadMore="loadMore"
    /></v-card-text>
  </v-card>
</template>
<script>
import SearchResults from "../components/video/VideoSearchResults";
import videoManager from "../apis/video.js";
import config from "../config";

export default {
  data() {
    return {
      date_start_modal: false,
      date_end_modal: false,
      flt_radios: [],
      flt_locales: [],
      filter: null,
      filters: {
        region: null,
        category: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        dateTypes: 2,
        speakers: null,
        purchased: null,
        radio: null,
        locale: null,
      },

      perPage: 12,
      currentPage: 1,

      fetchingFilters: false,
      fetchingData: false,
      categories: [],
      regions: [],
      speakers: [],
      states: [
        { state_text: this.$t("gbl-all-male"), state_value: null },
        {
          state_text: this.$t("mediavideo-filter-purchase-state-purchased"),
          state_value: true,
        },
        {
          state_text: this.$t("mediavideo-filter-purchase-state-notpurchased"),
          state_value: false,
        },
      ],

      dateTypes: [
        {
          text: this.$t("mediavideo-filter-purchase-datetype-interview"),
          value: 1,
        },
        {
          text: this.$t("mediavideo-filter-purchase-datetype-loading"),
          value: 2,
        },
      ],

      searchResults: null,
      loadingMore: false,
    };
  },

  name: "Home",

  components: {
    SearchResults,
  },

  mounted() {
    this.fetchingFilters = true;

    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];
    this.flt_locales = [
      { code: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.tr_locales,
    ];

    this.fetchCategories()
      .then(() => {
        this.fetchRegions()
          .then(() => {
            this.fetchSpeakers()
              .then(() => {
                this.fetchingFilters = false;
                this.submitSearch(false);
              })
              .catch((err) => {
                console.log(err);
                this.fetchingFilters = false;
              });
          })
          .catch((err) => {
            console.log(err);
            this.fetchingFilters = false;
          });
      })
      .catch((err) => {
        console.log(err);
        this.fetchingFilters = false;
      });
  },

  watch: {
    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.currentPage = 1;
          this.submitSearch();
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.submitSearch();
      },
    },
  },

  computed: {
    appBarHeight: function() {
      return this.$vuetify.breakpoint.mobile ? 300 : 522;
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  methods: {
    resetStartDate: function() {
      this.filters.filterByCustomDateStart = null;
    },

    resetEndDate: function() {
      this.filters.filterByCustomDateEnd = null;
    },

    resetSearch: function() {
      this.filter = null;
      this.searchResults = null;
      this.loadingMore = false;
    },

    formSearch: function() {
      this.currentPage = 1;
      this.loadingMore = false;
      this.submitSearch(false);
    },

    submitSearch: function(concat) {
      const opts = {
        filter: this.filter,
        filters: this.filters,
        perPage: this.perPage,
        currentPage: this.currentPage,
      };
      if (!concat) this.searchResults = null;

      this.fetchingData = true;
      videoManager
        .search(opts)
        .then((res) => {
          this.fetchingData = false;
          if (!res.items.length) {
            if (concat) {
              this.loadingMore = false;
            } else {
              this.searchResults = res;
            }
          } else {
            if (!concat) this.searchResults = res;
            else {
              this.searchResults.items = this.searchResults.items.concat(
                res.items
              );
            }
            this.loadingMore = false;
          }
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });
    },

    loadMore: function() {
      this.currentPage = this.currentPage + 1;
      this.loadingMore = true;

      this.submitSearch(true);
    },

    fetchCategories: function() {
      return new Promise((resolve, reject) => {
        this.fetchingCategories = true;
        videoManager
          .fetchCategories()
          .then((res) => {
            this.categories = [
              { cat_name: this.$t("gbl-all-female"), id: null },
              ...res,
            ];
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions: function() {
      return new Promise((resolve, reject) => {
        videoManager
          .fetchRegions()
          .then((res) => {
            this.regions = [
              { reg_name: this.$t("gbl-all-female"), id: null },
              ...res,
            ];
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchSpeakers: function() {
      return new Promise((resolve, reject) => {
        videoManager
          .fetchSpeakers()
          .then((res) => {
            let inverted_speakers = res.map((x) => {
              let names = x.speaker_name.split(" ");
              if (names.length >= 2) {
                let name = names[0];
                names.shift();
                names.push(name);
              }
              names = names.map((x) => {
                return x.trim();
              });
              names = names.filter((x) => {
                return x.length > 0 ? true : false;
              });

              x.speaker_name = names.join(" ");
              return x;
            });

            inverted_speakers = inverted_speakers.sort((x, y) => {
              return x.speaker_name.localeCompare(y.speaker_name);
            });

            this.speakers = [
              { speaker_name: this.$t("gbl-all-male"), speaker_id: null },
              ...inverted_speakers,
            ];
            resolve(this.speakers);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>
