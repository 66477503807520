import i18n from '../plugins/i18n'

let staticData = {

    periods : [
      {name : 'gbl-periods-lastyear', id : 0},
      {name : 'gbl-periods-since1year', id : 1},
      {name : 'gbl-periods-thisyear', id : 2},
      {name : 'gbl-periods-last6months', id : 3},
      {name : 'gbl-periods-last90days', id : 4},
      {name : 'gbl-periods-last60days' , id : 5},
      {name : 'gbl-periods-last30days' , id : 6},
      {name : 'gbl-periods-lastmonth', id : 7},
      {name : 'gbl-periods-thismonth', id: 8},
      {name : 'gbl-periods-today' , id : 11},
      {name : 'gbl-periods-yesterday' , id : 12},
      {name : 'gbl-periods-date' , id : 9},
      {name : 'gbl-periods-all' , id : 10},
    ],

    dataTableFooterOptions : {
      showFirstLastPage: true,
      'items-per-page-options': [5 , 10 , 20, 50, 100, 500, 1000, 5000],
    },

    defaultPerPage : 50,

    filtersPeriods () {
      let p = staticData.periods.map((x)=> {
        return { name : i18n.t(x.name) , id :x.id}
      });
      return p;
    }

  }

export default staticData;

