import axios from "axios";
import config from "../config";

let podcasts = new Object({
  
  setStreamState: (id, state) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/setExcludeStream",
        data: { id: id, state: state },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rescheduleStream: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/rescheduleStream",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteStreamScheduling: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/deleteStreamScheduling",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteMedia: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/deleteMedia",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAudioStreams: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/fetchAudioStreams",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.podcasts,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  search: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/search",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.podcasts,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  generateEpisode: (id, progressCallback) => {
    return new Promise((resolve, reject) => {
      progressCallback(null);
      axios({
        url: config.apiEndPoint + "/audio/generateEpisode",
        data: { id: id },
        method: "POST",
        onDownloadProgress: (progressEvent) => {
          progressCallback(progressEvent.currentTarget.response);
        },
      })
        .then((resp) => {
          resolve(resp.data.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPodcastsForEpisodes: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/fetchPodcastsForEpisodes",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.podcasts,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCategories: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/categories",
        data: content,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.categories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSpeakers: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/speakers",
        data: content,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRegions: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/regions",
        data: content,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.regions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchEpisodes: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/fetchEpisodes",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.episodes,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addEpisode: (episode) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/addEpisode",
        data: { episode: episode },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteEpisode: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/audio/deleteEpisode",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default podcasts;
