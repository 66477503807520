<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    max-height="400"
    :value="openDialog"
  >
    <setString ref="setString" />
    <msgBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-card class="pa-0 ">
            <v-toolbar color="primary" dark>
              <v-app-bar-nav-icon
                ><v-icon large>mdi-database-import</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title class="white--text">{{
                $t("scraper-import-prefs-title")
              }}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text v-if="fetchingNations">
              <v-row class="text-center" align="start" justify="center">
                <v-col>
                  <v-progress-circular
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="!fetchingNations">
              <v-row dense class="text-center" align="start" justify="center">
                <v-col>
                  <v-select
                    dense
                    v-model="nation_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="nations"
                    :label="$t('scraper-import-prefs-nation')"
                    :disabled="fetchingNations"
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row dense class="text-center" align="start" justify="center">
                <v-col>
                  <v-select
                    dense
                    v-model="region_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="regions"
                    :label="$t('scraper-import-prefs-region')"
                    :disabled="fetchingRegions"
                    hide-details
                  />
                </v-col>

                <v-col>
                  <v-checkbox
                    dense
                    hide-details
                    v-model="region_use_prefix"
                    :label="$t('scraper-import-prefs-region-fixed')"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="!fetchingProvinces"
                class="text-center"
                align="start"
                justify="center"
              >
                <v-col>
                  <v-select
                    dense
                    v-model="province_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="provinces"
                    :label="$t('scraper-import-prefs-province')"
                    :disabled="fetchingProvinces"
                    hide-details
                  />
                </v-col>

                <v-col>
                  <v-checkbox
                    dense
                    hide-details
                    v-model="provinces_use_prefix"
                    :label="$t('scraper-import-prefs-province-fixed')"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="!fetchingTowns"
                class="text-center"
                align="start"
                justify="center"
              >
                <v-col>
                  <v-select
                    dense
                    v-model="town_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="towns"
                    :label="$t('scraper-import-prefs-city')"
                    :disabled="fetchingTowns"
                    hide-details
                  />
                </v-col>

                <v-col>
                  <v-checkbox
                    dense
                    hide-details
                    v-model="towns_use_prefix"
                    :label="$t('scraper-import-prefs-city-fixed')"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="!fetchingTowns"
                class="text-center"
                align="start"
                justify="center"
              >
                <v-col>
                  <v-select
                    dense
                    v-model="category_id"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="categories"
                    :label="$t('scraper-import-prefs-category')"
                    :disabled="fetchingCategories"
                    hide-details
                  />
                </v-col>

                <v-col
                  ><v-btn color="primary" @click.stop="addCategory()">{{
                    $t("scraper-import-add-category")
                  }}</v-btn></v-col
                >
              </v-row>

              <v-row
                dense
                v-if="!fetchingTowns"
                class="text-center"
                align="start"
                justify="center"
              >
                <v-col>
                  <v-btn color="green" v-on:click="confirm()">{{
                    $t("scraper-import-start")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import tasksManager from "../../apis/scraper/tasks.js";
import editorialManager from "../../apis/editorial.js";
import setString from "../../components/SetString";
import msgBox from "../../components/MessageBox";

export default {
  data() {
    return {
      dialogResolve: null,
      dialogReject: null,
      openDialog: false,
      fetchingNations: false,
      fetchingRegions: false,
      fetchingTowns: false,
      fetchingProvinces: false,
      fetchingCategories: false,

      nation_id: 86,
      region_id: null,
      town_id: null,
      province_id: null,
      category_id: null,

      region_use_prefix: true,
      towns_use_prefix: true,
      provinces_use_prefix: true,

      nations: [],
      regions: [],
      towns: [],
      provinces: [],
      categories: [],
    };
  },

  components: {
    setString,
    msgBox,
  },

  watch: {
    nation_id: {
      handler() {
        this.region_id = null;
        this.town_id = null;
        this.province_id = null;
        this.category_id = null;
        this.fetchRegions(this.nation_id);
        this.provinces = [{ id: null, name: this.$t("gbl-none-female") }];
        this.towns = [{ id: null, name: this.$t("gbl-none-female") }];
        this.fetchCategories(this.nation_id);
      },
    },

    region_id: {
      handler() {
        this.province_id = null;
        if (this.region_id) this.fetchProvinces(this.nation_id, this.region_id);
      },
    },

    province_id: {
      handler() {
        this.town_id = null;
        if (this.region_id && this.province_id)
          this.fetchTowns(this.nation_id, this.region_id, this.province_id);
      },
    },
  },

  methods: {
    fetchCategories(nation_id) {
      this.fetchingCategories = true;
      tasksManager
        .fetchCategories(nation_id)
        .then((categories) => {
          this.fetchingCategories = false;

          this.categories = [{id : null, name : this.$t('gbl-none-female')},...categories];
        })
        .catch((err) => {
          this.fetchingCategories = false;
          console.log(err);
        });
    },

    fetchNations() {
      this.fetchingNations = true;
      this.region_id = null;
      this.province_id = null;
      this.town_id = null;

      editorialManager
        .fetchNations()
        .then((nations) => {
          this.fetchingNations = false;
          this.nations = nations.map((x) => {
            x.name = this.$t(x.name);
            return x;
          });
        })
        .catch((err) => {
          this.fetchingNations = false;
          console.log(err);
        });
    },

    fetchRegions(nation_id) {
      this.fetchingRegions = true;
      this.regions = [{ id: null, name: this.$t("gbl-none-female") }];
      this.region_id = null;
      this.province_id = null;
      this.town_id = null;

      editorialManager
        .fetchRegions(nation_id)
        .then((regions) => {
          this.fetchingRegions = false;
          this.regions = [
            { id: null, name: this.$t("gbl-none-female") },
            ...regions,
          ];
        })
        .catch((err) => {
          this.fetchingRegions = false;
          console.log(err);
        });
    },

    fetchProvinces(nation_id, region_id) {
      this.fetchingProvinces = true;
      this.provinces = [{ id: null, name: this.$t("gbl-none-female") }];
      this.town_id = null;
      editorialManager
        .fetchProvinces(nation_id, region_id)
        .then((provinces) => {
          this.fetchingProvinces = false;
          this.provinces = [
            { id: null, name: this.$t("gbl-none-female") },
            ...provinces,
          ];
        })
        .catch((err) => {
          this.fetchingProvinces = false;
          console.log(err);
        });
    },

    fetchTowns(nation_id, region_id, province_id) {
      this.fetchingTowns = true;
      this.towns = [{ id: null, name: this.$t("gbl-none-female") }];
      editorialManager
        .fetchTowns(nation_id, province_id)
        .then((towns) => {
          this.fetchingTowns = false;
          this.towns = [
            { id: null, name: this.$t("gbl-none-female") },
            ...towns,
          ];
        })
        .catch((err) => {
          this.fetchingTowns = false;
          console.log(err);
        });
    },

    addCategory() {
      this.$refs.setString
        .show(
          this.$t("scraper-import-add-category"),
          "Nuova categoria",
          "Nome categoria"
        )
        .then((res) => {
          tasksManager.addCategory(res.value, this.nation_id).then((id) => {
            if (id) {
              this.category_id = id;
              this.fetchCategories(this.nation_id);
            } else {
              this.$refs.msgBox
                .show(
                  this.$t("scraper-import-add-category-exists"),
                  this.$t("scraper-import-add-category"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            }
          });
        })
        .catch(() => {});
    },

    show() {
      return new Promise((resolve, reject) => {
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.fetchNations();
        this.nation_id = 86;
        this.fetchRegions(this.nation_id);
        this.region_id = null;
        this.province_id = null;
        this.fetchCategories(this.nation_id);
        this.regions = [{ id: null, name: this.$t("gbl-none-female") }];
        this.provinces = [{ id: null, name: this.$t("gbl-none-female") }];
        this.towns = [{ id: null, name: this.$t("gbl-none-female") }];
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve({
        nation_id: this.nation_id,
        region_id: this.region_id,
        town_id: this.town_id,
        province_id: this.province_id,
        category_id: this.category_id,
        region_use_prefix: this.region_use_prefix,
        towns_use_prefix: this.towns_use_prefix,
        provinces_use_prefix: this.provinces_use_prefix,
      });
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
