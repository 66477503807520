var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"800","max-height":"400","value":_vm.openDialog}},[_c('SetString',{ref:"setItemText"}),_c('MessageBox',{ref:"msgBox"}),_c('input',{ref:"file",attrs:{"type":"file","id":"fileUpload","hidden":""},on:{"change":function($event){return _vm.handleImport()}}}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('v-card',{staticClass:"pa-0 "},[_c('v-toolbar',{attrs:{"color":"purple","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-database-plus")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("scraper-task-addtask")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',{staticClass:"text-center",attrs:{"align":"start","justify":"center"}},[_c('v-col',{staticClass:"text-left"},[(_vm.loadingZones || _vm.loadingKeywords)?_c('v-row',{staticClass:"text-center",attrs:{"align":"start","justify":"center"}},[_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}})],1)],1):_vm._e(),(!_vm.loadingZones && !_vm.loadingKeywords)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"outlined":"","label":_vm.$t('scraper-addtask-keywords'),"item-text":"v","item-value":"id","items":_vm.keywords,"error-messages":errors,"success":valid,"required":"","dense":"","return-object":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"dense":"","outlined":"","label":_vm.$t('scraper-addtask-zones'),"item-text":"v","item-value":"id","items":_vm.zones,"error-messages":errors,"success":valid,"required":"","return-object":""},model:{value:(_vm.zone),callback:function ($$v) {_vm.zone=$$v},expression:"zone"}})]}}],null,true)})],1)],1):_vm._e()],1)],1),(!_vm.loadingZones && !_vm.loadingKeywords)?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"dense":"","outlined":"","label":_vm.$t('scraper-addtask-host'),"item-text":"n","item-value":"v","items":_vm.hosts,"required":""},model:{value:(_vm.hostname),callback:function ($$v) {_vm.hostname=$$v},expression:"hostname"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","outlined":"","label":_vm.$t('scraper-addtask-exactmatch'),"item-text":"value","item-value":"id","items":_vm.matches,"required":""},model:{value:(_vm.exact_match),callback:function ($$v) {_vm.exact_match=$$v},expression:"exact_match"}})],1)],1):_vm._e(),(!_vm.loadingZones && !_vm.loadingKeywords)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"outlined":"","label":_vm.$t('scraper-addtask-priority'),"items":_vm.priorities,"error-messages":errors,"success":valid,"required":"","item-text":"v","item-value":"n","dense":""},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})]}}],null,true)})],1)],1):_vm._e(),(!_vm.loadingZones && !_vm.loadingKeywords)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"outlined":"","label":_vm.$t('scraper-addtask-provider'),"items":_vm.providers,"error-messages":errors,"success":valid,"required":"","item-text":"v","item-value":"v","dense":"","immediate":""},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{staticClass:"text-center",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("scraper-addtask-numiteractions"))+" "+_vm._s(_vm.getIteractions)+" ")])],1),_c('v-row',{staticClass:"text-center",attrs:{"align":"center","justify":"center"}},[_c('v-col',[(_vm.addingTask)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}}):_vm._e(),(!_vm.addingTask)?_c('v-btn',{staticClass:"mr-2 white--text",attrs:{"disabled":_vm.loadingZones || _vm.loadingKeywords || !validated || invalid,"color":"purple"},on:{"click":function($event){return _vm.addTask()}}},[_c('v-icon',[_vm._v("mdi-database-plus")]),_vm._v(" "+_vm._s(_vm.$t("scraper-addtask-add")))],1):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }