var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"800","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(">mdi-bank-transfer")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("paymenteditor-title")))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('div',{staticStyle:{"height":"10px"}}),(_vm.fetchingPayment)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}}):_vm._e()],1)],1),(!_vm.fetchingPayment)?_c('v-form',{ref:"gsteditor-editor-form"},[_c('v-tabs',{staticClass:"ma-1",attrs:{"color":"cyan","background-color":"white","centered":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"teal lighten-3"}}),(_vm.tipologia == 2)?_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" Dati fatturazione "),_c('v-icon',[_vm._v("mdi-card-text-outline")])],1):_vm._e(),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" Dettagli pagamento "),_c('v-icon',[_vm._v("mdi-cart")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('ValidationObserver',{ref:"obs_1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [(_vm.tipologia == 2)?_c('v-tab-item',{attrs:{"eager":"","value":"tab-1"}},[_c('div',{staticStyle:{"height":"10px"}}),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('paymenteditor-hint-type'),"outlined":"","dense":"","error-messages":errors,"success":valid,"item-text":"name","item-value":"id","items":_vm.users_types},model:{value:(_vm.payment.tipo_utente),callback:function ($$v) {_vm.$set(_vm.payment, "tipo_utente", $$v)},expression:"payment.tipo_utente"}})]}}],null,true)})],1)],1),(_vm.payment.tipo_utente != 1)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-fiscalname'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.ragione_sociale),callback:function ($$v) {_vm.$set(_vm.payment, "ragione_sociale", $$v)},expression:"payment.ragione_sociale"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.payment.tipo_utente == 1)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-name'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.nome),callback:function ($$v) {_vm.$set(_vm.payment, "nome", $$v)},expression:"payment.nome"}})]}}],null,true)})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-surname')},model:{value:(_vm.payment.cognome),callback:function ($$v) {_vm.$set(_vm.payment, "cognome", $$v)},expression:"payment.cognome"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-address'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.indirizzo),callback:function ($$v) {_vm.$set(_vm.payment, "indirizzo", $$v)},expression:"payment.indirizzo"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-postalcode'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.cap),callback:function ($$v) {_vm.$set(_vm.payment, "cap", $$v)},expression:"payment.cap"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-city'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.comune),callback:function ($$v) {_vm.$set(_vm.payment, "comune", $$v)},expression:"payment.comune"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('paymenteditor-hint-province'),"outlined":"","dense":"","item-text":"name","item-value":"sigla","items":_vm.provinces},model:{value:(_vm.payment.provincia),callback:function ($$v) {_vm.$set(_vm.payment, "provincia", $$v)},expression:"payment.provincia"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('paymenteditor-hint-nation'),"outlined":"","dense":"","item-text":"name","item-value":"name","items":_vm.countries},model:{value:(_vm.payment.nazione),callback:function ($$v) {_vm.$set(_vm.payment, "nazione", $$v)},expression:"payment.nazione"}})]}}],null,true)})],1)],1),_c('v-row',[(_vm.payment.tipo_utente == 1)?_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-fiscalcode-mandatory'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.codice_fiscale),callback:function ($$v) {_vm.$set(_vm.payment, "codice_fiscale", $$v)},expression:"payment.codice_fiscale"}})]}}],null,true)})],1):_vm._e(),(_vm.payment.tipo_utente != 1)?_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-fiscalcode')},model:{value:(_vm.payment.codice_fiscale),callback:function ($$v) {_vm.$set(_vm.payment, "codice_fiscale", $$v)},expression:"payment.codice_fiscale"}})],1):_vm._e(),(_vm.payment.tipo_utente != 1)?_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-vat'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.payment.partita_iva),callback:function ($$v) {_vm.$set(_vm.payment, "partita_iva", $$v)},expression:"payment.partita_iva"}})]}}],null,true)})],1):_vm._e(),(_vm.payment.tipo_utente != 1)?_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-uniquecode')},model:{value:(_vm.payment.codice_univoco),callback:function ($$v) {_vm.$set(_vm.payment, "codice_univoco", $$v)},expression:"payment.codice_univoco"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-pec')},model:{value:(_vm.payment.pec_destinatario),callback:function ($$v) {_vm.$set(_vm.payment, "pec_destinatario", $$v)},expression:"payment.pec_destinatario"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('paymenteditor-hint-email')},model:{value:(_vm.payment.email_fatturazione),callback:function ($$v) {_vm.$set(_vm.payment, "email_fatturazione", $$v)},expression:"payment.email_fatturazione"}})],1)],1)],1):_vm._e()]}}],null,false,3895890188)}),_c('ValidationObserver',{ref:"obs_2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-tab-item',{attrs:{"eager":"","value":"tab-2"}},[_c('div',{staticStyle:{"height":"10px"}}),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('paymenteditor-hint-method'),"outlined":"","dense":"","error-messages":errors,"success":valid,"item-text":"name","item-value":"id","items":_vm.payment_methods},model:{value:(_vm.metodo_pagamento),callback:function ($$v) {_vm.metodo_pagamento=$$v},expression:"metodo_pagamento"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('paymenteditor-hint-version'),"outlined":"","dense":"","error-messages":errors,"success":valid,"item-text":"name","item-value":"id","items":_vm.versions},model:{value:(_vm.pacchetto),callback:function ($$v) {_vm.pacchetto=$$v},expression:"pacchetto"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.payment)?_c('v-checkbox',{attrs:{"label":_vm.$t('paymenteditor-hint-no-invoice')},model:{value:(_vm.payment.fattura_no),callback:function ($$v) {_vm.$set(_vm.payment, "fattura_no", $$v)},expression:"payment.fattura_no"}}):_vm._e()],1)],1),_c('div',{staticStyle:{"height":"10px"}})],1)]}}],null,false,3406036825)})],1),_c('v-row',[_c('v-col',[(!_vm.eventEmitted)?_c('v-btn',{attrs:{"width":"120","color":"cyan"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]):_vm._e(),(_vm.eventEmitted)?_c('v-progress-circular',{staticClass:"ml-2 mr-2",attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}}):_vm._e(),(!_vm.eventEmitted)?_c('v-btn',{attrs:{"width":"120","color":"gray"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))]):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }