import "./plugins";
import "./components/design";

import Vue from "vue";
import Vuetify from "vuetify";
import interceptors from "./services/interceptors";
import store from "./store";
import router from "./router";
import VueCoreVideoPlayer from "./plugins/vue-core-video-player/index";
import i18n from "./plugins/i18n";
import App from "./App.vue";

interceptors.setupInterceptors();

Vue.config.productionTip = false;

const vuetifyOptions = {
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: {
        primary: "#0d9fea",
        secondary: "#77aa22",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#ffffff",
      },
    },
  },
};

new Vue({
  i18n,
  store,
  router,
  vuetify: new Vuetify(vuetifyOptions),
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueCoreVideoPlayer);
