<template>
  <v-card class="ma-1">
    <v-toolbar color="gray" dark>
      <v-app-bar-nav-icon
        ><v-icon large>mdi-radio-tower</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">{{
        $t("mediaaudio-streamers-title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <audioFilters
        :filters="filters"
        :showDateType="true"
        :fetchingData="loading"
        @filtersReady="filtersReady"
        :streamDates="true"
        :streamStates="true"
        :waState="true"
        :mailState="true"
        :purchaseState="true"
      />

      <v-data-table
        v-if="!loadingFilters"
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="medias"
        :options.sync="options"
        :server-items-length="totalMedias"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="unique_id"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.interview_date="{ item }">
          {{ item.interview_date | toLocaleDate }}
        </template>

        <template v-slot:item.wa_date="{ item }">
          <span v-if="item.wa_date"
            >{{ item.wa_date | toLocaleDate }}

            <v-tooltip
              bottom
              v-if="
                (item.statuses && item.statuses.split(',').includes('sent')) ||
                  item.wa_date
              "
              ><template v-slot:activator="{ on }"
                ><v-icon small color="green" v-on="on"
                  >mdi-check</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-sent')}}</span></v-tooltip
            >

            <v-tooltip
              bottom
              v-if="
                item.statuses && item.statuses.split(',').includes('delivered')
              "
              ><template v-slot:activator="{ on }"
                ><v-icon small color="cyan" v-on="on"
                  >mdi-check</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-delivered')}}</span></v-tooltip
            >

            <v-tooltip
              bottom
              v-if="item.statuses && item.statuses.split(',').includes('read')"
              ><template v-slot:activator="{ on }"
                ><v-icon small color="cyan" v-on="on"
                  >mdi-check</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-read')}}</span></v-tooltip
            >

            <v-tooltip
              bottom
              v-if="
                item.statuses && item.statuses.split(',').includes('failed')
              "
              ><template v-slot:activator="{ on }"
                ><v-icon small color="red" v-on="on"
                  >mdi-alert-circle-outline</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-failed')}}</span></v-tooltip
            >

            <v-tooltip
              bottom
              v-if="
                item.statuses &&
                  item.statuses.split(',').includes('undelivered')
              "
              ><template v-slot:activator="{ on }"
                ><v-icon small color="red" v-on="on"
                  >mdi-alert-circle-outline</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-notdelivered')}}</span></v-tooltip
            >

            <v-tooltip bottom v-if="item.statuses"
              ><template v-slot:activator="{ on }"
                ><v-btn
                  small
                  text
                  icon
                  color="primary"
                  @click.stop="downloadWAReceipt(item)"
                  v-on="on"
                  ><v-icon>mdi-download</v-icon></v-btn
                ></template
              ><span>{{
                $t("gbl-download-wa-receipt")
              }}</span></v-tooltip
            >
          </span>
        </template>

        <template v-slot:item.mail_date="{ item }">
          <span v-if="item.mail_date"
            >{{ item.mail_date | toLocaleDate }}

            <v-tooltip
              bottom
              v-if="
                (item.mail_event && item.mail_event == 'Delivery') ||
                  item.mail_date
              "
              ><template v-slot:activator="{ on }"
                ><v-icon small color="green" v-on="on"
                  >mdi-check</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-sent')}}</span></v-tooltip
            >

            <v-tooltip
              bottom
              v-if="item.mail_event && item.mail_event == 'Bounce'"
              ><template v-slot:activator="{ on }"
                ><v-icon small color="red" v-on="on"
                  >mdi-alert-circle-outline</v-icon
                > </template
              ><span>{{$t('gbl-msg-state-bounce')}}</span></v-tooltip
            >

            <v-tooltip bottom v-if="item.mail_event"
              ><template v-slot:activator="{ on }"
                ><v-btn
                  small
                  text
                  icon
                  color="primary"
                  @click.stop="downloadMailReceipt(item)"
                  v-on="on"
                  ><v-icon>mdi-download</v-icon></v-btn
                ></template
              ><span>{{
                $t("gbl-download-mail-receipt")
              }}</span></v-tooltip
            >
          </span>
        </template>

        <template v-slot:item.planned_stream="{ item }">
          {{ item.planned_stream | toLocaleDateTime }}
        </template>

        <template v-slot:item.stream_time="{ item }">
          {{ item.stream_time | toLocaleDateTime }}
        </template>

         <template v-slot:item.guest_id="{ item }">
          OSP-{{ item.guest_id }}
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import usersManager from "../apis/users";
import audioManager from "../apis/audio";
import messengerManager from "../apis/messenger"
import audioFilters from "../components/audio/AudioFilters";
import staticData from "../apis/static";
import config from "../config";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      filters: {
        filter: null,
        region: null,
        category: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        dateTypes: 3,
        speakers: null,
        purchased: null,
        radio: null,
        locale: null,
        streamStates: null,
        waState: null,
        mailState: null,
      },

      medias: [],
      loading: false,
      loadingFilters: true,
      totalMedias: 0,
      options: {
        sortBy: ["stream_time"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("audiomedia-tbl-card-code"),
        align: "center",
        sortable: true,
        value: "guest_id",
      },
      {
        text: this.$t("audiomedia-tbl-name"),
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("audiomedia-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "interview_date",
      },
      {
        text: this.$t("audiomedia-tbl-wa-date"),
        align: "center",
        sortable: true,
        value: "wa_date",
      },
      {
        text: this.$t("audiomedia-tbl-mail-date"),
        align: "center",
        sortable: true,
        value: "mail_date",
      },
      {
        text: this.$t("audiomedia-tbl-planned-stream"),
        align: "center",
        sortable: true,
        value: "planned_stream",
      },
      {
        text: this.$t("audiomedia-tbl-stream-time"),
        align: "center",
        sortable: true,
        value: "stream_time",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  components: {
    audioFilters,
  },

  watch: {
    options: {
      handler() {
        this.fetchAudioStreams();
      },
      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.fetchAudioStreams();
      },
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.currentPage = 1;
          this.fetchAudioStreams();
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },
  },

  methods: {
    downloadWAReceipt(item) {
      usersManager
        .keepAlive()
        .then(() => {
          messengerManager
            .downloadWAReceipt(
              item.guest_id,
              this.$store.state.user.locale,
              2
            )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    downloadMailReceipt(item) {
      usersManager
        .keepAlive()
        .then(() => {
          messengerManager
            .downloadMailReceipt(
              item.guest_id,
              this.$store.state.user.locale,
              1
            )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    filtersReady: function() {
      this.loadingFilters = false;
      this.currentPage = 1;
    },

    fetchAudioStreams() {
      this.medias = [];
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        audioManager
          .fetchAudioStreams(ctx)
          .then((result) => {
            this.loading = false;
            this.medias = result.items;
            this.totalMedias = result.totalCount;
            resolve(result);
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
  },
};
</script>
