import config from "../config";
import locales from "../locales"

const helpers = {
  sanitizeName(inputName) {
    let name = inputName.replaceAll("'", "_");
    name = name.replaceAll("/", "_");
    name = name.replaceAll(": ", "_");
    name = name.replaceAll(":", "_");
    name = name.replaceAll(", ", "_");
    name = name.replaceAll(",", "_");
    name = name.replaceAll(". ", "_");
    name = name.replaceAll(".", "_");
    name = name.replaceAll("; ", "_");
    name = name.replaceAll(";", "_");
    name = name.replaceAll("' ", "_");
    name = name.replaceAll("'", "_");
    name = name.replaceAll("` ", "_");
    name = name.replaceAll("`", "_");
    name = name.replaceAll(' " ', "_");
    name = name.replaceAll('" ', "_");
    name = name.replaceAll(' "', "_");
    name = name.replaceAll('"', "_");
    name = name.replaceAll("’ ", "_");
    name = name.replaceAll("’", "_");
    name = name.replaceAll("‘ ", "_");
    name = name.replaceAll("‘", "_");
    name = name.replaceAll(" - ", "_");
    name = name.replaceAll("-", "_");
    name = name.replaceAll(" – ", "_");
    name = name.replaceAll("–", "_");
    name = name.replaceAll("@", "_");
    name = name.replaceAll("%", "_");
    name = name.replaceAll("#", "_");
    name = name.replaceAll("&", "_");
    name = name.replaceAll("(", "");
    name = name.replaceAll(")", "");
    name = name.replaceAll("?", "_");
    name = name.replaceAll("!", "_");
    name = name.replaceAll("¡", "_");
    name = name.replaceAll("¿", "_");

    name = name.replaceAll("´", "_");
    name = name.replaceAll("`", "_");
    name = name.replaceAll("®", "_");
    name = name.replaceAll("£", "_");
    name = name.replaceAll("£", "_");
    name = name.replaceAll("&", "e");
    name = name.replaceAll("<", "_");
    name = name.replaceAll(">", "_");
    name = name.replaceAll("©", "_");
    name = name.replaceAll("’", "_");
    name = name.replaceAll("‘", "_");
    name = name.replaceAll("”", "_");
    name = name.replaceAll("“", "_");
    name = name.replaceAll("—", "_");
    name = name.replaceAll("»", "_");
    name = name.replaceAll("«", "_");
    name = name.replaceAll("^", "_");
    name = name.replaceAll("°", "_");

    name = name.replaceAll(" ", "_");

    name = name.replaceAll("à", "a");
    name = name.replaceAll("á", "a");
    name = name.replaceAll("Á", "a");
    name = name.replaceAll("À", "a");
    name = name.replaceAll("â", "a");
    name = name.replaceAll("Â", "a");
    name = name.replaceAll("å", "a");
    name = name.replaceAll("Å", "a");
    name = name.replaceAll("ã", "a");
    name = name.replaceAll("Ã", "a");
    name = name.replaceAll("ä", "a");
    name = name.replaceAll("Ä", "a");
    name = name.replaceAll("æ", "a");
    name = name.replaceAll("Æ", "a");

    name = name.replaceAll("ç", "c");
    name = name.replaceAll("Ç", "c");

    name = name.replaceAll("è", "e");
    name = name.replaceAll("é", "e");
    name = name.replaceAll("É", "e");
    name = name.replaceAll("È", "e");
    name = name.replaceAll("ê", "e");
    name = name.replaceAll("Ê", "e");
    name = name.replaceAll("ë", "e");
    name = name.replaceAll("Ë", "e");

    name = name.replaceAll("ì", "i");
    name = name.replaceAll("í", "i");
    name = name.replaceAll("Í", "i");
    name = name.replaceAll("Ì", "i");
    name = name.replaceAll("î", "i");
    name = name.replaceAll("Î", "i");
    name = name.replaceAll("Ï", "i");
    name = name.replaceAll("ï", "i");

    name = name.replaceAll("ñ", "n");
    name = name.replaceAll("Ñ", "n");

    name = name.replaceAll("ò", "o");
    name = name.replaceAll("ó", "o");
    name = name.replaceAll("Ó", "o");
    name = name.replaceAll("Ò", "o");
    name = name.replaceAll("ô", "o");
    name = name.replaceAll("Ô", "o");
    name = name.replaceAll("õ", "o");
    name = name.replaceAll("Õ", "o");
    name = name.replaceAll("ö", "o");
    name = name.replaceAll("Ö", "o");

    name = name.replaceAll("ù", "u");
    name = name.replaceAll("ú", "u");
    name = name.replaceAll("Ú", "u");
    name = name.replaceAll("Ù", "u");
    name = name.replaceAll("û", "u");
    name = name.replaceAll("Û", "u");
    name = name.replaceAll("ü", "u");
    name = name.replaceAll("Ü", "u");

    name = name.replaceAll("ß", "u");
    name = name.replaceAll("ÿ", "u");
    name = name.replaceAll("ć", "c");
    

    // eslint-disable-next-line
    //let url = name.replace(/[^\x00-\x7F]+/g, "").toLowerCase();
    //url = url.replace(/[^a-z0-9_]+/gi, "");
    return name.toLowerCase();
  },

  buildPaymentLinkURL(item) {
    let links = locales.strings.payment_link_address;
    let locale = item.locale.toLowerCase();
    let link_prefix = links['en'];
    if (links[locale]) link_prefix = links[locale];

    let link =
      (item.url_radio ? item.url_radio : config.paymentLinkURL) +
      config.paymentLinkFormat
        .replace("{tr-guest-form-code}", link_prefix)
        .replace("{1}", item.ID)
        .replace("{2}", this.sanitizeName(item.nome_intervistato_1));
    return link;
  },

  buildPaymentShortLinkURL(item) {
    let links = locales.strings.payment_link_address;
    let locale = item.locale.toLowerCase();
    let link_prefix = links['en'];
    if (links[locale]) link_prefix = links[locale];

    let link = config.paymentLinkFormat
      .replace("/", "")
      .replace("{tr-guest-form-code}", link_prefix)
      .replace("{1}", item.ID)
      .replace("{2}", this.sanitizeName(item.nome_intervistato_1));
    return link;
  },
};

export default helpers;
