<template>
  <div>
    <messageBox ref="msgBox" />

    <v-card class="ma-4">
      <v-toolbar color="#0044a3">
        <v-icon color="white" large class="mr-2">mdi-chart-areaspline</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("editorial-stats-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <div style="margin:15px">
        <v-row dense justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-operator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-select
              :label="$t('editorial-filter-formation')"
              outlined
              dense
              v-model="filters.formation"
              item-text="name"
              item-value="id"
              :items="flt_formation"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$t('editorial-filter-source-radio')"
              outlined
              dense
              v-model="filters.radio"
              item-text="name"
              item-value="id"
              :items="flt_radios"
            />
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col>
            <v-select
              :label="$t('editorial-filter-datetype')"
              outlined
              dense
              v-model="filters.date_type"
              item-text="name"
              item-value="id"
              :items="date_types"
            />
          </v-col>

          <v-col>
            <v-select
              class="minfield"
              outlined
              dense
              :label="$t('filters-period-hint')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
              :disabled="fetchingData || loading"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingData || loading || filters.filterByPeriod != 9
                  "
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('guests-filter-datestart')"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingData || loading || filters.filterByPeriod != 9
                  "
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('guests-filter-dateend')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <v-btn
            :disabled="fetchingData || loading"
            color="success"
            @click.stop="downloadExcelFile()"
            ><v-icon>mdi-file-excel</v-icon
            >{{ $t("editorial-stats-export") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="green"
            icon="mdi-target"
            title="Esiti totali"
            :value="
              statistics.total_outcomes != null
                ? statistics.total_outcomes.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Esiti giornalieri"
            :value="
              statistics.day_outcomes != null
                ? statistics.day_outcomes.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="purple"
            icon="mdi-target"
            title="Esiti richiamati"
            :value="
              statistics.post_outcomes != null
                ? statistics.post_outcomes.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="green"
            icon="mdi-account-multiple"
            title="Contatti totali"
            :value="
              statistics.total_contacts != null
                ? statistics.total_contacts.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-account-multiple"
            title="Contatti giornalieri"
            :value="
              statistics.day_contacts != null
                ? statistics.day_contacts.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="purple"
            icon="mdi-account-multiple"
            title="Contatti richiamati"
            :value="
              statistics.post_contacts != null
                ? statistics.post_contacts.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-calendar"
            title="Turni OPE"
            :value="
              statistics.total_working_days != null
                ? statistics.total_working_days.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Totale appuntamenti"
            :value="
              statistics.total_appointments != null
                ? statistics.total_appointments.toString() +
                  '/' +
                  statistics.total_appointments_closed.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Totale whatsapp"
            :value="
              statistics.total_whatsapp != null
                ? statistics.total_whatsapp.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          /> </v-col
        ><v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-target"
            title="Totale ins. NC"
            :value="
              statistics.total_insert_not_called != null
                ? statistics.total_insert_not_called.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('editorial-resume-daterange')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon color="green">mdi-checkbox-blank-circle</v-icon
          >{{ $t("editorial-stats-tbl-outcomes-aptapoapr-legend") }}
        </v-col>
        <v-col>
          <v-icon color="red">mdi-checkbox-blank-circle</v-icon
          >{{ $t("editorial-stats-tbl-outcomes-drtdrodrp-legend") }}
        </v-col>

        <v-col>
          <v-icon color="purple">mdi-checkbox-blank-circle</v-icon
          >{{ $t("editorial-stats-tbl-outcomes-nrtnronrp-legend") }}
        </v-col>

        <v-col>
          <v-icon color="cyan">mdi-checkbox-blank-circle</v-icon
          >{{ $t("editorial-stats-tbl-outcomes-nitnionip-legend") }}
        </v-col>

        <v-col>
          <v-icon color="orange">mdi-checkbox-blank-circle</v-icon
          >{{ $t("editorial-stats-tbl-outcomes-gvt-legend") }}
        </v-col>
        <v-col>
          <v-icon color="yellow">mdi-checkbox-blank-circle</v-icon
          >{{ $t("editorial-stats-tbl-outcomes-sct-legend") }}
        </v-col>
      </v-row>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="editorialOperators"
        :options.sync="options"
        :server-items-length="totalEditorialOperators"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.first_name="{ item }">
          <v-avatar size="36px" v-if="checkGravatar(item) && getGravatar(item)">
            <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
          </v-avatar>

          <v-avatar
            v-else-if="getProfileLetters(item)"
            color="blue"
            size="36px"
          >
            <span class="profile-text">{{ getProfileLetters(item) }}</span>
          </v-avatar>
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template v-slot:item.activity="{ item }">
          <v-icon v-if="item.activity && item.activity_time >= 1800" color="red"
            >mdi-circle</v-icon
          >
          <v-icon
            v-if="item.activity && item.activity_time <= 900"
            color="green"
            >mdi-circle</v-icon
          >
          <v-icon
            v-if="
              item.activity &&
                item.activity_time > 900 &&
                item.activity_time < 1800
            "
            color="orange"
            >mdi-circle</v-icon
          >
          {{ item.activity | toLocaleDateTime }}
        </template>
        <template v-slot:item.call_duration="{ item }">
          {{ formatDuration(item) }}
        </template>
        <template v-slot:item.user_id="{ item }">
          {{ item.ope_code }} ({{ item.user_id }})
        </template>

        <template v-slot:item.aptapoapr="{ item }">
          {{ item.apt + "/" + item.apo + "/" + item.apr + "/" + item.apc }}
        </template>
        <template v-slot:item.drtdrodrp="{ item }">
          {{ item.drt + "/" + item.dro + "/" + item.drp }}
        </template>
        <template v-slot:item.nrtnronrp="{ item }">
          {{ item.nrt + "/" + item.nro + "/" + item.nrp }}
        </template>
        <template v-slot:item.nitnionip="{ item }">
          {{ item.nit + "/" + item.nio + "/" + item.nip }}
        </template>
        <template v-slot:item.nvtnvonvp="{ item }">
          {{ item.nvt + "/" + item.nvo + "/" + item.nvp }}
        </template>
        <template v-slot:item.masmaumahman="{ item }">
          {{ item.mas + "/" + item.mau + "/" + item.mah + "/" + item.man }}
        </template>
        <template v-slot:item.total_contacts="{ item }">
          {{ item.day_contacts + item.post_contacts }}
        </template>
        <template v-slot:item.total_summed_contacts="{ item }">
          {{ item.total_contacts }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import config from "../config";
import guestManager from "../apis/guests";
import editorial from "../apis/editorial";
import messageBox from "../components/MessageBox";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      statistics: {
        day_guests: "...",
        post_guests: "...",
        day_paying_guests: "..."
      },

      date_types: [
        { id: 0, name: this.$t("editorial-filter-date-outcome") },
        { id: 1, name: this.$t("editorial-filter-date-assignment") }
      ],

      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      searchOperatorInput: null,

      operator: null,
      editorialOperators: [],
      operators: [],
      isLoadingOperator: false,
      loading: false,
      totalEditorialOperators: 0,
      perPage: staticData.defaultPerPage,

      options: {
        sortBy: ["user_id"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },

      headers: [],

      flt_schedulers: [],
      flt_radios: [],
      flt_formation: [
        {
          id: null,
          name: this.$t("gbl-all-male")
        },
        {
          id: true,
          name: this.$t("gbl-yes")
        },
        {
          id: false,
          name: this.$t("gbl-no")
        }
      ],
      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByPeriod: 11,
        operator: null,
        formation: false,
        date_type: 0
      },

      fetchingData: false
    };
  },

  components: {
    messageBox
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  mounted() {
    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios
    ];

    this.headers = [
      {
        text: this.$t("editorial-stats-tbl-firstname"),
        align: "left",
        sortable: true,
        value: "first_name"
      },

      {
        text: this.$t("editorial-stats-tbl-code"),
        align: "left",
        sortable: true,
        value: "user_id"
      },
      {
        text: this.$t("editorial-stats-tbl-activity"),
        align: "left",
        sortable: true,
        value: "activity"
      },
      {
        text: this.$t("editorial-stats-tbl-working-days"),
        align: "center",
        sortable: true,
        value: "total_working_days"
      },
      {
        text: this.$t("editorial-stats-tbl-calls-duration"),
        align: "center",
        sortable: true,
        value: "call_duration"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-outcomes"),
        align: "center",
        sortable: true,
        value: "total_outcomes"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-doutcomes"),
        align: "center",
        sortable: true,
        value: "day_outcomes"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-poutcomes"),
        align: "center",
        sortable: true,
        value: "post_outcomes"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-tcontacts"),
        align: "center",
        sortable: true,
        value: "total_contacts"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-dcontacts"),
        align: "center",
        sortable: true,
        value: "day_contacts"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-pcontacts"),
        align: "center",
        sortable: true,
        value: "post_contacts"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-insert-not-called"),
        align: "center",
        sortable: true,
        value: "insert_not_called"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-totalcontacs"),
        align: "center",
        sortable: true,
        value: "total_summed_contacts"
      },

      {
        text: this.$t("editorial-stats-tbl-outcomes-aptapoapr"),
        align: "center",
        sortable: true,
        value: "aptapoapr"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-drtdrodrp"),
        align: "center",
        sortable: true,
        value: "drtdrodrp"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-nrtnronrp"),
        align: "center",
        sortable: true,
        value: "nrtnronrp"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-nitnionip"),
        align: "center",
        sortable: true,
        value: "nitnionip"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-nvtnvonvp"),
        align: "center",
        sortable: true,
        value: "nvtnvonvp"
      },

      {
        text: this.$t("editorial-stats-tbl-outcomes-gvt"),
        align: "center",
        sortable: true,
        value: "gvt"
      },
      {
        text: this.$t("editorial-stats-tbl-outcomes-sct"),
        align: "center",
        sortable: true,
        value: "sct"
      },
      {
        text: this.$t("editorial-stats-tbl-whatsapp"),
        align: "center",
        sortable: true,
        value: "whatsapp"
      }
    ];

    this.operators = [];
  },

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then(res => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.new_id : null;
      }
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadEditorialOperators();
        }, config.searchInputsFastTimeout);
      },
      deep: true
    },

    filters: {
      handler() {
        this.editorialOperators = [];
        this.totalEditorialOperators = 0;
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["user_id"],
          sortDesc: [true]
        };
      },

      deep: true
    }
  },

  methods: {
    formatDuration: function(item) {
      let duration = item.call_duration;

      let format = "";
      let seconds = parseInt(Math.floor(duration));
      let hours = Math.floor(seconds / 60 / 60);
      if (hours < 1) hours = 0;
      seconds -= 60 * 60 * hours;
      let minutes = Math.floor(seconds / 60);
      seconds -= 60 * minutes;
      if (seconds < 1) seconds = 1;
      if (hours > 0) format += hours + ":";
      if (minutes > 0 && minutes.toString().length < 2) format += "0";
      if (minutes > 0) format += minutes + ":";
      else format += "00:";
      if (seconds.toString().length < 2) format += "0";
      format += seconds;
      return format;
    },

    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    reloadEditorialOperators: function() {
      this.editorialOperators = [];
      this.totalEditorialOperators = 0;
      this.statistics = {};

      this.fetchEditorialStatistics()
        .then(data => {
          this.editorialOperators = data.items;
          this.totalEditorialOperators = data.totalCount;
          this.statistics = data.stats;
        })
        .catch(err => {
          if (err) console.log(err);
        });
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestManager
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchEditorialStatistics() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        editorial
          .fetchEditorialStatistics(ctx)
          .then(result => {
            this.loading = false;
            resolve(result);
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    downloadExcelFile() {
      editorial.downloadExcelGlobalStatistics(
        this.$store.state.user.locale,
        this.filters
      );
    }
  }
};
</script>

<style>
.rs-bold {
  font-weight: 600;
}

a {
  color: #0044a3 !Important;
}
</style>
