import axios from "axios";
import config from "../config";

let podcasts = new Object({
  defrostSource: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/defrost",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  validateSource: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/validateSource",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setStreamState: (id, state) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/setExcludeStream",
        data: { id: id, state: state },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteMedia: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/deleteMedia",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  search: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/search",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.podcasts,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCategories: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/categories",
        data: content,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.categories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSpeakers: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/speakers",
        data: content,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchStreamingData: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/prestream",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.stream);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPurchased: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/fetchPurchased",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.podcasts,
            totalCount: resp.data.responseData.podcasts.length,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRegions: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videos/regions",
        data: content,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.regions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default podcasts;
