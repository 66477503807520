<template>
  <div id="scraper-container">
    <messageBox ref="msgBox" />
    <keywordsEditor ref="keywordsEditor" />
    <zonesEditor ref="zonesEditor" />
    <addTask ref="addTask" />
    <syncProgress ref="syncProgress" />
    <importTask ref="importTask" />
    <importResults ref="importResults" />

    <v-card class="ma-4">
      <v-toolbar color="red" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-account-convert</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("scraper-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div style="height:20px" />
      <v-row class="text-center" align="center" justify="center">
        <v-btn
          v-if="!fetchingFilters"
          :disabled="!$store.state.hasRight('27') || loading"
          v-on:click="addNewTask()"
          class="mr-2"
          color="purple"
          >{{ $t("scraper-add-task") }}</v-btn
        >
        <v-btn
          v-if="!fetchingFilters"
          :disabled="!$store.state.hasRight('43') || loading"
          v-on:click="manageKeywords()"
          class="mr-2"
          color="orange"
          >{{ $t("scraper-manage-keywords") }}</v-btn
        >
        <v-btn
          v-if="!fetchingFilters"
          :disabled="!$store.state.hasRight('42') || loading"
          v-on:click="manageZones()"
          class="mr-2"
          color="orange"
          >{{ $t("scraper-manage-zones") }}</v-btn
        >
      </v-row>

      <div class="vertical-spacer" />

      <v-row v-if="fetchingFilters" class="text-center">
        <v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row dense v-if="!fetchingFilters" class="ml-2">
        <v-col>
          <v-text-field
            outlined
            v-model="filters.keywords"
            append-icon="mdi-key"
            :label="$t('scraper-filter-keyword')"
            :disabled="loading"
            hide-details
            dense
          />
        </v-col>

        <v-col>
          <v-autocomplete
            :disabled="loading"
            clearable
            dense
            v-model="operator"
            :items="operators"
            :loading="isLoadingOperator"
            :search-input.sync="searchOperatorInput"
            cache-items
            outlined
            item-text="name"
            item-value="id"
            :label="$t('scraper-filter-operator')"
            return-object
            hide-no-data
          ></v-autocomplete>
        </v-col>

        <v-col>
          <v-text-field
            v-model="filters.region"
            outlined
            append-icon="mdi-city-variant-outline"
            :label="$t('scraper-filter-zone')"
            :disabled="loading"
            hide-details
            dense
          />
        </v-col>

        <v-col>
          <v-select
            :label="$t('scraper-filter-host')"
            outlined
            dense
            v-model="filters.host"
            append-icon="mdi-server-network"
            :items="hosts"
            item-text="value"
            item-value="key"
            :disabled="loading"
          />
        </v-col>

        <v-col>
          <v-select
            :label="$t('scraper-filter-state')"
            outlined
            dense
            v-model="filters.state"
            append-icon="mdi-database-refresh"
            :items="states"
            item-text="value"
            item-value="key"
            :disabled="loading"
          />
        </v-col>
      </v-row>
      <v-row dense v-if="!fetchingFilters" class="ml-2">
        <v-col>
          <v-select
            :label="$t('scraper-filter-archive')"
            outlined
            dense
            v-model="filters.archive"
            append-icon="mdi-archive"
            :items="flt_archive"
            item-text="value"
            item-value="key"
            :disabled="loading"
          />
        </v-col>

        <v-col>
          <v-select
            :label="$t('scraper-filter-provider')"
            outlined
            dense
            v-model="filters.provider"
            append-icon="mdi-facebook"
            :items="providers"
            item-text="value"
            item-value="key"
            :disabled="loading"
          />
        </v-col>
        <v-col>
          <v-select
            outlined
            dense
            :label="$t('scraper-filter-period')"
            v-model="filters.filterByPeriod"
            :items="getPeriodFilters()"
            item-text="name"
            item-value="id"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-dialog
            ref="dialog_start_date"
            v-model="date_start_modal"
            :return-value.sync="filters.filterByCustomDateStart"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                outlined
                v-model="start_date_formatted"
                :disabled="loading || filters.filterByPeriod != 9"
                :label="$t('filters-startdate-hint')"
                readonly
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              @input="
                $refs.dialog_start_date.save(filters.filterByCustomDateStart)
              "
              v-model="filters.filterByCustomDateStart"
              scrollable
            >
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-dialog
            ref="dialog_end_date"
            v-model="date_end_modal"
            :return-value.sync="filters.filterByCustomDateEnd"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :disabled="loading || filters.filterByPeriod != 9"
                dense
                outlined
                v-model="end_date_formatted"
                :label="$t('filters-enddate-hint')"
                readonly
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="$refs.dialog_end_date.save(filters.filterByCustomDateEnd)"
              v-model="filters.filterByCustomDateEnd"
              scrollable
            >
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-data-table
        v-if="!fetchingFilters"
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="tasks"
        :options.sync="options"
        :server-items-length="totalTasks"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="lsm_task.id"
      >
        <template v-slot:item.user="{ item }">
          <v-avatar size="36px" v-if="checkGravatar(item) && getGravatar(item)">
            <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
          </v-avatar>

          <v-avatar
            v-else-if="getProfileLetters(item)"
            color="blue"
            size="36px"
          >
            <span class="profile-text">{{ getProfileLetters(item) }}</span>
          </v-avatar>
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template v-slot:item.insert_date="{ item }">
          {{ item.insert_date | toLocaleDateTime }}
        </template>
        <template v-slot:item.start_date="{ item }">
          {{ item.start_date | toLocaleDateTime }}
        </template>
        <template v-slot:item.state="{ item }">
          {{ getItemState(item.state) }}
        </template>
        <template v-slot:item.exact_match="{ item }">
          {{ item.exact_match ? $t("gbl-yes") : $t("gbl-no") }}
        </template>

        <template v-slot:item.hostname="{ item }">
          {{ item.hostname ? item.hostname : "Tutti" }}
        </template>
        <template v-slot:item.progress="{ item }">
          {{
            item.iterations_count
              ? (
                  (item.completed_iterations / item.iterations_count) *
                  100.0
                ).toFixed(2) + " %"
              : ""
          }}
        </template>

        <template
          v-if="$store.state.hasRight('29')"
          v-slot:item.actions="{ item }"
        >
          <div style="min-width:140px">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="
                    !item.archived && item.state != 3 && item.state != 0
                  "
                  x-small
                  text
                  icon
                  color="red"
                  @click.stop="reboot(item)"
                  v-on="on"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-restart") }}</span></v-tooltip
            >
            <v-tooltip
              v-if="!item.archived && $store.state.hasRight('29')"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.state == 3 || item.state == 0"
                  x-small
                  text
                  icon
                  color="purple"
                  @click.stop="rebootUnprocessed(item)"
                  v-on="on"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                ></template
              ><span>{{
                $t("scraper-act-restart-unprocessed")
              }}</span></v-tooltip
            >

            <v-tooltip v-if="$store.state.hasRight('30')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.state == 0"
                  x-small
                  text
                  icon
                  color="success"
                  @click.stop="downloadResults(item)"
                  v-on="on"
                  ><v-icon>mdi-download</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-download-csv") }}</span></v-tooltip
            >
            <v-tooltip v-if="$store.state.hasRight('30')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.state == 0"
                  x-small
                  text
                  icon
                  color="success"
                  @click.stop="downloadExcelResults(item)"
                  v-on="on"
                  ><v-icon>mdi-file-excel</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-download-excel") }}</span></v-tooltip
            >
            <v-tooltip v-if="$store.state.hasRight('165')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.state == 0"
                  x-small
                  text
                  icon
                  color="red"
                  @click.stop="uploadToServer(item)"
                  v-on="on"
                  ><v-icon>mdi-upload</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-upload") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                !item.archived &&
                  item.state == 3 &&
                  $store.state.hasRight('138')
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="orange"
                  @click.stop="archiveItem(item)"
                  v-on="on"
                  ><v-icon>mdi-archive</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-archive-task") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="item.archived && $store.state.hasRight('138')"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="unarchiveItem(item)"
                  v-on="on"
                  ><v-icon>mdi-archive</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-unarchive-task") }}</span></v-tooltip
            >

            <v-tooltip v-if="$store.state.hasRight('28')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteItem(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-act-remove-task") }}</span></v-tooltip
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import tasksManager from "../apis/scraper/tasks";

import staticData from "../apis/static";
import AvatarServices from "../services/avatars";
import messageBox from "../components/MessageBox";
import keywordsEditor from "../components/scraper/KeywordsEditor";
import zonesEditor from "../components/scraper/ZonesEditor";
import addTask from "../components/scraper/AddTask";
import syncProgress from "../components/SyncProgress";
import importTask from "../components/scraper/ImportTask";
import importResults from "../components/scraper/ImportResults";

import config from "../config";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,
      users: [],
      loading: false,
      totalTasks: 0,
      flt_archive: [],
      operators: [],
      operator: null,
      isLoadingOperator: false,
      searchOperatorInput: null,
      tasks: [],
      hosts: [],
      states: [],
      providers: [],
      options: {
        sortBy: ["insert_date"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: 50,
      filters: {
        keywords: null,
        region: null,
        host: null,
        state: null,
        provider: null,
        operator: null,
        archive: false,
        filterByPeriod: 11,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
      },
      tasksTimeout: null,
      fetchingFilters: false,
    };
  },

  components: {
    messageBox,
    keywordsEditor,
    zonesEditor,
    addTask,
    syncProgress,
    importTask,
    importResults,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },

      set: function() {
        this.filters.filterByCustomDateStart = null;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function() {
        this.filters.filterByCustomDateEnd = null;
      },
    },
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("scraper-tbl-user"),
        align: "left",
        sortable: true,
        value: "user",
      },
      {
        text: this.$t("scraper-tbl-keywords"),
        align: "left",
        sortable: true,
        value: "keywords",
      },
      {
        text: this.$t("scraper-tbl-zone"),
        align: "left",
        sortable: true,
        value: "zone",
      },
      {
        text: this.$t("scraper-tbl-host"),
        align: "left",
        sortable: true,
        value: "hostname",
      },
      {
        text: this.$t("scraper-tbl-provider"),
        align: "left",
        sortable: true,
        value: "provider",
      },
      {
        text: this.$t("scraper-tbl-exactmatch"),
        align: "left",
        sortable: true,
        value: "exact_match",
      },
      {
        text: this.$t("scraper-tbl-priority"),
        align: "center",
        sortable: true,
        value: "priority",
      },
      {
        text: this.$t("scraper-tbl-progress"),
        align: "center",
        sortable: false,
        value: "progress",
      },
      {
        text: this.$t("scraper-tbl-insertdate"),
        align: "center",
        sortable: true,
        value: "insert_date",
      },
      {
        text: this.$t("scraper-tbl-startdate"),
        align: "center",
        sortable: true,
        value: "start_date",
      },
      {
        text: this.$t("scraper-tbl-state"),
        align: "center",
        sortable: true,
        value: "state",
      },
      {
        text: this.$t("scraper-tbl-iteractions"),
        align: "center",
        sortable: true,
        value: "iterations_count",
      },
      {
        text: this.$t("scraper-tbl-results"),
        align: "center",
        sortable: true,
        value: "results_count",
      },
      {
        text: this.$t("scraper-tbl-duplicates"),
        align: "center",
        sortable: true,
        value: "duplicates_count",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];

    this.states = [
      { key: null, value: this.$t("gbl-all-male") },
      { key: 0, value: this.$t("scraper-state-onqueue") },
      { key: 1, value: this.$t("scraper-state-processing") },
      { key: 3, value: this.$t("scraper-state-completed") },
    ];

    this.flt_archive = [
      { key: false, value: this.$t("scraper-filter-unarchived") },
      { key: true, value: this.$t("scraper-filter-archived") },
      { key: null, value: this.$t("gbl-all-male") },
    ];

    this.fetchingFilters = true;
    tasksManager
      .fetchFilters()
      .then((result) => {
        this.hosts = result.items.hosts.map((x) => {
          return { key: x.host, value: x.host };
        });
        this.hosts = [
          { key: null, value: this.$t("gbl-all-male") },
          ...this.hosts,
        ];

        this.providers = result.items.providers.map((x) => {
          return { key: x.provider, value: x.provider };
        });
        this.providers = [
          { key: null, value: this.$t("gbl-all-male") },
          ...this.providers,
        ];

        this.fetchingFilters = false;
        this.loopReload();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadTasks(true);
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadTasks(true);
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },
  },
  methods: {
    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        tasksManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    getItemState: function(state) {
      switch (state) {
        case 0:
          return this.$t("scraper-state-onqueue");
        case 1:
          return this.$t("scraper-state-processing");
        case 2:
          return this.$t("scraper-state-processing");
        case 3:
          return this.$t("scraper-state-completed");
      }
      return this.$t("scraper-state-undefined");
    },

    loopReload: function() {
      if (this.tasksTimeout) clearTimeout(this.tasksTimeout);
      this.tasksTimeout = setTimeout(() => {
        if (document.getElementById("scraper-container")) this.updateTasks();
        this.loopReload();
      }, 5000);
    },

    updateTasks: function() {
      this.fetchTasks(false)
        .then((data) => {
          let invalid = false;
          let existingTasks = {};
          for (let n = 0; n < this.tasks.length; n++) {
            existingTasks[this.tasks[n].task_id] = this.tasks[n];
          }
          for (let n = 0; n < data.items.length; n++) {
            let item = data.items[n];
            let id = item.task_id;

            if (existingTasks[id]) {
              existingTasks[id].completed_iterations =
                item.completed_iterations;
              existingTasks[id].state = item.state;
              existingTasks[id].start_date = item.start_date;
            } else {
              invalid = true;
            }
          }

          if (invalid) {
            this.tasks = data.items;
            this.totalTasks = data.totalCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reloadTasks: function(showLoader) {
      this.fetchTasks(showLoader)
        .then((data) => {
          this.tasks = data.items;
          this.totalTasks = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    fetchTasks(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        tasksManager
          .fetchTasks(ctx)
          .then((result) => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("scraper-msg-confirmdelete"),
          this.$t("scraper-msg-confirmop")
        )
        .then(() => {
          tasksManager
            .removeTask(item.task_id)
            .then(() => {
              this.reloadTasks(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    archiveItem(item) {
      tasksManager
        .archiveTask(item.task_id)
        .then(() => {
          this.reloadTasks(true);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    unarchiveItem(item) {
      tasksManager
        .unarchiveTask(item.task_id)
        .then(() => {
          this.reloadTasks(true);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    rebootUnprocessed(item) {
      this.$refs.msgBox
        .show(
          this.$t("scraper-msg-restartunprocessed"),
          this.$t("scraper-msg-confirmop")
        )
        .then(() => {
          tasksManager
            .rebootUnprocessed(item.task_id)
            .then(() => {
              this.reloadTasks(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    reboot(item) {
      this.$refs.msgBox
        .show(this.$t("scraper-msg-restart"), this.$t("scraper-msg-confirmop"))
        .then(() => {
          tasksManager
            .reboot(item.task_id)
            .then(() => {
              this.reloadTasks(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    downloadResults(item) {
      tasksManager.downloadResults(item.task_id);
    },

    downloadExcelResults(item) {
      tasksManager.downloadExcelResults(item.task_id);
    },

    uploadToServer(item) {
      let import_name = item.task_id + "-" + item.keywords + "-" + item.zone
      this.$refs.importTask
        .show()
        .then((options) => {
          this.$refs.syncProgress.show(
            "Caricamento contatti",
            "Caricamento contatti su Redazione in corso...."
          );
          this.$refs.syncProgress.setProgress(0);
          let upload_result = null;
          tasksManager
            .uploadTask(item.task_id,import_name,options, (res) => {
              if (res) {
                let items = res.split("\n");
                for (let n = 0; n < items.length; n++) {
                  if (items[n].length > 0) {
                    if (items[n][0] != ":") {
                      let tokens = items[n].split(",");
                      if (tokens.length >= 2) {
                        this.$refs.syncProgress.setProgress(
                          parseFloat(tokens[1])
                        );
                        this.$refs.syncProgress.setStatus(tokens[0]);
                      }
                    } else {
                      // Completed
                      let result = items[n].substr(1, items[n].length - 1);
                      upload_result = JSON.parse(result);
                      this.$refs.importResults.show(upload_result).then(()=>{}).catch(()=>{});
                    }
                  }
                }
              } else {
                this.$refs.syncProgress.setProgress(0);
              }
            })
            .then(() => {
              this.$refs.syncProgress.hide();
            });
        })
        .catch(() => {});
    },

    addNewTask() {
      this.$refs.addTask
        .show()
        .then(() => {
          this.reloadTasks(true);
        })
        .catch(() => {});
    },

    manageKeywords() {
      this.$refs.keywordsEditor
        .show()
        .then(() => {})
        .catch(() => {});
    },

    manageZones() {
      this.$refs.zonesEditor
        .show()
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>
