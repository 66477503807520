import axios from "axios";
import config from "../config";

let voip = {
  getToken: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/voip/token",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCalls: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/voip/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.calls,
            totalCount: resp.data.responseData.count,
            duration : resp.data.responseData.duration
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeCall: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/voip/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUsers: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/voip/users",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.users,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  
};

export default voip;
