// module variables
const config = require("./config.json");
const defaultConfig = config.development;
const environment = process.env.NODE_ENV || "production";
const environmentConfig = config[environment];
const finalConfig = Object.assign({}, defaultConfig, environmentConfig);

finalConfig.emails = {};

finalConfig.emails.paymentLinkMessage =
"Gentile %NOMEOSPITE%,\r\n\r\n\
a seguito del precedente messaggio, stà per essere contattato da un assistente di studio per avere un follow up sulla sua esperienza.\r\n\
Le proponiamo in attesa un link ad un video dimostrativo utile a scegliere una versione audiovideo (Video Podcast) della sua registrazione \
effettuata , condivisibile su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp,..).\r\n\r\n\
GUARDI IL VIDEO DIMOSTRATIVO!\r\n\
%LINKDEMO%\r\n\r\n\
Di seguito troverà anche un secondo link che le permetterà di richiedere la versione personalizzata più adatta alla sue esigenze professionali.\r\n\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente i due link, le chiediamo di salvare in rubrica (anche temporaneamente) questo numero. In tal modo il link diventerà blu e potrà accedervi direttamente.\
\r\nCon l’occasione La ringraziamo e Le auguriamo una buona giornata!\r\n\r\n\
Le chiediamo cortesemente di non rispondere a questo messaggio ma di attendere la chiamata del nostro assistente. Grazie.\r\n\
%REDAZIONE%\r\n\r\n";

finalConfig.emails.paymentLinkMessageRRTS =
"Gentile %NOMEOSPITE%,\r\n\r\n\
a seguito del precedente messaggio, stà per essere contattato da un assistente di studio per avere un follow up sulla sua esperienza.\r\n\
Le proponiamo in attesa un link ad un video dimostrativo utile a scegliere una versione audiovideo (Video Podcast) della sua registrazione \
effettuata , condivisibile su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp,..).\r\n\r\n\
GUARDI IL VIDEO DIMOSTRATIVO!\r\n\
%LINKDEMO%\r\n\r\n\
Di seguito troverà anche un secondo link che le permetterà di richiedere la versione personalizzata più adatta alla sue esigenze professionali.\r\n\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente i due link, le chiediamo di salvare in rubrica (anche temporaneamente) questo numero. In tal modo il link diventerà blu e potrà accedervi direttamente.\
\r\nCon l’occasione La ringraziamo e Le auguriamo una buona giornata!\r\n\r\n\
Le chiediamo cortesemente di non rispondere a questo messaggio ma di attendere la chiamata del nostro assistente. Grazie.\r\n\
RadioRegioneTalkShow\r\n\r\n";

finalConfig.emails.paymentAutoLinkMessage =
"Salve %NOMEOSPITE%, sono %NOMEOPE%.\r\n\r\n\
Come le ho spiegato, a questo link potrà visionare il video dimostrativo utile a scegliere il formato più adatto a rappresentarla su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp) e il form di pagamento per confermare la sua scelta.\r\n\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente il link,  salvi in rubrica questo numero.\r\n\
In tal modo il link diventerà blu e potrà accedervi direttamente.\r\n\r\n\
Le ricordo che potrà inoltre scegliere se aggiungere foto e immagini al suo video!\r\n\r\n\
Resto a sua disposizione,\r\n\
questo è il mio numero, può chiamarmi o rispondere direttamente a questo messaggio :)\r\n";

finalConfig.emails.paymentAutoLinkMessageRRTS =
"Gentile %NOMEOSPITE%, sono %NOMEOPE%.\r\n\r\n\
Come le ho spiegato, a questo link potrà visionare il form di pagamento per confermare la sua scelta riguardo il video più adatto a rappresentarla su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp).\r\n\
Aprendo il link, troverà anche un click per accedere ad una video demo dimostrativa sulle diverse tipologie di formato che potrà scegliere in base alle esigenze di visibilità.\r\n\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente il link, salvi in rubrica questo numero. In tal modo il link diventerà blu e potrà accedervi direttamente.\r\n\r\n\
Le ricordo che potrà inoltre scegliere se aggiungere foto e immagini al suo video!\r\n\r\n\
Resto a sua disposizione, questo è il mio numero, può chiamarmi o rispondere direttamente a questo messaggio :)\r\n";

finalConfig.emails.paymentAutoLinkMessageSart =
"Gentile %NOMEOSPITE%, sono %NOMEOPE% da ADV Social Network.\r\n\r\n\
Come le ho spiegato, a questo link potrà procedere al pagamento per la realizzazione del suo Video Sartoriale, utile a rappresentarla su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp).\r\n\r\n\
Le ricordiamo che appena il pagamento sarà contabilizzato verrà contattato per organizzare contenuti e tempi di registrazione.\r\n\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente il link, salvi in rubrica il mio numero. In tal modo il link diventerà blu e potrà accedervi direttamente.\r\n\r\n\
Resto a sua disposizione, può chiamarmi o rispondere direttamente a questo messaggio\r\n\r\nGrazie\r\n";

finalConfig.emails.paymentNrAutoLinkMessageRRTS =
"Gentile %NOMEOSPITE%, sono %NOMEOPE%.\r\n\r\n\
Ho provato a contattarla per fornirle le informazioni relative alla sua messa in onda.\r\n\
Con l’occasione volevo anche spiegarle i diversi formati video più adatti a rappresentarla su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp).\r\n\r\n\
Può trovare una demo video con le 4 proposte aprendo questo link:\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente il link, salvi in rubrica questo numero. In tal modo il link diventerà blu e potrà accedervi direttamente.\r\n\r\n\
Le ricordo che potrà inoltre scegliere se aggiungere foto e immagini al suo video!\r\n\r\n\
Resto a sua disposizione, questo è il mio numero, può chiamarmi o rispondere direttamente a questo messaggio :)\r\n";

finalConfig.emails.paymentNrAutoLinkMessage =
"Salve %NOMEOSPITE%, sono %NOMEOPE%.\r\n\r\n\
Ho provato a contattarla per fornirle le informazioni relative alla sua messa in onda.\r\n\r\n\
Con l’occasione volevo anche spiegarle i vari format video più adatti a rappresentarla su qualunque canale social (Facebook, YouTube, Instagram, Twitter, WhatsApp)\r\n\r\n\
Può trovare una demo e le 3 proposte a questo link:\r\n\
%LINKFORMPAGAMENTO%\r\n\r\n\
Se non visualizza correttamente il link,  salvi in rubrica questo numero.\r\n\
In tal modo il link diventerà blu e potrà accedervi direttamente.\r\n\r\n\
Le ricordo che potrà inoltre scegliere se aggiungere foto e immagini al suo video!\r\n\r\n\
Resto a sua disposizione, \r\n\
questo è il mio numero, può chiamarmi o rispondere direttamente a questo messaggio :)\r\n";

finalConfig.emails.interviewToBeSentAgain = "Gentile %NOMEOSPITE%,\r\
siamo spiacenti di non aver potuto registrare il suo intervento radiofonico in onda su %NOMERADIO%.\r\
Sarà nostra premura ricontattarla per rifissare il suo appuntamento.\r\n\
Certi di averla presto ai nostri microfoni, le inviamo i nostri saluti!\r\n\
%REDAZIONE%";

finalConfig.emails.interviewNoAnswer = "Gentile %NOMEOSPITE%,\r\
abbiamo provato a raggiungerla per registrare il suo intervento radiofonico in onda su %NOMERADIO%.\r\n\
La preghiamo di rendersi disponibile nei prossimi  minuti perché proveremo a ricontattarla.\r\n\
Cordiali saluti\r\n\
%REDAZIONE%";

finalConfig.emails.rrEditing = "Gentile %NOMEOSPITE%,\r\n\r\n\
Alleghiamo il link per poter scaricare il video del suo intervento radiofonico. Il link ha valenza di 30 giorni e le è stato inviato anche sulla mail che ha lasciato nel momento della registrazione, se non pervenuta controlli anche lo spam.\r\n\r\n\
Per qualsiasi problematica riscontrata può rispondere a questa mail: %EMAILSUPPORT%\r\n\
\r\n\
%LINK%\r\n\
\r\n\
Se non visualizza correttamente il link,  salvi in rubrica questo numero.\r\n\
In tal modo il link diventerà blu e potrà accedervi direttamente.\r\n\r\n\
Se vuole spendere alcuni minuti saremo lieti di ricevere un suo feedback sull'esperienza attraverso questo link:\r\n\r\n%REVIEWS%\r\n\
\r\n\
Cordiali Saluti\r\n\
\r\n\
%STAFF%\r\n"

finalConfig.emails.rrEditingSubject = "Radio Regione - Link intervento radiofonico"

export default finalConfig;
