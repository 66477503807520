<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-merge</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("editorial-merge-lists-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :label="$t('editorial-merge-lists-container')"
                    v-model="container_mode"
                    :items="container_modes"
                    item-text="name"
                    item-value="id"
                    :disabled="fetchingLists"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :label="$t('editorial-merge-lists-destination')"
                    v-model="source_list"
                    :items="source_lists"
                    item-text="name"
                    item-value="id"
                    :disabled="fetchingLists"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :label="$t('editorial-merge-lists-source')"
                    v-model="destination_list"
                    :items="destination_lists"
                    item-text="name"
                    item-value="id"
                    :disabled="fetchingLists"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="mt-2">
                  <v-btn
                    width="120"
                    v-on:click="confirm()"
                    color="cyan"
                    :disabled="
                      !source_list || !destination_list || fetchingLists
                    "
                    >{{ $t("gbl-ok") }}</v-btn
                  >

                  <v-btn width="120" v-on:click="cancel()" color="gray">{{
                    $t("gbl-cancel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorialManager from "../../apis/editorial";

export default {
  data() {
    return {
      source_list: null,
      destination_list: null,

      fetchingLists: false,
      source_lists: [],
      destination_lists: [],
      container_mode: 0,

      container_modes: [
        { id: 0, name: this.$t("editorial-add-list-derived-list") },
        { id: 1, name: this.$t("editorial-add-list-container-list") }
      ]
    };
  },

  props: ["value"],

  components: {},

  mounted() {},

  watch: {
    container_mode: {
      handler() {
        this.destination_lists = [];
        this.destination_list = null;
        this.source_lists = [];
        this.source_list = null;
        this.fetchLists(null, "source_lists");
      }
    },

    source_list: {
      handler() {
        this.destination_lists = [];
        this.destination_list = null;
        this.fetchLists(this.source_list, "destination_lists");
      }
    }
  },

  methods: {
    reset() {
      this.source_lists = [];
      this.source_list = null;
      this.destination_list = null;
      this.destination_lists = [];
      this.container_mode = 0;
      this.fetchLists(null, "source_lists");
    },

    fetchLists(excluded, destination) {
      this.fetchingLists = true;
      return new Promise((resolve, reject) => {
        editorialManager
          .fetchListsNames({ filters: { archived: false, excluded: excluded , container: this.container_mode} })
          .then(result => {
            this[destination] = result;
            this.fetchingLists = false;
            resolve(result);
          })
          .catch(err => {
            this.fetchingLists = false;
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: function() {
      this.$emit("onListMerged", {
        source_list: this.source_list,
        destination_list: this.destination_list,
        container_mode: this.container_mode
      });
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    setItem(item) {
      this.user = item;
      this.selectedUser = null;
      this.nation = 86; // Get the nation from the user defaults //
      this.fetchCategories().then(res => {
        this.categories = res;
        if (isNaN(Date.parse(this.user.override_date))) {
          this.override_date = this.$options.filters.toDatePickerDate(
            new Date(Date.now()).toString()
          );
        } else {
          this.override_date = this.$options.filters.toDatePickerDate(
            this.user.override_date
          );
        }
      });
    }
  }
};
</script>
