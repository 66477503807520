<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("gsteditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-row class="text-center">
              <v-col>
                <div style="height:10px;" />
                <v-progress-circular
                  v-if="fetchingMetadata"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="light-blue"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form v-if="!fetchingMetadata" ref="gsteditor-editor-form">
              <v-tabs
                color="cyan"
                v-model="tab"
                background-color="white"
                centered
                icons-and-text
                class="ma-1"
              >
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

                <v-tab href="#tab-1">
                  {{ $t("gsteditor-tab-data") }}
                  <v-icon>mdi-card-text-outline</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  {{ $t("gsteditor-tab-state") }}
                  <v-icon>mdi-card-text-outline</v-icon>
                </v-tab>

                <v-tab href="#tab-3">
                  {{ $t("gsteditor-tab-interview") }}
                  <v-icon>mdi-microphone</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <ValidationObserver ref="obs_1" v-slot="{ invalid, validated }">
                  <v-tab-item eager value="tab-1">
                    <div style="height:10px" />
                    <v-row>
                      <v-col>
                        <ValidationProvider
                          :immediate="editingGuest"
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            :label="$t('gsteditor-hint-locale')"
                            outlined
                            dense
                            v-model="guest.locale"
                            prepend-icon="mdi-account-supervisor"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="code"
                            :items="locales"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          :immediate="editingGuest"
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field outlined dense
                            v-model="guest.nome_intervistato_1"
                            :label="$t('gsteditor-hint-name-1')"
                            prepend-icon="mdi-account-voice"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>

                        <v-text-field outlined dense
                          v-model="guest.nome_intervistato_2"
                          :label="$t('gsteditor-hint-name-2')"
                          prepend-icon="mdi-account-voice"
                        ></v-text-field>

                        <ValidationProvider 
                          :immediate="editingGuest"
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field outlined dense
                            v-model="guest.indirizzo"
                            :label="$t('gsteditor-hint-address')"
                            prepend-icon="mdi-account-voice"
                            :error-messages="errors"
                            :success="valid"
                          ></v-text-field>
                        </ValidationProvider>

                        <ValidationProvider
                          :immediate="editingGuest"
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select outlined
                            :label="$t('gsteditor-hint-region')"
                            dense
                            v-model="guest.regione"
                            prepend-icon="mdi-map-outline"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="regions"
                          />
                        </ValidationProvider>

                        <v-text-field outlined dense
                          v-model="guest.attivita"
                          :label="$t('gsteditor-hint-activity')"
                          prepend-icon="mdi-office-building"
                        ></v-text-field>

                        <ValidationProvider
                          :immediate="editingGuest"
                          :rules="{
                            required: true,
                            regex: /^(\+\d{2})?\d{9,11}$/,
                          }"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field outlined dense
                            v-model="guest.telefono"
                            :label="$t('gsteditor-hint-phone')"
                            prepend-icon="mdi-phone"
                            :error-messages="errors"
                            :success="valid"
                          ></v-text-field>
                        </ValidationProvider>

                        <v-text-field outlined dense
                          v-model="guest.sito"
                          :label="$t('gsteditor-hint-website')"
                          prepend-icon="mdi-web"
                        ></v-text-field>

                        <v-text-field outlined dense
                          v-model="guest.instagram"
                          :label="$t('gsteditor-hint-instagram')"
                          prepend-icon="mdi-instagram"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <ValidationProvider
                          :immediate="editingGuest"
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-textarea
                            v-model="guest.argomento"
                            :label="$t('gsteditor-hint-argument')"
                            :error-messages="errors"
                            :success="valid"
                            outlined
                            rows="10"
                          ></v-textarea>
                        </ValidationProvider>
                        <ValidationProvider
                          :immediate="editingGuest"
                          rules="required|email"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            v-model="guest.email" outlined dense
                            :label="$t('gsteditor-hint-email')"
                            prepend-icon="mdi-email-outline"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          :immediate="editingGuest"
                          rules="required|email"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field outlined dense
                            v-model="guest.contact_email"
                            :label="$t('gsteditor-hint-contact-mail')"
                            prepend-icon="mdi-email-outline"
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-text-field>
                        </ValidationProvider>

                        <v-text-field outlined dense
                          v-model="guest.facebook"
                          :label="$t('gsteditor-hint-facebook')"
                          prepend-icon="mdi-facebook"
                        ></v-text-field>

                        <v-text-field outlined dense
                          v-model="guest.youtube"
                          :label="$t('gsteditor-hint-youtube')"
                          prepend-icon="mdi-youtube"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </ValidationObserver>
                <ValidationObserver ref="obs_2" v-slot="{ invalid, validated }">
                  <v-tab-item eager value="tab-2">
                    <div style="height:10px" />
                    <v-row>
                      <v-col>
                        <ValidationProvider
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            :label="$t('gsteditor-hint-type')"
                            outlined
                            dense
                            v-model="guest.tipologia"
                            prepend-icon="mdi-microphone-variant"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="types"
                            :disabled="!$store.state.hasRight('120')"
                          />
                        </ValidationProvider>

                        <ValidationProvider
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            :label="$t('gsteditor-hint-radio')"
                            outlined
                            dense
                            v-model="guest.id_radio"
                            prepend-icon="mdi-radio"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="radios"
                            :disabled="!$store.state.hasRight('120')"
                          />

                          <v-select
                            :label="$t('gsteditor-hint-territories-radios')"
                            outlined
                            dense
                            v-model="guest.id_territorio"
                            prepend-icon="mdi-radio"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="territories_radios"
                            :disabled="!$store.state.hasRight('120')"
                          />
                        </ValidationProvider>

                        <ValidationProvider
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            :label="$t('gsteditor-hint-program')"
                            outlined
                            dense
                            v-model="guest.id_programma"
                            prepend-icon="mdi-radio-tower"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="programs"
                            :disabled="!$store.state.hasRight('120')"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            :label="$t('gsteditor-hint-category')"
                            outlined
                            dense
                            v-model="guest.id_categoria"
                            prepend-icon="mdi-progress-wrench"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="categories"
                            item-disabled="disabled"
                            :disabled="!$store.state.hasRight('120')"
                          />
                        </ValidationProvider>

                        <v-checkbox
                          dense
                          hide-details
                          v-model="guest.disable_site_listing"
                           class="shrink mr-2 mt-0"
                          :label="$t('gsteditor-disable-site-listing')"
                        ></v-checkbox>
                      </v-col>

                      <v-col>
                        <ValidationProvider
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                          vid="outcome"
                        >
                          <v-select
                            :label="$t('gsteditor-hint-outcome')"
                            outlined
                            dense
                            v-model="guest.esito"
                            prepend-icon="mdi-target"
                            :error-messages="errors"
                            :success="valid"
                            item-text="name"
                            item-value="id"
                            :items="computed_outcomes"
                            :disabled="
                              !$store.state.hasRight('120') || !editingGuest
                            "
                          >
                            <template slot="item" slot-scope="data">
                              <span class="body-2 mr-1">
                                {{ data.item.name }}</span
                              >
                              <span
                                class="body-2 red--text"
                                v-if="data.item.warning"
                                >- {{ data.item.warning }}</span
                              >
                            </template>
                          </v-select>
                        </ValidationProvider>

                        <v-dialog
                          ref="dialog_start_date"
                          v-model="interview_date_modal"
                          :return-value.sync="guest.data_intervista"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="interview_date_formatted"
                              prepend-icon="mdi-calendar"
                              :label="$t('gsteditor-hint-interview-date')"
                              readonly
                              v-on="on"
                              clearable
                              :disabled="!$store.state.hasRight('120')"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            @input="
                              $refs.dialog_start_date.save(interview_date)
                            "
                            v-model="interview_date"
                            scrollable
                          >
                          </v-date-picker>
                        </v-dialog>

                        <ValidationProvider
                          v-if="guest.esito == 5"
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-textarea
                            v-model="guest.notes.motivo_rifiuto"
                            :label="$t('gsteditor-hint-refuse-reason')"
                            outlined
                            rows="5"
                            :error-messages="errors"
                            :success="valid"
                            required
                            :disabled="!$store.state.hasRight('120')"
                          ></v-textarea>
                        </ValidationProvider>

                        <v-textarea
                          v-if="guest.esito != 5"
                          v-model="guest.notes.motivo_rifiuto"
                          disabled
                          :label="$t('gsteditor-hint-refuse-reason')"
                          outlined
                          rows="5"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-textarea
                          v-model="guest.notes.note"
                          :label="$t('gsteditor-hint-notes')"
                          outlined
                          rows="7"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </ValidationObserver>
                <ValidationObserver ref="obs_3" v-slot="{ invalid, validated }">
                  <v-tab-item eager value="tab-3">
                    <div style="height:10px" />
                    <v-row dense>
                      <v-col>
                        <ValidationProvider
                          rules="required|min_value:1"
                          v-slot="{ errors, valid }"
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                        >
                          <v-select
                            :label="$t('gsteditor-details-source-scheduler')"
                            outlined
                            dense
                            v-model="guest.palinsesto_origine"
                            item-text="name"
                            item-value="id"
                            :items="schedulers"
                            :error-messages="errors"
                            :success="valid"
                            :disabled="!$store.state.hasRight('120')"
                            required
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <h2>{{ $t("gsteditor-details-operators") }}</h2>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-autocomplete
                          clearable
                          dense
                          v-model="guest_operator"
                          :items="operators"
                          :loading="isLoadingOperators"
                          :search-input.sync="searchOperatorInput"
                          cache-items
                          outlined
                          item-text="name"
                          item-value="id"
                          :label="$t('gsteditor-details-operators-operator')"
                          return-object
                          hide-no-data
                        ></v-autocomplete>
                      </v-col>

                      <v-col v-if="editingGuest">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors, valid }"
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                        >
                          <v-autocomplete
                            clearable
                            dense
                            v-model="guest_coordinator"
                            :items="coordinators"
                            :loading="isLoadingCoordinators"
                            :search-input.sync="searchCoordinatorInput"
                            :disabled="!$store.state.hasRight('120')"
                            cache-items
                            outlined
                            item-text="name"
                            item-value="id"
                            :label="
                              $t('gsteditor-details-operators-coordination')
                            "
                            :error-messages="errors"
                            :success="valid"
                            return-object
                            required
                            hide-no-data
                          ></v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col v-if="!editingGuest">
                        <v-autocomplete
                          clearable
                          dense
                          v-model="guest_coordinator"
                          :items="coordinators"
                          :loading="isLoadingCoordinators"
                          :search-input.sync="searchCoordinatorInput"
                          :disabled="!$store.state.hasRight('120')"
                          cache-items
                          outlined
                          item-text="name"
                          item-value="id"
                          :label="
                            $t('gsteditor-details-operators-coordination')
                          "
                          return-object
                          hide-no-data
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <v-autocomplete
                          clearable
                          dense
                          v-model="guest_firstcall"
                          :items="firstcalls"
                          :loading="isLoadingFirstcalls"
                          :search-input.sync="searchFirstcallInput"
                          cache-items
                          outlined
                          item-text="name"
                          item-value="id"
                          :label="$t('gsteditor-details-operators-firstcall')"
                          return-object
                          hide-no-data
                        ></v-autocomplete>
                      </v-col>

                      <v-col>
                        <v-autocomplete
                          v-if="guest.esito != 2"
                          clearable
                          dense
                          v-model="guest_speaker"
                          :items="speakers"
                          :loading="isLoadingSpeakers"
                          :search-input.sync="searchSpeakerInput"
                          cache-items
                          outlined
                          item-text="name"
                          item-value="id"
                          :label="$t('gsteditor-details-operators-speaker')"
                          return-object
                          hide-no-data
                          disabled
                        ></v-autocomplete>

                        <ValidationProvider
                          v-if="guest.esito == 2 || guest.esito == 8"
                          :immediate="
                            editingGuest && $store.state.hasRight('120')
                          "
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-autocomplete
                            clearable
                            dense
                            v-model="guest_speaker"
                            :items="speakers"
                            :loading="isLoadingSpeakers"
                            :search-input.sync="searchSpeakerInput"
                            :disabled="!$store.state.hasRight('120')"
                            cache-items
                            outlined
                            item-text="name"
                            item-value="id"
                            :label="$t('gsteditor-details-operators-speaker')"
                            return-object
                            hide-no-data
                            :error-messages="errors"
                            :success="valid"
                            required
                          ></v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <h2>{{ $t("gsteditor-details-title") }}</h2>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.nome_intervistato"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-name')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.qualifica"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-qualification')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.attivita"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-activity')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.argomento"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-argument')"
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.telefono"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-phone')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.email"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-email')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.indirizzo"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-address')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.facebook"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-facebook')"
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.sito"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-website')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.instagram"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-instagram')"
                        ></v-checkbox>

                        <v-checkbox
                          dense
                          hide-details
                          class="ml-4"
                          v-model="guest.metadata.youtube"
                          false-value="N"
                          true-value="Y"
                          :label="$t('gsteditor-details-youtube')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <div style="height:10px" />
                  </v-tab-item>
                </ValidationObserver>
              </v-tabs-items>

              <v-row>
                <v-col>
                  <v-btn
                    v-if="!eventEmitted"
                    width="120"
                    v-on:click="confirm()"
                    color="cyan"
                    >{{ $t("gbl-ok") }}</v-btn
                  >
                  <v-progress-circular
                    v-if="eventEmitted"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                    class="ml-2 mr-2"
                  ></v-progress-circular>
                  <v-btn
                    v-if="!eventEmitted"
                    width="120"
                    v-on:click="cancel()"
                    color="gray"
                    >{{ $t("gbl-cancel") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import guestsManager from "../../apis/guests";
import config from "../../config";

export default {
  data() {
    return {
      guest: null,
      editingGuest: false,
      tab: "tab-1",
      interview_date_modal: false,
      interview_date: null,
      fetchingMetadata: false,
      isLoadingFirstcalls: false,
      isLoadingOperators: false,
      isLoadingCoordinators: false,
      isLoadingSpeakers: false,
      firstcalls: [],
      operators: [],
      coordinators: [],
      speakers: [],
      searchFirstcallInput: null,
      searchOperatorInput: null,
      searchCoordinatorInput: null,
      searchSpeakerInput: null,
      firstcall: null,
      operator: null,
      speaker: null,
      coordinator: null,
      guest_firstcall: null,
      guest_operator: null,
      guest_speaker: null,
      guest_coordinator: null,
      ref: null,
      eventEmitted: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: [
    "value",
    "vcoutcomes",
    "livesoutcomes",
    "regions",
    "radios",
    "territories_radios",
    "locales",
    "types",
    "programs",
    "categories",
    "users",
    "filtered_users",
    "schedulers",
  ],

  mounted() {},

  computed: {
    interview_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.interview_date);
      },
      set: function(v) {
        this.interview_date = v;
      },
    },

    computed_outcomes: {
      get: function() {
        let outcomes = this.vcoutcomes;
        if (this.guest.tipologia == 1) outcomes = this.livesoutcomes;
        outcomes = outcomes.map((x) => {
          if (
            this.guest.data_invio_link_pagamento == "0000-00-00 00:00:00" &&
            x.id == 2
          ) {
            return {
              id: x.id,
              name: x.name,
              disabled: true,
              warning: this.$t("gsteditor-outcome-only-with-sniper"),
            };
          } else {
            return x;
          }
        });
        return outcomes;
      },
    },
  },

  watch: {
    "guest.tipologia": function(newVal, oldVal) {
      if (newVal && oldVal && newVal != oldVal) {
        this.guest.esito = 1;
        this.guest.id_territorio = null;
        this.guest.id_programma = null;
      }
    },

    tab() {
      //this.applyModalValidation(this.editingGuest);
    },

    searchFirstcallInput(val) {
      clearTimeout(this._searchFirstcallInput);
      if (val) {
        this._searchFirstcallInput = setTimeout(() => {
          this.isLoadingFirstCall = true;
          this.fetchUsers(val)
            .then((res) => {
              this.firstcalls = res.items;
              this.isLoadingFirstCall = false;
            })
            .catch(() => {
              this.isLoadingFirstCall = false;
            });
        }, config.searchInputsTimeout);
      }
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsTimeout);
      }
    },

    searchCoordinatorInput(val) {
      clearTimeout(this._searchCoordinatorInput);
      if (val) {
        this._searchCoordinatorInput = setTimeout(() => {
          this.isLoadingCoordinator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.coordinators = res.items;
              this.isLoadingCoordinator = false;
            })
            .catch(() => {
              this.isLoadingCoordinator = false;
            });
        }, config.searchInputsTimeout);
      }
    },

    searchSpeakerInput(val) {
      clearTimeout(this._searchSpeakerInput);
      if (val) {
        this._searchSpeakerInput = setTimeout(() => {
          this.isLoadingSpeaker = true;
          this.fetchUsers(val)
            .then((res) => {
              this.speakers = res.items;
              this.isLoadingSpeaker = false;
            })
            .catch(() => {
              this.isLoadingSpeaker = false;
            });
        }, config.searchInputsTimeout);
      }
    },
  },

  methods: {
    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: async function() {
      try {
        let res = await this.$refs.obs_1.validate();
        if (!res) this.tab = "tab-1";
        else {
          if (this.$store.state.hasRight("120")) {
            res = await this.$refs.obs_2.validate();
            if (!res) this.tab = "tab-2";
            else {
              res = await this.$refs.obs_3.validate();
              if (!res) this.tab = "tab-3";
              else this.handleSubmit();
            }
          } else {
            this.handleSubmit();
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      // Normalize contents
      if (this.guest_operator) this.guest.operatore = this.guest_operator.id;
      if (this.guest_firstcall) this.guest.firstcall = this.guest_firstcall.id;
      else this.guest.guest_firstcall = 0;
      if (this.guest_speaker) this.guest.speaker = this.guest_speaker.id;
      else this.guest.speaker = 0;
      if (this.guest_coordinator)
        this.guest.coordinamento = this.guest_coordinator.id;
      else this.guest.coordinamento = 0;

      if (this.guest.id_programma == null) this.guest.id_programma = 0;
      if (this.guest.id_radio == null) this.guest.id_radio = 0;
      if (this.guest.id_territorio == null) this.guest.id_territorio = 0;
      if (this.guest.tipologia == null) this.guest.tipologia = 0;
      if (this.guest.regione == null) this.guest.regione = 0;

      this.guest.data_intervista = this.interview_date + " 12:00:00";
      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.$refs.obs_1.reset();
        this.$refs.obs_2.reset();
        this.$refs.obs_3.reset();
        this.eventEmitted = true;
        this.$emit("guestConfigured", {
          editingGuest: this.editingGuest,
          item: this.guest,
          ref: this.ref,
        });
      });
    },

    resetModal() {
      this.guest_operator = null;
      this.guest_firstcall = null;
      this.guest_speaker = null;
      this.guest_coordinator = null;
    },

    fetchMetadata() {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchMetadata(this.guest.ID)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    applyModalValidation(editing) {
      if (editing) {
        requestAnimationFrame(() => {
          if (this.$refs.obs) {
            this.$refs.obs_1.validate();
            this.$refs.obs_2.validate();
            this.$refs.obs_3.validate();
          }
        });
      } else {
        requestAnimationFrame(() => {
          if (this.$refs.obs_1) this.$refs.obs_1.reset();
          if (this.$refs.obs_2) this.$refs.obs_2.reset();
          if (this.$refs.obs_3) this.$refs.obs_3.reset();
        });
      }
    },

    setModalGuest(guest, editing) {
      this.fetchingMetadata = true;
      this.eventEmitted = false;
      this.editingGuest = editing;
      this.tab = "tab-1";
      this.guest = Object.assign({}, guest);
      this.ref = guest;
      if (this.guest.id_programma == 0) this.guest.id_programma = null;
      if (this.guest.id_radio == 0) this.guest.id_radio = null;
      if (this.guest.id_territorio == 0) this.guest.id_territorio = null;
      if (this.guest.tipologia == 0) this.guest.tipologia = null;
      if (this.guest.regione == 0) this.guest.regione = null;


      this.speakers = this.users;
      this.operators = this.users;
      this.coordinators = this.users;
      this.firstcalls = this.users;
      if (this.guest.operatore > 0)
        this.guest_operator = this.filtered_users[this.guest.operatore];
      else this.guest_operator = null;

      if (this.guest.firstcall > 0)
        this.guest_firstcall = this.filtered_users[this.guest.firstcall];
      else this.guest_firstcall = null;

      if (this.guest.speaker > 0)
        this.guest_speaker = this.filtered_users[this.guest.speaker];
      else this.guest_speaker = null;

      if (this.guest.coordinamento > 0)
        this.guest_coordinator = this.filtered_users[this.guest.coordinamento];
      else this.guest_coordinator = null;

      if (isNaN(Date.parse(guest.data_intervista))) {
        this.interview_date = this.$options.filters.toDatePickerDate(
          new Date(Date.now()).toString()
        );
      } else {
        this.interview_date = this.$options.filters.toDatePickerDate(
          this.guest.data_intervista
        );
      }

      if (!this.editingGuest) {
        this.resetModal();
        this.fetchingMetadata = false;
        this.applyModalValidation(editing);
      } else {
        this.fetchMetadata()
          .then((res) => {
            this.guest.metadata = res.metadata;
            this.guest.notes = res.notes;
            this.fetchingMetadata = false;
            this.applyModalValidation(editing);
          })
          .catch((err) => {
            console.log(err);
            this.guest.metadata = {
              nome_intervistato: "N",
              qualifica: "N",
              attivita: "N",
              argomento: "N",
              telefono: "N",
              email: "N",
              indirizzo: "N",
              facebook: "N",
              sito: "N",
              instagram: "N",
              youtube: "N",
            };
            this.guest.notes = { motivo_rifiuto: "", note: "" };
            this.fetchingMetadata = false;
            this.applyModalValidation(editing);
          });
      }
    },
  },
};
</script>
