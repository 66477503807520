<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <messageBox ref="msgBox" />

    <v-dialog
      overlay-opacity="0.6"
      width="800"
      persistent
      :value="moveContactDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-move-contact") }}</v-col
                  >

                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="closeMoveContactDialog()" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-progress-circular
                  v-if="loadingMoveLists"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="light-blue"
                ></v-progress-circular>

                <v-row>
                  <v-col>
                    <v-select
                      dense
                      v-model="moveContactTargetList"
                      outlined
                      item-text="name"
                      item-value="id"
                      :items="moveContactLists"
                      :label="$t('editorial-move-contact-target-list')"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                :disabled="loadingMoveLists || !moveContactTargetList"
                width="120"
                @click="onMoveContactFromDialog()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                :disabled="loadingMoveLists"
                width="120"
                @click="closeMoveContactDialog()"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      width="600"
      persistent
      :value="interviewDatePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="closeDatePickerDialog()" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-date-picker
                      landscape
                      v-model="interviewPickerDate"
                    ></v-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="changeInterviewDate()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="closeDatePickerDialog()"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog
      overlay-opacity="0.6"
      width="600"
      persistent
      :value="recallDatePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="closeRecallDatePickerDialog()" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-date-picker
                      landscape
                      v-model="recallPickerDate"
                    ></v-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn width="120" @click="changeRecallDate()" color="primary">{{
                $t("gbl-ok")
              }}</v-btn>
              <v-btn
                width="120"
                @click="closeRecallDatePickerDialog()"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      width="600"
      persistent
      :value="recallTimePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="closeRecallTimePickerDialog()" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text style="min-height:200px;">
                <v-row>
                  <v-col>
                    <vue-timepicker
                      :minute-interval="5"
                      v-model="recallPickerTime"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn width="120" @click="changeRecallTime()" color="primary">{{
                $t("gbl-ok")
              }}</v-btn>
              <v-btn
                width="120"
                @click="closeRecallTimePickerDialog()"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      width="600"
      persistent
      :value="interviewTimePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-time") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="closeTimePickerDialog()" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <vue-timepicker
                      :minute-interval="5"
                      v-model="interviewPickerTime"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="changeInterviewTime()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="closeTimePickerDialog()"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-target</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    updateMode
                      ? $t("gstoutcome-edit-title")
                      : $t("gstoutcome-title")
                  }}</v-col
                >
                <v-col cols="1" class="text-right pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-form ref="gstoutcome-editor-form">
                <div style="height:10px;" />
                <v-row v-if="processing">
                  <v-col>
                    <v-progress-circular
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                  </v-col>
                </v-row>

                <v-row
                  v-if="!processing"
                  dense
                  class="text-center"
                  align="start"
                  justify="center"
                >
                  <v-col>
                    <v-select
                      dense
                      v-model="contact.nation_id"
                      outlined
                      item-text="name"
                      item-value="id"
                      :items="nations"
                      :label="$t('scraper-import-prefs-nation')"
                      :disabled="fetchingNations"
                      hide-details
                    />
                    <v-progress-circular
                      v-if="fetchingNations"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                  </v-col>
                  <v-col>
                    <v-select
                      dense
                      v-model="contact.region_id"
                      outlined
                      item-text="name"
                      item-value="id"
                      :items="regions"
                      :label="$t('scraper-import-prefs-region')"
                      :disabled="fetchingRegions"
                      hide-details
                    />
                    <v-progress-circular
                      v-if="fetchingRegions"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                  </v-col>
                </v-row>

                <v-row
                  v-if="!processing"
                  dense
                  class="text-center"
                  align="start"
                  justify="center"
                >
                  <v-col>
                    <v-select
                      dense
                      v-model="contact.province_id"
                      outlined
                      item-text="name"
                      item-value="id"
                      :items="provinces"
                      :label="$t('scraper-import-prefs-province')"
                      :disabled="fetchingProvinces"
                      hide-details
                    />
                    <v-progress-circular
                      v-if="fetchingProvinces"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                  </v-col>

                  <v-col>
                    <v-select
                      dense
                      v-model="contact.town_id"
                      outlined
                      item-text="name"
                      item-value="id"
                      :items="towns"
                      :label="$t('scraper-import-prefs-city')"
                      :disabled="fetchingTowns"
                      hide-details
                    />
                    <v-progress-circular
                      v-if="fetchingTowns"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                  </v-col>
                </v-row>

                <v-row
                  v-if="!processing"
                  dense
                  class="text-center"
                  align="start"
                  justify="center"
                >
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-text-field
                          :label="$t('editorial-outcome-hint-search-category')"
                          outlined
                          hide-details
                          dense
                          v-model="categoryFilter"
                        />
                      </v-col>
                      <v-col>
                        <v-select
                          dense
                          v-model="contact.category_id"
                          outlined
                          item-text="name"
                          item-value="id"
                          :items="categories"
                          :label="$t('scraper-import-prefs-category')"
                          :disabled="fetchingCategories"
                          hide-details
                        />
                        <v-progress-circular
                          v-if="fetchingCategories"
                          :indeterminate="true"
                          :rotate="0"
                          :size="32"
                          :width="4"
                          color="light-blue"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense v-if="!processing">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        hide-details
                        :label="$t('editorial-outcome-hint-name')"
                        outlined
                        dense
                        v-model="contact.name"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense v-if="!processing">
                  <v-col>
                    <v-text-field
                      hide-details
                      :label="$t('editorial-outcome-hint-business-name')"
                      outlined
                      dense
                      v-model="contact.business_name"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      hide-details
                      :label="$t('editorial-outcome-hint-url')"
                      outlined
                      dense
                      v-model="contact.url"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="!processing">
                  <v-col>
                    {{
                      $t("editorial-outcome-hint-number-format") +
                        currentPrefix +
                        "XXXXXXX"
                    }}
                  </v-col>
                </v-row>
                <v-row align="center" dense v-if="!processing">
                  <v-col>
                    <v-text-field
                      :label="$t('editorial-outcome-hint-phone')"
                      outlined
                      dense
                      v-model="contact.contact_phone"
                    />
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      :rules="notRequiredPhone"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        :label="$t('editorial-outcome-hint-additional-phone')"
                        outlined
                        dense
                        v-model="contact.additional_phone"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense v-if="!processing">
                  <v-col>
                    <ValidationProvider
                      :rules="mailRules"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        hide-details
                        :label="$t('editorial-outcome-email')"
                        outlined
                        dense
                        v-model="contact.email"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense v-if="!processing && !updateMode">
                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('editorial-outcome-hint-outcome')"
                      outlined
                      dense
                      v-model="contact.outcome"
                      prepend-icon="mdi-target"
                      item-text="name"
                      item-value="id"
                      :items="outcomes"
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row
                  dense
                  v-if="
                    !processing &&
                      !updateMode &&
                      suboutcomes &&
                      suboutcomes.length > 0
                  "
                >
                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('editorial-outcome-hint-outcome-sub')"
                      outlined
                      dense
                      v-model="contact.suboutcome"
                      item-text="name"
                      item-value="id"
                      :items="suboutcomes"
                      return-object
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('editorial-outcome-hint-outcome-priority')"
                      outlined
                      dense
                      v-model="contact.priority"
                      item-text="name"
                      item-value="id"
                      :items="priorities"
                    />
                  </v-col>
                </v-row>

                <v-row dense v-if="!processing && !updateMode">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        hide-details
                        :label="$t('editorial-outcome-radio')"
                        outlined
                        dense
                        v-model="contact.id_radio"
                        item-text="name"
                        item-value="id"
                        :items="radios"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        hide-details
                        :label="$t('editorial-outcome-program')"
                        outlined
                        dense
                        v-model="contact.id_program"
                        item-text="name"
                        item-value="id"
                        :items="programs"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        hide-details
                        :label="$t('editorial-outcome-territory')"
                        outlined
                        dense
                        v-model="contact.id_territory"
                        item-text="name"
                        item-value="id"
                        :items="territories_radios"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row
                  dense
                  v-if="
                    !processing && !updateMode && contact.outcome.action == 1
                  "
                >
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        hide-details
                        :label="$t('editorial-outcome-type')"
                        outlined
                        dense
                        v-model="contact.id_type"
                        item-text="name"
                        item-value="id"
                        :items="types"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        hide-details
                        :label="$t('editorial-outcome-interview-date')"
                        outlined
                        dense
                        v-model="contact.interview_date"
                        readonly
                        :error-messages="errors"
                        :success="valid"
                        append-icon="mdi-calendar"
                        @click:append="setInterviewDate()"
                      ></v-text-field
                    ></ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        hide-details
                        :label="$t('editorial-outcome-interview-time')"
                        outlined
                        dense
                        v-model="contact.interview_time"
                        readonly
                        :error-messages="errors"
                        :success="valid"
                        append-icon="mdi-clock"
                        @click:append="setInterviewTime()"
                      ></v-text-field
                    ></ValidationProvider>
                  </v-col>
                </v-row>

                <v-row
                  dense
                  v-if="
                    !processing && !updateMode && contact.outcome.action == 3
                  "
                >
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        hide-details
                        :label="$t('editorial-outcome-recall-date')"
                        outlined
                        dense
                        v-model="recall_date"
                        readonly
                        append-icon="mdi-calendar"
                        @click:append="setRecallDate()"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        hide-details
                        :label="$t('editorial-outcome-recall-time')"
                        outlined
                        dense
                        v-model="recall_time"
                        readonly
                        append-icon="mdi-clock"
                        @click:append="setRecallTime()"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense v-if="!processing && !updateMode">
                  <v-col>
                    <v-btn
                      :disabled="!$store.state.hasRight('181')"
                      v-on:click="sendWA()"
                      color="primary"
                      >{{ $t("editorial-send-wa") }}</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row v-if="!processing && !updateMode">
                  <v-col>
                    <v-textarea
                      v-model="notes"
                      :label="$t('editorial-outcome-notes-hint')"
                      outlined
                      rows="4"
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row dense v-if="!processing && !updateMode">
                  <v-col>
                    {{ $t("outcome-editorial-call-quality")
                    }}<v-rating length="5" v-model="rating"></v-rating>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      v-if="!checkingPhones"
                      :disabled="
                        processing || !isConfirmable || invalid || !validated
                      "
                      width="120"
                      v-on:click="confirm()"
                      color="cyan"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn
                      v-if="!checkingPhones"
                      :disabled="processing"
                      width="120"
                      v-on:click="cancel()"
                      color="gray"
                      >{{ $t("gbl-cancel") }}</v-btn
                    >
                    <v-btn
                      v-if="!checkingPhones && $store.state.hasRight('168')"
                      :disabled="processing || !attributesChanged"
                      width="120"
                      v-on:click="relocate()"
                      color="orange"
                      >{{ $t("editorial-outcome-btn-relocate") }}</v-btn
                    >

                    <v-btn
                      v-if="!checkingPhones"
                      :disabled="processing || true"
                      width="120"
                      v-on:click="move()"
                      color="orange"
                      >{{ $t("editorial-outcome-btn-move") }}</v-btn
                    >

                    <v-progress-circular
                      v-if="checkingPhones"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorialManager from "../../apis/editorial.js";
import messageBox from "../../components/MessageBox";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Vue from "vue";
import VueTimepicker from "vue2-timepicker";
import editorial from "../../apis/editorial";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  data() {
    return {
      currentPrefix: "+39",
      fetchingProvinces: false,
      fetchingRegions: false,
      fetchingNations: false,
      fetchingTowns: false,
      fetchingCategories: false,
      disableWatchers: true,
      contact: {},
      recall_date: null,
      recall_time: null,
      notes: null,
      rating: 0,
      outcomes: [],
      processing: false,
      checkingPhones: false,
      suboutcomes: [],
      priorities: [
        { id: 1, name: "Bassa" },
        { id: 2, name: "Media" },
        { id: 3, name: "Alta" }
      ],
      nations: [],
      regions: [],
      provinces: [],
      towns: [],
      categories: [],
      attributesChanged: false,
      interviewDatePickerDialog: false,
      interviewPickerDate: new Date().toISOString().substr(0, 10),
      recallPickerDate: new Date().toISOString().substr(0, 10),
      recallDatePickerDialog: false,
      recallPickerTime: "08:00",
      recallTimePickerDialog: false,
      interviewTimePickerDialog: false,
      interviewPickerTime: "08:00",
      moveContactDialog: false,
      moveContactTargetList: null,
      moveContactLists: [],
      loadingMoveLists: false,
      categoryFilter: null
    };
  },

  props: [
    "value",
    "radios",
    "types",
    "programs",
    "territories_radios",
    "prefills",
    "updateMode"
  ],

  components: {
    messageBox,
    ValidationProvider,
    ValidationObserver,
    VueTimepicker
  },

  watch: {
    categoryFilter: {
      handler() {
        this.fetchCategories(this.contact.nation_id);
      }
    },
    "contact.outcome.id": {
      handler() {
        this.updateOutcomeDetails();
      },

      deep: true
    },

    "contact.category_id": {
      handler() {
        if (!this.disableWatchers) {
          this.attributesChanged = true;
        }
      }
    },

    "contact.nation_id": {
      handler() {
        if (!this.disableWatchers) {
          this.disableWatchers = true;
          this.attributesChanged = true;
          this.contact.region_id = null;
          this.contact.town_id = null;
          this.contact.province_id = null;
          this.contact.category_id = null;
          this.fetchRegions(this.contact.nation_id).then(() => {
            this.fetchPrefixes(this.contact.nation_id).then(() => {
              this.fetchCategories(this.contact.nation_id).then(() => {
                this.disableWatchers = false;
              });
            });
          });
        }
      }
    },

    "contact.region_id": {
      handler() {
        if (!this.disableWatchers) {
          this.disableWatchers = true;
          this.attributesChanged = true;
          this.contact.province_id = null;
          if (this.contact.region_id) {
            this.fetchProvinces(
              this.contact.nation_id,
              this.contact.region_id
            ).then(() => {
              this.disableWatchers = false;
            });
          } else {
            this.disableWatchers = false;
          }
        }
      }
    },

    "contact.province_id": {
      handler() {
        if (!this.disableWatchers) {
          this.contact.town_id = null;
          this.attributesChanged = true;
          this.disableWatchers = true;
          if (this.contact.region_id && this.contact.province_id) {
            this.fetchTowns(
              this.contact.nation_id,
              this.contact.region_id,
              this.contact.province_id
            ).then(() => {
              this.disableWatchers = false;
            });
          } else {
            this.disableWatchers = false;
          }
        }
      }
    },

    "contact.town_id": {
      handler() {
        if (!this.disableWatchers) {
          this.attributesChanged = true;
        }
      }
    }
  },

  computed: {
    mailRules() {
      if (
        (this.contact.outcome && this.contact.outcome.mandatory_email == 1) ||
        this.contact.outcome.id == 8 ||
        this.contact.outcome.id == 9
      )
        return {
          required: true,
          email: true
        };
      else
        return {
          email: true
        };
    },

    requiredPhone() {
      return {
        required: true,
        min: 8,
        regex: "^\\" + this.currentPrefix + "\\d{8,12}$"
      };
    },

    notRequiredPhone() {
      return {
        min: 8,
        regex: "^\\" + this.currentPrefix + "\\d{8,12}$"
      };
    },

    isConfirmable() {
      if (this.updateMode) return true;
      if (!this.contact.outcome) return false;
      if (this.contact.outcome.action == 1) {
        return (
          this.contact.id_radio &&
          this.contact.id_program &&
          this.contact.id_territory &&
          this.contact.id_type
        );
      } else {
        return true;
      }
    }
  },

  methods: {
    sendWA() {
      console.log(this.contact);
      let item = this.contact;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "editorial-open-wa",
        item.contact_phone
      );
      editorial
        .logWhatsapp(item.id)
        .then(() => {
          const link = document.createElement("a");
          link.setAttribute(
            "href",
            "https://api.whatsapp.com/send?phone=" +
              encodeURIComponent(item.contact_phone)
          );
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          this.$store.state.global_overlay = false;
          link.remove();
        })

        .catch(err => {
          this.fetchingArchiveContacts = false;
          if (err) console.log(err);
        });
    },

    fetchMoveLists() {
      this.loadingMoveLists = true;
      editorialManager
        .fetchMoveListsNames()
        .then(res => {
          this.loadingMoveLists = false;
          this.moveContactLists = res;
        })
        .catch(err => {
          console.log(err);
        });
    },

    move() {
      this.fetchMoveLists();
      this.moveContactDialog = true;
    },

    closeMoveContactDialog() {
      this.moveContactDialog = false;
    },

    onMoveContactFromDialog() {
      editorialManager
        .moveContact(this.contact.id, this.moveContactTargetList)
        .then(() => {
          this.moveContactDialog = false;
          this.$emit("contactMoved", {
            item: this.contact
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    setInterviewDate() {
      this.interviewDatePickerDialog = true;
    },

    setRecallDate() {
      this.recall_date = null;
      this.recallDatePickerDialog = true;
    },

    setRecallTime() {
      this.recall_time = "08:00";
      this.recallTimePickerDialog = true;
    },

    setInterviewTime() {
      this.interviewPickerTime = "08:00";
      this.interviewTimePickerDialog = true;
    },

    changeInterviewTime() {
      this.interviewTimePickerDialog = false;
      this.contact.interview_time = this.interviewPickerTime;
    },

    changeInterviewDate() {
      this.interviewDatePickerDialog = false;
      this.contact.interview_date = this.interviewPickerDate;
    },

    changeRecallDate() {
      this.recallDatePickerDialog = false;
      this.recall_date = this.recallPickerDate;
    },

    changeRecallTime() {
      this.recallTimePickerDialog = false;
      this.recall_time = this.recallPickerTime;
    },

    closeRecallDatePickerDialog() {
      this.recallDatePickerDialog = false;
    },

    closeRecallTimePickerDialog() {
      this.recallTimePickerDialog = false;
    },

    closeDatePickerDialog() {
      this.interviewDatePickerDialog = false;
    },

    closeTimePickerDialog() {
      this.interviewTimePickerDialog = false;
    },

    relocate() {
      this.$refs.msgBox
        .show(
          this.$t("editorial-outcome-relocate-text"),
          this.$t("editorial-outcome-relocate-title")
        )
        .then(() => {
          this.$emit("contactRelocated", {
            item: this.contact
          });
        })
        .catch(() => {});
    },

    fetchCategories(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingCategories = true;
        editorialManager
          .fetchCategories(nation_id, this.categoryFilter)
          .then(categories => {
            this.fetchingCategories = false;
            this.categories = categories;
            resolve();
          })
          .catch(err => {
            this.fetchingCategories = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchNations() {
      return new Promise((resolve, reject) => {
        this.fetchingNations = true;
        this.regions = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        this.provinces = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        this.towns = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        editorialManager
          .fetchNations()
          .then(nations => {
            this.fetchingNations = false;
            this.nations = nations.map(x => {
              x.name = this.$t(x.name);
              return x;
            });
            resolve();
          })
          .catch(err => {
            this.fetchingNations = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchPrefixes(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingPrefixes = true;

        editorialManager
          .fetchPrefixes(nation_id)
          .then(res => {
            this.currentPrefix = res[0].prefix;
            this.fetchingPrefixes = false;
            resolve();
          })
          .catch(err => {
            this.fetchingPrefixes = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingRegions = true;
        this.regions = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        this.provinces = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        this.towns = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];

        editorialManager
          .fetchRegions(nation_id)
          .then(regions => {
            this.fetchingRegions = false;
            this.regions = [
              { id: null, name: this.$t("gbl-none-female"), disabled: true },
              ...regions
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingRegions = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchProvinces(nation_id, region_id) {
      return new Promise((resolve, reject) => {
        this.fetchingProvinces = true;
        this.provinces = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        this.towns = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        editorialManager
          .fetchProvinces(nation_id, region_id)
          .then(provinces => {
            this.fetchingProvinces = false;
            this.provinces = [
              { id: null, name: this.$t("gbl-none-female"), disabled: true },
              ...provinces
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingProvinces = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchTowns(nation_id, region_id, province_id) {
      return new Promise((resolve, reject) => {
        this.fetchingTowns = true;
        this.towns = [
          { id: null, name: this.$t("gbl-none-female"), disabled: true }
        ];
        editorialManager
          .fetchTowns(nation_id, province_id)
          .then(towns => {
            this.fetchingTowns = false;
            this.towns = [
              { id: null, name: this.$t("gbl-none-female"), disabled: true },
              ...towns
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingTowns = false;
            console.log(err);
            reject(err);
          });
      });
    },

    updateOutcomeDetails() {
      if (this.contact.outcome) this.suboutcomes = this.contact.outcome.sub;
      if (this.suboutcomes) this.contact.suboutcome = this.suboutcomes[0];
      this.contact.recall_date = null;
      this.contact.recall_time = null;
      this.contact.interview_time = null;
      this.contact.interview_date = null;
      Vue.nextTick(() => {
        if (this.$refs.obs) this.$refs.obs.validate();
      });
    },

    confirm: function() {
      this.handleSubmit(false);
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      callback(true);
    },

    handleSubmit(force) {
      this.checkFormValidity(res => {
        if (!res) return false;
        this.checkingPhones = true;
        editorialManager
          .checkPhones({
            force: force,
            id: this.contact.id,
            list_id: this.contact.list_id,
            nation_id: this.contact.nation_id,
            primary: this.contact.contact_phone,
            secondary: this.contact.additional_phone
          })
          .then(res => {
            this.checkingPhones = false;
            if (res.valid) {
              if (res.swap) {
                let swap = this.contact.contact_phone;
                this.contact.contact_phone = this.contact.additional_phone;
                this.contact.additional_phone = swap;
              }
              if (!this.updateMode) {
                this.processing = true;
                this.contact.recall_date = this.recall_date;
                this.contact.recall_time = this.recall_time;
                this.contact.notes = this.notes;
                this.contact.rating = this.rating;
                this.$emit("outcomeConfigured", {
                  item: this.contact,
                  swap: res.swap
                });
              } else {
                this.$emit("contactUpdated", {
                  item: this.contact
                });
              }
            } else {
              // Phones error
              this.$refs.msgBox
                .show(
                  this.$t("editorial-outcome-phone-exist-text"),
                  this.$t("editorial-outcome-phone-exist-title")
                )
                .then(() => {
                  this.handleSubmit(true);
                })
                .catch(() => {});

              this.checkingPhones = false;
            }
          })
          .catch(err => {
            this.checkingPhones = false;
            console.log(err);
          });
      });
    },

    setModalContact(outcomes, contact) {
      return new Promise((resolve, reject) => {
        this.recall_date = null;
        this.recall_time = null;
        this.notes = contact.notes;
        this.outcomes = [...outcomes];
        for (let x = 0; x < this.outcomes.length; x++) {
          let outcome = this.outcomes[x];
          if (outcome.sub)
            outcome.sub = outcome.sub.filter(x => {
              return x.enabled;
            });
        }

        this.outcomes = this.outcomes.filter(x => {
          return x.enabled;
        });
        this.processing = true;
        this.disableWatchers = true;
        this.attributesChanged = false;
        this.rating = 0;

        contact.outcome = this.outcomes[0];
        contact.suboutcome = null;

        this.contact = Object.assign({}, contact);
        this.contact.priority = this.priorities[0].id;
        if (this.contact.town_id == 0) this.contact.town_id = null;
        if (this.contact.region_id == 0) this.contact.region_id = null;
        if (this.contact.province_id == 0) this.contact.province_id = null;
        if (this.contact.category_id == 0) this.contact.category_id = null;

        if (!this.contact.nation_id) this.contact.nation_id = 86;

        if (this.prefills.prefill_editorial_type) {
          this.contact.id_type = this.prefills.prefill_editorial_type;
        }

        if (this.prefills.prefill_editorial_radio) {
          this.contact.id_radio = this.prefills.prefill_editorial_radio;
        }

        if (this.prefills.prefill_editorial_program) {
          this.contact.id_program = this.prefills.prefill_editorial_program;
        }

        if (this.prefills.prefill_editorial_territories) {
          this.contact.id_territory = this.prefills.prefill_editorial_territories;
        }

        this.updateOutcomeDetails();

        this.fetchCategories(this.contact.nation_id)
          .then(() =>
            this.fetchNations()
              .then(() => {
                this.fetchRegions(this.contact.nation_id).then(() => {
                  this.fetchPrefixes(this.contact.nation_id)
                    .then(() => {
                      if (this.contact.region_id) {
                        this.fetchProvinces(
                          this.contact.nation_id,
                          this.contact.region_id
                        )
                          .then(() => {
                            if (this.contact.province_id) {
                              this.fetchTowns(
                                this.contact.nation_id,
                                this.contact.region_id,
                                this.contact.province_id
                              )
                                .then(() => {
                                  this.disableWatchers = false;
                                  this.processing = false;
                                  resolve();
                                })
                                .catch(err => {
                                  this.disableWatchers = false;
                                  this.processing = false;
                                  reject(err);
                                });
                            } else {
                              this.disableWatchers = false;
                              this.processing = false;
                              resolve();
                            }
                          })
                          .catch(err => {
                            this.disableWatchers = false;
                            this.processing = false;
                            reject(err);
                          });
                      } else {
                        this.disableWatchers = false;
                        this.processing = false;
                        resolve();
                      }
                    })
                    .catch(err => {
                      this.disableWatchers = false;
                      this.processing = false;
                      reject(err);
                    });
                });
              })
              .catch(err => {
                this.disableWatchers = false;
                this.processing = false;
                reject(err);
              })
          )
          .catch(err => {
            this.disableWatchers = false;
            this.processing = false;
            reject(err);
          });
      });
    }
  }
};
</script>

<style scoped>
.outcome-pane {
  background-color: #dddddd;
  border: 2px solid green;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background-color: #0d9fea;
}

.vue__time-picker {
  font-size: 20px;
}
</style>
