var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('BlacklistEditor',{ref:"addBlackListDialog",on:{"blacklistConfigured":_vm.onBlackListConfigured},model:{value:(_vm.addBlackListDialog),callback:function ($$v) {_vm.addBlackListDialog=$$v},expression:"addBlackListDialog"}}),_c('v-card',{staticClass:"ma-1"},[_c('v-toolbar',{attrs:{"color":"#0044a3","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-tie-voice-off-outline")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("editorial-blacklists-title")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("editorial-blacklists-title")))]),_c('v-spacer'),(_vm.$store.state.hasRight('164'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addBlacklist()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.blackLists,"options":_vm.options,"server-items-length":_vm.totalBlackLists,"loading":_vm.fetchingBlackLists,"footer-props":_vm.dataTableFooterOptions,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteBlacklist(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("editorial-lists-archive")))])])]}},{key:"item.category_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category_name ? item.category_name : _vm.$t("gbl-none-female"))+" ")]}},{key:"item.list_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.list_name ? item.list_name : _vm.$t("gbl-all-female"))+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }