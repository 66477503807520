<template>
  <div>
    <v-dialog
      overlay-opacity="0.6"
      width="600"
      :value="interviewDatePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-date") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="interviewDatePickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-date-picker
                      landscape
                      v-model="interviewPickerDate"
                    ></v-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmChangeInterviewDate()"
                color="green"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="interviewDatePickerDialog = false"
                color="primary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-dialog
      overlay-opacity="0.6"
      width="600"
      persistent
      :value="interviewTimePickerDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("editorial-change-interview-time") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="interviewTimePickerDialog = false" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row>
                  <v-col>
                    <vue-timepicker
                      :minute-interval="5"
                      v-model="interviewPickerTime"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-btn
                width="120"
                @click="confirmChangeInterviewTime()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                width="120"
                @click="interviewTimePickerDialog = false"
                color="gray"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <VoipAudioPlayer ref="audioPlayerDialog" v-model="audioPlayerDialog" />

    <ChooseEditorialOperator
      @onOperatorChoosen="onOperatorChoosen"
      ref="chooseEditorialOperator"
    />
    <messageBox ref="msgBox" />

    <EditorialOutcomesResume
      ref="outcomesResumeDialog"
      v-model="outcomesResumeDialog"
    />

    <EditorialContactOutcomeEditor
      ref="contactEditor"
      v-model="contactEditorDialog"
      @contactRelocated="onContactRelocated"
      @contactMoved="onContactMoved"
      @contactUpdated="onContactUpdated"
      :updateMode="true"
      :radios="radios"
      :types="types"
      :programs="$store.state.programs"
      :territories_radios="$store.state.territories_radios"
      :prefills="[]"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-cellphone-sound</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("editorial-manage-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row v-if="fetchingOutcomes || fetchingData">
          <v-col>
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-card v-if="!fetchingOutcomes && !fetchingData" class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("editorial-manage-contacts")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row dense justify="center">
              <v-col class="text-center">
                <v-text-field
                  :disabled="fetchingContacts"
                  :value="filter"
                  @change="v => (filter = v)"
                  append-icon="mdi-magnify"
                  :label="$t('gbl-search')"
                  hide-details
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col class="text-center">
                <v-select
                  :disabled="fetchingContacts"
                  dense
                  v-model="filters.outcome"
                  outlined
                  item-text="name"
                  item-value="id"
                  :items="flt_outcomes"
                  :label="$t('editorial-filter-outcomes')"
                  hide-details
                />
              </v-col>

              <v-col class="text-center">
                <v-select
                  :disabled="fetchingContacts"
                  dense
                  v-model="filters.suboutcome"
                  outlined
                  item-text="name"
                  item-value="id"
                  :items="flt_suboutcomes"
                  :label="$t('editorial-filter-suboutcomes')"
                  hide-details
                />
              </v-col>

              <v-col>
                <v-autocomplete
                  :disabled="fetchingContacts"
                  clearable
                  dense
                  v-model="operator"
                  :items="operators"
                  :loading="isLoadingOperator"
                  :search-input.sync="searchOperatorInput"
                  cache-items
                  outlined
                  item-text="name"
                  item-value="id"
                  :label="$t('editorial-filter-operator')"
                  return-object
                  hide-no-data
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col>
                <v-select
                  :label="$t('editorial-filter-datetype')"
                  outlined
                  dense
                  v-model="filters.date_type"
                  item-text="name"
                  item-value="id"
                  :items="date_types"
                  :disabled="fetchingContacts"
                />
              </v-col>

              <v-col>
                <v-select
                  class="minfield"
                  outlined
                  dense
                  :label="$t('filters-period-hint')"
                  ref="period"
                  v-model="filters.filterByPeriod"
                  :items="getPeriodFilters()"
                  item-text="name"
                  item-value="id"
                  :disabled="fetchingContacts"
                >
                </v-select>
              </v-col>

              <v-col>
                <v-dialog
                  ref="dialog_start_date"
                  v-model="date_start_modal"
                  :return-value.sync="filters.filterByCustomDateStart"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      v-model="start_date_formatted"
                      :disabled="
                        fetchingContacts || filters.filterByPeriod != 9
                      "
                      :label="$t('filters-startdate-hint')"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="
                      $refs.dialog_start_date.save(
                        filters.filterByCustomDateStart
                      )
                    "
                    v-model="filters.filterByCustomDateStart"
                    scrollable
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col>
                <v-dialog
                  ref="dialog_end_date"
                  v-model="date_end_modal"
                  :return-value.sync="filters.filterByCustomDateEnd"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      v-model="end_date_formatted"
                      :disabled="
                        fetchingContacts || filters.filterByPeriod != 9
                      "
                      :label="$t('filters-enddate-hint')"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="
                      $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                    "
                    v-model="filters.filterByCustomDateEnd"
                    scrollable
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  :disabled="fetchingData"
                  color="success"
                  @click.stop="downloadExcelFile()"
                  ><v-icon>mdi-file-excel</v-icon
                  >{{ $t("firstcall-stats-export") }}</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" lg="4">
                <base-material-card
                  color="error"
                  icon="mdi-account-multiple"
                  :title="$t('editorial-manage-queue')"
                >
                  <v-card-text>
                    <v-divider />
                    <v-container>
                      <v-row>
                        <v-col>
                          {{ $t("editorial-manage-todaypendingcalls") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.dailyContacts
                              ? statistics.dailyContacts.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("editorial-manage-pastpendingcalls") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.pastContacts
                              ? statistics.pastContacts.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("editorial-manage-recoverypendingcalls") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.pendingContacts
                              ? statistics.pendingContacts.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </base-material-card>
              </v-col>

              <v-col cols="12" sm="6" lg="4">
                <base-material-card
                  color="warning"
                  icon="mdi-phone"
                  :title="$t('editorial-manage-queue')"
                >
                  <v-card-text>
                    <v-divider />
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col>
                              {{ $t("editorial-manage-todayoutcomes") }}
                            </v-col>
                            <v-col>
                              {{
                                statistics.dailyCalls != null
                                  ? statistics.dailyCalls.toString()
                                  : "..."
                              }}
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              {{ $t("editorial-manage-pastpendingoutcomes") }}
                            </v-col>
                            <v-col>
                              {{
                                statistics.pastCalls != null
                                  ? statistics.pastCalls.toString()
                                  : "..."
                              }}
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              {{ $t("editorial-manage-todayoutcomes-nr") }}
                            </v-col>
                            <v-col>
                              {{
                                statistics.todaynr != null
                                  ? statistics.todaynr.toString()
                                  : "..."
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-col>
                              {{
                                $t("editorial-manage-pastpendingoutcomes-nr")
                              }}
                            </v-col>
                            <v-col>
                              {{
                                statistics.pastnr != null
                                  ? statistics.pastnr.toString()
                                  : "..."
                              }}
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              {{ $t("editorial-manage-no-appointment") }}
                            </v-col>
                            <v-col>
                              {{
                                statistics.noappointments != null
                                  ? statistics.noappointments.toString()
                                  : "..."
                              }}
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              {{ $t("editorial-manage-appointment") }}
                            </v-col>
                            <v-col>
                              {{
                                statistics.appointments != null
                                  ? statistics.appointments.toString()
                                  : "..."
                              }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </base-material-card>
              </v-col>

              <v-col cols="12" sm="6" lg="4">
                <base-material-stats-card
                  color="green"
                  icon="mdi-phone"
                  :title="$t('editorial-manage-global-queue-voip-time')"
                  :value="
                    this.statistics.totalVoip
                      ? formatElapsed(this.statistics.totalVoip)
                      : '...'
                  "
                  sub-icon="mdi-calendar"
                  :sub-text="$t('editorial-resume-daterange')"
                />

                <base-material-stats-card
                  color="green"
                  icon="mdi-account-group"
                  :title="$t('editorial-manage-global-queue-processed')"
                  :value="
                    this.statistics.queueProcessed
                      ? this.statistics.queueProcessed.toString()
                      : '...'
                  "
                  sub-icon="mdi-calendar"
                  :sub-text="$t('editorial-resume-daterange')"
                />

                <base-material-stats-card
                  color="orange"
                  icon="mdi-account-group"
                  :title="$t('editorial-manage-global-queue-available')"
                  :value="
                    this.statistics.queueAvailable
                      ? this.statistics.queueAvailable.toString()
                      : '...'
                  "
                  sub-icon="mdi-calendar"
                  :sub-text="$t('editorial-resume-daterange')"
                />
              </v-col>
            </v-row>

            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="contacts"
              :options.sync="options"
              :server-items-length="totalContacts"
              :loading="fetchingContacts"
              :footer-props="dataTableFooterOptions"
              item-key="outcome_id"
            >
              <template v-slot:item.operator="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-icon v-if="item.operator_name" color="cyan" v-on="on"
                      >mdi-information-outline</v-icon
                    > </template
                  ><span>{{ item.operator_name }}</span></v-tooltip
                >
                {{ item.operator_code }}
              </template>
              <template v-slot:item.voip_call="{ item }">
                <span v-if="item.voip_call_id">
                  <v-tooltip v-if="item.voip_call_id" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="listenVoipCall(item)"
                        v-on="on"
                        ><v-icon color="green">mdi-phone</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-manage-voip-listen")
                    }}</span></v-tooltip
                  >{{ formatElapsed(item.voip_call_duration) }}</span
                >
                <v-tooltip v-if="!item.voip_call_id" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-icon v-on="on" color="red"
                      >mdi-alert-outline</v-icon
                    ></template
                  ><span>{{
                    $t("editorial-manage-voip-missing")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>

              <template v-slot:item.recall_date="{ item }">
                {{ item.recall_date | toLocaleDate }}
              </template>

              <template v-slot:item.interview_date="{ item }">
                {{ item.interview_date | toLocaleDate }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewDate(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-date")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.interview_time="{ item }">
                {{
                  item.last_outcome_id == 1
                    ? $options.filters.toLocaleTime(item.interview_time)
                    : ""
                }}
                <v-tooltip v-if="item.last_outcome_id == 1" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeInterviewTime(item)"
                      v-on="on"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-change-interview-time")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.guest_id="{ item }">
                <router-link
                  v-if="item.guest_id"
                  :to="'/guests?id=' + item.guest_id"
                >
                  OSP-{{ item.guest_id }}
                </router-link>
              </template>

              <template v-slot:item.assignment_day="{ item }">
                {{ item.assignment_day | toLocaleDate }}
              </template>

              <template v-slot:item.assignment_time="{ item }">
                {{ item.assignment_time | toLocaleTime }}
              </template>

              <template v-slot:item.outcome_date="{ item }">
                {{ item.outcome_date | toLocaleDate }}
              </template>

              <template v-slot:item.outcome_time="{ item }">
                {{ item.outcome_time | toLocaleTime }}
              </template>

              <template v-slot:item.url="{ item }">
                <v-tooltip v-if="item.url" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="success"
                      @click.stop="openLink(item)"
                      v-on="on"
                      ><v-icon>mdi-link</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("editorial-contacts-tbl-action-open")
                  }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>

              <template v-slot:item.last_outcome="{ item }">
                <span class="font-weight-bold"
                  >{{ $t(item.last_outcome) }}<br />
                  <span class="font-weight-light">{{
                    $t(item.last_suboutcome)
                  }}</span></span
                >
              </template>

              <template v-slot:guest_outcome="{ item }">
                {{ item.esito }}
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:120px">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="editContact(item)"
                        :disabled="!$store.state.hasRight('185')"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-edit-contact") }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="
                      $store.state.hasRight('183') &&
                        !item.missing_guest &&
                        item.last_outcome_id == 1 &&
                        item.guest_outcome == 0
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        v-on="on"
                        color="red"
                        @click.stop="missingContact(item)"
                        ><v-icon>mdi-account-remove-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-action-missing-guest")
                    }}</span></v-tooltip
                  >
                  <v-tooltip
                    v-if="
                      $store.state.hasRight('184') &&
                        item.missing_guest &&
                        item.last_outcome_id == 1 &&
                        item.guest_outcome == 0
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        v-on="on"
                        color="green"
                        @click.stop="recoverContact(item)"
                        ><v-icon>mdi-account-reactivate</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("editorial-action-recover-guest")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('168')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        v-on="on"
                        color="purple"
                        @click.stop="reattachAssignment(item)"
                        ><v-icon>mdi-pipe</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-action-attach") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('169')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        v-on="on"
                        color="red"
                        @click.stop="detachAssignment(item)"
                        ><v-icon>mdi-pipe-disconnected</v-icon></v-btn
                      ></template
                    ><span>{{ $t("editorial-action-detach") }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import config from "../config";
import staticData from "../apis/static";
import editorial from "../apis/editorial";
import EditorialOutcomesResume from "../components/editorial/EditorialOutcomesResume";
import EditorialContactOutcomeEditor from "../components/editorial/EditorialContactOutcomeEditor";
import VoipAudioPlayer from "../components/recorder/VoipAudioPlayer";
import ChooseEditorialOperator from "../components/backend/ChooseEditorialOperator";
import messageBox from "../components/MessageBox";
import moment from "moment";
import duration from "moment-duration-format";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

duration(moment);

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      contacts: [],
      fetchingContacts: false,
      totalContacts: 0,
      perPage: staticData.defaultPerPage,
      ftl_schedulers: [],
      flt_radios: [],

      options: {
        sortBy: ["assignment_day"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1
      },

      statistics: {
        dailyContacts: "...",
        pastContacts: "...",
        pendingContacts: "...",

        dailyCalls: "...",
        pastCalls: "...",

        queueProcessed: "...",
        queueAvailable: "...",
        totalVoip : null
      },

      filter: null,

      filters: {
        operator: null,
        date_type: 0,
        outcome: null,
        suboutcome: null,
        filterByPeriod: 11
      },

      date_types: [
        { id: 0, name: this.$t("editorial-filter-date-outcome") },
        { id: 2, name: this.$t("editorial-filter-date-assignment") }
      ],

      isLoadingOperator: false,

      operator: null,
      operators: [],
      searchOperatorInput: null,

      categories: [],
      radios: [],
      types: [],
      programs: [],
      contactEditorDialog: false,
      headers: [],
      users: [],
      flt_users: {},
      flt_outcomes: [],
      flt_suboutcomes: [{ id: null, name: this.$t("gbl-all-male") }],
      fetchingData: false,
      fetchingOutcomes: true,
      audioPlayerDialog: false,
      outcomesResumeDialog: false,

      date_start_modal: false,
      date_end_modal: false,

      def_outcomes: [],
      def_outcomes_by_id: {},
      list_outcomes: [],

      interviewDatePickerDialog: false,
      interviewPickerDate: new Date().toISOString().substr(0, 10),
      interviewDatePickerItem: null,
      interviewTimePickerDialog: false,
      interviewPickerTime: "00:00",
      interviewTimePickerItem: null
    };
  },

  components: {
    messageBox,
    EditorialOutcomesResume,
    EditorialContactOutcomeEditor,
    ChooseEditorialOperator,
    VoipAudioPlayer,
    VueTimepicker
  },

  destroyed() {},

  mounted() {
    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios
    ];
    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers
    ];

    this.headers = [
      {
        text: this.$t("editorial-tbl-code"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("editorial-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },

      {
        text: this.$t("editorial-tbl-operator"),
        align: "left",
        sortable: true,
        value: "operator"
      },
      {
        text: this.$t("editorial-tbl-assignment-date"),
        align: "left",
        sortable: true,
        value: "assignment_day"
      },
      {
        text: this.$t("editorial-tbl-assignment-time"),
        align: "left",
        sortable: true,
        value: "assignment_time"
      },
      {
        text: this.$t("editorial-tbl-outcome-date"),
        align: "left",
        sortable: true,
        value: "outcome_date"
      },
      {
        text: this.$t("editorial-tbl-outcome-time"),
        align: "left",
        sortable: true,
        value: "outcome_time"
      },

      {
        text: this.$t("editorial-tbl-phone"),
        align: "left",
        sortable: false,
        value: "contact_phone"
      },

      {
        text: "",
        align: "center",
        sortable: true,
        value: "voip_call"
      },
      {
        text: "",
        align: "center",
        sortable: true,
        value: "listen_call"
      },

      {
        text: this.$t("editorial-tbl-outcome-counts"),
        align: "center",
        sortable: true,
        value: "outcomes_count"
      },
      {
        text: this.$t("editorial-tbl-outcome"),
        align: "center",
        sortable: true,
        value: "last_outcome"
      },
      {
        text: this.$t("editorial-tbl-guest-id"),
        align: "center",
        sortable: true,
        value: "guest_id"
      },
      {
        text: this.$t("editorial-tbl-guest-outcome"),
        align: "center",
        sortable: true,
        value: "guest_outcome"
      },

      {
        text: this.$t("editorial-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "interview_date"
      },
      {
        text: this.$t("editorial-tbl-interview-time"),
        align: "center",
        sortable: true,
        value: "interview_time"
      },
      {
        text: this.$t("editorial-tbl-recall-date"),
        align: "center",
        sortable: true,
        value: "recall_date"
      },
      {
        text: this.$t("editorial-tbl-recall-time"),
        align: "center",
        sortable: true,
        value: "recall_time"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    if (this.$store.state.radios) {
      let locked_radios = this.$store.state.user.locked_radio
        ? this.$store.state.user.locked_radio.split(",").map(x => {
            return parseInt(x);
          })
        : [];
      this.radios = [
        ...this.$store.state.radios.filter(x => {
          if (locked_radios.length < 1) return true;
          else return locked_radios.includes(x.id);
        })
      ];
    }

    if (this.$store.state.types) {
      let locked_types = this.$store.state.user.locked_type
        ? this.$store.state.user.locked_type.split(",").map(x => {
            return parseInt(x);
          })
        : [];

      this.types = [
        ...this.$store.state.types.filter(x => {
          if (locked_types.length < 1) return true;
          else return locked_types.includes(x.id);
        })
      ];
    }

    this.fetchingData = true;
    this.fetchUsers()
      .then(res => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map(x => {
          this.flt_users[x.id] = x;
        });
        this.fetchOutcomes()
          .then(() => {
            this.flt_outcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...this.def_outcomes
            ];

            this.fetchingData = false;
          })
          .catch(err => {
            this.fetchingData = false;
            console.log(err);
          });
      })
      .catch(err => {
        this.fetchingData = false;
        console.log(err);
      });
  },

  watch: {
    "filters.outcome": {
      handler() {
        this.filters.suboutcome = null;
        this.flt_suboutcomes = [{ id: null, name: this.$t("gbl-all-male") }];
        if (this.filters.outcome) {
          const outcome = this.def_outcomes_by_id[this.filters.outcome];

          if (outcome.sub) {
            this.flt_suboutcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...outcome.sub.map(x => {
                return { id: x.id, name: x.name };
              })
            ];
          }
        }
      }
    },

    options: {
      handler() {
        this.fetchAssignedContacts();
      },
      deep: true
    },

    filters: {
      handler() {
        this.fetchAssignedContacts();
      },
      deep: true
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true]
        };
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      }
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then(res => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    }
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    }
  },

  methods: {
    formatElapsed(time) {
      if (time) {
        const moment_time = moment.duration(time, "seconds");
        return moment_time.format("d[d] hh:mm:ss");
      } else {
        return null;
      }
    },

    listenVoipCall(item) {
      this.$refs.audioPlayerDialog.setCall({ id: item.voip_call_id });
      this.audioPlayerDialog = true;
    },

    onContactRelocated(item) {
      editorial
        .reassignContact(item)
        .then(() => {
          this.contactEditorDialog = false;
        })
        .catch(err => {
          console.log(err);
          this.contactEditorDialog = false;
        });
    },

    onContactMoved() {
      this.contactEditorDialog = false;
    },

    onContactUpdated(item) {
      editorial
        .updateContact(item)
        .then(() => {
          this.contactEditorDialog = false;
          this.fetchAssignedContacts();
        })
        .catch(err => {
          console.log(err);
          this.contactEditorDialog = false;
        });
    },

    editContact(item) {
      this.$refs.contactEditor.setModalContact([], item).then(() => {
        this.contactEditorDialog = true;
      });
    },

    downloadExcelFile() {
      editorial.downloadExcelStatistics(
        this.$store.state.user.locale,
        this.filters
      );
    },

    confirmChangeInterviewDate() {
      this.interviewDatePickerDialog = false;

      editorial
        .setContactInterviewTime(
          this.interviewDatePickerItem.assignment_id,
          this.interviewDatePickerItem.last_outcome_item_id,
          this.interviewPickerDate,
          this.interviewDatePickerItem.interview_time,
          this.interviewDatePickerItem.guest_id
        )
        .then(() => {
          this.fetchAssignedContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    confirmChangeInterviewTime() {
      this.interviewTimePickerDialog = false;
      editorial
        .setContactInterviewTime(
          this.interviewTimePickerItem.assignment_id,
          this.interviewTimePickerItem.last_outcome_item_id,
          this.$options.filters.toDatePickerDate(
            this.interviewTimePickerItem.interview_date
          ),
          this.interviewPickerTime,
          this.interviewTimePickerItem.guest_id
        )
        .then(() => {
          this.fetchAssignedContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    changeInterviewDate(item) {
      this.interviewDatePickerDialog = true;
      if (item.interviewDate)
        this.interviewPickerDate = this.$options.filters.toDatePickerDate(
          item.interviewDate
        );
      else this.interviewPickerDate = new Date().toISOString().substr(0, 10);
      this.interviewDatePickerItem = item;
    },

    changeInterviewTime(item) {
      this.interviewTimePickerDialog = true;
      if (item.interviewTime) this.interviewTime = item.interviewTime;
      else this.interviewTime = "00:00";
      this.interviewTimePickerItem = item;
    },

    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        editorial
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    outcomesResume(item) {
      this.$refs.outcomesResumeDialog.setItem(this.def_outcomes, item.id);
      this.outcomesResumeDialog = true;
    },

    detachAssignment(item) {
      this.$refs.msgBox
        .show(
          this.$t("editorial-detach-warning"),
          this.$t("editorial-detach-title")
        )
        .then(() => {
          editorial
            .detachAssignment(item.assignment_id)
            .then(() => {
              this.fetchAssignedContacts();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    onOperatorChoosen(payload) {
      editorial
        .reassignAssignment(payload.item, payload.selection)
        .then(() => {
          this.$refs.chooseEditorialOperator.closeDialog();
          this.fetchAssignedContacts();
        })
        .catch(err => {
          this.$refs.chooseEditorialOperator.closeDialog();
          console.log(err);
        });
    },

    missingContact(item) {
      editorial
        .missingContact(item.id)
        .then(() => {
          this.fetchAssignedContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    recoverContact(item) {
      editorial
        .recoverContact(item.id)
        .then(() => {
          this.fetchAssignedContacts();
        })
        .catch(err => {
          console.log(err);
        });
    },

    reattachAssignment(item) {
      this.$refs.chooseEditorialOperator.openModal(item.assignment_id);
    },

    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        editorial
          .fetchOutcomes()
          .then(result => {
            this.fetchingOutcomes = false;
            this.def_outcomes = result.outcomes;
            this.def_outcomes_by_id = {};
            for (let x in this.def_outcomes) {
              this.def_outcomes[x].name = this.$t(this.def_outcomes[x].name);
              this.def_outcomes_by_id[
                this.def_outcomes[x].id
              ] = this.def_outcomes[x];
              if (this.def_outcomes[x].sub) {
                for (let y in this.def_outcomes[x].sub) {
                  this.def_outcomes[x].sub[y].name = this.$t(
                    this.def_outcomes[x].sub[y].name
                  );
                }
              }
            }

            resolve();
          })
          .catch(err => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    fetchAssignedContacts() {
      this.fetchingContacts = true;
      this.contacts = [];
      this.totalContacts = 0;
      this.statistics = {
        dailyContacts: "...",
        pastContacts: "...",
        pendingContacts: "...",

        dailyCalls: "...",
        pastCalls: "...",

        queueProcessed: "...",
        queueAvailable: "...",
        totalVoip : null
      };

      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        editorial
          .fetchAssignedContacts(ctx)
          .then(result => {
            this.contacts = result.items;
            this.totalContacts = result.totalCount;
            this.statistics = result.statistics;
            this.fetchingContacts = false;
            resolve();
          })

          .catch(err => {
            this.fetchingContacts = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    }
  }
};
</script>

<style>
.date-invisible {
  display: none;
}
</style>
