<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account-plus-outline</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("editorial-add-override-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text v-if="!fetchingCategories && !fetchingLists">
              <v-row dense>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :label="$t('editorial-add-blacklist-list')"
                    v-model="list.list_id"
                    :items="lists"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :label="$t('editorial-add-blacklist-categories')"
                    v-model="list.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="mt-2">
                  <v-btn
                    width="120"
                    v-on:click="confirm()"
                    color="cyan"
                    :disabled="fetchingCategories || fetchingLists || !this.list.category_id "
                    >{{ $t("gbl-ok") }}</v-btn
                  >

                  <v-btn width="120" v-on:click="cancel()" color="gray">{{
                    $t("gbl-cancel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorialManager from "../../apis/editorial";

export default {
  data() {
    return {
      fetchingCategories: false,
      fetchingLists: false,
      lists: [],
      categories: [],
      list: {
        list_id: null,
        category_id: null
      }
    };
  },

  props: ["value"],

  mounted() {
    this.fetchLists();
  },

  methods: {
    fetchCategories() {
      this.fetchingCategories = true;
      return new Promise((resolve, reject) => {
        editorialManager
          .fetchCategories(86)
          .then(result => {
            this.categories = [
              { id: null, name: this.$t("gbl-none-female") },
              ...result
            ];
            this.fetchingCategories = false;
            resolve(result);
          })
          .catch(err => {
            this.fetchingCategories = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchLists() {
      this.fetchingLists = true;
      return new Promise((resolve, reject) => {
        editorialManager
          .fetchListsNames({ filters: { archived: false } })
          .then(result => {
            this.lists = [
              { id: null, name: this.$t("gbl-all-female") },
              ...result
            ];

            this.fetchingLists = false;
            resolve(result);
          })
          .catch(err => {
            this.fetchingLists = false;
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: function() {
      this.$emit("blacklistConfigured", this.list);
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    setItem(list) {
      this.list.category_id = list.category_id;
      this.list.list_id = list.list_id;
      this.fetchCategories();
      this.fetchLists();
    }
  }
};
</script>
