<template>
  <div>
    <messageBox ref="msgBox" />

    <BlacklistEditor
      ref="addBlackListDialog"
      v-model="addBlackListDialog"
      @blacklistConfigured="onBlackListConfigured"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-account-tie-voice-off-outline</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("editorial-blacklists-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("editorial-blacklists-title")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$store.state.hasRight('164')"
              icon
              @click="addBlacklist()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="blackLists"
              :options.sync="options"
              :server-items-length="totalBlackLists"
              :loading="fetchingBlackLists"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="red"
                      v-on="on"
                      @click.stop="deleteBlacklist(item)"
                      ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("editorial-lists-archive") }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.category_name="{ item }">
                {{
                  item.category_name ? item.category_name : $t("gbl-none-female")
                }}
              </template>

              <template v-slot:item.list_name="{ item }">
                {{
                  item.list_name ? item.list_name : $t("gbl-all-female")
                }}
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import editorial from "../apis/editorial";
import messageBox from "../components/MessageBox";
import BlacklistEditor from "../components/editorial/BlacklistEditor";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      perPage: staticData.defaultPerPage,
      blackLists: [],
      totalBlackLists: 0,
      fetchingBlackLists: false,

      options: {
        sortBy: ["b1.id"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1
      },

      headers: [],
      addBlackListDialog: false
    };
  },

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("editorial-blacklists-tbl-list"),
        align: "left",
        sortable: true,
        value: "list_name"
      },

      {
        text: this.$t("editorial-blacklists-tbl-category"),
        align: "center",
        sortable: true,
        value: "category_name"
      },

      { text: "", align: "right", sortable: false, value: "actions" }
    ];
  },

  components: {
    messageBox,
    BlacklistEditor
  },

  watch: {
    options: {
      handler() {
        this.fetchBlackList();
      },
      deep: true
    }
  },

  computed: {},

  methods: {
    onBlackListConfigured: function(e) {
      this.addBlackListDialog = false;
      editorial
        .addBlackList(e)
        .then(() => {
          this.fetchBlackList();
        })
        .catch(err => {
          console.log(err);
        });
    },

    deleteBlacklist: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("editorial-blacklists-action-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          editorial
            .removeBlackList(item.id)
            .then(() => {
              this.fetchBlackList();
            })

            .catch(err => {
              if (err) console.log(err);
            });
        })
        .catch(() => {});
    },

    addBlacklist() {
      this.$refs.addBlackListDialog.setItem({
        category_id: null,
        list_id: null
      });
      this.addBlackListDialog = true;
    },

    fetchBlackList() {
      this.fetchingBlackLists = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        editorial
          .fetchBlackLists(ctx)
          .then(result => {
            this.blackLists = result.items;
            this.totalBlackLists = result.count;
            this.fetchingBlackLists = false;

            resolve();
          })

          .catch(err => {
            this.fetchingBlackLists = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    }
  }
};
</script>
