<template>
  <div>
    <messageBox ref="msgBox" />
    <AudioPlayer
      ref="audioPlayerDialog"
      :results="groupsResults"
      v-model="audioPlayerDialog"
    />

    <v-card class="ma-4">
      <v-toolbar color="red" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-phone</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("callrec-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row class="text-center">
        <v-col>
          <v-progress-circular
            v-if="fetchingFilters"
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <div style="margin:15px">
        <v-row v-if="!fetchingFilters" justify="center">
          <v-col class="text-center">
            <v-text-field
              dense
              outlined
              :label="$t('callrec-filter-name')"
              :disabled="loading"
              :value="filter"
              @change="(v) => (filter = v)"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('callrec-filter-operator')"
              return-object
              :disabled="loading"
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select
              dense
              v-model="filters.group"
              outlined
              item-text="text"
              item-value="value"
              :items="groups"
              :label="$t('callrec-filter-group')"
              :disabled="loading"
              hide-details
            />
          </v-col>
          <v-col>
            <v-select
              dense
              v-model="filters.result"
              outlined
              item-text="text"
              item-value="value"
              :items="results"
              :label="$t('callrec-filter-outcome')"
              :disabled="loading"
              hide-details
            />
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('callrec-filter-datestart')"
                  :disabled="loading"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('callrec-filter-dateend')"
                  :disabled="loading"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
      <div class="vertical-spacer" />
      <v-data-table
        v-if="!fetchingFilters"
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="calls"
        :options.sync="options"
        :server-items-length="totalCalls"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip v-if="$store.state.hasRight('46')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="success"
                @click.stop="downloadCall(item)"
                v-on="on"
                ><v-icon>mdi-arrow-down</v-icon></v-btn
              ></template
            ><span>{{ $t("callrec-action-download") }}</span></v-tooltip
          >

          <v-tooltip v-if="$store.state.hasRight('24')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="purple"
                @click.stop="playCall(item)"
                v-on="on"
                ><v-icon>mdi-phone</v-icon></v-btn
              ></template
            ><span>{{ $t("callrec-action-listen") }}</span></v-tooltip
          >

          <v-tooltip v-if="$store.state.hasRight('44')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="error"
                @click.stop="deleteItem(item)"
                v-on="on"
                ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
              ></template
            ><span>{{ $t("callrec-action-delete") }}</span></v-tooltip
          >
        </template>

        <template v-slot:item.timestamp="{ item }">
          {{ item.timestamp | toLocaleDateTime }}
        </template>

        <template v-slot:item.duration="{ item }">
          {{ formatDuration(item) }}
        </template>

        <template v-slot:item.phone="{ item }">
          {{
            item.phone.startsWith("00")
              ? "+" + item.phone.substr(2, item.phone.length - 2)
              : item.phone
          }}
        </template>

        <template v-slot:item.call_result="{ item }">
          {{ resultToText(item) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import callsManager from "../apis/recorder";
import config from "../config"
import localStorageService from "../services/localStorageService";

import staticData from "../apis/static";
import AvatarServices from "../services/avatars";
import messageBox from "../components/MessageBox";
import AudioPlayer from "../components/recorder/AudioPlayer";
import axios from "axios";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      results: [],
      groups: [],
      calls: [],

      searchOperatorInput: null,
      operator: null,
      operators: [],
      isLoadingOperator: false,

      loading: false,
      totalCalls: 0,
      perPage: staticData.defaultPerPage,
      options: {
        sortBy: ["timestamp"],
        sortDesc: [true],
        itemsPerPage: 50,
        currentPage: 1,
      },
      headers: [],
      filter: null,
      filters: {
        operator: null,
        group: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        result: null,
      },

      fetchingData: false,
      fetchingFilters: true,

      groupsResults: null,
      audioPlayerDialog: false,
    };
  },

  components: {
    messageBox,
    AudioPlayer,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("callrec-tbl-operator"),
        align: "left",
        sortable: true,
        value: "nome",
      },
      {
        text: this.$t("callrec-tbl-code"),
        align: "left",
        sortable: true,
        value: "operator",
      },
      {
        text: this.$t("callrec-tbl-group"),
        align: "left",
        sortable: true,
        value: "operator_group",
      },
      {
        text: this.$t("callrec-tbl-date"),
        align: "center",
        sortable: true,
        value: "timestamp",
      },
      {
        text: this.$t("callrec-tbl-length"),
        align: "center",
        sortable: true,
        value: "duration",
      },

      {
        text: this.$t("callrec-tbl-number"),
        align: "center",
        sortable: true,
        value: "phone",
      },
      {
        text: this.$t("callrec-tbl-result"),
        align: "center",
        sortable: true,
        value: "call_result",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];

    this.operators = [];
    this.fetchGroupsResults()
      .then((res) => {
        this.groups = [];
        this.results = [];
        this.groupsResults = res;

        for (let index in this.groupsResults) {
          let item = this.groupsResults[index];
          this.groups.push({ text: item.name, value: item.name });
          let array = item.states;
          this.groupsResults[item.name] = {};
          for (let x in array) {
            this.groupsResults[item.name][array[x].id] = array[x].value;
            this.results.push({
              text: item.name + " - " + array[x].value,
              value: array[x].id,
            });
          }
        }

        this.groups = [
          { text: this.$t("gbl-all-male"), value: null },
          ...this.groups,
        ];
        this.$store.state.groups = this.groups;
        this.results = [
          { text: this.$t("gbl-all-male"), value: null },
          ...this.results,
        ];
        this.fetchingFilters = false;
        this.fetchingData = false;
      })
      .catch((err) => {
        console.log(err);
        this.fetchingFilters = false;
      });
  },

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.code : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadCalls();
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadCalls();
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadCalls();
      },

      deep: true,
    },
  },

  methods: {
    reloadCalls: function() {
      this.calls = [];
      this.totalCalls = 0;
      this.fetchCalls()
        .then((data) => {
          this.calls = data.items;
          this.totalCalls = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    formatDuration: function(item) {
      let format = "";
      let seconds = parseInt(Math.floor(item.duration));
      let hours = Math.floor(seconds / 60 / 60);
      if (hours < 1) hours = 0;
      seconds -= 60 * 60 * hours;
      let minutes = Math.floor(seconds / 60);
      seconds -= 60 * minutes;
      if (seconds < 1) seconds = 1;
      if (hours > 0) format += hours + ":";
      if (minutes > 0 && minutes.toString().length < 2) format += "0";
      if (minutes > 0) format += minutes + ":";
      else format += "00:";
      if (seconds.toString().length < 2) format += "0";
      format += seconds;
      return format;
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        callsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchGroupsResults() {
      return new Promise((resolve, reject) => {
        callsManager
          .fetchGroupsResults()
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchCalls() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        callsManager
          .fetchCalls(ctx)
          .then((result) => {
            this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("callrec-delete-confirm"),
          this.$t("callrec-delete-title")
        )
        .then(() => {
          callsManager
            .removeCall(item.id)
            .then(() => {
              this.reloadCalls();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    downloadCall(p) {
      const token = localStorageService.getAccessToken();
      let tokens = p.path.split("/");
      let filename = tokens.pop();
      const url =
        config.apiEndPoint +
        "/calls/download/" +
        p.id +
        "?token=" +
        encodeURIComponent(token);

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    playCall(item) {
      this.$refs.audioPlayerDialog.setCall(item);
      this.audioPlayerDialog = true;
    },

    resultToText(item) {
      if (this.groupsResults[item.operator_group]) {
        if (this.groupsResults[item.operator_group][item.call_result])
          return this.groupsResults[item.operator_group][item.call_result];
      }

      return this.$t("callrec-invalid");
    },
  },
};
</script>
