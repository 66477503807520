<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-target</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("gstoutcome-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form ref="gstoutcome-editor-form">
              <div style="height:10px;" />

              <v-row class="text-center">
                <v-col>
                  <div style="height:10px;" />
                  <v-progress-circular
                    v-if="fetchingMetadata"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </v-col>
              </v-row>

              <ValidationObserver
                v-if="!fetchingMetadata"
                ref="obs"
                v-slot="{ invalid, validated }"
              >
                <v-row>
                  <v-col>
                    <ValidationProvider
                      immediate
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        hide-details
                        :label="$t('gstoutcome-hint-outcome')"
                        outlined
                        dense
                        v-model="guest.esito"
                        prepend-icon="mdi-target"
                        :error-messages="errors"
                        :success="valid"
                        item-text="name"
                        item-value="id"
                        :items="computed_outcomes"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="guest.esito == 2 || guest.esito == 8">
                  <v-col v-if="$store.state.hasRight('120')">
                    <ValidationProvider
                      immediate
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-autocomplete
                        prepend-icon="mdi-microphone"
                        clearable
                        dense
                        v-model="guest_speaker"
                        :items="speakers"
                        :loading="isLoadingSpeakers"
                        :search-input.sync="searchSpeakerInput"
                        cache-items
                        outlined
                        item-text="name"
                        item-value="id"
                        :label="$t('gsteditor-details-operators-speaker')"
                        return-object
                        hide-no-data
                        :error-messages="errors"
                        :success="valid"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="guest.esito == 5">
                  <v-col>
                    <ValidationProvider
                      immediate
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-textarea
                        v-model="guest.notes.motivo_rifiuto"
                        :label="$t('gsteditor-hint-refuse-reason')"
                        outlined
                        rows="4"
                        :error-messages="errors"
                        :success="valid"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="invalid || !validated"
                      v-on:click="confirm()"
                      color="cyan"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="gray">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import guestsManager from "../../apis/guests";
import config from "../../config";

export default {
  data() {
    return {
      guest: null,
      isLoadingSpeakers: false,
      speakers: [],
      searchSpeakerInput: null,
      speaker: null,
      guest_speaker: null,
      fetchingMetadata: false,
      previousOutcome: null
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: ["value", "users", "filtered_users", "vcoutcomes", "livesoutcomes"],

  mounted() {},

  computed: {
    computed_outcomes: {
      get: function() {
        if (this.guest.tipologia == 2) return this.vcoutcomes;
        else return this.livesoutcomes;
      }
    }
  },

  watch: {
    searchSpeakerInput(val) {
      clearTimeout(this._searchSpeakerInput);
      if (val) {
        this._searchSpeakerInput = setTimeout(() => {
          this.isLoadingSpeaker = true;
          this.fetchUsers(val)
            .then(res => {
              this.speakers = res.items;
              this.isLoadingSpeaker = false;
            })
            .catch(() => {
              this.isLoadingSpeaker = false;
            });
        }, config.searchInputsTimeout);
      }
    }
  },

  methods: {
    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      // Normalize contents
      if (this.$store.state.hasRight("120")) {
        if (this.guest_speaker) this.guest.speaker = this.guest_speaker.id;
      }
      if (this.guest.esito != 5) this.guest.notes.motivo_rifiuto = null;

      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity(res => {
        if (!res) return false;
        this.$refs.obs.reset();
        if (this.$store.state.hasRight("120")) {
          this.$emit("outcomeConfigured", {
            item: this.guest,
            forceSpeaker: false,
            previousOutcome: this.previousOutcome
          });
        } else {
          this.$emit("outcomeConfigured", {
            item: this.guest,
            forceSpeaker: true,
            previousOutcome: this.previousOutcome
          });
        }
      });
    },

    resetModal() {
      this.guest_speaker = null;
    },

    applyModalValidation() {
      requestAnimationFrame(() => {
        if (this.$refs.obs) {
          this.$refs.obs.validate();
        }
      });
    },

    fetchMetadata() {
      return new Promise((resolve, reject) => {
        guestsManager
          .fetchMetadata(this.guest.ID)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    setModalGuest(guest) {
      this.resetModal();
      this.previousOutcome = guest.esito;
      this.guest = Object.assign({}, guest);
      if (!this.guest.notes)
        this.guest.notes = { motivo_rifiuto: "", note: "" };
      this.speakers = this.users;
      if (this.guest.speaker > 0)
        this.guest_speaker = this.filtered_users[this.guest.speaker];

      this.fetchingMetadata = true;

      this.fetchMetadata()
        .then(res => {
          this.guest.notes = res.notes;
          this.fetchingMetadata = false;
          this.applyModalValidation();
        })
        .catch(err => {
          console.log(err);
          this.guest.notes = { motivo_rifiuto: "", note: "" };
          this.fetchingMetadata = false;
          this.applyModalValidation();
        });
    }
  }
};
</script>
