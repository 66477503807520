import {
  min,
  max,
  required,
  required_if,
  email,
  confirmed,
  min_value,
  max_value,
  regex
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "./i18n";

extend("required_if", {
  ...required_if,
  message: i18n.t("vee-required")
});

extend("required", {
  ...required,
  message: i18n.t("vee-required")
});

extend("email", {
  ...email,
  message: i18n.t("vee-email")
});

extend("confirmed", {
  ...confirmed,
  message: i18n.t("vee-confirmed")
});

extend("min_value", {
  ...min_value,
  message: i18n.t("vee-min_value")
});

extend("max_value", {
  ...max_value,
  message: i18n.t("vee-max_value")
});

extend("min", {
  ...min,
  message: i18n.t("vee-min")
});

extend("max", {
  ...max,
  message: i18n.t("vee-max")
});

extend("regex", {
  ...regex,
  message: i18n.t("vee-regex")
});
