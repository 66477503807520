import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import Login from "../views/Login";
import MediaAudio from "../views/MediaAudio";
import MediaAudioStreams from "../views/MediaAudioStreams";
import MediaVideo from "../views/MediaVideo";
import Guests from "../views/Guests";
import Schedule from "../views/Schedule";
import Editorial from "../views/Editorial";
import EditorialManage from "../views/EditorialManage";
import EditorialStatistics from "../views/EditorialStatistics";
import EditorialOutcomesStatistics from "../views/EditorialOutcomesStatistics";

import EditorialContacts from "../views/EditorialContacts";
import EditorialBlackLists from "../views/EditorialBlackLists";
import EditorialLists from "../views/EditorialLists";
import EditorialImports from "../views/EditorialImports";
import Recorder from "../views/Recorder";
import VoipRecorder from "../views/VoipRecorder";
import FirstCall from "../views/FirstCall";
import FirstCallListen from "../views/FirstCallListen";
import FirstCallLastAttempt from "../views/FirstCallLastAttempt";
import FirstCallRecovery from "../views/FirstCallRecovery";

import Scraper from "../views/Scraper";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SideBar from "../components/Sidebar";

const UsersManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/UsersManager.vue");
const GroupsManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/GroupsManager.vue");
const ApplicationsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/ApplicationsManager.vue"
  );
const NotificatorManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/NotificationsManager.vue"
  );
const Logs = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Logs.vue");
const CallRecorderGroupsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/CallRecorderGroupsManager.vue"
  );
//const HelperManager = () =>
//  import(/* webpackChunkName: "admin-bundle" */ "../views/HelperManager.vue");

const FirstCallStatistics = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/FirstCallStatistics.vue"
  );

const FirstCallManage = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/FirstCallManage");

const FirstCallOverrides = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/FirstCallOverrides");

const ScraperCloud = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/ScraperCloud.vue");

const Dashboard = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Dashboard.vue");
const Recovery = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Recovery.vue");
const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Profile.vue");
const FileManager = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/FileManager.vue");
const AudioEpisodes = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/AudioEpisodes.vue");
const VideoEditing = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/VideoEditing.vue");
const VideoEditingManager = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/VideoEditingManager.vue"
  );

const Speakers = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Speakers.vue");
const SpeakersStatistics = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/SpeakersStatistics.vue"
  );

const SchedulerTemplates = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/SchedulerTemplates.vue"
  );

const SchedulerCalendar = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/SchedulerCalendar.vue"
  );

const SchedulerDetail = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/SchedulerDetail.vue");

const SchedulerSpeakersShifts = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/SchedulerSpeakersShifts.vue"
  );

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      components: {
        main: Login
      }
    },
    {
      path: "/recovery",
      name: "recovery",
      components: {
        main: Recovery
      }
    },
    {
      path: "/reset",
      name: "reset",
      components: {
        main: Reset
      }
    },

    {
      path: "/",
      name: "dashboard",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Dashboard,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/permissions-users",
      name: "users",
      components: {
        header: Header,
        sideBar: SideBar,
        main: UsersManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/permissions-groups",
      name: "groups",
      components: {
        header: Header,
        sideBar: SideBar,
        main: GroupsManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/permissions-applications",
      name: "applications",
      components: {
        header: Header,
        sideBar: SideBar,
        main: ApplicationsManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true
      }
    },

    {
      path: "/notifications",
      name: "notifications",
      components: {
        header: Header,
        sideBar: SideBar,
        main: NotificatorManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/profile",
      name: "profile",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Profile,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/filemanager",
      name: "filemanager",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FileManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/media/audio",
      name: "media-audio",
      components: {
        header: Header,
        sideBar: SideBar,
        main: MediaAudio,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/media/audio-streamers",
      name: "media-audio-stremers",
      components: {
        header: Header,
        sideBar: SideBar,
        main: MediaAudioStreams,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/media/video",
      name: "media-video",
      components: {
        header: Header,
        sideBar: SideBar,
        main: MediaVideo,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/guests",
      name: "guests",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Guests,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/schedule",
      name: "schedule",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Schedule,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/editorial",
      name: "editorial",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Editorial,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/editorial-manage",
      name: "editorial-manage",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialManage,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/editorial-statistics",
      name: "editorial-statistics",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialStatistics,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/editorial-outcomes-statistics",
      name: "editorial-outcomes-statistics",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialOutcomesStatistics,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/editorial-contacts",
      name: "editorial-contacts",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialContacts,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/editorial-blacklists",
      name: "editorial-blacklists",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialBlackLists,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/editorial-lists",
      name: "editorial-lists",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialLists,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/editorial-imports",
      name: "editorial-imports",
      components: {
        header: Header,
        sideBar: SideBar,
        main: EditorialImports,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/recorder",
      name: "recorder",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Recorder,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/recorder-voip",
      name: "voiprecorder",
      components: {
        header: Header,
        sideBar: SideBar,
        main: VoipRecorder,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/recorderManager",
      name: "recorderManager",
      components: {
        header: Header,
        sideBar: SideBar,
        main: CallRecorderGroupsManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/audioepisodes",
      name: "audioepisodes",
      components: {
        header: Header,
        sideBar: SideBar,
        main: AudioEpisodes,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/videoediting",
      name: "videoediting",
      components: {
        header: Header,
        sideBar: SideBar,
        main: VideoEditing,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/videoediting-manager",
      name: "videoediting-manager",
      components: {
        header: Header,
        sideBar: SideBar,
        main: VideoEditingManager,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/speakers",
      name: "speakers",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Speakers,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/speakers-statistics",
      name: "speakers-statistics",
      components: {
        header: Header,
        sideBar: SideBar,
        main: SpeakersStatistics,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/scheduler-templates",
      name: "scheduler-templates",
      components: {
        header: Header,
        sideBar: SideBar,
        main: SchedulerTemplates,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/scheduler-calendar",
      name: "scheduler-calendar",
      components: {
        header: Header,
        sideBar: SideBar,
        main: SchedulerCalendar,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/scheduler-detail",
      name: "scheduler-detail",
      components: {
        header: Header,
        sideBar: SideBar,
        main: SchedulerDetail,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/scheduler-speakers-shifts",
      name: "scheduler-speakers-shifts",
      components: {
        header: Header,
        sideBar: SideBar,
        main: SchedulerSpeakersShifts,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/firstcall-listen",
      name: "firstcall-listen",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCallListen,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/firstcall",
      name: "firstcall",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCall,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/firstcall-lastattempt",
      name: "firstcall-last-attempt",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCallLastAttempt,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/firstcall-recovery",
      name: "firstcall-recovery",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCallRecovery,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/firstcall-manage",
      name: "firstcall-manage",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCallManage,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/firstcall-overrides",
      name: "firstcall-overrides",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCallOverrides,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/firstcall-statistics",
      name: "firstcall-statistics",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FirstCallStatistics,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/scraper",
      name: "scraper",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Scraper,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/scraper-cloud",
      name: "ScraperCloud",
      components: {
        header: Header,
        sideBar: SideBar,
        main: ScraperCloud,
        footer: Footer
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/logs",
      name: "logs",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Logs,
        footer: Footer
      },
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
