<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1024" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <MessageBox ref="msgBox" />
    <ChooseEditorialOperator
      @onOperatorChoosen="onOperatorChoosen"
      ref="chooseEditorialOperator"
    />

    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-target</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("editorial-outcomes-resume-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div style="height:10px" />
            <v-progress-circular
              v-if="fetchingOutcomes"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>

            <v-list three-line>
              <template v-for="item in outcomesItems">
                <div :key="item.id">
                  <v-divider inset></v-divider>
                  <v-list-item>
                    <div v-if="checkGravatar(item)" class="profile-container">
                      <div v-if="!item.gravatar" class="profile-circle">
                        <div class="profile-text">
                          {{ getProfileLetters(item) }}
                        </div>
                      </div>
                      <div
                        v-if="item.gravatar"
                        class="profile-gravatar"
                        v-html="buildGravatar(item)"
                      ></div>
                    </div>

                    <v-list-item-content>
                      <v-container>
                        <v-row>
                          <v-col cols="8">
                            <v-list-item-title class="text-left"
                              ><span style="font-weight:600">{{
                                getOutcome(item)
                              }}</span>
                              - {{ getOutcomeTime(item) }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              style="font-weight:600"
                              class="text-left"
                              >{{ getSubOutcome(item) }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle class="text-left"
                              ><b>{{ item.ope_code }}</b> -
                              {{ item.first_name }}
                              {{ item.last_name }}
                              <v-tooltip v-if="item.notes" bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-icon color="cyan" v-on="on"
                                    >mdi-information-outline</v-icon
                                  > </template
                                ><span>{{ item.notes }}</span></v-tooltip
                              ></v-list-item-subtitle
                            >
                            <span
                              style="color:#999; display: block; text-align: left;font-weight:600; font-size:14px; align:left"
                              >{{ item.notes }}</span
                            >
                          </v-col>

                          <v-col class="text-right" style="min-width:80px">
                            <v-tooltip
                              v-if="$store.state.hasRight('169')"
                              bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  x-small
                                  text
                                  icon
                                  color="primary"
                                  @click.stop="reassignOutcome(item)"
                                  v-on="on"
                                  ><v-icon>mdi-account-edit</v-icon></v-btn
                                ></template
                              ><span>{{
                                $t("outcome-resume-reassign-tooltip")
                              }}</span></v-tooltip
                            >

                            <v-tooltip
                              v-if="$store.state.hasRight('167')"
                              bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  x-small
                                  text
                                  icon
                                  color="error"
                                  @click.stop="deleteOutcome(item)"
                                  v-on="on"
                                  ><v-icon
                                    >mdi-delete-circle-outline</v-icon
                                  ></v-btn
                                ></template
                              ><span>{{
                                $t("outcome-resume-delete-tooltip")
                              }}</span></v-tooltip
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
            </v-list>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import editorial from "../../apis/editorial";
import AvatarServices from "../../services/avatars";
import MessageBox from "../../components/MessageBox.vue";
import ChooseEditorialOperator from "../../components/backend/ChooseEditorialOperator.vue";
export default {
  data() {
    return {
      fetchingOutcomes: false,
      outcomesItems: [],
      outcomesById: {},
      suboutcomesById: {},
      itemId: null,
      editOutcomes: [],
      filteredOutcomes: [],
      outcomes: []
    };
  },

  components: {
    MessageBox,
    ChooseEditorialOperator
  },

  props: ["value"],

  mounted() {},

  watch: {},

  methods: {
    onOutcomeConfigured(item) {
      editorial
        .changeOutcome(item.item.ID, item.item.outcome)
        .then(() => {
          this.outcomeDialog = false;
          this.fetchOutcomes();
        })
        .catch(err => {
          this.outcomeDialog = false;
          console.log(err);
        });
    },

    onOperatorChoosen(payload) {
      editorial
        .reassignOutcome(payload.item, payload.selection)
        .then(() => {
          this.$refs.chooseEditorialOperator.closeDialog();
          this.fetchOutcomes();
        })
        .catch(err => {
          this.$refs.chooseEditorialOperator.closeDialog();
          console.log(err);
        });
    },

    reassignOutcome(item) {
      this.$refs.msgBox
        .show(
          this.$t("outcome-resume-reassign"),
          this.$t("outcome-resume-reassign-title")
        )
        .then(() => {
          this.$refs.chooseEditorialOperator.openModal(item.id);
        })
        .catch(() => {});
    },

    deleteOutcome(item) {
      this.$refs.msgBox
        .show(
          this.$t("outcome-resume-delete"),
          this.$t("outcome-resume-delete-title")
        )
        .then(() => {
          editorial
            .deleteOutcome(item.id)
            .then(() => {
              this.fetchOutcomes();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    getOutcome(item) {
      let outcome = this.outcomesById[item.outcome_id];
      return outcome ? outcome : "";
    },

    getSubOutcome(item) {
      let outcome = this.suboutcomesById[item.suboutcome_id];
      return outcome ? outcome : "";
    },

    getOutcomeTime(item) {
      let time = this.$options.filters.toLocaleDateTime(item.outcome_datetime);
      return time;
    },

    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    setItem(outcomes, id) {
      this.outcomes = outcomes;
      this.itemId = id;
      this.outcomesById = {};
      this.suboutcomesById = {};
      for (let n = 0; n < this.outcomes.length; n++) {
        let outcome = this.outcomes[n];
        this.outcomesById[outcome.id] = outcome.name;
        for (let x in outcome.sub) {
          let suboutcome = outcome.sub[x];
          this.suboutcomesById[suboutcome.id] = suboutcome.name;
        }
      }
      this.fetchOutcomes();
    },

    fetchOutcomes() {
      this.outcomesItems = [];
      this.fetchingOutcomes = true;
      editorial
        .fetchOutcomes(true)
        .then(res => {
          this.editOutcomes = res.outcomes;
          editorial
            .fetchContactOutcome(this.itemId)
            .then(res => {
              this.outcomesItems = res.outcomes;
              this.fetchingOutcomes = false;
              if (this.outcomesItems.length < 1) this.closeDialog();
            })
            .catch(err => {
              console.log(err);
              this.fetchingOutcomes = false;
            });
        })
        .catch(err => {
          console.log(err);
          this.fetchingOutcomes = false;
        });
    }
  }
};
</script>

<style scoped>
.profile-text {
  line-height: 48px;
}
</style>
